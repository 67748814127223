// 10級パッケージの7級
type ckptTypeForGrade_7_10kyu =
  | 'しっかり体を倒して走る'
  | '足を前に前に'
  | '腕ふり'
  | '前あしに体重をのせてかまえる'
  | 'いい姿勢で体をたおしてかまえる';

export const ckptForGrade_7_10kyu: ckptTypeForGrade_7_10kyu[] = [
  'しっかり体を倒して走る',
  '足を前に前に',
  '腕ふり',
  '前あしに体重をのせてかまえる',
  'いい姿勢で体をたおしてかまえる',
];

export const requirement_7_10kyu: ckptTypeForGrade_7_10kyu[] = [
  '前あしに体重をのせてかまえる',
  'いい姿勢で体をたおしてかまえる',
  'しっかり体を倒して走る',
];

export type ckptDetails_7_10 =
  | '構えで前脚に体重がのっている'
  | '構えで上体角度が45度程度'
  | '構えで横から見て上体と後ろ足がまっすぐ'
  | '走りで6歩以上体を倒せている'
  | '足を前に前に持ってくる'
  | 'うでを前に前に振る';

type allCheckpoints_7_10kyu_type = {
  [key in ckptTypeForGrade_7_10kyu]: ckptDetails_7_10[];
};

export const allCheckPoints_7_10kyu: allCheckpoints_7_10kyu_type = {
  前あしに体重をのせてかまえる: ['構えで前脚に体重がのっている'],
  いい姿勢で体をたおしてかまえる: [
    '構えで上体角度が45度程度',
    '構えで横から見て上体と後ろ足がまっすぐ',
  ],
  しっかり体を倒して走る: ['走りで6歩以上体を倒せている'],
  足を前に前に: ['足を前に前に持ってくる'],
  腕ふり: ['うでを前に前に振る'],
};

export const borderLine_7_10kyu: allCheckpoints_7_10kyu_type = {
  前あしに体重をのせてかまえる: ['構えで前脚に体重がのっている'],
  いい姿勢で体をたおしてかまえる: ['構えで上体角度が45度程度'],
  しっかり体を倒して走る: ['走りで6歩以上体を倒せている'],
  足を前に前に: ['足を前に前に持ってくる'],
  腕ふり: ['うでを前に前に振る'],
};
