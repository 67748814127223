import { RunnerType } from 'adapters/repositories/typeDefinition';
import { onboardType } from '../components/GuidanceTools/type';

// beforeLetterだけはrunnerの状態で決まる
export const decideOnboardState = (currentRunner: RunnerType) => {
  const onboardPhase =
    (localStorage.getItem(String(currentRunner.id)) as onboardType) ||
    ('beforeMsg' as onboardType);

  console.log(currentRunner);

  // beforeLetterだけはrunnerの状態で決まる
  if (
    currentRunner.latest_exam_video.length > 0 &&
    !currentRunner.latest_exam_video[0].checked_by_boss
  ) {
    return 'beforeLetter';
  }

  if (
    onboardPhase === 'beforeLetter' &&
    currentRunner.latest_exam_video.length > 0 &&
    currentRunner.latest_exam_video[0].checked_by_boss
  ) {
    return 'beforeRead';
  }

  return onboardPhase;
};
