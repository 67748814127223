import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Modal, Fade, Backdrop } from '@material-ui/core';

const useStylesForModal = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#EEEEEE',
    border: '3px solid #87cefa',
    borderRadius: '10%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
}));

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onVideoClick: () => void;
  onManualClick: () => void;
};

export const UploadModal: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  onVideoClick,
  onManualClick,
}) => {
  const classes = useStylesForModal();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <div className={classes.paper}>
          <h3 id="transition-modal-title">今日の練習を記録しよう！</h3>
          <div id="transition-modal-description">
            <div>
              <div>
                <Button
                  onClick={onVideoClick}
                  variant="outlined"
                  color="secondary"
                  style={{
                    width: '150px',
                  }}
                >
                  動画と一緒に記録
                </Button>
              </div>
              <div style={{ marginTop: '10px' }}>
                <Button
                  onClick={onManualClick}
                  variant="outlined"
                  color="primary"
                  style={{ width: '150px' }}
                >
                  動画なしで記録
                </Button>
              </div>

              <div style={{ marginTop: '30px' }}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  閉じる
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
