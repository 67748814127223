import React, { FC, memo } from 'react';
import classes from './practiceCarousel.module.css';
import joy_girl from 'static/materials/joy_girl.png';
import howToSend from 'static/materials/howToSend.png';

export const EighthView: FC = memo(() => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '20%' }}>
          <img src={joy_girl} alt="" width="60%"></img>
        </div>
        <div style={{ width: '80%' }}>
          <div className={classes.redBorder}>ステップ3. 動画を送信！</div>
        </div>
      </div>
      <div style={{ marginTop: '16px' }}>
        <img src={howToSend} alt="" width="82%"></img>
      </div>
    </>
  );
});
