import React, { useState } from 'react';
import { ParttimeLoginPage } from './components/ParttimeLoginPage';
import { ScoringComponent } from './components/ScoringPage';

type parttimeType = {
  readonly name: string;
  readonly id: string;
  readonly password: string;
};

export const ScoringPageForParttime = () => {
  const [parttime, setParttime] = useState<parttimeType>({
    name: '',
    id: '',
    password: '',
  });

  const partTimeList = [
    {
      name: 'parttime0',
      id: 'a68b9c60-a042-4308-8540-481e28dab4ad',
      password: 'run1daten',
    },
    {
      name: 'partTime1',
      id: '89eab1c2-4343-4828-870d-3cf60d0526ab',
      password: 'run1daten1',
    },
  ];

  if (!parttime.id) {
    return (
      <>
        <ParttimeLoginPage
          partTimeList={partTimeList}
          setParttime={setParttime}
        />
      </>
    );
  }
  return <ScoringComponent parttime={parttime} />;
};
