import {
  gradeGoalDict,
  ScoreEditType, //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { RunnerType } from 'adapters/repositories/typeDefinition';

export function crtComment6th(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  const setence1 = crt_line1(score, yobina);
  const sentence2 = crt_line2(runnerInfo, score, yobina);
  const sentence3 = crt_line3(score, yobina);
  return (
    setence1 +
    '<<paragraph>>' +
    sentence2 +
    '<<paragraph>>' +
    sentence3 +
    '<<paragraph>>'
  );
}

function crt_line1(score: ScoreEditType, yobina: string) {
  const head1 = yobina + '、練習が継続できていて素晴らしいですね！';
  const head2 = `今回の動画は6級の目標である「${gradeGoalDict['6']}」`;
  let evaluation =
    score.eval === '◯合格'
      ? 'が大変良くできています！5級昇級おめでとうございます！'
      : 'に少しだけ課題があります。';
  return head1 + head2 + evaluation;
}

function crt_line2(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  console.log(score);
  return commentLogic(score, yobina);
}

const commentLogic = (score: ScoreEditType, yobina: string) => {
  if (score['前後傾'] === '×:前傾しすぎ') {
    return `${yobina}の一番の伸びしろポイントとして「体を少し前に倒しすぎている」ことがあります。体を前に倒すことは大事ですが、「こぶし半個分」というのを意識して、もう少しだけ体を起こすと、より走りやすくなると思います！目安として15m先の地面を見ながら走ってみましょう！これが出来ると足も前に返ってきやすくなりますよ！`;
  }

  if (score['腰が落ちていない'] === '×:落ちている（膝のつぶれ）') {
    return `まず${yobina}のよかった点としては、「腰を折らずに」走ることが出来ていますね！そんな${yobina}の伸びしろポイントとして「少し腰の位置が下がってしまっている」ことがあります。走り出す前の足踏みの時から走っている間まで、「キーホルダーになった気分で頭のてっぺんを上から紐でつられている」ようなイメージを持ってみて下さい！いい姿勢から背伸びした腰やお尻が高ーい位置のままポンポン走れるようになりますよ！`;
  }

  if (score['後ろに蹴っていない'] === '×:蹴ってしまっている') {
    return `まず${yobina}のよかった点としては、「腰を折らずに背の高いまま」走ることが出来ていますね！${yobina}の伸びしろポイントとしては「後ろに地面を蹴って走っている」ことがあります。速く走りたいと思うとつい地面を後ろに蹴ってしまいがちですが、それをしてしまうと足が前で回らなくなってしまいます！まずは「砂の公園で後ろに砂が飛ばないように走る」イメージで自転車ジョグや自転車ダッシュを復習してみましょう！慣れてきたら自転車をこぐときの様に「自然と足が前にかえってくる」ようになりますよ！`;
  }

  if (score['足の上がり'] === '×:足が上がっていない') {
    return `まず${yobina}のよかった点としては、「腰を折らずに背の高いまま」走ることが出来ていますね！${yobina}の伸びしろポイントとして「より足が前でおおきく動く」とよいです！そのために、「地面を下に踏む」感覚を覚えましょう！自転車を立ちこぎするとき、ペダルを「下に踏んで」進みますよね？走るときも同じです！まずは「足があがった姿勢（ベースポジションのような姿勢）を空中で一瞬維持する（足が上がっていないとペダルを踏めませんよね？）」ことを意識して、慣れてきたら「上から空き缶を潰すように地面を踏む」ことを意識しましょう！一気に楽に体が前に進むようになりますよ！練習としてはベースポジションウォークや123ハイニーを「下に踏む意識で」やってみましょう！いい足音（高くて短い音）がなるか、もチェックポイントです！`;
  }

  if (score['前後傾'] === '×:後傾しすぎ') {
    if (score['腕が地面と平行'] === '×:出来ていない') {
      return `まず${yobina}のよかった点としては、しっかり足が前に返ってきていますね！そんな${yobina}の伸びしろポイントとしては「腕を前にまっすぐ出す」ことです！目の前にある自転車のハンドルを握るイメージで走りましょう！なるべく地面と平行を維持して上や下を向いたり上下に揺れないよう意識しましょう！それができると自然とこぶし半個分前に倒れた姿勢がとれますし、足も前で回しやすくなりますよ！`;
    }

    return `まず${yobina}のよかった点としては、しっかり足が前に返ってきていますね！${yobina}の伸びしろポイントとして「上半身が少し後ろに傾いている」ことがあります。足を頑張ってあげようと思うと、どうしても上半身が後ろにいってしまいますが、10級で習った「こぶし半個分だけ前に倒す」を意識しましょう！目安として15m先の地面を見ながら走ってみましょう！あるいは手を前にグーッと伸ばしてみましょう！体を少し前に倒せるようになると、まさに自転車の立ち漕ぎみたいに一気に前にスイスイ進むようになりますよ！`;
  }

  if (score['下に踏む'] === '×:体の遠くに接地') {
    return `まず${yobina}のよかった点として、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！姿勢もばっちりです！そんな${yobina}に上級者向けの伸びしろポイントをお伝えすると「足を体の遠くについてしまっている」ことがあります。足を腰から遠く前に着いてしまうと1歩1歩ブレーキがかかってしまいます。自転車を立ちこぎするとき、ペダルは前でなく「下に」踏んで進みますよね？走るときも同じです！「上から空き缶を潰すように地面を踏む」ことを意識しましょう！足が体の近くにつけるようになり、楽に体が前に進むようになりますよ！練習としてはベースポジションウォークや123ハイニーを「下に踏む意識で」やってみましょう！「いい足音（高くて短い音）がなるか」もチェックポイントです！これが出来ると「母趾球（ぼしきゅう）」あたりから地面につくのもやりやすくなりますよ！`;
  }

  if (score['接地様式'] === '×:かかとからついている') {
    return `まず${yobina}のよかった点としては、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！そんな${yobina}に上級者向けの伸びしろポイントをお伝えすると「足をかかとからついてしまっている」ことがあります。トランポリンジャンプやその場足踏みでもかかとからつくとポンポンしづらいですよね？目安として「母趾球（ぼしきゅう）」あたりから地面につけるとよいです！ また足を腰から遠く前に着いてしまうとかかとから着きやすくなってしまっています。自転車を立ちこぎするとき、ペダルは前でなく「下に」踏んで進みますよね？走るときも同じです！足を「真下にポンポンおとして走る」ことを意識しましょう！足が体の近くにつけるようになり、楽に体が前に進むようになりますよ！`;
  }

  if (score['接地様式'] === '×:つま先だけでついている') {
    return `まず${yobina}のよかった点としては、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！${yobina}に上級者向けの伸びしろポイントをお伝えすると「足をつま先からつきすぎている」ことがあります。トランポリンジャンプやその場足踏みでもつま先からつきすぎるとポンポンしづらいですよね？目安として「母趾球（ぼしきゅう）」あたりから地面につけるとよいです！またこれに関してはベースポジションウォークの時のように「つま先をあげる」「上から空き缶を潰すように地面を踏む」というのをできるようになると一気にうまくなる子が多いです！練習としてはベースポジションウォークや3歩ハイニーを「下に踏む意識で」やってみましょう！いい足音（高くて短い音）がなるか、もチェックポイントです！`;
  }

  if (score['スピードが出せている'] === '×:遅すぎる') {
    return `${yobina}、もうフォームはばっちりですね！よく練習を頑張ってくれたのが伝わります！「本気で走ってもいいフォームで走れるか！？」にトライしていただければと思います！①保護者様が横で手拍子をしてリズムをとる（初めは1秒に4歩弱くらいのリズムで、なれてきたら1秒に4.5歩くらいまで行けるとすごいです！）②${yobina}は手拍子に接地を合わせてポンポン走るというのにトライしてみて下さい！ポイントは「上がっている足を上からポンポン足を落とす」ことです！「上から空き缶を潰すように地面を踏む」ようなイメージで走ってみましょう！`;
  }

  return 'これはもう文句なしですね、すばらしいです！';
};

function crt_line3(score: ScoreEditType, yobina: string) {
  let evaluation =
    score.eval === '◯合格'
      ? `5級でも足の動かし方を練習していきます！そして、5級の昇級テストは今までで最も難しい練習になります。少し大変かもしれませんがしっかり練習すれば${yobina}ならきっと合格できると思います！ぜひこの調子で頑張りましょう！`
      : '####いい具合になぐさめる####';
  return evaluation;
}
