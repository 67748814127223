import React, { FC, useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import TimerIcon from '@mui/icons-material/Timer';

import classes from './stopwatch.module.css';
import loadingGif from 'static/running-101-gif.gif';
import goal from 'static/goal_posture.png';
import start from 'static/start_posture.png';
import apology from 'static/apology.png';

type Props = {
  estTime: number;
  id: string;
};
export const RunnerAnimation: FC<Props> = ({ estTime, id }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [currentPercent, setCurrentPercent] = React.useState<number>(0);
  let timeShown = (estTime * currentPercent) / 80;

  useEffect(() => {
    if (isRunning && currentPercent < 80) {
      setTimeout(() => setCurrentPercent(currentPercent + 1), 16);
      return;
    }
    if (isRunning && currentPercent >= 80) {
      setIsRunning(false);
    }
  }, [currentPercent, isRunning]);

  useEffect(() => {
    setCurrentPercent(0);
  }, [id]);

  const screenWidth = window.parent.screen.width;

  const handleAnimationClick = () => {
    setIsRunning(true);
    setCurrentPercent(0);
  };

  const seisu = Math.floor(timeShown);
  const shosu = Math.floor((timeShown - seisu) * 100);

  if (estTime < 0) {
    return (
      <div>
        <img alt="" src={apology} width="80%"></img>
      </div>
    );
  }

  return (
    <>
      <div style={{ marginTop: '10px', marginBottom: '20px' }}>
        {isRunning && (
          <Button disabled variant="contained">
            けっかをみる <TimerIcon fontSize="large" />
          </Button>
        )}
        {(!isRunning && currentPercent === 0 && (
          <Button
            onClick={handleAnimationClick}
            variant="outlined"
            size="medium"
            style={{
              backgroundColor: '#ff69b4',
              color: 'white',
              fontSize: '12pt',
              fontWeight: 'bold',
            }}
          >
            &nbsp;けっかをみる <TimerIcon fontSize="large" />
          </Button>
        )) ||
          (!isRunning && (
            <Button
              onClick={handleAnimationClick}
              variant="contained"
              color="primary"
            >
              もう一回見る！
            </Button>
          ))}
      </div>
      <div className={classes.watchInner}>
        <div style={{ fontSize: '40pt', color: 'white' }}>{seisu}</div>
        <div style={{ fontSize: '16pt', color: 'white' }}>&nbsp;秒&nbsp;</div>
        <div style={{ fontSize: '40pt', color: 'white' }}>
          {shosu < 10 ? `0${shosu}` : shosu}
        </div>
      </div>
      <div style={{ textAlign: 'left', marginTop: '10px' }}>
        <div>
          {isRunning ? (
            <div style={{ paddingLeft: `${currentPercent}vw` }}>
              <img
                alt=""
                src={loadingGif}
                width={`${screenWidth * 0.1}px`}
              ></img>
            </div>
          ) : (
            <>
              {(currentPercent === 0 && (
                <img alt="" src={start} width="10%"></img>
              )) || (
                <div style={{ paddingLeft: `80vw` }}>
                  <img alt="" src={goal} width={`${screenWidth * 0.1}px`}></img>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
