import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { postLinkVideos } from 'v1apiRequest';
import {
  postDjoserRegister,
  sendRegisterInfo,
} from 'features/auth/api/register';
import { handleRegisterError, networkError } from './repository';
import {
  checkIsFormComplete,
  crtValidateMessage,
  ValidationMsgType,
} from './ErrorCheckLogic';
import { AccountInfoType } from 'features/auth/types';
import { useWindowSize } from 'hooks/useWindowSize';
import { PCRegisterView } from './PCRegisterView';
import { getVideoForShareAdapter } from 'adapters/getVideosAdapter';
import { SmartPhoneRegisterView } from './SmartPhoneRegisterView';

type infoKeys =
  | 'userName'
  | 'email'
  | 'password'
  | 'confirmPassword'
  | 'gender'
  | 'height'
  | 'showPassword';

type inputEvent = React.ChangeEvent<HTMLInputElement>;
type handleInputChange = (keyName: infoKeys) => (e: inputEvent) => void;
type heightChangeFunc = (
  event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
) => void;

// FIXME : 流石に再利用しよう。。

interface RouterParams {
  videoId: string;
}

export const PrefilledRegisterPage = () => {
  const { videoId } = useParams<RouterParams>();
  const size = useWindowSize();
  const isSmartPhoneView = size[0] < 500 || size[1] / size[0] > 1.5;
  const navigate = useNavigate();
  const [accountInfo, setAccountInfo] = useState<AccountInfoType>({
    userName: '',
    email: '',
    password: '',
    confirmPassword: 'con',
    gender: '',
    height: 140,
    showPassword: false,
    birthYear: '',
    birthMonth: '',
    birthDate: '',
  });
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<ValidationMsgType>(
    {
      userName: '',
      email: '',
      password: '',
      confirmPassword: '',
      gender: '',
      height: '',
    },
  );
  const [isFormComplete, setIsFormComplete] = React.useState(false);

  useEffect(() => {
    //console.log(checkIsFormComplete(accountInfo, validationMessage));
    setIsFormComplete(checkIsFormComplete(accountInfo, validationMessage));
  }, [accountInfo, validationMessage]);

  useEffect(() => {
    console.log('loaded');
    getVideoForShareAdapter(videoId).then((video) => {
      const accountData = JSON.parse(video[0].no_account_info);
      const videoMetaData = {
        ...accountInfo,
        email: video[0].no_account_email,
        userName: accountData.username,
        height: video[0].no_account_height,
      };
      setAccountInfo(videoMetaData);
    });
  }, [videoId]);

  const handleHeightChange: heightChangeFunc = (e) => {
    if (typeof e.target.value === 'number')
      setAccountInfo({ ...accountInfo, height: e.target.value });
  };

  const handleInfoChange: handleInputChange = (keyName: infoKeys) => (e) => {
    let _info = { ...accountInfo };
    _info[keyName] = e.target.value;
    setAccountInfo(_info);
    const validationMsg = crtValidateMessage(
      keyName,
      e.target.value,
      accountInfo,
      validationMessage,
    );

    setValidationMessage(validationMsg);
  };

  const handleSendClick = () => {
    const sendInfo = {
      ...accountInfo,
      ...{
        last_name: 'df',
        confirmPassword: accountInfo.password,
        birthday: `${accountInfo.birthYear}-${accountInfo.birthMonth}-${accountInfo.birthDate}`,
        first_name: 'df',
        app: 'midaten',
        app_version: 'test',
        domain: process.env.REACT_APP_FRONT_DOMAIN,
      },
    };
    console.log(sendInfo);
    setIsUploading(true);
    postDjoserRegister(sendInfo)
      .then((res) => {
        setIsUploading(false);
        console.log(res.data);
        sendRegisterInfo(sendInfo).then((_res) => {
          postLinkVideos(accountInfo.email).then((_) => {
            alert('認証メールを送信しました');
            navigate('/mail-sent');
          });
        });
      })
      .catch((error: networkError) => {
        console.log(error);
        const alertMsg = handleRegisterError(error);
        setIsUploading(false);
        alert(alertMsg);
      });
  };

  const handleClickShowPassword = () => {
    setAccountInfo({
      ...accountInfo,
      showPassword: !accountInfo.showPassword,
    });
  };

  const gotoLogin = () => {
    navigate('/login');
  };

  if (!isSmartPhoneView) {
    return (
      <PCRegisterView
        accountInfo={accountInfo}
        handleInfoChange={handleInfoChange}
        handleHeightChange={handleHeightChange}
        validationMessage={validationMessage}
        handleClickShowPassword={handleClickShowPassword}
        isFormComplete={isFormComplete}
        isUploading={isUploading}
        goToLogin={gotoLogin}
        handleSendClick={handleSendClick}
      />
    );
  }

  return (
    <SmartPhoneRegisterView
      accountInfo={accountInfo}
      handleInfoChange={handleInfoChange}
      handleHeightChange={handleHeightChange}
      validationMessage={validationMessage}
      handleClickShowPassword={handleClickShowPassword}
      isFormComplete={isFormComplete}
      isUploading={isUploading}
      goToLogin={gotoLogin}
      handleSendClick={handleSendClick}
    />
  );
};
