import {
  ScoreEditType, //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { RunnerType } from 'adapters/repositories/typeDefinition';
import { gradeTargetDict_20 } from 'components/MenuConst/GoalsForEachGrade/targetsForEachGrade';
import { commentLogic_18th } from './18kyuComment';
import { commentLogic_19th } from './19kyuComment';
import { commentLogic_20th } from './20kyuComment';

export function crtComment17th_20(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  const setence1 = crt_line1(score, yobina, runnerInfo);
  const sentence2 = crt_line2(runnerInfo, score, yobina);
  const sentence3 = crt_line3(score);
  return (
    setence1 +
    '<<paragraph>>' +
    sentence2 +
    '<<paragraph>>' +
    sentence3 +
    '<<paragraph>>'
  );
}

function crt_line1(
  score: ScoreEditType,
  yobina: string,
  runnerInfo: RunnerType,
) {
  const head1 = yobina + '、そろそろかけっこ教室には慣れてきたかな？';
  const head2 = `今回の動画は17級の目標である「${
    gradeTargetDict_20[runnerInfo.weak_point ?? '20']
  }」`;
  let evaluation =
    score.eval === '◯合格'
      ? 'が大変良くできています！16級昇級おめでとうございます！'
      : 'に少しだけ課題があります。';
  return head1 + head2 + evaluation;
}

function crt_line2(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  console.log(score);
  return commentLogic_17th(score, yobina, runnerInfo);
}

export const commentLogic_17th = (
  score: ScoreEditType,
  yobina: string,
  runnerInfo: RunnerType,
) => {
  if (runnerInfo.weak_point === '18') {
    return commentLogic_18th(score, yobina);
  }
  if (runnerInfo.weak_point === '19') {
    return commentLogic_19th(score, yobina);
  }
  return commentLogic_20th(score, yobina);
};

function crt_line3(score: ScoreEditType) {
  let evaluation =
    score.eval === '◯合格'
      ? `16級ではもう一度足の動かし方の練習してもらいます！今までやってきた3歩ハイニーの動きをより実際の走りに近づけるための「マーク走」という練習になります！ぜひこの調子で頑張りましょう！`
      : '####いい具合になぐさめる####';
  return evaluation;
}
