import React, { FC } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';

type Props = {
  srcURL: string;
  //eslint-disable-next-line
  ratio?: number;
};

export const YoutubeVideoPlayer: FC<Props> = ({ srcURL, ratio = 1 }) => {
  const size = useWindowSize();
  const { width, height } = calculateVideoScreen(size, ratio);

  return (
    <div style={{ paddingTop: '10px', width: '99%' }}>
      <iframe
        width={width}
        height={height}
        src={`${srcURL}?rel=0&modestbranding=1`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ marginBottom: '8px' }}
      ></iframe>
    </div>
  );
};

const calculateVideoScreen = (size: number[], ratio: number) => {
  return {
    width: Math.trunc(size[0] * 0.95 * ratio),
    height: Math.trunc(size[0] * 0.65 * ratio),
  };
};
