import React, { FC, memo, useState } from 'react';
import { menuType, menuVariationType } from '../type';
import { MenuCard } from './MenuCard';
import { usePlaylistWatchModal } from 'pages/PracticePage/hooks/usePlaylistWatchModal';

type Props = {
  menuVariation: menuVariationType;
  menuPlaylists: menuType[];
};

const convertMenuVariation = {
  up: 'アップのための練習',
  family: '家族で楽しむ運動！',
  run: 'ハイレベル練習！',
  tech: '昇級のための練習！',
  levelUp: '昇級',
};

export const MenuListViewer: FC<Props> = memo(
  ({ menuVariation, menuPlaylists }) => {
    const [selectedMenu, setSelectedMenu] = useState<menuType | undefined>(
      undefined,
    );
    const { forceModalOpen, MenuWatchModal } =
      usePlaylistWatchModal(selectedMenu);

    const handleMenuClick = (m: menuType) => () => {
      forceModalOpen(true);
      setSelectedMenu(m);
    };
    if (!menuPlaylists || menuPlaylists.length < 1) {
      throw new Error('メニューがありません');
    }

    const menuShown = menuPlaylists.filter(
      (m) => !!m.menuVariation && m.menuVariation.includes(menuVariation),
    );

    return (
      <div style={{ marginTop: '16px', textAlign: 'left' }}>
        {MenuWatchModal}
        <div style={{ fontWeight: 'bold', margin: '12px 0px 2px 3%' }}>
          {convertMenuVariation[menuVariation]}
        </div>
        <div>
          {menuShown.map((m) => {
            return (
              <MenuCard menuPlaylist={m} handleMenuClick={handleMenuClick(m)} />
            );
          })}
        </div>
      </div>
    );
  },
);
