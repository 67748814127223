import React from 'react';
import { VideoProvider } from 'contexts/VideoContext';
import { AuthContext } from 'contexts/AuthContext';
import { AuthorizedRouter } from './authorizedRouter';
import { UnauthorizedRouter } from './unauthorizedRouter';

export const Router = () => {
  const { isAuthenticated, setIsAuthenticated } = React.useContext(AuthContext);
  React.useEffect(() => {
    const authToken = localStorage.getItem('access');
    console.log('トークンの有無', !!authToken);
    if (authToken !== 'undefined' && authToken) {
      console.log('トークンあり');
      setIsAuthenticated(true);
      return;
    }
    if (authToken === 'undefined') {
      console.log('トークンなし');
      setIsAuthenticated(false);
      return;
    }
    console.log(authToken);
  }, []); // eslint-disable-line

  return (
    <>
      {isAuthenticated ? (
        <VideoProvider>
          <AuthorizedRouter />
        </VideoProvider>
      ) : (
        // authenticateされてない場合はこっちへ
        <UnauthorizedRouter />
      )}
    </>
  );
};
