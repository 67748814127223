// TRAC10級パッケージの6級

export const ckptFor6kyu_trac10 = [
  'しせい',
  '一歩のきょり',
  '安定感',
  'うでふり',
] as const;

export type ckpt_6kyu_type = typeof ckptFor6kyu_trac10[number];

export const requirement_6kyu_trac: ckpt_6kyu_type[] = ['しせい', '安定感'];

type options = {
  [key in ckpt_6kyu_type]: string[];
};

export const optionDict_6kyu_trac: options = {
  しせい: ['◯おへそと目線がまっすぐ', '×おへそと目線がまっすぐではない'],
  一歩のきょり: ['◯ちょうど良い', '×遠い', '×近い'],
  安定感: ['◯フラフラしていない', '×フラフラしている'],
  うでふり: [
    '◯手足と連動している',
    '×手足が一緒になっている',
    '×腕が止まっている',
  ],
};

type complimentType = {
  [key in ckpt_6kyu_type]: string;
};

// まずxxくんの良い点として、に続く文章を書く
export const complimentDict_6: complimentType = {
  しせい:
    'おへそと目線をまっすぐにできている点があります！とても良い姿勢ができていますね！',
  一歩のきょり:
    '力強く大きな一歩を踏み出せている点があります！素晴らしいです！',
  安定感:
    '「フラフラせず安定感のある動きができている」ことがあります！体がぐらぐらしないことはとても大切なので、ぜひ走るときも同じように体を安定させられるようにしましょう！',
  うでふり:
    '「手足が連動している」点があります！地面を押すことだけでなく腕も使えている点がとても良いですね！',
};

export const nobishiroOptions_6_trac = Object.values(optionDict_6kyu_trac)
  .flat()
  .filter((value) => !value.includes('◯'));

type nobishiroDict = {
  [key in typeof nobishiroOptions_6_trac[number]]?: string;
};

// そんな〇〇くんの伸びしろポイントはズバリ、に続く文章を書く
export const nobishiroDict_6: nobishiroDict = {
  '×おへそと目線がまっすぐではない':
    '「おへそと目線がまっすぐになっていないこと」です！おへそと目線がまっすぐになるだけでとても綺麗な姿勢を作れるので、ここをしっかり意識しましょう！',
  '×遠い':
    '「1歩が少し大きすぎる」ことです。もう少しだけ小さくした上で、その分きれいに動くことを意識してみましょう！',
  '×近い':
    '「1歩が少し小さい」ことです。思い切ってもう少しだけ強く地面を押してみましょう！',
  '×フラフラしている':
    '「動作がフラフラしてしまっている」ことです！焦らなくてよいので、体を安定させてやってみることを心がけてみましょう！',
  '×手足が一緒になっている':
    '「手足が一緒になってしまっている」ことです！腕振りを忘れずにやろうとしてくれている点はとてもよいので、あとは手足が逆にならないように気をつけましょう！',
  '×腕が止まっている':
    '「地面を押すことに集中してしまい、腕振りが止まってしまっている」ことです！腕も忘れずにつけてみましょう！',
};
