//20級パッケージの11級
// 10級パッケージの1級と同様

export const ckptForGrade_2kyu_20 = [
  'いい姿勢で体をたおしてかまえる',
  'しっかり体を倒す',
  '足を前に前に',
  '腕ふり',
  '前あしに体重をのせてかまえる',
];

type ckptTypeForGrade_2_20kyu = typeof ckptForGrade_2kyu_20[number];

export const requirement_2kyu_20: ckptTypeForGrade_2_20kyu[] = [
  'しっかり体を倒す',
  '足を前に前に',
  'いい姿勢で体をたおしてかまえる',
];

export type ckptDetails_2kyu_20 =
  | '六歩以上体を倒せている'
  | '足の流れ'
  | 'リーチアウト'
  | '足の上がり'
  | 'うでを前に前に振る'
  | '構えで上体角度が45度程度'
  | '構えで横から見て上体と後ろ足がまっすぐ'
  | '構えで前脚に体重がのっている'
  | '足を壁押しのように動かす';

type allCheckpoints_2_20kyu_type = {
  [key in ckptTypeForGrade_2_20kyu]: ckptDetails_2kyu_20[];
};

export const allCheckPoints_2kyu_20: allCheckpoints_2_20kyu_type = {
  いい姿勢で体をたおしてかまえる: [
    '構えで上体角度が45度程度',
    '構えで前脚に体重がのっている',
  ],
  しっかり体を倒す: ['六歩以上体を倒せている'],
  足を前に前に: [
    '足の流れ',
    '足の上がり',
    'リーチアウト',
    '足を壁押しのように動かす',
  ],
  腕ふり: ['うでを前に前に振る'],
};

export const borderLine_2kyu_20: allCheckpoints_2_20kyu_type = {
  いい姿勢で体をたおしてかまえる: ['構えで上体角度が45度程度'],

  しっかり体を倒す: ['六歩以上体を倒せている'],
  足を前に前に: ['足の流れ', '足の上がり'],
  腕ふり: ['うでを前に前に振る'],
};
