import { ckptFor10kyu_trac10, ckpt_10kyu_type } from './10kyu';
import { ckptFor1kyu_trac10 } from './1kyu';
import { ckptFor2kyu_trac10 } from './2kyu';
import { ckptFor3kyu_trac10 } from './3kyu';
import { ckptFor4kyu_trac10 } from './4kyu';
import { ckptFor5kyu_trac10, ckpt_5kyu_type } from './5kyu';
import { ckptFor6kyu_trac10, ckpt_6kyu_type } from './6kyu';
import { ckptFor7kyu_trac10, ckpt_7kyu_type } from './7kyu';
import { ckptFor8kyu_trac10, ckpt_8kyu_type } from './8kyu';
import { ckptFor9kyu_trac10, ckpt_9kyu_type } from './9kyu';

type ckptDictType = {
  10: ckpt_10kyu_type[];
  9: ckpt_9kyu_type[];
  8: ckpt_8kyu_type[];
  7: ckpt_7kyu_type[];
  6: ckpt_6kyu_type[];
  5: ckpt_5kyu_type[];
  4: ckpt_9kyu_type[];
  3: ckpt_9kyu_type[];
  2: ckpt_9kyu_type[];
  1: ckpt_9kyu_type[];
  0: ckpt_9kyu_type[];
};

export const checkPointDictTrac: ckptDictType = {
  10: ckptFor10kyu_trac10,
  9: ckptFor9kyu_trac10,
  8: ckptFor8kyu_trac10,
  7: ckptFor7kyu_trac10,
  6: ckptFor6kyu_trac10,
  5: ckptFor5kyu_trac10,
  4: ckptFor4kyu_trac10,
  3: ckptFor3kyu_trac10,
  2: ckptFor2kyu_trac10,
  1: ckptFor1kyu_trac10,
  0: ckptFor9kyu_trac10,
};
