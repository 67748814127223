import React from 'react';
import basePositinJump_thumb from 'components/MenuConst/thumbnails/basePositinJump.jpg';
import baseWalk from 'components/MenuConst/thumbnails/baseWalk.png';
import kabeoshi_thumb from 'components/MenuConst/thumbnails/kabeoshi.png';
import yHighkneeSprint_thumb from 'components/MenuConst/thumbnails/yHighkneeSprint.jpg';
import armSwing from 'components/MenuConst/thumbnails/arm_swing.png';
import startpose from 'components/MenuConst/thumbnails/start_posture.png';
import singleLegRotation_thumb from 'components/MenuConst/thumbnails/singleLegRotation.jpg';
import challenge19th_thumb from 'components/MenuConst/thumbnails/challenge_19th.jpg';
import intro_19th_thumb from 'components/MenuConst/thumbnails/intro_19th.jpg';

import {
  re_basePositinJumpVideos,
  basePositinWalkVideos,
  kabeoshiBasicVideos,
  yHighkneeSprintVideos,
  singleLegRotationVideos,
} from './videos';

import { howToStartPoseVideos, armSwingVideos } from '../grade_0/videos';

import { menuType } from 'pages/PracticePage/type';
import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';

export const re_basePositinJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'ベースポジションジャンプ',
  isInside: 'able',
  target: ['しせい', '足の動き'],
  menuVariation: ['up'],
  hitWordList: [
    'べーす',
    'ベース',
    'ジャンプ',
    'じゃんぷ',
    'いえ',
    '家',
    'おうち',
  ],
  grade: 0,
  image: basePositinJump_thumb,
  video: re_basePositinJumpVideos,
};

export const basePositionWalk_19th: menuType = {
  title: 'ベースポジションウォーク',
  isInside: 'able',
  target: ['しせい', '足の動き'],
  grade: 1,
  duration: 10,
  menuVariation: ['up'],
  hitWordList: [
    'ベース',
    'べーす',
    'うぉーく',
    'ウォーク',
    'Y字',
    'ワイ',
    'Y',
    'もも',
    'じゃんぷ',
    'ジャンプ',
    'いえ',
    '家',
    'おうち',
  ],
  image: baseWalk,
  video: basePositinWalkVideos,
};

export const kabeoshiBasic: menuType = {
  grade: 1,
  duration: 15,
  title: '壁押し ☆昇級テスト',
  target: ['足の動き'],
  menuVariation: ['tech'],
  isInside: 'able',
  hitWordList: ['かべ', '壁',],
  image: kabeoshi_thumb,
  video: kabeoshiBasicVideos,
};

export const howToStartPose_19th: menuType = {
  duration: 5,
  title: 'スタートのかまえ',
  target: ['スタート'],
  isInside: 'able',
  menuVariation: ['tech'],
  hitWordList: [
    'スタート',
    'すたーと',
    '構え',
    'かまえ',
    'いえ',
    '家',
    'おうち',
  ],
  grade: 0,
  image: startpose,
  video: howToStartPoseVideos,
};

export const arm_swing_19th: menuType = {
  duration: 5,
  title: 'ペットボトルうでふり',
  target: ['腕ふり'],
  isInside: 'able',
  menuVariation: ['run'],
  hitWordList: ['うで', '腕', 'ふり', '振り', 'いえ', '家', 'おうち'],
  grade: 0,
  image: armSwing,
  video: armSwingVideos,
};

export const yHighkneeSprint: menuType = {
  grade: 1,
  isInside: 'unable',
  duration: 15,
  title: 'Y字ももあげスプリント',
  target: ['しせい', '足の動き'],
  menuVariation: ['run'],
  hitWordList: ['かべ', '壁', 'おし', '押し', 'いえ', '家', 'おうち'],
  image: yHighkneeSprint_thumb,
  video: yHighkneeSprintVideos,
};

//バネ人間はgrade_0から

//かけっこ診断はgrade_0から

export const singleLegRotation: menuType = {
  grade: 1,
  duration: 15,
  title: 'かたあし回転ジャンプ',
  target: ['しせい'],
  isInside: 'able',
  menuVariation: ['family'],
  hitWordList: [
    'かた',
    'あし',
    '片',
    '足',
    'かいてん',
    '回転',
    'じゃんぷ',
    'ジャンプ',
    'いえ',
    '家',
    'おうち',
  ],
  image: singleLegRotation_thumb,
  video: singleLegRotationVideos,
};

export const intro_19th: menuType = {
  duration: 2,
  hitWordList: ['19', '昇級', 'メッセージ', 'めっせーじ', 'きゅう'],
  title: '19級の方へのメッセージ',
  menuVariation: ['levelUp'],
  target: ['足の動き'],
  isInside: 'able',
  grade: 1,
  image: intro_19th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['19'],
      title: 'intro19th',
      message: <span>19級の方へのメッセージはコチラ！ </span>,
    },
  ],
};

export const challenge_19th: menuType = {
  grade: 1,
  duration: 5,
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['levelUp'],
  title: '19級の昇級用メニュー',
  hitWordList: ['19', '昇級', 'かべ', '壁', 'おし', '押し', '家'],
  image: challenge19th_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/QZ2Q4eL5qVk',
      title: 'お手本動画壁押し',
      message: <span>19級の昇級テスト挑戦動画はコチラ！</span>,
    },
  ],
};
