import React, { FC, memo } from 'react';
import classes from './gradeSheet.module.css';
import {
  calcMaxGrade,
  convertRunnerGrade,
  is10GradesPackage,
} from 'components/constants';
import { Grid } from '@material-ui/core';
import medal from 'static/materials/medal.png';
import { onboardType } from './GuidanceTools/type';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { returnThumbnailForGrade } from '../func/returnThumbnailForTheGrade';
import undefinedThumbnail from 'static/target_thumbnail/undefinedThumbnail.png';
import {
  gradeObjectDict,
  gradeObjectDict_10,
  gradeObjectNewTrac,
  grades10,
} from 'components/MenuConst/GradeGoalConst';
import { calcTarget } from 'components/MenuConst/GoalsForEachGrade/targetsForEachGrade';

type Props = {
  onboardState: onboardType;
  runner: RunnerType;
};

export const GradeSheet: FC<Props> = memo(({ onboardState, runner }) => {
  const maxGrade = calcMaxGrade(runner);
  const currentGrade = convertRunnerGrade(runner);
  const thumbnailForTheGrade = returnThumbnailForGrade(runner);
  if (onboardState === 'beforeRead') {
    const latestGrade =
      (runner.latest_exam_video.length > 0 &&
        runner.latest_exam_video[0].grade_before) ||
      0;
    return (
      <div>
        <div className={classes.sheet}>
          <div className={classes.upperSection}>
            <Grid container>
              <Grid item xs={3}>
                <img src={undefinedThumbnail} alt="" width="99%"></img>
              </Grid>
              <Grid item xs={9}>
                <div style={{ margin: '8px 0 0 4px', textAlign: 'left' }}>
                  <div
                    style={{
                      fontSize: '10pt',
                      fontWeight: 'bold',
                      lineHeight: '10vw',
                    }}
                  >
                    ???級のテスト： ???
                  </div>
                  <div className={classes.horizontalLine}></div>
                  <div
                    style={{
                      fontSize: '10pt',
                      fontWeight: 'bold',
                      lineHeight: '10vw',
                    }}
                  >
                    ねらい： ???
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {
              // eslint-disable-next-line
              [...Array(maxGrade)].map((_, g) => {
                if (g < latestGrade) {
                  return (
                    <div className={classes.completedGrade}>
                      <img src={medal} alt="" width="100%"></img>
                    </div>
                  );
                }
                if (g === latestGrade) {
                  return (
                    <div className={classes.currentGrade}>
                      {String(maxGrade - g)}
                    </div>
                  );
                }
                return (
                  <div className={classes.undoneGrade}>
                    {String(maxGrade - g)}
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={classes.sheet}>
        <div className={classes.upperSection}>
          <Grid container>
            <Grid item xs={3}>
              {thumbnailForTheGrade}
            </Grid>
            <Grid item xs={9}>
              <div style={{ margin: '8px 0 0 4px', textAlign: 'left' }}>
                <div
                  style={{
                    fontSize: '10pt',
                    fontWeight: 'bold',
                    lineHeight: '10vw',
                  }}
                >
                  {(currentGrade === '0' && '1級クリアおめでとう！') ||
                    `${currentGrade}級のテスト：`}

                  {calcTarget(runner)}
                </div>
                <div className={classes.horizontalLine}></div>
                <div
                  style={{
                    fontSize: '10pt',
                    fontWeight: 'bold',
                    lineHeight: '10vw',
                  }}
                >
                  ねらい：
                  {calcAim(runner)}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {
            // eslint-disable-next-line
            [...Array(maxGrade)].map((_, g) => {
              if (g < runner.grade) {
                return (
                  <div className={classes.completedGrade}>
                    <img src={medal} alt="" width="100%"></img>
                  </div>
                );
              }
              if (g === runner.grade) {
                return (
                  <div className={classes.currentGrade}>
                    {String(maxGrade - g)}
                  </div>
                );
              }
              return (
                <div className={classes.undoneGrade}>
                  {String(maxGrade - g)}
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
});

export const calcAim = (runner: RunnerType) => {
  if (runner.student_group === 'newtrac') {
    const kyuOfRunner = convertRunnerGrade(runner) as grades10;
    return gradeObjectNewTrac[kyuOfRunner];
  }
  if (is10GradesPackage(runner)) {
    const kyuOfRunner = convertRunnerGrade(runner) as grades10;
    return gradeObjectDict_10[kyuOfRunner];
  }
  const kyuOfRunner = convertRunnerGrade(runner);
  return gradeObjectDict[kyuOfRunner];
};
