import React from 'react';
import start_posture from 'components/MenuConst/thumbnails/start_posture.png';
import inclineDash_thumb from 'components/MenuConst/thumbnails/inclination_dash.png';
import train_thumb from 'components/MenuConst/thumbnails/train.jpg';
import intro_5th_thumb from 'components/MenuConst/thumbnails/intro_5th.jpg';
import three_step_thumb from 'components/MenuConst/thumbnails/3step.png';
import challenge5th_thumb from 'components/MenuConst/thumbnails/challenge_5th.jpg';
import { menuType } from 'pages/PracticePage/type';
import {
  intro5thVideos,
  threeStepVideos,
  trainVideos,
  firstAccelVideos,
  inclineDashVideos,
} from './videos';

export const threeStep: menuType = {
  duration: 15,
  grade: 5,
  title: '3歩ステップ#昇級テスト',
  image: three_step_thumb,
  video: threeStepVideos,
  menuVariation: ['tech'],
  target: ['足の動き'],
  isInside: 'half',
};

export const train = {
  duration: 10,
  grade: 5,
  title: '列車ランニング',
  image: train_thumb,
  menuVariation: ['tech', 'family'],
  video: trainVideos,
};

export const firstAccel = {
  duration: 7,
  grade: 5,
  title: '50mの走り方',
  image: start_posture,
  video: firstAccelVideos,
  menuVariation: ['run'],
};

export const inclineDash5 = {
  title: '倒れこみダッシュ',
  grade: 5,
  duration: 10,
  image: inclineDash_thumb,
  video: inclineDashVideos,
  menuVariation: ['run'],
};

export const threeStep_1 = {
  title: '3ステップ',
  grade: 3,
  duration: 15,
  image: three_step_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/9cuO2W7C18w',
      title: 'ベースポジション踏みかえ',
      message: (
        <span>
          （動画1）「ベースポジションジャンプ踏みかえ」を復習（目安2分）！！
          <br />
          ☆「空中であしを入れかえる！」が連続10回出来たら合格！
          <br />
          ☆あしを入れかえる際、太鼓をたたくイメージで腕を振ってリズムをとるとやりやすいかも！
        </span>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/Bdfi35kBYDU',
      title: 'ベースポジションウォーク',
      message: (
        <span>
          （動画2）「ベースポジションウォーク10歩」× 2セット（目安）行う！
          <br />
          ☆1セットは2秒キープでやってみて、慣れてきたら連続で行ってみよう！
          <br />
          ☆あしを真下に踏む際、太鼓をたたくイメージで腕を下に振るとやりやすいかも！
        </span>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/aVvoKl6RIgo',
      title: 'ベースポジション3歩ステップ',
      message: (
        <span>
          （動画3）「ベースポジション3歩ステップ」を特訓（目安5～8分）！！
          <br />
          ☆「体をまっすぐ立てて、空中であしを入れかえる！」が連続10回出来るようなったら合格！
          <br />
          ☆まずは「3」であしを入れかえるとき上に少し大きくはねてみるとやりやすいかも！
          <br />
          ☆あしを入れかえる際、太鼓をたたくイメージで腕を下に振ってリズムをとるとやりやすいかも！
          <br />
          ※1歩1歩で前に進もうとしすぎないこと！（後ろにける動きが入ってしまったり、からだがうしろにそってしまったりする原因になります）
          <br />
          ⇒出来たら「ベースポジション3歩ステップ10歩」× 2セット行う！
        </span>
      ),
    },
  ],
  menu: (
    <div>
      <span style={{ textAlign: 'center' }}>練習B</span>
      <ul style={{ fontSize: '10pt', textAlign: 'left' }}>
        <li>
          動画1/3を見て、「Y字ポジションもも上げスプリント20m」を余裕を持ったスピードで（1秒に3歩くらいのリズムで）2本行う！
          <br />
          ⇒出来る限りスピードを上げて3本行う！（「いい姿勢」と「あしをからだの前で動かす」は絶対に忘れないこと！）
        </li>
      </ul>
    </div>
  ),
};

export const intro_5th = {
  duration: 2,
  hitWordList: ['5', '昇級', 'メッセージ', 'めっせーじ', 'ごきゅう'],
  title: '5級の方へのメッセージ',
  grade: 5,
  image: intro_5th_thumb,
  video: intro5thVideos,
};
