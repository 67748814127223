import React, { FC, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import toNineGrade from 'static/lp/toNinteenGrade.png';
import ux_explanation from 'static/lp/ux_explanation_20.png';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { crtYobinaFromVideo } from './TrainingComponent';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';

//eslint-disable-next-line

type Props = {
  handleBackClick: () => void;
  video: VideoType;
};

export const InvitationScreen: FC<Props> = ({ handleBackClick, video }) => {
  const navigate = useNavigate();
  const yobina = crtYobinaFromVideo(video);

  const handleGoToForm = useCallback(() => {
    navigate(`/register-prefilled/${video.id}`);
  }, []);

  return (
    <div style={{ marginTop: '16px' }}>
      {' '}
      <div
        style={{
          marginTop: '16px',
          fontSize: '12pt',
          color: '#424242',
          textAlign: 'center',
        }}
      >
        かけっこ診断をがんばった
        <br />
        {yobina}を
        <span
          style={{ fontWeight: 'bold', fontSize: '16pt', color: '#ff0000' }}
        >
          20級
        </span>
        に認定します！
      </div>
      <div
        style={{
          textAlign: 'center',
          width: '90%',
          marginLeft: '5%',
          marginTop: '16px',
        }}
      >
        <img src={toNineGrade} width="98%" alt=""></img>
      </div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '12pt',
          margin: '16px 0 2px 0 ',
          color: '#424242',
          fontWeight: 'bold',
        }}
      >
        オンラインかけっこ教室の
        <br />
        IDATEN-Kidsに登録して、
        <br />
        さっそく19級にチャレンジしよう！
        <br />
      </div>
      <div
        style={{
          textAlign: 'center',
          fontSize: '12pt',
          margin: '0px 0 16px 0',
          color: '#424242',
        }}
      >
        ※登録および19級挑戦は
        <span style={{ fontWeight: 'bold', color: '#4169e1' }}>無料</span>
        です！
      </div>
      <div style={{ textAlign: 'center' }}>
        <Button
          size="medium"
          onClick={handleGoToForm}
          variant="contained"
          color="secondary"
        >
          <span style={{ fontWeight: 'bold', fontSize: '14pt' }}>
            {' '}
            IDATEN-Kidsに登録する（無料）&nbsp;
          </span>
          <PlayCircleFilledWhiteIcon style={{ color: '#ffff0f' }} />
        </Button>
        <div
          style={{
            textAlign: 'center',
            fontSize: '9pt',
            margin: '8px 0 16px 0',
            color: '#424242',
          }}
        >
          ☆ご登録頂くと
          <span style={{ fontWeight: 'bold' }}>何回でも</span>
          かけっこ診断ができます！
        </div>
      </div>
      <img src={ux_explanation} width="98%" alt=""></img>
      <div style={{ display: 'flex', margin: '24px 0 20px 0' }}>
        <div style={{ width: '44%' }}>
          <Button onClick={handleBackClick} variant="outlined">
            {'<< 前の画面へ戻る'}
          </Button>
        </div>

        <div style={{ width: '56%' }}>
          <br />
        </div>
      </div>
    </div>
  );
};
