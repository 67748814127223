import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterStepper } from './Register/Stepper';
import { Card } from '@material-ui/core';
import emailSticker from '../../static/email_sticker.gif';

export const AfterMailSentPage = () => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: 'center', fontFamily: 'Meiryo' }}>
      <RegisterStepper activeStep={1} />
      <div style={{ padding: '20px' }}>
        <Card
          style={{
            padding: '15px',
            backgroundColor: '#f0ffff',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            確認用のemailを送信しました。
          </div>
          <div>
            <img src={emailSticker} width="40%" alt=""></img>
          </div>
          <div>メール内のリンクにアクセスして下さい。</div>
          <div>この画面は閉じて頂いて大丈夫です。</div>
        </Card>

        <div style={{ marginTop: '30px' }}>
          -- メールが届かない場合 --
          <div
            style={{
              padding: '4px',
              backgroundColor: '#fff',
              fontSize: '11pt',
            }}
          >
            <Card
              style={{
                padding: '10px',
                backgroundColor: '#ffe4e1',
                fontSize: '11pt',
              }}
            >
              <div style={{ padding: '10px', textAlign: 'left' }}>
                Gmailやその他のメールクライアントをお使いの方で、メールが
                <span style={{ fontWeight: 'bold' }}>「プロモーション」</span>や
                <span style={{ fontWeight: 'bold' }}>「迷惑メール」</span>
                フォルダに保存されるというケースがございます。もしメールが来ない際は当該フォルダをご確認いただけますと幸いです。
              </div>
              <div style={{ marginTop: '12px', textAlign: 'left' }}>
                もし上記のフォルダにもメールが届いていない場合は、大変お手数ですがもう一度
                <span
                  className="textlink"
                  onClick={() => navigate('/register')}
                >
                  アカウント登録画面
                </span>
                からメールアドレスの入力をお願いします。
              </div>
            </Card>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '40px' }}>
        <br />
      </div>
    </div>
  );
};
