import React, { useState } from 'react';
import { RunnerType, AccountType } from 'adapters/repositories/typeDefinition';

type ContextType = {
  accountInfo: AccountType | undefined;
  setAccountInfo: React.Dispatch<React.SetStateAction<AccountType | undefined>>;
  currentRunner: RunnerType | undefined;
  setCurrentRunner: React.Dispatch<
    React.SetStateAction<RunnerType | undefined>
  >;
};

export const AccountContext = React.createContext<ContextType>({
  accountInfo: undefined,
  setAccountInfo: () => '',
  currentRunner: undefined,
  setCurrentRunner: () => '',
});

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const AccountProvider = ({ children }: Props): JSX.Element => {
  const [accountInfo, setAccountInfo] = useState<AccountType>();
  const [currentRunner, setCurrentRunner] = useState<RunnerType>();
  return (
    <AccountContext.Provider
      value={{
        accountInfo,
        setAccountInfo,
        currentRunner,
        setCurrentRunner,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
