import {
  ScoreEditType,
} from 'components/MenuConst/GradeGoalConst';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { evalEachPoint } from 'pages/ResultPage/components/StepFunctions/func';
import {  requirementsToGetStar, requirementsToPass, starndardsToGetGood } from 'components/MenuConst/GoalsForEachGrade/standardsForEachGrade';


type decideUpOrStayType = (score:ScoreEditType, video:VideoType) => boolean;
export const decideUpOrStay:decideUpOrStayType = (score, video) => {
    const border = starndardsToGetGood(video.upload_runner, video.grade_before)
    const rqsStar = requirementsToGetStar(video.upload_runner, video.grade_before)
    const requirements = requirementsToPass(video.upload_runner, video.grade_before)

    const evaluation = evalEachPoint(rqsStar, border, score )
    console.log(evaluation)

    // 要するに、requirementsに含まれているものは全てborderを超えないといけない
    const upOrStay = evaluation.map((ckptShallow) => ckptShallow.isBorderPassed || !requirements.includes(ckptShallow.title)).every(u => u)
    console.log(upOrStay)
    return upOrStay
}
