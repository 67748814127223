import React, { FC } from 'react';
import { Box, LinearProgress, Typography } from '@material-ui/core';

type ProgressType = {
  progress: number;
};

export const LinearProgressWithLabel: FC<ProgressType> = ({ progress }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color="textSecondary"
        >{`${progress}%`}</Typography>
      </Box>
    </Box>
  );
};
