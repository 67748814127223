import React, { FC } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import loadingGif from '../../../static/running-101-gif.gif';
import { postVideoWithProgress } from 'features/upload/api/postVideo';
import { LinearProgressWithLabel } from 'features/upload/components/atoms/LinearProgressWithLabel';

type Props = {
  activeStep: number;
  params: FormData;
  completeFunc: () => void;
  failFunc: () => void;
  color: 'default' | 'inherit' | 'primary' | 'secondary';
  title: string;
};
export const VideoSendButtonWithProgress: FC<Props> = ({
  activeStep,
  params,
  completeFunc,
  failFunc,
  color,
  title,
}) => {
  const [progress, setProgress] = React.useState(0.0);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const sendVideo = () => {
    console.log('push');
    setIsUploading(true);
    postVideoWithProgress(
      params,
      setProgress,
      completeFunc,
      failFunc,
      setIsUploading,
    );
  };
  if (activeStep >= 2) {
    return (
      <div style={{ marginTop: '20px' }}>
        {isUploading ? (
          <>
            送信中 <CircularProgress size={20} />
            <div>
              <LinearProgressWithLabel progress={progress} />
            </div>
            <div>
              <img alt="ロード中" src={loadingGif} height="150" width="auto" />
            </div>
          </>
        ) : (
          <>
            <Button variant="contained" color={color} onClick={sendVideo}>
              {title}
            </Button>
          </>
        )}
      </div>
    );
  }
  return (
    <>
      <Button variant="contained" disabled>
        {title}
      </Button>
    </>
  );
};
