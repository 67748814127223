import { RunnerType } from 'adapters/repositories/typeDefinition';
import { decideMenuForTrac } from 'components/MenuConst/DecideMenu/decideMenuForTrac';
import { convertRunnerGrade } from 'components/constants';
import React, { FC, useState } from 'react';
import { MenuCard } from './MenuCard';
import { grades10 } from 'components/MenuConst/GradeGoalConst';
import { usePlaylistWatchModal } from '../hooks/usePlaylistWatchModal';
import { menuType } from '../type';

type Props = {
  runner: RunnerType;
};

export const TRACMenuTab: FC<Props> = ({ runner }) => {
  const [selectedMenu, setSelectedMenu] = useState<menuType | undefined>(
    undefined,
  );
  const grade = convertRunnerGrade(runner) as grades10;
  const menu = decideMenuForTrac(grade);

  const { forceModalOpen, MenuWatchModal } =
    usePlaylistWatchModal(selectedMenu);

  const handleMenuClick = (m: menuType) => () => {
    forceModalOpen(true);
    setSelectedMenu(m);
  };

  return (
    <div>
      {MenuWatchModal}
      {menu.map((m) => {
        return (
          <MenuCard menuPlaylist={m} handleMenuClick={handleMenuClick(m)} />
        );
      })}
    </div>
  );
};
