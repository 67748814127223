import React from 'react';

import spring_thumb from 'components/MenuConst/thumbnails/spring.jpg';
import skaterjump_thumb from 'components/MenuConst/thumbnails/skaterjump.jpg';
import dash_30m_thumb from 'components/MenuConst/thumbnails/dash_30m.jpg';
import diagnose_thumb from 'components/MenuConst/thumbnails/diagnose_thumb.png';
import henkeiDash_thumb from 'components/MenuConst/thumbnails/henkeidash.jpg';
import singleLeg_cross_thumb from 'components/MenuConst/thumbnails/singleLeg_cross.jpg';

import intro_6th_thumb from 'components/MenuConst/thumbnails/intro_6th.jpg'; //done
import { menuType } from 'pages/PracticePage/type';

import {
  spring6thVideos,
  skaterJump6thVideos,
  singleLegTraining6thVideos,
  dash30m6thVideos,
} from './videos';

import {
  henkei10thVideos,
} from '../grade_10/videos';

import {
  fiftyTrialVideos,
} from '../grade_0/videos';

import { trainVideos } from '../grade_4/videos';

import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';
// up: 準備, tech: ぎじゅつ, run: 体力, family: 家族

// プレイリスト

////
//run
////

export const spring_6th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'ばね人間チャレンジ',
  isInside: 'unable',
  target: ['しせい', '全力走'],
  menuVariation: ['run'],
  hitWordList: ['ばね', 'バネ'],
  grade: 14,
  image: spring_thumb,
  video: spring6thVideos,
};

export const skaterJump_6th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'スケータージャンプ',
  isInside: 'unable',
  target: ['スタート'],
  menuVariation: ['run'],
  hitWordList: ['すけーたー', 'スケーター'],
  grade: 14,
  image: skaterjump_thumb,
  video: skaterJump6thVideos,
};

export const dash30m_6th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '30mダッシュ',
  isInside: 'unable',
  target: ['スタート', '全力走'],
  menuVariation: ['run'],
  hitWordList: ['30', 'さん', 'だっしゅ', 'ダッシュ'],
  grade: 14,
  image: dash_30m_thumb,
  video: dash30m6thVideos,
};


////
//family
////

export const fiftyTrial_6th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'かけっこ診断',
  isInside: 'unable',
  target: ['スタート', '全力走'],
  menuVariation: ['family'],
  hitWordList: ['かけっこ', '診断'],
  grade: 14,
  image: diagnose_thumb,
  video: fiftyTrialVideos,
};

export const henkeiDash_6th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '変形ダッシュ',
  isInside: 'unable',
  target: ['スタート', '全力走'],
  menuVariation: ['family'],
  hitWordList: ['へんけい', '変形', 'だっしゅ', 'ダッシュ'],
  grade: 14,
  image: henkeiDash_thumb,
  video: henkei10thVideos,
};

export const singleLegTraining_6th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '片足トレーニング',
  isInside: 'able',
  target: ['しせい', '全力走'],
  menuVariation: ['family'],
  hitWordList: ['かたあし', '片足', 'トレーニング', 'とれーにんぐ'],
  grade: 14,
  image: singleLeg_cross_thumb,
  video: singleLegTraining6thVideos,
};

////
//levelup
////

//復習の島なので、challengeはなし

//introVideoDictFor20Gradesの中身はまだ変更してない
export const intro_6th: menuType = {
  duration: 2,
  isInside: 'able',
  target: ['しせい'],
  hitWordList: ['6', '昇級', 'メッセージ', 'めっせーじ', 'きゅう'],
  title: '6級の方へのメッセージ',
  menuVariation: ['levelUp'],
  grade: 14,
  image: intro_6th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['6'],
      title: 'intro6th',
      message: <span>6級の方へのメッセージはコチラ！ </span>,
    },
  ],
};
