import React, { FC } from 'react';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { EachScoreViewer } from 'pages/FiftyResultPage/components/EachScoreViewer';
import { AIScore } from 'components/MenuConst/thresholds';

type Props = {
  chosenVideo: VideoType;
};
export const AIEvaluationPart: FC<Props> = ({ chosenVideo }) => {
  const detailCheckPoints =
    (!!chosenVideo?.score?.ai_eval &&
      (JSON.parse(chosenVideo.score.ai_eval) as AIScore)) ||
    '';
  return (
    <>
      {!!chosenVideo.score && (
        <>
          {/* <EstimateTimeViewer video={video} /> */}
          {(!!detailCheckPoints && (
            <EachScoreViewer
              score={detailCheckPoints}
              speed={chosenVideo.score.real_speed}
            />
          )) || <></>}
        </>
      )}
    </>
  );
};
