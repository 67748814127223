import { menuType } from 'pages/PracticePage/type';
import { lunge_squat, lunge_walk, surisuri_walk } from './videos';
import surisuri_thumb from 'components/MenuConst/thumbnails/surisuri_thumb.png';
import lunge_sq_thumb from 'components/MenuConst/thumbnails/lunge_squat_thumb.png';
import lunge_walk_thumb from 'components/MenuConst/thumbnails/lunge_walk_thumb.png';

export const SuriSuriWalk: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'すりすり走',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['tech'],
  hitWordList: ['すりすり走', 'B'],
  grade: 2,
  image: surisuri_thumb,
  video: surisuri_walk,
};

export const LungeSquat: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'ランジスクワット',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['tech'],
  hitWordList: ['ランジスクワット', 'らんじすくわっと'],
  grade: 2,
  image: lunge_sq_thumb,
  video: lunge_squat,
};

export const LungeWalk: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'ランジウォーク #昇級テスト',
  isInside: 'able',
  target: ['足の動き', '腕ふり'],
  menuVariation: ['tech'],
  hitWordList: ['ランジウォーク', 'らんじうぉ'],
  grade: 2,
  image: lunge_walk_thumb,
  video: lunge_walk,
};
