type levels = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10';

export const calcFormScore = (
  posture: levels,
  arm: levels,
  leg: levels,
  estTime: number,
) => {
  let basePoint = 80;

  // まずはスピードを考慮
  if (estTime > 12) {
    basePoint *= 0.6;
  } else if (estTime < 6.3) {
    basePoint *= 0.9;
  } else {
    basePoint = -4 * estTime + 96;
  }

  // まずは姿勢の分
  if (['2'].includes(posture)) {
    basePoint += 3;
  } else if (['3'].includes(posture)) {
    basePoint += 5;
  } else if (['4'].includes(posture)) {
    basePoint += 4;
  } else if (['5'].includes(posture)) {
    basePoint += 4;
  } else if (['6'].includes(posture)) {
    basePoint += 8;
  } else if (['7'].includes(posture)) {
    basePoint += 2;
  }

  // 次は足の動かし方
  if (['2'].includes(leg)) {
    basePoint += 2;
  } else if (['3'].includes(leg)) {
    basePoint += 4;
  } else if (['4'].includes(leg)) {
    basePoint += 6;
  } else if (['5'].includes(leg)) {
    basePoint += 6;
  } else if (['6'].includes(leg)) {
    basePoint += 8;
  } else if (['7'].includes(leg)) {
    basePoint += 10;
  }

  // 腕振り
  if (['2'].includes(arm)) {
    basePoint += 1;
  } else if (['3'].includes(arm)) {
    basePoint += 2;
  } else if (['4'].includes(arm)) {
    basePoint += 2;
  } else if (['5'].includes(arm)) {
    basePoint += 4;
  } else if (['6'].includes(arm)) {
    basePoint += 5;
  } else if (['7'].includes(arm)) {
    basePoint += 4;
  } else if (['8'].includes(arm)) {
    basePoint += 6;
  } else if (['9'].includes(arm)) {
    basePoint += 4;
  } else if (['10'].includes(arm)) {
    basePoint += 7;
  }

  if (basePoint > 100) {
    return 100;
  }

  return Math.floor(basePoint);
};
