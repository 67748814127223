import React from 'react';
import classes from '../guidanceTools.module.css';
import { buttonStateType } from '../type';

export const CrownPopover = (
  buttonState: buttonStateType,
  isFirst: boolean,
) => {
  if (isFirst) {
    return (
      <div className={classes.popover}>
        昇級テストにいどむ前に、まずは練習だ！きいろいボタンをおしてみよう！
      </div>
    );
  }
  if (buttonState === 'shouldTouch') {
    return (
      <div className={classes.popover}>
        昇級用の動画は撮れたかな？動画が撮れたら右下の＋ボタンをおして「👑昇級に挑戦」をクリックしよう！
      </div>
    );
  }

  if (buttonState === 'disabled') {
    return (
      <div className={classes.popover}>
        昇級テストにいどむ前に、まずは練習だ！きいろいボタンをおしてみよう！
      </div>
    );
  }

  if (buttonState === 'canTouch') {
    return (
      <div className={classes.popover}>
        昇級テストにいどむ前に、まずは練習だ！きいろいボタンをおしてみよう！
      </div>
    );
  }
  return (
    <>
      <div>
        先生からのコメントまちです！もし昇級テスト用の動画を変えたい場合は、右下の＋ボタンをおして「👑昇級に挑戦」から動画をへんこうできるよ！
      </div>
    </>
  );
};
