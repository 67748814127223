import React from 'react';
import { Grid } from '@material-ui/core';
import classes from '../purchasepage.module.css';
import coaches from 'static/coaches.png';

export const AppliStrength = ({ isSmartPhone = true }) => {
  return (
    <div>
      <div className={classes.appliStrength}>
        <div className={classes.applicircle}></div>

        <div className={classes.upper}>
          <Grid container>
            <Grid item xs={11}>
              <div style={{ textAlign: 'left' }}>
                <div className={classes.title}>オンラインだからこそ</div>
                <div className={classes.title}>コーチとマンツーマンで</div>
                <div className={classes.title}>すすめられる!</div>
                <div className={classes.subtext}>
                  専用アプリから動画を送ると
                  <br />
                  コーチから丁寧なコメントと練習動画が届く!
                </div>
              </div>
              {(isSmartPhone && (
                <div style={{ marginTop: '10px' }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: ` <video
                  width="100%"
                  autoplay
                  muted
                  playsinline
                  loop
                  poster="https://shimotakaido.s3.amazonaws.com/lesson_videos/online_school_thumbnail.png"
                >
                <source src="https://shimotakaido.s3.amazonaws.com/lesson_videos/onlineschool.mp4" type="video/mp4" />
                </video>`,
                    }}
                  />
                </div>
              )) || (
                <div style={{ marginTop: '10px' }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: ` <video
                  width="82%"
                  autoplay
                  muted
                  playsinline
                  loop
                  poster="https://shimotakaido.s3.amazonaws.com/lesson_videos/online_school_thumbnail.png"
                >
                <source src="https://shimotakaido.s3.amazonaws.com/lesson_videos/onlineschool.mp4" type="video/mp4" />
                </video>`,
                    }}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={1}>
              <br />
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: '30px' }}>
          <br />
        </div>
      </div>

      {/* コーチいきり */}
      <div className={classes.appliStrength2}>
        <div style={{ overflowX: 'hidden' }}>
          <div className={classes.applicircleRight}></div>
        </div>

        <div className={classes.upper}>
          <Grid container>
            <Grid item xs={2}>
              <br />
            </Grid>
            <Grid item xs={10}>
              {(isSmartPhone && (
                <div style={{ textAlign: 'center' }}>
                  <div className={classes.title}>監修はプロコーチ</div>
                  <div className={classes.title}>✖️</div>
                  <div className={classes.title}>東京大学の研究者</div>
                  <div
                    style={{ textAlign: 'left' }}
                    className={classes.subtext}
                  >
                    作り込まれたカリキュラムと
                    <br />
                    正確なアドバイスで効率よく速くなる！
                  </div>

                  <div style={{ padding: '8px' }}>
                    <img src={coaches} width="100%" alt="" />
                  </div>
                </div>
              )) || (
                <div style={{ textAlign: 'center' }}>
                  <div className={classes.title}>監修はプロコーチ</div>
                  <div className={classes.title}>✖️</div>
                  <div className={classes.title}>東京大学の研究者</div>
                  <div className={classes.subtext}>
                    作り込まれたカリキュラムと
                    <br />
                    正確なアドバイスで効率よく速くなる！
                  </div>

                  <div style={{ padding: '8px' }}>
                    <img src={coaches} width="70%" alt="" />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: '30px' }}>
          <br />
        </div>
      </div>
    </div>
  );
};
