import {
  dribbleJump,
  basePositinJump,
  pongpongJog,
  y_sprint,
  y_practice,
  spring,
  trial_50m,
  arm_swing,
  howToStartPose,
} from './menus/grade_0/starter_20th';

import { menuType } from 'pages/PracticePage/type';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { decideMenuFor10grades } from './DecideMenu/decideMenuFor10Grades';
import { decideMenuFor20grades } from './DecideMenu/decideMenuFor20Grades';
import { convertRunnerGrade, is10GradesPackage } from 'components/constants';
import { nonFukushuGrades } from './GoalsForEachGrade/targetsForEachGrade';

type decideMenu = (runner: RunnerType | undefined) => menuType[];

export const decideMenu: decideMenu = (runner) => {
  // 現在の級とstay_timeに応じて出る。

  if (!runner || !runner.grade) {
    return [
      trial_50m,
      dribbleJump,
      basePositinJump,
      pongpongJog,
      y_sprint,
      y_practice,
      spring,
      arm_swing,
      howToStartPose,
    ];
  }

  // 10級パッケージの場合
  if (is10GradesPackage(runner)) {
    return decideMenuFor10grades(runner.grade);
  }

  // 20級パッケージの場合
  const grade = convertRunnerGrade(runner);
  if (!nonFukushuGrades.includes(grade)) {
    // さらに復習メニューは体力とお楽しみを出さないように修正
    let fukushuMenu =
      runner.weak_point &&
      decideMenuFor20grades(runner.weak_point).filter(
        (m) =>
          !!m.menuVariation &&
          !m.menuVariation.includes('family') &&
          !m.menuVariation.includes('run'),
      );

    const menuOfTheGrade = decideMenuFor20grades(grade);
    return (
      (!!fukushuMenu && menuOfTheGrade.concat(fukushuMenu)) || menuOfTheGrade
    );
  }
  return decideMenuFor20grades(grade);
};
