import React, { useEffect, FC, memo, useCallback } from 'react';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from 'contexts/AccountContext';
import './fab.css';

type Props = {
  goToUploadPage: () => void;
};

export const UploadFAB: FC<Props> = memo(({ goToUploadPage }) => {
  const { currentRunner } = React.useContext(AccountContext);
  const navigate = useNavigate();
  const [_isFabClicked, setIsFabClicked] = React.useState(false); //renderタイミング制御のために入れてる
  let target = document.getElementsByClassName('plus')[0] || null;
  let workoutButton = document.getElementsByClassName('buttons')[0];
  let modalOverlay = document.getElementsByClassName('modal-overlay')[0];
  let modalContent = document.getElementsByClassName('modal-content')[0];

  useEffect(() => {
    if (!!target) {
      return;
    }
    target = document.getElementsByClassName('plus')[0];
    workoutButton = document.getElementsByClassName('buttons')[0];
  }, []);

  const toggleToClicked = () => {
    if (!target) {
      return;
    }
    target.classList.add('clicked');
    workoutButton.classList.add('clicked');
    modalOverlay.classList.add('clicked');
    modalContent.classList.add('clicked');
  };

  const toggleToRemove = useCallback(() => {
    target.classList.remove('clicked');
    workoutButton.classList.remove('clicked');
    modalOverlay.classList.remove('clicked');
    modalContent.classList.remove('clicked');
  }, [target]);

  const handlePlusClick = () => {
    console.log((!!target && target?.classList) || 'no target');
    if (!!target) {
      toggleToClicked();
      setIsFabClicked(true);
    }
  };
  const handleCloseClick = () => {
    toggleToRemove();
    setIsFabClicked(false);
  };

  const handleAddButtonClick = () => {
    toggleToRemove();
    setIsFabClicked(false);
    goToUploadPage();
  };

  const handleGoToLevelup = () => {
    toggleToRemove();
    setIsFabClicked(false);
    navigate('/levelup');
  };

  const handleGoToTrialUpload = () => {
    toggleToRemove();
    setIsFabClicked(false);
    navigate('/trial-upload');
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="buttons">
          <div style={{ marginBottom: '20px' }}>
            <button className="trial" onClick={handleGoToTrialUpload}>
              <span style={{ fontSize: '16pt', fontWeight: 'bold' }}>💯</span>
              <span style={{ fontSize: '12pt', fontWeight: 'bold' }}>
                かけっこ診断
              </span>
            </button>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <button className="try-levelup" onClick={handleGoToLevelup}>
              <span style={{ fontSize: '12pt', fontWeight: 'bold' }}>
                👑 昇級に挑戦
              </span>
            </button>
          </div>
          {!!currentRunner && currentRunner.grade > 0 && (
            <div style={{ marginBottom: '20px' }}>
              <button className="add-video" onClick={handleAddButtonClick}>
                <span style={{ fontSize: '12pt', fontWeight: 'bold' }}>
                  📔 練習を記録
                </span>
              </button>
            </div>
          )}
        </div>

        <div className="fab">
          <button className="plus">
            {!!target && target.classList.contains('clicked') ? (
              <CloseIcon
                style={{ color: '#ffebcd' }}
                onClick={handleCloseClick}
              />
            ) : (
              <AddIcon style={{ color: '#ffebcd' }} onClick={handlePlusClick} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
});
