import React, { FC } from 'react';
import SyncIcon from '@mui/icons-material/Sync';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  title: string;
  isLoading: boolean;
  handleUpdate: () => void;
};
export const RefreshButton: FC<Props> = ({
  title,
  isLoading,
  handleUpdate,
}) => {
  return (
    <>
      {(isLoading && (
        <div style={{ textAlign: 'center' }}>
          <div
            style={{
              fontSize: '8pt',
              color: '#1e90ff',
            }}
          >
            更新中...
          </div>
          <div
            style={{
              // borderRadius: '4px',
              // padding: '3px',
              // border: 'solid 1px #1e90ff',
              color: ' #1e90ff',
            }}
          >
            <CircularProgress size={16} />
          </div>
        </div>
      )) || (
        <div
          style={{
            textAlign: 'center',
            border: '#1e90ff 1px solid',
            borderRadius: '3px',
          }}
          onClick={handleUpdate}
        >
          <div
            style={{
              fontSize: '8pt',
              color: '#1e90ff',
            }}
          >
            {title}
          </div>
          <div
            style={{
              color: ' #1e90ff',
            }}
          >
            <SyncIcon />
          </div>
        </div>
      )}
    </>
  );
};
