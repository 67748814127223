import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { BossCheckPage } from './BossCheckPage';

export const ScoringRoutes = () => {
  return (
    <Routes>
      <Route path="boss-check" element={<BossCheckPage />} />
    </Routes>
  );
};
