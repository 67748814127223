// 10級パッケージの4級
type ckptTypeForGrade_6_10kyu =
  | 'よい姿勢'
  | '足を体の前で回す'
  | '足のつき方'
  | 'うで振り'
  | 'スピードを出す';

export const ckptForGrade_6_10kyu: ckptTypeForGrade_6_10kyu[] = [
  'よい姿勢',
  '足を体の前で回す',
  '足のつき方',
  'うで振り',
  'スピードを出す',
];

export const requirement_6_10kyu: ckptTypeForGrade_6_10kyu[] = [
  'よい姿勢',
  '足を体の前で回す',
  '足のつき方',
];

export type ckptDetails_6_10 =
  | '前後傾'
  | '腰の引け具合'
  | '下に踏む'
  | '足の上がり'
  | '前さばき'
  | '接地様式'
  | 'うで振り'
  | 'スピードが出せている'
  //採点用
  | '腰が落ちていない'
  | '後ろに蹴っていない';

type allCheckpoints_6_10kyu_type = {
  [key in ckptTypeForGrade_6_10kyu]: ckptDetails_6_10[];
};

export const allCheckPoints_6_10kyu: allCheckpoints_6_10kyu_type = {
  よい姿勢: ['前後傾', '腰の引け具合'],
  足を体の前で回す: ['足の上がり', '下に踏む', '前さばき'],
  足のつき方: ['接地様式'],
  うで振り: ['うで振り'],
  スピードを出す: ['スピードが出せている'],
};

export const borderLine_6_10kyu: allCheckpoints_6_10kyu_type = {
  よい姿勢: ['前後傾'],
  足を体の前で回す: ['足の上がり', '下に踏む'],
  足のつき方: ['接地様式'],
  うで振り: ['うで振り'],
  スピードを出す: ['スピードが出せている'],
};
