import {
  allCheckPoints_0_10kyu,
  borderLine_0_10kyu,
  ckptForGrade_0_10kyu,
  requirement_0_10kyu,
} from './10_kyu';
import {
  allCheckPoints_1_10kyu,
  borderLine_1_10kyu,
  ckptForGrade_1_10kyu,
  requirement_1_10kyu,
} from './9_kyu';
import {
  allCheckPoints_2_10kyu,
  borderLine_2_10kyu,
  ckptForGrade_2_10kyu,
  requirement_2_10kyu,
} from './grade_2';
import {
  allCheckPoints_3_10kyu,
  borderLine_3_10kyu,
  ckptForGrade_3_10kyu,
  requirement_3_10kyu,
} from './grade_3';
import {
  allCheckPoints_4_10kyu,
  borderLine_4_10kyu,
  ckptForGrade_4_10kyu,
  requirement_4_10kyu,
} from './grade_4';
import {
  allCheckPoints_5_10kyu,
  borderLine_5_10kyu,
  ckptForGrade_5_10kyu,
  requirement_5_10kyu,
} from './grade_5';
import {
  allCheckPoints_6_10kyu,
  borderLine_6_10kyu,
  ckptForGrade_6_10kyu,
  requirement_6_10kyu,
} from './grade_6';
import {
  allCheckPoints_7_10kyu,
  borderLine_7_10kyu,
  ckptForGrade_7_10kyu,
  requirement_7_10kyu,
} from './grade_7';
import {
  allCheckPoints_8_10kyu,
  borderLine_8_10kyu,
  ckptForGrade_8_10kyu,
  requirement_8_10kyu,
} from './grade_8';
import {
  allCheckPoints_9_10kyu,
  borderLine_9_10kyu,
  ckptForGrade_9_10kyu,
  requirement_9_10kyu,
} from './grade_9';

export const standardsFor10grades = {
  10: ckptForGrade_0_10kyu,
  9: ckptForGrade_1_10kyu,
  8: ckptForGrade_2_10kyu,
  7: ckptForGrade_3_10kyu,
  6: ckptForGrade_4_10kyu,
  5: ckptForGrade_5_10kyu,
  4: ckptForGrade_6_10kyu,
  3: ckptForGrade_7_10kyu,
  2: ckptForGrade_8_10kyu,
  1: ckptForGrade_9_10kyu,
};

export const requirementsToPass_10_kyu = {
  10: requirement_0_10kyu,
  9: requirement_1_10kyu,
  8: requirement_2_10kyu,
  7: requirement_3_10kyu,
  6: requirement_4_10kyu,
  5: requirement_5_10kyu,
  4: requirement_6_10kyu,
  3: requirement_7_10kyu,
  2: requirement_8_10kyu,
  1: requirement_9_10kyu,
};

export const allCheckpoints_10_kyu = {
  10: allCheckPoints_0_10kyu,
  9: allCheckPoints_1_10kyu,
  8: allCheckPoints_2_10kyu,
  7: allCheckPoints_3_10kyu,
  6: allCheckPoints_4_10kyu,
  5: allCheckPoints_5_10kyu,
  4: allCheckPoints_6_10kyu,
  3: allCheckPoints_7_10kyu,
  2: allCheckPoints_8_10kyu,
  1: allCheckPoints_9_10kyu,
};

export const toGetGoodRequirements_10 = {
  10: borderLine_0_10kyu,
  9: borderLine_1_10kyu,
  8: borderLine_2_10kyu,
  7: borderLine_3_10kyu,
  6: borderLine_4_10kyu,
  5: borderLine_5_10kyu,
  4: borderLine_6_10kyu,
  3: borderLine_7_10kyu,
  2: borderLine_8_10kyu,
  1: borderLine_9_10kyu,
};
