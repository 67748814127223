import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  InputAdornment,
  IconButton,
  Input,
  InputLabel,
  FormControl,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { postPasswordChange } from '../../v1apiRequest';

export const ChangePasswordPage = () => {
  const navigate = useNavigate();

  const [accountInfo, setAccountInfo] = React.useState({
    current_password: '',
    new_password: '',
    re_new_password: '',
  });
  const [isUploading, setIsUploading] = React.useState(false);
  const [validationMessage, setValidationMessage] = React.useState({
    current_password: '',
    new_password: '',
    re_new_password: '',
  });
  const [isFormComplete, setIsFormComplete] = React.useState(false);

  // FIXME: Validation Messageはstateで持たせる必要ない！！
  React.useEffect(() => {
    const formValues = Object.values({
      ...accountInfo,
      showPassword: 'as',
    });
    const isAllFilled = formValues.every((f: any) => String(f).length > 0);
    const isNoValidation = Object.values(validationMessage).every(
      (f: any) => f.length === 0,
    );
    console.log(formValues, isNoValidation);
    console.log(validationMessage);

    if (isAllFilled && isNoValidation) {
      setIsFormComplete(true);
    } else setIsFormComplete(false);
  }, [accountInfo, validationMessage]);

  const handleInfoChange = (props: any) => (e: any) => {
    let _info = { ...accountInfo };
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    _info[props] = e.target.value;
    setAccountInfo(_info);

    if (props === 'new_password') {
      if (e.target.value.length <= 7) {
        setValidationMessage({
          ...validationMessage,
          new_password: 'パスワードは8文字以上入力してください',
        });
      } else if (
        //confirmPasswordが入力された後に再度PWが変更されたら

        e.target.value !== accountInfo.re_new_password &&
        accountInfo.re_new_password.length > 0
      ) {
        setValidationMessage({
          ...validationMessage,
          ...{
            re_new_password: 'パスワードが一致していません',
            new_password: '',
          },
        });
      } else {
        setValidationMessage({
          ...validationMessage,
          new_password: '',
        });
      }
    }
    if (props === 're_new_password') {
      let message = '';
      if (e.target.value === accountInfo.new_password) {
        setValidationMessage({
          ...validationMessage,
          re_new_password: '',
        });
      } else {
        message = 'パスワードが一致していません';
        setValidationMessage({
          ...validationMessage,
          re_new_password: message,
        });
      }
    }
  };

  const handleSendClick = () => {
    const sendInfo = {
      current_password: accountInfo.current_password,
      new_password: accountInfo.new_password,
      re_new_password: accountInfo.re_new_password,
    };
    console.log(sendInfo);
    setIsUploading(true);
    postPasswordChange(sendInfo)
      .then((res) => {
        //alert('account created');
        setIsUploading(false);
        console.log(res.data);
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
        setIsUploading(false);
      });
  };

  const handleClickShowPassword = () => {
    setAccountInfo({
      ...accountInfo,
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ showPassword: boolean; current... Remove this comment to see the full error message
      showPassword: !(accountInfo as any).showPassword,
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <div style={{ textAlign: 'center' }}>
      パスワード再設定
      <div>
        <FormControl style={{ width: '70%' }}>
          <InputLabel htmlFor="standard-adornment-password">
            現在のパスワード
          </InputLabel>
          <Input
            id="standard-adornment-password"
            value={accountInfo.current_password}
            type={(accountInfo as any).showPassword ? 'text' : 'password'}
            onChange={handleInfoChange('current_password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {(accountInfo as any).showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText
            id="standard-adornment-password"
            style={{ color: 'red' }}
          >
            {validationMessage.current_password}
          </FormHelperText>
        </FormControl>
      </div>
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        新しいパスワードを入力
      </div>
      <div>
        <FormControl style={{ width: '70%' }}>
          <InputLabel htmlFor="standard-adornment-password">
            新しいパスワード
          </InputLabel>
          <Input
            id="standard-adornment-password"
            value={accountInfo.new_password}
            type={(accountInfo as any).showPassword ? 'text' : 'password'}
            onChange={handleInfoChange('new_password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {(accountInfo as any).showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText
            id="standard-adornment-password"
            style={{ color: 'red' }}
          >
            {validationMessage.new_password}
          </FormHelperText>
        </FormControl>

        <FormControl style={{ width: '70%', marginTop: '2%' }}>
          <InputLabel htmlFor="standard-adornment-password">
            パスワード再入力
          </InputLabel>
          <Input
            id="standard-adornment-password2"
            value={accountInfo.re_new_password}
            type={(accountInfo as any).showPassword ? 'text' : 'password'}
            onChange={handleInfoChange('re_new_password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {(accountInfo as any).showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText
            id="standard-adornment-password2"
            style={{ color: 'red' }}
          >
            {validationMessage.re_new_password}
          </FormHelperText>
        </FormControl>
      </div>
      {isFormComplete ? (
        isUploading ? (
          <Button variant="contained" disabled style={{ marginTop: '30px' }}>
            送信 <CircularProgress size={20} />
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '30px' }}
            onClick={handleSendClick}
          >
            送信
          </Button>
        )
      ) : (
        <Button variant="contained" disabled style={{ marginTop: '30px' }}>
          送信
        </Button>
      )}
    </div>
  );
};
