import { menuType } from 'pages/PracticePage/type';
import posture_trac from 'components/MenuConst/thumbnails/posture_trac.png';
import senobi_trac from 'components/MenuConst/thumbnails/senobi_trac.png';
import sonkyo_trac from 'components/MenuConst/thumbnails/sonkyo_trac.png';
import { startPosture } from './videos';

export const StartPosture: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'スタートのしせい！ #昇級テスト',
  isInside: 'able',
  target: ['しせい'],
  menuVariation: ['tech'],
  hitWordList: ['姿勢', 'しせい'],
  grade: 0,
  image: sonkyo_trac,
  video: startPosture,
};
