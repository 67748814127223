import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import 'index.css';
import 'App.css';
import sampleThumbnail from 'static/sample_thumbnail.png';

export const CardForFirstLogin = () => {
  const navigate = useNavigate();
  return (
    <div id="videoCard">
      <Grid container>
        <Grid item xs={4}>
          <div>
            <img
              alt=""
              onClick={() => {
                navigate(`/result-share/sample`);
              }}
              width="95%"
              src={sampleThumbnail}
            ></img>
          </div>
        </Grid>

        <Grid item xs={8}>
          <div style={{ textAlign: 'left' }}>
            <div
              style={{ fontSize: '11pt', display: 'flex' }}
              onClick={() => {
                navigate(`/result-share/sample`);
              }}
            >
              サンプル動画を見る
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
