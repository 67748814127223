import React, { FC, ChangeEventHandler } from 'react';
import 'index.css';

type argTypes = {
  onChooseVideo: () => void;
};

export const useVideoUploadComponent = ({ onChooseVideo }: argTypes) => {
  const [file, setFile] = React.useState<File | undefined>(undefined);
  const [videoSrc, setVideoSrc] = React.useState<string | undefined>(undefined);

  return {
    file: file,
    uploadButton: (
      <UploadButton
        setFile={setFile}
        videoSrc={videoSrc}
        setVideoSrc={setVideoSrc}
        onChooseVideo={onChooseVideo}
      />
    ),
  };
};

type Props = {
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  videoSrc: string | undefined;
  setVideoSrc: React.Dispatch<React.SetStateAction<string | undefined>>;
  onChooseVideo: () => void;
};

export const UploadButton: FC<Props> = ({
  setFile,
  videoSrc,
  setVideoSrc,
  onChooseVideo,
}) => {
  const chooseVideo: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files;
    if (!files) {
      return;
    }
    setFile(files[0]);
    const videoUrl = window.URL.createObjectURL(files[0]);
    setVideoSrc(videoUrl);
    onChooseVideo();
  };

  return (
    <div style={{ margin: '16px 0px 0px 0px' }}>
      <label className="inputwrapper">
        動画を選択
        <input type="file" accept="video/*" onChange={chooseVideo} />
      </label>
      <div style={{ paddingTop: '10px' }}>
        {!!videoSrc ? (
          <div>
            <video
              id="v"
              src={videoSrc + '#t=0.1'}
              height="120px"
              width="200px"
              muted
            ></video>
            {/* <canvas id="c" width="0px" height="0px" /> */}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};
