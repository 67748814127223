import React, { memo, FC } from 'react';
import { Button, Card, Grid } from '@material-ui/core';
import { IntroductionVideo } from './IntroductionVideo';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { RefreshButton } from 'utils/components/RefreshButton';
import SyncIcon from '@mui/icons-material/Sync';

type Props = {
  handleClick: () => void;
  video: VideoType | null;
  isRefreshing: boolean;
  handleUpdate: () => void;
};

export const FirstScreen: FC<Props> = memo(
  ({ handleClick, video, isRefreshing, handleUpdate }) => {
    return (
      <div style={{ marginTop: '16px' }}>
        {(!!video && (
          <>
            <Card style={{ margin: '10px', padding: '8px' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '50%' }}>
                  <img width="90%" alt="" src={`${video.thumbnail}`} />{' '}
                </div>
                {(!video.score && (
                  <div style={{ width: '90%', textAlign: 'left' }}>
                    <Grid container>
                      <Grid item xs={9}>
                        <div style={{ display: 'flex' }}>
                          <div style={{ color: '#424242', fontSize: '10pt' }}>
                            診断中 🔎
                          </div>
                        </div>

                        <div
                          style={{
                            color: '#424242',
                            fontSize: '10pt',
                            marginTop: '0px',
                          }}
                        >
                          <span>完了目安:</span>
                          <span style={{ color: '#ff69b4' }}>
                            {formatTime(video?.estimated_complete_date || '')}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <RefreshButton
                          title={'更新'}
                          isLoading={isRefreshing}
                          handleUpdate={handleUpdate}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )) || (
                  <div
                    style={{
                      width: '40%',
                      textAlign: 'left',
                      marginLeft: '24px',
                    }}
                  >
                    <div
                      style={{
                        color: 'white',
                        fontSize: '10pt',
                        fontWeight: 'bold',
                        borderRadius: '30px',
                        border: 'solid 1px',
                        backgroundColor: '#4169e1',
                        textAlign: 'center',
                      }}
                    >
                      診断完了！
                    </div>
                  </div>
                )}
              </div>
            </Card>
            <div
              style={{
                color: '#424242',
                margin: '16px 16px 10px 16px',
                textAlign: 'center',
                fontSize: '12pt',
              }}
            >
              動画は
              <span style={{ color: '#ff69b4' }}>
                {formatTime(video?.estimated_complete_date || '')}
              </span>
              に診断完了予定ですので
              <br />
              お待ちの間ぜひ下の動画をご覧下さい 🎥
              <div style={{ margin: '16px 0 24px 0' }}>
                <IntroductionVideo />
              </div>
              <div style={{ margin: '8px 16px 10px 16px', textAlign: 'left' }}>
                時間になりましたら右上の&nbsp;
                <span style={{ color: '#4169e1' }}>
                  更新
                  <SyncIcon style={{ fontSize: '14pt', marginTop: '4px' }} />
                </span>
                &nbsp; を押してみて下さい。
              </div>
            </div>
          </>
        )) || <></>}

        <div style={{ marginTop: '24px' }}>
          {(!video?.score && (
            <Button disabled variant="contained">
              診断結果を見る！
            </Button>
          )) || (
            <Button onClick={handleClick} variant="contained" color="primary">
              診断結果を見る！
            </Button>
          )}
        </div>
      </div>
    );
  },
);

const formatTime = (timeStamp: string) => {
  const TokyoDate = new Date(timeStamp);

  const time = `${TokyoDate.getHours()}時${TokyoDate.getMinutes() + 1}分`;

  return time;
};
