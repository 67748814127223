import React, { FC } from 'react';
import { YoutubeVideoPlayer } from 'utils/components/YoutubeVideoPlayer';
import { Button } from '@material-ui/core';
import shooting from 'static/lp/first_shooting.png';
import onegai from 'static/lp/onegai.png';

type Props = {
  handleNextClick: () => void;
};
export const ExplanationScreen: FC<Props> = ({ handleNextClick }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ margin: '16px 0 0 8px' }}>
        <img src={shooting} width="90%" alt="撮影方法" />
      </div>
      <div style={{ color: '#424242' }}>
        <div style={{ fontWeight: 'bold', fontSize: '14pt' }}>
          30m走をして動画を撮影しよう！
        </div>
        <div
          style={{
            textAlign: 'left',
            width: '90%',
            marginLeft: '5%',
            marginTop: '24px',
          }}
        >
          <div style={{ fontWeight: 'bold', fontSize: '12pt' }}>
            <span style={{ textDecoration: 'underline 1px solid' }}>場所</span>
            <span>：30m程度の直線ならどこでもOK！</span>
            <br />
            <div style={{ fontWeight: 'normal', fontSize: '10pt' }}>
              &nbsp;※
              厳密に30mを測る必要はありません！下記の動画のようなものが撮れればどこでも大丈夫です。
            </div>
            <div style={{ fontWeight: 'normal', fontSize: '10pt' }}>
              &nbsp;※ 公園やお家の前の道路で撮影される方が多いです。
            </div>
          </div>

          <div
            style={{ fontWeight: 'bold', fontSize: '12pt', marginTop: '8px' }}
          >
            <span style={{ textDecoration: 'underline 1px solid' }}>服装</span>
            <span>：動きの見えやすい服装で走ろう！</span>
            <br />
            <div style={{ fontWeight: 'normal', fontSize: '10pt' }}>
              &nbsp;※ スカートやダボダボの服は避けて下さい。
            </div>
          </div>

          <div
            style={{ fontWeight: 'bold', fontSize: '12pt', marginTop: '8px' }}
          >
            <span style={{ textDecoration: 'underline 1px solid' }}>
              その他
            </span>
            <span>：走るのは1人で明るい場所で撮ろう！</span>
            <br />
            <div style={{ fontWeight: 'normal', fontSize: '10pt' }}>
              &nbsp;※
              AIがお子様を判別できなくなるため、お子様同士の並走もお控え下さい。
            </div>
          </div>
        </div>
        <div style={{ margin: '16px 0 8px 0' }}>
          <img src={onegai} width="90%" alt="おねがい" />
        </div>
        <div style={{ fontWeight: 'bold', fontSize: '12pt' }}>
          撮影前に以下の動画を
          <span style={{ color: '#ff0000' }}>必ず一度</span>ご覧下さい！
        </div>
      </div>
      <YoutubeVideoPlayer
        srcURL="https://www.youtube.com/embed/X4x_Adf490I"
        ratio={0.95}
      />
      <div
        style={{
          fontWeight: 'bold',
          fontSize: '11pt',
          color: '#424242',
          marginLeft: '10px',
        }}
      >
        ★ 16m地点と23m地点に目印を置くと撮りやすい！
      </div>

      <div
        style={{
          fontWeight: 'bold',
          fontSize: '11pt',
          color: '#424242',
          width: '94%',
          backgroundColor: 'white',
          border: '3px solid #ffa500',
          borderRadius: '4px',
          margin: '16px 0 12px 2%',
        }}
      >
        撮影した動画は <span style={{ color: '#ff0000' }}>必ず一度</span>
        &nbsp;ご確認下さい！
        <div style={{ textAlign: 'left', margin: '8px 8px 8px 8px' }}>
          ✅ スタートから20m地点を撮影している。
          <br />✅ 背景に走っている人が映っていない。
          <br />✅ 6歩程度映っている。
        </div>
      </div>

      <div
        style={{
          fontWeight: 'bold',
          fontSize: '11pt',
          color: '#424242',
          marginLeft: '10px',
        }}
      >
        {'確認できたら「次へ >>」ボタンをタップ！'}
      </div>

      <div style={{ margin: '16px 0 32px 0' }}>
        <Button onClick={handleNextClick} variant="contained" color="primary">
          {' 次へ >> '}
        </Button>
      </div>
      <div>
        <br />
      </div>
    </div>
  );
};
