import React from 'react';
import kabeoshi_Ade_thumb from 'components/MenuConst/thumbnails/kabeoshi_seq.png';
import inclinationdash_thumb from 'components/MenuConst/thumbnails/inclination_dash.png';
import skaterjump_thumb from 'components/MenuConst/thumbnails/skaterjump.jpg';
import dash_10m_thumb from 'components/MenuConst/thumbnails/dash_10m.jpg';
import aboveSkip from 'components/MenuConst/thumbnails/above_skip.png';
import howto_50m_thumb from 'components/MenuConst/thumbnails/howto50m.jpg';
import teoshiguruma_thumb from 'components/MenuConst/thumbnails/teoshiguruma.jpg';

import challenge11th_thumb from 'components/MenuConst/thumbnails/challenge_11th.png';
import intro_11th_thumb from 'components/MenuConst/thumbnails/intro_11th.jpg';
import { menuType } from 'pages/PracticePage/type';

import {
  re_kabeoshiAdeVideos,
  taorekomidash11thVideos,
  dash10m11thVideos,
  howto50m_11thVideos,
  teoshigurumaVideos
} from './videos';

import {
  skaterJumpVideos,
} from '../grade_6/videos';

import {
  skipAdvVideos,
} from '../grade_8/videos';

import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';

// up: 準備, tech: ぎじゅつ, run: 体力, family: 家族

// プレイリスト

////
//up
////
export const re_kabeoshiAde: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'かべおし上級復習',
  isInside: 'able',
  target: ['スタート'],
  menuVariation: ['up'],
  hitWordList: [
    'かべおし',
    '壁',
  ],
  grade: 9,
  image: kabeoshi_Ade_thumb,
  video: re_kabeoshiAdeVideos,
};

export const inclinationdash_11th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'たおれこみダッシュ',
  isInside: 'unable',
  target: ['スタート'],
  menuVariation: ['up'],
  hitWordList: [
    'たおれこみ',
    '倒れ',
  ],
  grade: 9,
  image: inclinationdash_thumb,
  video: taorekomidash11thVideos,
};


////
//tech
////

export const skaterJump_11th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'スケータージャンプ',
  isInside: 'half',
  target: ['スタート'],
  menuVariation: ['tech'],
  hitWordList: [
    'スケータージャンプ',
    'すけーたー',
  ],
  grade: 9,
  image: skaterjump_thumb,
  video: skaterJumpVideos,
};

export const dash10m_11th: menuType = {
  duration: 5,
  title: '10mダッシュ ☆昇級テスト',
  target: ['スタート'],
  isInside: 'unable',
  menuVariation: ['tech'],
  hitWordList: [
    'スタート',
    'すたーと',
    '10',
    'じゅう',
    'ダッシュ',
    'だっしゅ',
  ],
  grade: 9,
  image: dash_10m_thumb,
  video: dash10m11thVideos,
};


////
//run
////

export const SkipAde_11th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '3歩スキップ',
  isInside: 'unable',
  target: ['スタート'],
  menuVariation: ['run'],
  hitWordList: [
    '3',
    'さん',
    'スキップ',
    'すきっぷ',
  ],
  grade: 9,
  image: aboveSkip,
  video: skipAdvVideos,
};

export const howto50m_11th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '10m以降の走り方',
  isInside: 'unable',
  target: ['スタート', '全力走'],
  menuVariation: ['run'],
  hitWordList: [
    '10',
    '50',
    'はしりかた',
    '走り方',
  ],
  grade: 9,
  image: howto_50m_thumb,
  video: howto50m_11thVideos,
};


////
//family
////

export const teoshiguruma: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '手押し車',
  isInside: 'able',
  target: ['しせい'],
  menuVariation: ['family'],
  hitWordList: [
    '10',
    '50',
    'はしりかた',
    '走り方',
  ],
  grade: 9,
  image: teoshiguruma_thumb,
  video: teoshigurumaVideos,
};


////
//levelup
////


export const challenge_11th: menuType = {
  duration: 5,
  hitWordList: ['11', '昇級', 'Y字', 'ワイ', 'Y', 'スプリント', 'タオル'],
  title: '11級の昇級用メニュー',
  grade: 9,
  menuVariation: ['levelUp'],
  image: challenge11th_thumb,
  isInside: 'unable',
  target: ['しせい'],
  video: [
    {
      link: 'https://www.youtube.com/embed/1BqgOS35y-M',
      title: '11級の昇級用メニュー',
      message: (
        <span>
          11級の昇級テスト挑戦動画はコチラ！
          <br />
          ☆動画の指示に従って撮影し、画面右下の「+ボタン」から「昇級に挑戦」をタップして、動画を送信しよう！
          <br />
          ☆詳しい練習方法やポイントは「おすすめ」から確認！
        </span>
      ),
    },
  ],
};

export const intro_11th: menuType = {
  duration: 2,
  isInside: 'able',
  target: ['しせい'],
  hitWordList: ['11', '昇級', 'メッセージ', 'めっせーじ', 'じゅう'],
  title: '11級の方へのメッセージ',
  menuVariation: ['levelUp'],
  grade: 9,
  image: intro_11th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['11'],
      title: 'intro11th',
      message: <span>11級の方へのメッセージはコチラ！ </span>,
    },
  ],
};
