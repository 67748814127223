import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';

////
//up
////

//列車はgrade_4のtrainVideosを使う


export const cycleDash7thVideos = [
  {
    link: 'https://www.youtube.com/embed/ApkuMRS9CFU',
    title: '自転車ダッシュ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「自転車ダッシュ20m」× 3本を行う！！
          <br/>
          ☆9級昇級テストでの先生からのコメントを見直してからやってみてね！！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ふくしゅうのポイント
        </div>
        1. よい姿勢 ☆たおしすぎていないかな？あごを引いて15m先に地面を見よう！
        <br />
        2. 足を体の前で回す
        ☆足が自然とまえにもどってきて、自然とからだの前で回る感覚がつかめたらGood！
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ2つができた子はチャレンジ！～
        </div>
        3. 足のつき方 ☆ぼしきゅうからつけているかな？
        <br />
        4. スピードを出す
        ☆なれてきたらチャレンジ！思いっきり走る練習はとてもだいじ！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/7VuxJpha3sY',
    title: '自転車からのダッシュ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「自転車ダッシュ10m⇒腕を下ろしてダッシュ15m」× 2本を行う！！
        </div>
        ☆腕を下ろした後もあしが自然と戻ってくる（あしが自然とからだの前で回る）感覚がつかめたらGood！
      </div>
    ),
  },
];



////
//tech
////

export const threeStep7thVideos = [
  {
    link: 'https://www.youtube.com/embed/1isIaXlEFZw',
    title: 'ベースポジションジャンプ移動',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「ベースポジションジャンプ移動」をたくさんやってみる（目安3～5分）！！
        </div>
        ☆両方の足でやってみよう！かっこいい姿勢の移動できるかな？
        <br />
        ☆よろしければ保護者の方は肩を軽く押してあげて下さい！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/9cuO2W7C18w',
    title: 'ベースポジション踏みかえ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「ベースポジションジャンプ踏みかえ」をふくしゅう！！
        </div>
        ☆「空中であしを入れかえる！」が連続10回出来たら合格！
        <br />
        ☆あしを入れかえる際、太鼓をたたくイメージで腕を振ってリズムをとるとやりやすいかも！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/aVvoKl6RIgo',
    title: 'ベースポジション3歩ステップ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3:「ベースポジション3歩ステップ」を8級昇級テストでの先生からのコメントをもとに復習！
          <br/>
          ⇒「ベースポジション3歩ステップ10m⇒そのまま15mダッシュ」を3～5本おこなう！！
          <br/>
          ☆ダッシュの時も体の前で足をスパンスパンいれかえられるかな？？
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ふくしゅうのポイント
        </div>
        1. 頭から足までまっすぐ
        ☆これがとにかく一番大事！おしりはたか～い位置にあるかな？1歩1歩で前に進もうとしすぎないこと！
        <br />
        2. （1.2のとき）ベースポジションで進めている
        ☆ふとももがちゃんとあがっているかな？
        <br />
        3. （3のとき）あしを体の前で入れかえる
        ☆腕も下に振ってリズムをとってみよう！
      </div>
    ),
  },
];


export const markRun7thVideos = [
  {
    link: 'https://www.youtube.com/embed/aas9F7szROM',
    title: 'マーク走の準備の仕方',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: マーク走の準備の仕方を確認
        </div>
        ☆マークのない場合は、「地面に線を引く」や「マークに代わるものを置く（踏んでも足をひねらないような薄いものやつぶれるものを推奨）」等で代用可能です！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/60A_GIb-laQ',
    title: 'マーク走',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「マーク走」をたくさん行う！！
        </div>
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本を送信！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          昇級のポイント
        </div>
        1. よい姿勢 ☆たおしすぎていないかな？あごを引いて15m先に地面を見よう！
        <br />
        2. 足を体の前で回す ☆あしを体の前で入れかえることが出来ているかな？
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ2つができた子はチャレンジ！～
        </div>
        3. 足のつき方 ☆ぼしきゅうからつけているかな？
        <br />
        4. うで振り ☆下にポンポンふろう！
        <br />
        5. スピードを出す
        ☆なれてきたらチャレンジ！思いっきり走る練習はとてもだいじ！

      </div>

    ),
  },
];


////
//run
////

export const dash30m7thVideos = [

  {
    link: 'https://www.youtube.com/embed/p94g4g_NmHc',
    title: 'スタートの構えの作り方',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: スタートの構えから30mダッシュをやってみよう！
          <br />
          スタートはぐいぐい走ったうえで、20m過ぎからマーク走の時のように走れるかな？？
        </div>
        <div className={classes.content}>
          ☆復習用に動画1:スタートの構えの作り方、動画2:10mまでの走り方、動画3:10mから20mまでの走り方、動画45:20mいこうの走り方をおいてあります！ご活用ください！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/SbSr3_5aXTI',
    title: '1次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「スタートから10mまでの走り方」の解説動画です！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          復習のポイント
        </div>
        1. （スタートの構え）前あしに体重をのせる
        ☆後ろ足のかかとはういているかな？前あしの膝が足首のだいたい真上にあるかな？
        <br />
        2. （スタートの構え）いい姿勢で体をたおす
        ☆横から見て上体と後ろ足がまっすぐかな？目線は3m先を見れているかな？
        <br />
        3. しっかり体を倒す
        ☆6歩以上体をたおして走ろう！あごをひいて、目線は5m先くらい！
        <br />
        ※のぼり坂で10mダッシュの練習をすると意識しやすいかも！近場にのぼり坂のある子は試してみてね！
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ3つができた子はチャレンジ！～
        </div>
        4. 足を前に前に ☆かべおしのときと同じ！
        <br />
        5. 腕ふり ☆前に前にふろう！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/6HdnLynUPT4',
    title: '2次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3:「10mから20mまでの走り方」の解説動画です！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/lFQtglK4VFI',
    title: '中間走の走り方',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画4:「20mいこうの走り方」の解説動画です！
        </div>
      </div>
    ),
  },
];


////
//family
////





