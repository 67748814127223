import React, { FC, memo, useState, useEffect } from 'react';
import treasure_box from 'static/materials/treasure_box.png';
import treasure_box_open from 'static/materials/treasure_box_open.png';
import { useMessageVideoModal } from 'hooks/useMessageVideoModal';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { onClickFuncType, onboardType } from './type';
import classes from './guidanceTools.module.css';

type Props = {
  runner: RunnerType;
  onClickFunc: onClickFuncType;
  onboardState: onboardType;
};

export const TreasureBox: FC<Props> = memo(
  ({ runner, onClickFunc, onboardState }) => {
    const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false);
    const handleClose = () => {
      setIsBoxOpen(false);
      forceModalOpen(false);

      // ここでonboardPhaseがこうなるのは、一定の条件下なので本当はif文が入る
      const phase = localStorage.getItem(String(runner.id)) || 'yet';
      if (['yet', 'beforeMsg'].includes(phase) && runner.grade === 0) {
        localStorage.setItem(String(runner.id), 'beforePractice');
      }
      if (
        ['yet', 'beforeMsg', 'beforePractice'].includes(phase) &&
        runner.grade > 0
      ) {
        localStorage.setItem(String(runner.id), 'beforeChallenge');
      }
      onClickFunc();
    };

    const { forceModalOpen, MessageMovieModal } = useMessageVideoModal(
      runner,
      handleClose,
    );
    useEffect(() => {
      setIsBoxOpen(false);
    }, [runner]);

    const handleOpen = () => {
      setIsBoxOpen(true);
      setTimeout(() => {
        forceModalOpen(true);
      }, 200);
    };
    return (
      <>
        <div className={classes.treasureBoxPosition}>
          {MessageMovieModal}
          {onboardState === 'beforeMsg' && (
            <div style={{ display: 'flex' }}>
              <div className={classes.shouldBeTouched}>
                {(isBoxOpen && (
                  <div className={classes.treasureBox} onClick={handleClose}>
                    <img src={treasure_box_open} alt="" width="70%"></img>
                  </div>
                )) || (
                  <div className={classes.treasureBox} onClick={handleOpen}>
                    <img src={treasure_box} alt="" width="60%"></img>
                  </div>
                )}
              </div>
              <div className={classes.treasureBoxExplanation}>なにかな？</div>
            </div>
          )}

          {onboardState !== 'beforeMsg' && (
            <div className={classes.canTouch}>
              {(isBoxOpen && (
                <div className={classes.treasureBox} onClick={handleClose}>
                  <img src={treasure_box_open} alt="" width="70%"></img>
                </div>
              )) || (
                <div className={classes.treasureBox} onClick={handleOpen}>
                  <img src={treasure_box} alt="" width="70%"></img>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    );
  },
);
