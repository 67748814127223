import { ckptDetails_9_20 } from 'components/MenuConst/GoalsForEachGrade/CheckpointsFor20Pkg/11_kyu';

type options = {
  [key in ckptDetails_9_20]: string[];
};

export const optionsForGrade_9_20pkg: options = {
  六歩以上体を倒せている: [
    '◯:倒せている',
    '×:すぐ起きてしまっている',
    '◯:やや起きるのが早い',
  ],
  足の流れ: ['◯:出来ている', '×:後ろで大きく回っている'],
  足を壁押しのように動かす: [
    '◯:出来ている',
    '×:無理に体の前で漕ぐようにうごかしている',
  ],
  足の上がり: ['◯:90度程度上がっている（上げ過ぎは許容）', '×:上がっていない'],
  リーチアウト: [
    '◯:していない（足首が膝より前に出ていない）',
    '×:足が開いている',
  ],
  うでを前に前に振る: ['◯:前に振れている', '×:振れていない'],
};
