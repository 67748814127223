import React, { FC, useState, useCallback } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

export const useSnackBar = (message: string) => {
  const [open, setOpen] = useState<boolean>(false);
  const forceSnackOpen = useCallback((openOrClose: boolean) => {
    setOpen(openOrClose);
  }, []);

  const handleClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    },
    [],
  );
  return {
    forceSnackOpen: forceSnackOpen,
    CustomSnackbar: (
      <CustomizedSnackbars
        open={open}
        handleClose={handleClose}
        message={message}
      />
    ),
  };
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type Props = {
  open: boolean;
  handleClose: () => void;
  message: string;
};

export const CustomizedSnackbars: FC<Props> = ({
  open,
  handleClose,
  message,
}) => {
  return (
    <>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
