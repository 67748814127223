import React, { FC, useEffect, useState } from 'react';
import Button from '@mui/material/Button';

type Props = {
  onClick: any;
};
export const ShareButton: FC<Props> = ({ onClick }) => {
  return (
    <Button onClick={onClick} variant="outlined" style={{ margin: '14px' }}>
      <span style={{ fontWeight: 'bold', fontSize: '12pt' }}>
        {' '}
        ともだちに見せる！
      </span>
    </Button>
  );
};
