import React, { FC, memo } from 'react';
import classes from './onboardingCarousel.module.css';
import joy_girl from 'static/materials/joy_girl.png';
import enjoy_result from 'static/materials/enjoy_result_new.png';

export const SeventhView: FC = memo(() => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '20%' }}>
          <img src={joy_girl} alt="" width="60%"></img>
        </div>
        <div style={{ width: '80%' }}>
          <div className={classes.orangeBorder}>
            チャレンジ後は <br />
            ドキドキの結果発表！
          </div>
        </div>
      </div>
      <div style={{ marginTop: '8px' }}>
        <img src={enjoy_result} alt="" width="85%"></img>
      </div>
    </>
  );
});
