// 10級パッケージの10級
type ckptTypeForGrade_0_10kyu =
  | '体がまっすぐ'
  | 'ポンポン走る'
  | 'スピードを出す';

export const ckptForGrade_0_10kyu: ckptTypeForGrade_0_10kyu[] = [
  '体がまっすぐ',
  'ポンポン走る',
  'スピードを出す',
];

export const requirement_0_10kyu: ckptTypeForGrade_0_10kyu[] = [
  '体がまっすぐ',
  'ポンポン走る',
];

export type ckptDetails =
  | '前後傾'
  | '腕が体と平行である'
  | '腰が落ちていない'
  | '接地様式'
  | 'スピードが出せている'
  | '足が最低限上がっている'; //採点時のみ利用

type allCheckpoints_0_10kyu_type = {
  [key in ckptTypeForGrade_0_10kyu]: ckptDetails[];
};

export const allCheckPoints_0_10kyu: allCheckpoints_0_10kyu_type = {
  体がまっすぐ: ['前後傾', '腕が体と平行である'],
  ポンポン走る: ['腰が落ちていない', '接地様式'],
  スピードを出す: ['スピードが出せている'],
};

export const borderLine_0_10kyu: allCheckpoints_0_10kyu_type = {
  体がまっすぐ: ['前後傾'],
  ポンポン走る: ['腰が落ちていない'],
  スピードを出す: ['スピードが出せている'],
};
