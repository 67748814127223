import React, { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import '../calendar.css';
import { VideoLabelForUser } from './VideoLabelForUser';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { ImgWithLoading } from './ImgWithLoading';

type Props = {
  videos: VideoType[];
};

export const VideoListByDate: FC<Props> = memo(({ videos }) => {
  const navigate = useNavigate();

  const changeVideoId = useCallback(
    (vId: string) => () => {
      navigate(`/logs/result/${vId}`);
    },
    [],
  );

  return (
    <div style={{ textAlign: 'center', width: '85vw' }}>
      <div
        style={{
          margin: '3px',
          overflowY: 'scroll',
          maxHeight: `${window.parent.screen.height * 0.55}px`,
          border: '3px solid #EEEEEE',
        }}
      >
        {videos.map((vObj, idx) => {
          return (
            <VideoCard key={idx} video={vObj} handleClick={changeVideoId} />
          );
        })}
      </div>
    </div>
  );
});

type VideoCardProp = {
  video: VideoType;
  handleClick: (s: string) => () => void;
};

export const VideoCard: FC<VideoCardProp> = ({ video, handleClick }) => {
  return (
    <div id="videoCardByDay">
      <Grid container>
        <Grid item xs={4}>
          <div>
            <ImgWithLoading
              src={`${video.thumbnail}`}
              width="90%"
              onClickFunc={handleClick(video.id)}
            />
          </div>
        </Grid>

        <Grid item xs={5}>
          <div style={{ textAlign: 'left' }}>
            <div style={{ fontSize: '9pt', display: 'flex' }}>
              <div onClick={handleClick(video.id)}>
                <div
                  style={{
                    color: '#666666',
                    fontWeight: 'bold',
                  }}
                >
                  コメント
                </div>
                <div
                  style={{
                    paddingLeft: '6px',
                    fontSize: '10pt',
                    color: '#333333',
                  }}
                >
                  {' '}
                  {restrictCharLength(video.video_memo, 16)}
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ marginLeft: '5px' }}>
            <VideoLabelForUser video={video} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

function restrictCharLength(char: string, maxLength: number) {
  if (!char) {
    return '特になし';
  }
  if (String(char).length < maxLength) {
    return String(char);
  }
  return String(char).substring(0, maxLength) + '...';
}
