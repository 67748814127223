import { ckptDetails_12_20 } from 'components/MenuConst/GoalsForEachGrade/CheckpointsFor20Pkg/8_kyu';

type options = {
  [key in ckptDetails_12_20]: string[];
};

export const optionsForGrade_12_20pkg: options = {
  前後傾: ['◯:ちょうど良い', '×:前傾しすぎ', '×:後傾しすぎ', '◯:やや後傾'],
  腰が落ちていない: ['◯:腰高い', '×:落ちている（膝のつぶれ）'],
  足の上がり: ['◯:足が上がっている', '×:足が上がっていない'],
  後ろに蹴っていない: ['◯:できている', '×:蹴ってしまっている'],
  上げた足をキープできている: ['◯:できている', '×:上下している'],
  足をからだの前でいれかえる: [
    '◯:できている',
    '×:出来ていない',
    '◯:少しタイミング遅れ',
  ],
  うで振り: ['◯:できている', '×:出来ていない'],
  リーチアウト: [
    '◯:していない（足首が膝より前に出ていない）',
    '×:足が開いている',
  ],
};
