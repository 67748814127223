import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from 'contexts/AccountContext';

import './AccountSwitch.css';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

export const RunnerSwitcher = (props: any) => {
  const navigate = useNavigate();
  const { setCurrentRunner } = React.useContext(AccountContext);

  const { runnerList, goAddNewRunner } = props;
  const handleChangeRunner = (runner: any) => () => {
    setCurrentRunner(runner);
    localStorage.setItem('currentRunner', String(runner.id));
    props.handleClose();
    navigate('/');
  };
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; class: string; }' is ... Remove this comment to see the full error message
    <div className="menu">
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: string; for: string; }' is not a... Remove this comment to see the full error message */}
      <label htmlFor="menu_bar01">ランナーの切り替え</label>
      <input type="checkbox" id="menu_bar01" />
      <ul id="links01">
        {runnerList.map((runner: any) => {
          return (
            <li onClick={handleChangeRunner(runner)}>
              <span id="name_icon">
                <span>{runner.username.split('')[0]}</span>
              </span>
              &nbsp;
              {runner.username}
            </li>
          );
        })}
        <li
          onClick={goAddNewRunner}
          style={{ fontSize: '10pt', display: 'flex' }}
        >
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <GroupAddIcon size="small" />
          <span style={{ marginLeft: '3px' }}>ランナーを追加</span>
        </li>
      </ul>
    </div>
  );
};
