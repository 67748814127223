// TRAC10級パッケージの8級

export const ckptFor8kyu_trac10 = [
  'しせい',
  '足のつきかた',
  'つま先のむき',
  'ひざ',
] as const;

export type ckpt_8kyu_type = typeof ckptFor8kyu_trac10[number];

export const requirement_8kyu_trac: ckpt_8kyu_type[] = [
  'しせい',
  '足のつきかた',
  'つま先のむき',
];

type options = {
  [key in ckpt_8kyu_type]: string[];
};

export const optionDict_8kyu_trac: options = {
  しせい: ['◯良い', '×悪い'],
  足のつきかた: ['◯良い', '×つま先すぎる', '×踵から接地', '×足全体で接地'],
  つま先のむき: ['◯前を向いている', '×下を向いている'],
  ひざ: ['◯あまり曲がっていない', '×大きく曲がっている'],
};

type complimentType = {
  [key in ckpt_8kyu_type]: string;
};

// まずxxくんの良い点として、に続く文章を書く
export const complimentDict_8: complimentType = {
  しせい:
    'ジャンプのときも走るときもとても良い姿勢を作れていました！基本の姿勢はバッチリです！',
  足のつきかた:
    '正しい位置で地面に足をつけている点があります！「足の親指のつけねのあたり」でつくということを今後も忘れないようにしましょう！',
  つま先のむき:
    'しっかりとつま先が前を向いている点があります！つま先の向きは難しいポイントですが、よく頑張りましたね！',
  ひざ: '膝の曲がりが少なくしっかり弾めている点があります！走りの中でもこの「弾む感じ」を忘れないようにしましょう！',
};

export const nobishiroOptions_8_trac = Object.values(optionDict_8kyu_trac)
  .flat()
  .filter((value) => !value.includes('◯'));

type nobishiroDict = {
  [key in typeof nobishiroOptions_8_trac[number]]?: string;
};

// そんな〇〇くんの伸びしろポイントはズバリ、に続く文章を書く
export const nobishiroDict_8: nobishiroDict = {
  '×悪い':
    '「弾むときの姿勢が曲がってしまっていること」です！はじめは大きくジャンプしなくて良いので、まずは小さくでも「良いしせいで弾む」ことを意識しましょう！',
  '×つま先すぎる':
    '「着地する場所がつま先に近すぎる」ことです。もう少しだけ親指の付け根近くで着地できるように練習してみましょう！',
  '×踵から接地':
    '「かかとから足をついてしまっている」ことです！もう一度その場ジャンプなどで足のつく位置を確認してみましょう！',
  '×足全体で接地':
    '足全体で着地してしまっていることです！もう少しだけ足の前側でつくイメージをしてみましょう！',
  '×下を向いている':
    '「少しつま先が下に向いている」ことです！もう少しだけつま先を上げるともっと弾みやすくなりますよ！',
  '×大きく曲がっている':
    '「ジャンプの際に大きくひざが曲がってしまっている」ことです！一本の棒になるようなイメージを持ちましょう！まずは小さく弾みながら「良いしせいで弾む」ことを意識しましょう',
};
