import React, { FC, useEffect, useState } from 'react';
import { MenuViewer } from 'pages/ResultPage/MenuViewer';
import { menuType } from 'pages/PracticePage/type';
import classes from './menuViewer.module.css';

type Props = {
  playlist: menuType;
};

export const MenuPlaylistViewer: FC<Props> = ({ playlist }) => {
  const [menuKey, setMenuKey] = useState<number>(0);
  useEffect(() => {
    setMenuKey(0);
  }, [playlist]);

  return (
    <div className={classes.modalWrapper}>
      <span style={{ fontWeight: 'bold', fontSize: '12pt' }}>
        メニューの説明
      </span>
      <div style={{ margin: '10px 4px 20px 2px' }}>
        <MenuViewer
          menuList={playlist}
          menuKey={menuKey}
          setMenuKey={setMenuKey}
          width={0.9}
        />
      </div>
      <div
        style={{ margin: '-2px 0 10px 0', fontSize: '10pt', color: '#424242' }}
      >
        （青枠外をタップで閉じる）
      </div>
    </div>
  );
};
