import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';

export const twoStepVideo = [
  {
    link: 'https://www.youtube.com/embed/tGB1v6aSeWU',
    title: '入れ換えジャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「2ステップ」× 3～4回行う！
        </div>
        ☆慣れてきたら2ステップで移動 →
        そのまま走る、というようにやると走りの中でも意識がしやすくなるよ！
        <br />
        ☆おうちの中でもできる練習です!
      </div>
    ),
  },
];

export const diagnoseForLevelUp = [
  {
    link: 'https://www.youtube.com/embed/X4x_Adf490I',
    title: 'かけっこ診断',
    message: (
      <div>
        <div className={classes.content}>
          ☆20mほどの直線が取れれば診断可能！全力で走る練習は足が速くなるうえで一番大事です！
        </div>
        <div className={classes.title_important}>チャレンジ方法</div>
        <div className={classes.content}>
          1.撮り方をチェックしたうえで、撮影方法を守って動画を撮影！思いっきり走ろう！
          <br />
          2.
          画面右下の+ボタンをタップ⇒かけっこ診断をタップ⇒案内に従って動画を送信！
          <br />
          3.
          合格点に達していたら同じ動画を「昇級テストに挑戦」からアップロード！
        </div>
        <div className={classes.title_important}>
          撮影方法 ☆必ずお守りください！
        </div>
        <div className={classes.caution}>
          1. 通常速度の動画を撮影しよう！（スロー動画はNG！）
          <br />
          2.
          動画はカメラを固定して真横から撮影！スピードに乗ったところで5.6歩程度うつるようにとるとGood！
          <br />
          3.
          人の少ない明るい場所で撮ろう！暗かったり人混みだとAIがお子様を認識できない恐れがあります！
        </div>
      </div>
    ),
  },
];
