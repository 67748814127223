import React from 'react';
import { getAllVideosWithUser } from '../v1apiRequest';

type uploadDataType = {
  analysis_status: string;
  is_exam: boolean;
  runner_id: string;
  student_group: string | null;
  uploaded_date: string;
  username: string;
};

export const useVideoUploadData = (runnerGroup: string) => {
  const [uploadData, setUploadData] = React.useState<uploadDataType[]>([]);
  const [videoUndone, setVideoUndone] = React.useState<uploadDataType[]>([]);
  React.useEffect(() => {
    getAllVideosWithUser()
      .then((res) => {
        const videosWithSameGroup = res.data.filter(
          (u) => u.student_group === runnerGroup || !runnerGroup,
        ) as uploadDataType[];
        console.log(res.data);
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ a: number; b: number; c: numbe... Remove this comment to see the full error message
        setUploadData(processUploadData(videosWithSameGroup));
        setVideoUndone(
          videosWithSameGroup.filter(
            (f) => f.analysis_status !== 'done' && f.is_exam,
          ),
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return {
    userVideoUploadData: uploadData,
    videoUndone: videoUndone,
  };
};

function processUploadData(list: any) {
  console.log(list);
  let countObj = {};

  for (const uploadLog of list) {
    if (uploadLog.username === 'idaten') {
      continue;
    }
    if (Object.keys(countObj).includes(uploadLog.runner_id)) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      countObj[uploadLog.runner_id].push(uploadLog);
      continue;
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    countObj[uploadLog.runner_id] = [uploadLog];
  }
  // ここまでで countObjは{user: [{},{}], user:回数}のobjになってる
  let retObj = {
    a: 0, //5回
    b: 0, //3回
    c: 0, //1回
  };

  for (const key of Object.keys(countObj)) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const len = countObj[key].length;
    if (len >= 10) {
      retObj.a += 1;
      continue;
    }
    if (len >= 6) {
      retObj.b += 1;
      continue;
    }
    if (len >= 2) {
      retObj.c += 1;
      continue;
    }
  }

  return retObj;
}
