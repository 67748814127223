import React, { FC, memo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  FilterType,
  filterConditionKey,
  filterConditionType,
  FilterValue,
} from '../types';
import Pagination from '@mui/material/Pagination';

type Props = {
  handleChangeFilterType: (key: filterConditionKey) => (c: FilterValue) => void;
  filterCondition: filterConditionType;
  count: number;
};

export const FilterConditionSetter: FC<Props> = memo(
  ({ handleChangeFilterType, filterCondition, count }) => {
    const handleChangeFilter = (event: SelectChangeEvent) => {
      handleChangeFilterType('filter')(event.target.value as FilterType);
    };

    const handlePageChange = (
      event: React.ChangeEvent<unknown>,
      page: number,
    ) => {
      handleChangeFilterType('page')((page - 1) as FilterValue);
    };

    const handleChangelimit = (event: SelectChangeEvent) => {
      handleChangeFilterType('limit')(event.target.value as FilterValue);
    };

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ maxWidth: '20vw' }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterCondition.filter}
            label="Age"
            onChange={handleChangeFilter}
          >
            <MenuItem value={'onlyExam'}>昇級動画のみ</MenuItem>
            <MenuItem value={'withoutDiagnose'}>昇級 + 練習動画</MenuItem>
            <MenuItem value={'all'}>全て</MenuItem>
          </Select>
        </div>
        <div style={{ maxWidth: '20vw', marginLeft: '5vw' }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={String(filterCondition.limit)}
            label="Age"
            onChange={handleChangelimit}
          >
            {[20, 40, 60, 80, 100].map((lim) => {
              return (
                <MenuItem value={lim} key={lim}>
                  {lim}件
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div style={{ maxWidth: '60vw', marginLeft: '40px' }}>
          <div style={{ fontSize: '8pt' }}>
            {count}件中&nbsp;&nbsp;{String(filterCondition.limit)}件を表示
          </div>
          <div>
            <Pagination
              count={Math.trunc((count - 1) / filterCondition.limit) + 1}
              page={filterCondition.page + 1}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    );
  },
);
