import React, { FC } from 'react';
import CampaignIcon from '@mui/icons-material/Campaign';
import classes from './stepFunctions.module.css';

type resultType = {
  title: string;
  isBorderPassed: boolean;
  isAllPassed: boolean;
};

type Props = {
  result: resultType;
};

export const UpOrStayBar: FC<Props> = ({ result }) => {
  if (result.isAllPassed) {
    return (
      <>
        <div className={classes.upStayBlockCorrect}>
          <div className={classes.upStayMessage}>{result.title}</div>
        </div>
      </>
    );
  }

  if (result.isBorderPassed) {
    return (
      <>
        <div className={classes.upStayBlockCorrect}>
          <div className={classes.upStayMessage}>{result.title}</div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className={classes.upStayBlockAlert}>
        <div className={classes.icon}>
          <CampaignIcon style={{ color: 'white' }} />
        </div>
        <div className={classes.message}>{result.title}</div>
      </div>
      <div className={classes.bottomArrow}></div>
    </>
  );
};
