import React, { FC, memo } from 'react';
import classes from './guidanceTools.module.css';
import { CrownPopover } from './popovers/CrownPopover';
import { usePopover } from 'hooks/usePopover';
import { onboardType } from './type';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
type Props = {
  onboardState: onboardType;
};

export const Crown: FC<Props> = memo(({ onboardState }) => {
  const buttonState = decideButtonState(onboardState);
  const innerComponent = CrownPopover(
    buttonState,
    onboardState === 'beforeMsg',
  );
  const { handleClick, CustomPopover } = usePopover(innerComponent);

  return (
    <>
      <div className={classes.crownPosition}>
        {buttonState === 'disabled' && (
          <>
            <div className={classes.disabled}>
              <div className={classes.iconContent}>
                <VideoCameraFrontIcon
                  style={{
                    fontSize: '16vw',
                    color: '#a9a9a9',
                  }}
                />
              </div>
            </div>
            {CustomPopover}
          </>
        )}

        {buttonState === 'canTouch' && (
          <>
            <div className={classes.canTouch} onClick={handleClick}>
              <div className={classes.iconContent}>
                <VideoCameraFrontIcon
                  style={{
                    fontSize: '16vw',
                    color: '#a9a9a9',
                  }}
                />
              </div>
            </div>
            {CustomPopover}
          </>
        )}

        {buttonState === 'shouldTouch' && (
          <>
            <div className={classes.shouldBeTouched} onClick={handleClick}>
              <div className={classes.iconContent}>
                <VideoCameraFrontIcon
                  style={{
                    fontSize: '22vw',
                    color: '#333333',
                  }}
                />
              </div>
            </div>
            {CustomPopover}
          </>
        )}
      </div>
    </>
  );
});

const decideButtonState = (onboardState: onboardType) => {
  if (onboardState === 'beforeMsg' || onboardState === 'beforePractice') {
    return 'disabled';
  }
  if (onboardState === 'beforeChallenge') {
    return 'shouldTouch';
  }
  return 'canTouch';
};
