import React from 'react';
import classes from '../fifty_check.module.css';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import quick from 'static/lp/quick2.png';
import coaches from 'static/lp/coaches_introduction.png';
import { useNavigate } from 'react-router-dom';

export const DiagnoseButton = () => {
  const navigate = useNavigate();

  return (
    <>
      <img src={quick} alt="quick" width="100%"></img>

      <div style={{ marginTop: '1vw' }}>
        <button
          className={classes.diagnoseButton}
          onClick={() => {
            navigate('/diagnose');
          }}
        >
          <span className={classes.innerText}>診断する&nbsp;</span>
          <PlayCircleFilledWhiteIcon style={{ color: '#ffff0f' }} />
        </button>
      </div>
      <div style={{ textAlign: 'center', marginTop: '24px' }}>
        <img src={coaches} alt="quick" width="90%"></img>
      </div>
    </>
  );
};
