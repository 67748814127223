import React, { FC, useCallback } from 'react';
import { ModalWrapper } from 'components/modals/ModalWrapper';
import { OnboardingCarousel } from 'hooks/FirstOnboarding/components/OnboardingCarousel';
import { RunnerType } from 'adapters/repositories/typeDefinition';

export const useFirstOnboardingModal = (runner: RunnerType | undefined) => {
  const [open, setOpen] = React.useState(false);

  const toggleModalOpen = () => {
    setOpen(!open);
  };

  const forceModalOpen = useCallback((openOrClose: boolean) => {
    setOpen(openOrClose);
  }, []);

  return {
    forceModalOpen: forceModalOpen,
    toggleModalOpen: toggleModalOpen,
    OnboardModal:
      (!!runner && (
        <OnboardModal
          isModalOpen={open}
          setIsModalOpen={setOpen}
          runner={runner}
        />
      )) ||
      null,
  };
};

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  runner: RunnerType;
};

const OnboardModal: FC<Props> = ({ isModalOpen, setIsModalOpen, runner }) => {
  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    localStorage.setItem(String(runner.id), 'beforeMsg');
  }, [runner]);

  return (
    <ModalWrapper isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div>
        <OnboardingCarousel handleClose={handleClose} runner={runner} />
      </div>
    </ModalWrapper>
  );
};
