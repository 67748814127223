import React, { FC } from 'react';
import { ModalWrapper } from './ModalWrapper';
import { Button } from '@material-ui/core';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { VideoListByDate } from 'pages/LogPage/components/VideoListByDate';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  date: string;
  videos: VideoType[];
};

export const CalendarModal: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  date,
  videos,
}) => {
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <ModalWrapper isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div style={{ fontWeight: 'bold', fontSize: '14pt' }}>
        {formatDateFromString(date)}の練習
      </div>
      <VideoListByDate videos={videos} />
      <br />
      <Button
        onClick={handleClose}
        variant="contained"
        color="primary"
        size="small"
      >
        閉じる
      </Button>
    </ModalWrapper>
  );
};

function formatDateFromString(string: string) {
  const TokyoDate = new Date(string);
  let hiduke = TokyoDate.getMonth() + 1 + '月' + TokyoDate.getDate() + '日';

  return hiduke;
}
