import React from 'react';
import challenge5th_thumb from 'components/MenuConst/thumbnails/challenge_5th.png'; //未変更
import spring_thumb from 'components/MenuConst/thumbnails/spring.jpg';

import boundingPng from 'components/MenuConst/thumbnails/bounding.png';
import { menuType } from 'pages/PracticePage/type';

import { BoundingVideos } from './videos';
import { springVideos } from '../grade_0/videos';

// up: 準備, tech: ぎじゅつ, run: 体力, family: 家族

// プレイリスト

////
//up
////
export const spring_4: menuType = {
  duration: 6,
  hitWordList: ['バネ', 'ばね', 'けんけん', 'ケンケン', 'ベース'],
  title: 'バネ人間チャレンジ',
  target: ['しせい'],
  menuVariation: ['up'],
  grade: 0,
  image: spring_thumb,
  isInside: 'unable',
  video: springVideos,
};

////
//tech
////

export const bounding: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'バウンディング ☆昇級テスト',
  isInside: 'unable',
  target: ['しせい', 'スタート', '足の動き'],
  menuVariation: ['tech'],
  hitWordList: ['バウンディング'],
  grade: 16,
  image: boundingPng,
  video: BoundingVideos,
};
