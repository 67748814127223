import React from 'react';
import basePositinJump_thumb from 'components/MenuConst/thumbnails/basePositinJump.jpg';
import baseWalk from 'components/MenuConst/thumbnails/baseWalk.png';
import kabeoshi_thumb from 'components/MenuConst/thumbnails/kabeoshi.png';
import yHighkneeSprint_thumb from 'components/MenuConst/thumbnails/yHighkneeSprint.jpg';
import armSwing from 'components/MenuConst/thumbnails/arm_swing.png';
import startpose from 'components/MenuConst/thumbnails/start_posture.png';
import singleLegRotation_thumb from 'components/MenuConst/thumbnails/singleLegRotation.jpg';
import challenge9th_thumb from 'components/MenuConst/thumbnails/challenge_9th.jpg';
import intro_9th_thumb from 'components/MenuConst/thumbnails/intro_9th.jpg';

import {
  re_basePositinJumpVideos,
  basePositinWalkVideos,
  kabeoshiBasicVideos,
  yHighkneeSprintVideos,
  singleLegRotationVideos,
} from './videos';

import {
  howToStartPoseVideos,
  armSwingVideos,
} from 'components/MenuConst/menus/grade_0/videos';

import { menuType } from 'pages/PracticePage/type';

export const re_basePositinJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'ベースポジションジャンプ',
  isInside: 'able',
  target: ['しせい', '足の動き'],
  menuVariation: ['up'],
  hitWordList: [
    'べーす',
    'ベース',
    'ジャンプ',
    'じゃんぷ',
    'いえ',
    '家',
    'おうち',
  ],
  grade: 0,
  image: basePositinJump_thumb,
  video: re_basePositinJumpVideos,
};

export const basePositionWalk_9th = {
  title: 'ベースポジションウォーク',
  isInside: 'able',
  target: ['しせい', '足の動き'],
  grade: 1,
  duration: 10,
  menuVariation: ['up'],
  hitWordList: [
    'ベース',
    'べーす',
    'うぉーく',
    'ウォーク',
    'Y字',
    'ワイ',
    'Y',
    'もも',
    'じゃんぷ',
    'ジャンプ',
    'いえ',
    '家',
    'おうち',
  ],
  image: baseWalk,
  video: basePositinWalkVideos,
};

export const kabeoshiBasic = {
  grade: 1,
  duration: 15,
  title: '壁押し ☆昇級テスト',
  target: ['足の動き'],
  menuVariation: ['tech'],
  isInside: 'able',
  hitWordList: ['Y字', 'ワイ', 'Y', 'もも'],
  image: kabeoshi_thumb,
  video: kabeoshiBasicVideos,
};

export const howToStartPose_9th = {
  duration: 5,
  title: 'スタートのかまえ',
  target: ['スタート'],
  isInside: 'able',
  menuVariation: ['tech'],
  hitWordList: [
    'スタート',
    'すたーと',
    '構え',
    'かまえ',
    'いえ',
    '家',
    'おうち',
  ],
  grade: 0,
  image: startpose,
  video: howToStartPoseVideos,
};

export const arm_swing_9th = {
  duration: 5,
  title: 'ペットボトルうでふり',
  target: ['腕ふり'],
  isInside: 'able',
  menuVariation: ['run'],
  hitWordList: ['うで', '腕', 'ふり', '振り', 'いえ', '家', 'おうち'],
  grade: 0,
  image: armSwing,
  video: armSwingVideos,
};

export const yHighkneeSprint = {
  grade: 1,
  duration: 15,
  title: 'Y字ももあげスプリント',
  target: ['しせい', '足の動き'],
  menuVariation: ['run'],
  hitWordList: ['かべ', '壁', 'おし', '押し', 'いえ', '家', 'おうち'],
  image: yHighkneeSprint_thumb,
  video: yHighkneeSprintVideos,
};

//バネ人間はgrade_0から

//かけっこ診断はgrade_0から

export const singleLegRotation = {
  grade: 1,
  duration: 15,
  title: 'かたあし回転ジャンプ',
  target: ['しせい'],
  isInside: 'able',
  menuVariation: ['family'],
  hitWordList: [
    'かた',
    'あし',
    '片',
    '足',
    'かいてん',
    '回転',
    'じゃんぷ',
    'ジャンプ',
    'いえ',
    '家',
    'おうち',
  ],
  image: singleLegRotation_thumb,
  video: singleLegRotationVideos,
};

export const intro_9th = {
  duration: 2,
  hitWordList: ['9', '昇級', 'メッセージ', 'めっせーじ', 'きゅう'],
  title: '9級の方へのメッセージ',
  grade: 1,
  image: intro_9th_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/BDGKhOTLb6c',
      title: '9級イントロ動画',
      message: <span>9級の方へのメッセージはコチラ！ </span>,
    },
  ],
  menu: <></>,
};

export const challenge_9th = {
  grade: 1,
  duration: 5,
  title: '9級の昇級用メニュー',
  hitWordList: ['9', '昇級', 'かべ', '壁', 'おし', '押し', '家'],
  image: challenge9th_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/y4N1gNtRfls',
      title: 'お手本動画壁押し',
      message: <span>9級の昇級テスト挑戦動画はコチラ！</span>,
    },
  ],
  menu: (
    <div>
      <span style={{ textAlign: 'center' }}>練習A</span>
      <ul style={{ fontSize: '10pt', textAlign: 'left' }}>
        <li>
          動画1/6を見て、「壁を押しやすい、いい姿勢」を見つける！（角度は60度だと難しかったら75度くらいでもOK！！ご両親などにうまくできているかチェックしてもらおう！）
        </li>
        <li>
          動画2/6～6/6を見て、「壁押しももあげ」を正しい形で出来るようになるまで特訓（目安5分）！
          （付き添いの方がいましたら、手のひらを「太ももが90度上がったらちょうどあたる位置」においてあげてください！お子様は、その手のひらに太ももを当てるように練習するとやりやすいです！）
          <br />
          ※壁を強く押す意識をすると姿勢がくずれてしまう場合は、一旦意識しなくて大丈夫です！
          <br />
          ⇒慣れたら、「壁押しももあげ」を10歩×3セット行う！
        </li>
      </ul>
    </div>
  ),
};
