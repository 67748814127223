// 10級パッケージの9級
type ckptTypeForGrade_1_20kyu =
  | 'よい姿勢'
  | '足の上がり'
  | '体をたおせているか';

export const ckptForGrade_1_20kyu: ckptTypeForGrade_1_20kyu[] = [
  'よい姿勢',
  '足の上がり',
  '体をたおせているか',
];

// これは合格に必要なもの
export const requirement_1_20kyu: ckptTypeForGrade_1_20kyu[] = [
  'よい姿勢',
  '足の上がり',
];

export type ckptDetails_1_20 =
  | '腰の引け具合'
  | '腕が地面と平行'
  | '足の上がり'
  | '腰の引け具合'
  | '倒れ込み角度'
  // 以下採点用
  | 'かかとが浮いているか'
  | '前に進んでいるか'
  | '後ろへの蹴り上げ'
  | 'リーチアウト';

type allCheckpoints_1_20kyu_type = {
  [key in ckptTypeForGrade_1_20kyu]: ckptDetails_1_20[];
};

export const allCheckPoints_1_20kyu: allCheckpoints_1_20kyu_type = {
  よい姿勢: ['腰の引け具合', '腕が地面と平行'],
  足の上がり: ['足の上がり'],
  体をたおせているか: ['腰の引け具合', '倒れ込み角度'],
};

export const borderLine_1_20kyu: allCheckpoints_1_20kyu_type = {
  よい姿勢: ['腰の引け具合'],
  足の上がり: ['足の上がり'],
  体をたおせているか: ['腰の引け具合', '倒れ込み角度'],
};
