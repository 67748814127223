import React, { FC } from 'react';

import '../../../components/components.css';
import classes from './videoPlayer.module.css';
import { Card } from '@material-ui/core';
import loadingGif from '../../../static/running-101-gif.gif';
import { VideoType } from 'adapters/repositories/typeDefinition';

type Props = {
  isLoading: boolean;
  videoInfo: VideoType;
};

export const VideoPlayer: FC<Props> = ({ isLoading, videoInfo }) => {
  const [isLightFileReady, setIsLightFileReady] = React.useState(false);
  console.log('light ready=', isLightFileReady);
  React.useEffect(() => {
    const videoElem = document.getElementById('videoHidden');
    // console.log(videoElem);
    if (!!videoElem && !isLightFileReady) {
      console.log('light-videoload');
      videoElem.addEventListener('loadedmetadata', () => {
        console.log('loaded');
        setIsLightFileReady(true);
      });
    }
  }, [isLightFileReady]);

  if (isLoading) {
    return (
      <div>
        ビデオをロード中
        <img src={loadingGif} height="150" width="auto" alt="" />
      </div>
    );
  }
  // https://idaten-compressed-video.s3.amazonaws.com/video/97a22ee7630d493f324d488bfe5016d0.mp4

  return (
    <div className={classes.videoWrapper}>
      {isLightFileReady ? (
        <video
          style={{ margin: '3px' }}
          className={classes.videoBasic}
          src={`https://idaten-compressed-video.s3.amazonaws.com/video/${videoInfo.file}`}
          controls
          muted
          loop
          poster={videoInfo.thumbnail}
        ></video>
      ) : (
        <video
          style={{ margin: '3px' }}
          className={classes.videoBasic}
          src={`https://shimotakaido.s3.amazonaws.com/static/${videoInfo.file}`}
          controls
          muted
          loop
          poster={`${videoInfo.thumbnail}`}
        ></video>
      )}
      <video
        style={{ margin: '3px' }}
        id="videoHidden"
        className={classes.hidden}
        src={`https://idaten-compressed-video.s3.amazonaws.com/video/${videoInfo.file}`}
        muted
        poster={videoInfo.thumbnail}
      ></video>

      <br />
    </div>
  );
};
