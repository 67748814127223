// TRAC10級パッケージの7級

export const ckptFor7kyu_trac10 = [
  'ふり下ろし',
  'わきの開き',
  '目線',
  'ひじ',
] as const;

export type ckpt_7kyu_type = typeof ckptFor7kyu_trac10[number];

export const requirement_7kyu_trac: ckpt_7kyu_type[] = [
  'ふり下ろし',
  'わきの開き',
  '目線',
];

type options = {
  [key in ckpt_7kyu_type]: string[];
};

export const optionDict_7kyu_trac: options = {
  ふり下ろし: ['◯できている', '×できていない'],
  わきの開き: ['◯開いていない', '×開いている'],
  目線: ['◯まっすぐ', '×下向き', '×上向き'],
  ひじ: ['◯伸びていない', '×伸びている', '×少し伸びている'],
};

type complimentType = {
  [key in ckpt_7kyu_type]: string;
};

// まずxxくんの良い点として、に続く文章を書く
export const complimentDict_7: complimentType = {
  ふり下ろし:
    'しっかりと腕を振り下ろせている点があります！このうでふりが走りの中でもできたら、力強い走りができますよ！',
  わきの開き:
    '脇を開かずに良いうでふりができている点があります！細かいところまでよく頑張りましたね！',
  目線: '目線もしっかりまっすぐにできていることがあります！腕だけでなく目線まで意識できるのは素晴らしいことですよ！',
  ひじ: 'ひじの曲がりがコンパクトに腕をふれている点があります！本気で走ったときも肘を伸ばさず、この腕振りができるように引き続き練習しましょう！',
};

export const nobishiroOptions_7_trac = Object.values(optionDict_7kyu_trac)
  .flat()
  .filter((value) => !value.includes('◯'));

type nobishiroDict = {
  [key in typeof nobishiroOptions_7_trac[number]]?: string;
};

// そんな〇〇くんの伸びしろポイントはズバリ、に続く文章を書く
export const nobishiroDict_7: nobishiroDict = {
  '×できていない':
    '「うでを勢いよく振り下ろせていないこと」です！はじめのうちは細かいことは気にせず、思いっきり腕を振り下ろしてみてください！',
  '×開いている':
    '「脇が開いてしまっている」ことです。腕を横に振るのではなく、ましたに振り下ろすようなイメージを持ってみましょう！',
  '×下向き':
    '「目線が少し下を向いてしまっている」ことです！腕ふりでも姿勢はとても大事なので、目線もまっすぐ前に向けるようにしましょう！',
  '×上向き':
    '「目線が少し上を向いてしまっている」ことです！腕ふりでも姿勢はとても大事なので、目線もまっすぐ前に向けるようにしましょう！',
  '×伸びている':
    '肘が伸びてしまっていることです！力強く腕を触れているのはとても良いですが、もう少しだけ肘が伸びないように頑張ってみましょう！',
  '×少し伸びている':
    '少しだけ肘が伸びてしまっていることです！力強く腕を触れているのはとても良いですが、これ以上肘が開かないように気をつけてみましょう！',
};
