import React from 'react';
import { useRoutes } from 'react-router-dom';
import { routes } from './publicRoutes';

export const UnauthorizedRouter = () => {
  const router = useRoutes(routes);
  return (
    <>
      {/* <HeaderComponent /> */}
      <main>
        <>{router}</>
      </main>
    </>
  );
};
