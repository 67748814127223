import React, { FC, useState, useEffect } from 'react';
import { TextField, Grid } from '@material-ui/core';

//import { getVideoByParttime } from 'v1apiRequest';
import { getVideoByParttimeAdapter } from 'adapters/getVideosAdapter';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { VideoCardAdmin } from 'pages/adminPage/VideoCardAdmin';
//eslint-diable-next-line
import { ScoreEditType } from 'components/MenuConst/GradeGoalConst';
import { SearchVideoBar } from '../../common/SearchVideoBar';
import { StudentCommentPart } from '../../common/StudentCommentPart';
import { ScoreSendButton } from '../../common/ScoreSendButton';

import { ManualScoringField } from '../../../../features/scoring/components/ManualScoringField';
import { decideUpOrStay } from '../../common/decideUpOrStay';

type parttimeType = {
  name: string;
  id: string;
};

type Props = {
  parttime: parttimeType;
};

type handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => void;
type handleScoreChange = (
  key: string,
) => (e: React.ChangeEvent<HTMLInputElement>) => void;
type ChosenVideoType = VideoType | null;

export const ScoringComponent: FC<Props> = (props) => {
  const { parttime } = props;
  const [videoList, setVideoList] = useState<VideoType[]>([]);
  const [videoToShow, setVideoToShow] = useState<VideoType[]>([]);
  const [chosenVideo, setChosenVideo] = useState<ChosenVideoType>(null);
  const [comment, setComment] = useState<string>('');

  const [score, setScore] = useState({} as ScoreEditType);

  // ChosenVideoが変わったら評価を初期化するやつ
  useEffect(() => {
    if (!chosenVideo) {
      return;
    }
    setScore({} as ScoreEditType);
    if (!!chosenVideo.score && !!chosenVideo?.score.coach_comment) {
      setComment(chosenVideo?.score.coach_comment);
      return;
    }
    setComment('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenVideo]);

  const onClickVideo = (v: VideoType) => () => {
    console.log(v);
    setChosenVideo(v);
  };

  const completeFunc = () => {
    alert('ok');
    getVideoByParttimeAdapter(parttime.id).then((videos) => {
      setVideoList(videos);
      setVideoToShow(videos);
      setChosenVideo(null);
    });
  };

  // parttimeが変わったらビデオを再取得
  useEffect(() => {
    getVideoByParttimeAdapter(parttime.id).then((videos) => {
      console.log(videos);
      setVideoList(videos);
      setVideoToShow(videos);
    });
  }, [parttime]);

  const onChangeScore: handleScoreChange = (key) => (e) => {
    if (!chosenVideo) {
      return;
    }
    const nextScore = { ...score, [key]: e.target.value };
    const upOrStay = decideUpOrStay(nextScore, chosenVideo)
      ? '◯合格'
      : '×不合格';

    setScore({ ...score, [key]: e.target.value, eval: upOrStay });
  };

  const onChangeComment: handleInputChange = (e) => {
    setComment(e.target.value);
  };

  return (
    <div
      style={{
        margin: '3px',
      }}
    >
      <p>採点ページ</p>
      <SearchVideoBar videoList={videoList} setVideoToShow={setVideoToShow} />

      <div
        style={{
          margin: '3px',
          overflowY: 'scroll',
          height: `${window.parent.screen.height * 0.5}px`,
          border: '3px solid #EEEEEE',
        }}
      >
        {videoToShow && videoToShow.length > 0
          ? videoToShow.map((vObj, idx) => {
              return (
                <VideoCardAdmin
                  key={idx}
                  video={vObj}
                  onClickFunc={onClickVideo}
                />
              );
            })
          : null}
      </div>
      {!!chosenVideo && (
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            <ManualScoringField
              chosenVideo={chosenVideo}
              onChangeScore={onChangeScore}
              score={score}
              setComment={setComment}
            />

            <TextField
              variant="outlined"
              label="コメント"
              style={{ width: '100%', marginTop: '26px' }}
              multiline
              placeholder="（例）足がちょっと流れています"
              value={comment}
              onChange={onChangeComment}
            />

            <ScoreSendButton
              score={score}
              comment={comment}
              chosenVideo={chosenVideo}
              completeFunc={completeFunc}
            />
          </Grid>
          <Grid item xs={6} style={{ padding: '10px' }}>
            <StudentCommentPart chosenVideo={chosenVideo} />
          </Grid>
        </Grid>
      )}
      <div style={{ marginTop: '100px' }}></div>
    </div>
  );
};
