import React, { useState } from 'react';

import { AccountContext } from 'contexts/AccountContext';
import { useGetVideoByRunner } from 'hooks/useGetVideoByRunner';
import { Card } from '@mui/material';

import { VideoContext } from 'contexts/VideoContext';
import { StickerCalendar } from 'pages/LogPage/components/StickerCalendar';
import {
  CalendarHeader,
  yearMonthType,
} from 'pages/LogPage/components/CalendarHeader';
import { getVideoMonthly } from 'pages/LogPage/func/simpleFuncs';
import { TabInfo } from 'components/TabInfo';
import { VideoListPage } from 'pages/LogPage/components/VideoListPage';

export const LogPage = () => {
  const { currentRunner } = React.useContext(AccountContext);
  const { videoList } = React.useContext(VideoContext);
  useGetVideoByRunner(currentRunner);

  const [yearMonth, setYearMonth] = useState<yearMonthType>({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  const videoMonthly = getVideoMonthly(
    videoList,
    yearMonth.year,
    yearMonth.month,
  );

  return (
    <>
      <TabInfo labels={['練習のきろく', 'カレンダー']}>
        <>
          <VideoListPage />
        </>
        <>
          {!currentRunner && <Announce />}
          <CalendarHeader
            yearMonth={yearMonth}
            setYearMonth={setYearMonth}
            monthlyTime={videoMonthly.length}
          />

          <div style={{ textAlign: 'center' }}>
            <StickerCalendar
              year={yearMonth.year}
              month={yearMonth.month}
              videoMonthly={videoMonthly}
            />
          </div>
        </>
      </TabInfo>
      <div style={{ marginTop: '80px' }}></div>
    </>
  );
};

const Announce = () => {
  return (
    <Card
      style={{
        padding: '12px',
        margin: '10px',
        backgroundColor: '#ccffcc',
        color: '#626262',
      }}
    >
      練習をきろくするとカレンダーに
      <span style={{ fontWeight: 'bold' }}>⭕️</span>
      <span style={{ fontWeight: 'bold' }}>スタンプ</span>
      がおされます。スタンプをタップするとその日の練習をかくにんできます！
    </Card>
  );
};
