import { useState, useEffect } from 'react';
import { getAllRunnersByCoach } from 'v1apiRequest';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { excludeAddresses } from '../constants/addresses';

export const useGetRunners = () => {
  const [runners, setRunners] = useState<RunnerType[]>([]);
  useEffect(() => {
    getAllRunnersByCoach().then((res) => {
      console.log(res.data);
      const runnersFromApi = res.data as RunnerType[];
      const students = runnersFromApi
        .filter((r) => !!r.owner && !excludeAddresses.includes(r.owner?.email))
        .filter((r) => r.username !== r.last_name);
      setRunners(students);
    });
  }, []);

  return { runners: runners };
};
