import React, { FC, useState } from 'react';
import { Button, TextField } from '@material-ui/core';

type parttimeType = {
  name: string;
  id: string;
  password: string;
};

type inputEvent = React.ChangeEvent<HTMLInputElement>;
type handleInputChange = (e: inputEvent) => void;

type Props = {
  partTimeList: parttimeType[];
  setParttime: React.Dispatch<React.SetStateAction<parttimeType>>;
};

export const ParttimeLoginPage: FC<Props> = (props) => {
  const { partTimeList, setParttime } = props;
  const [password, setPassword] = useState('');

  const handlePasswordChange: handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const tryLogin = () => {
    if (!password || password.length < 1) {
      alert('パスワードを入力してください');
      return;
    }

    const parttimeWithPassword = partTimeList.find(
      (p) => p.password === password,
    );
    if (!parttimeWithPassword) {
      alert('パスワードが正しくありません');
      return;
    }
    alert(`ようこそ、${parttimeWithPassword.name}様`);
    setParttime(parttimeWithPassword);
    return;
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <div>パスワードを入力してください</div>
      <div>
        <TextField
          style={{ marginTop: '20px', width: '70%' }}
          value={password}
          label="バイト用のパスワード"
          onChange={handlePasswordChange}
          placeholder="idatenkids"
        />
      </div>

      <Button
        variant="contained"
        style={{ marginTop: '30px' }}
        onClick={tryLogin}
      >
        ログイン
      </Button>
    </div>
  );
};
