import { menuType } from 'pages/PracticePage/type';
import posture_trac from 'components/MenuConst/thumbnails/posture_trac.png';
import senobi_trac from 'components/MenuConst/thumbnails/senobi_trac.png';
import sonkyo_trac from 'components/MenuConst/thumbnails/sonkyo_trac.png';
import { howToCreateGoodPosture, howToSenobi, howToSonkyo } from './videos';

export const PostureIntro: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '良い姿勢の作り方',
  isInside: 'able',
  target: ['しせい'],
  menuVariation: ['up'],
  hitWordList: ['姿勢', 'しせい'],
  grade: 0,
  image: posture_trac,
  video: howToCreateGoodPosture,
};

export const Senobi: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '「せのび」の練習！',
  isInside: 'able',
  target: ['しせい'],
  menuVariation: ['up'],
  hitWordList: ['せのび', '背伸び'],
  grade: 0,
  image: senobi_trac,
  video: howToSenobi,
};

export const Sonkyo: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'そんきょ！ #昇級テスト',
  isInside: 'able',
  target: ['しせい'],
  menuVariation: ['tech'],
  hitWordList: ['姿勢', 'しせい'],
  grade: 0,
  image: sonkyo_trac,
  video: howToSonkyo,
};
