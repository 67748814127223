import React, { useState, useCallback } from 'react';

import { UploadStepViewer } from 'pages/UploadPage/components/UploadStepViewer';
import { ExplanationScreen } from './components/ExplanationScreen';
import { UploadScreen } from './components/UploadScreen';
import { InformationScreen } from './components/InformationScreen';
import {
  RunnerInfoType,
  defaultRunner,
  handleInputChange,
  infoKeys,
  heightChangeFunc,
} from './types';
import classes from './fifty_check.module.css';

const steps = ['撮影方法', '動画選択', 'メモ'];

export const DiagnoseUploadPage = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [file, setFile] = useState<File | null>(null);
  const [runnerInfo, setRunnerInfo] = useState<RunnerInfoType>(defaultRunner);

  const handleInfoChange: handleInputChange = (keyName: infoKeys) => (e) => {
    let _info = { ...runnerInfo, [keyName]: e.target.value };
    setRunnerInfo(_info);
  };

  const handleHeightChange: heightChangeFunc = useCallback(
    (e) => {
      if (typeof e.target.value !== 'number') return;
      let _info = { ...runnerInfo, height: e.target.value };
      setRunnerInfo(_info);
    },
    [runnerInfo],
  );

  const handleNextClick = useCallback(() => {
    setActiveStep(activeStep + 1);
  }, [activeStep]);

  const handleBackClick = useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep]);

  return (
    <div className={classes.background}>
      <UploadStepViewer activeStep={activeStep} steps={steps} />
      {activeStep === 0 && (
        <ExplanationScreen handleNextClick={handleNextClick} />
      )}
      {activeStep === 1 && (
        <UploadScreen
          handleNextClick={handleNextClick}
          handleBackClick={handleBackClick}
          setFile={setFile}
          file={file}
        />
      )}
      {activeStep === 2 && (
        <InformationScreen
          handleBackClick={handleBackClick}
          file={file}
          runnerInfo={runnerInfo}
          handleInfoChange={handleInfoChange}
          handleHeightChange={handleHeightChange}
        />
      )}
    </div>
  );
};
