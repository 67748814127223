import React, { FC } from 'react';
import { Button, CircularProgress } from '@material-ui/core';

type Props = {
  title?: string;
  isUploading: boolean;
  onClickFunc: () => void;
};

export const SendButton: FC<Props> = ({
  title = '送信',
  isUploading,
  onClickFunc,
}) => {
  if (isUploading) {
    return (
      <Button variant="contained" disabled style={{ marginTop: '30px' }}>
        {title} <CircularProgress size={20} />
      </Button>
    );
  }

  return (
    <Button
      variant="contained"
      color="primary"
      style={{ marginTop: '30px' }}
      onClick={onClickFunc}
    >
      {title}
    </Button>
  );
};

SendButton.defaultProps = {
  title: '送信',
};
