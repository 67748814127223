import React, { FC, memo } from 'react';
import classes from './practiceCarousel.module.css';
import joy_girl from 'static/materials/joy_girl.png';
import torikata from 'static/materials/torikata.png';

export const SeventhView: FC = memo(() => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '20%' }}>
          <img src={joy_girl} alt="" width="60%"></img>
        </div>
        <div style={{ width: '80%' }}>
          <div className={classes.orangeBorder}>ステップ2. 動画を撮影！</div>
        </div>
      </div>
      <div style={{ marginTop: '16px' }}>
        <img src={torikata} alt="" width="82%"></img>
      </div>
    </>
  );
});
