import React, { useEffect } from 'react';
import { AccountContext } from 'contexts/AccountContext';
import { VideoContext } from 'contexts/VideoContext';

import { ReplaceVideoView } from './components/ReplaceVideoPage';
import { PageTitle } from '../../components/common/PageTitle';
import { StickerModal } from '../../components/Sticker/StickerModal';
import { scrollToCheckbox, goToTags } from '../UploadPage/func';
import { ManualViewer } from '../UploadPage/components/ManualVideo';
import { UploadNotification } from 'components/Notifications';
import '../../index.css';

import { useVideoUploadComponent } from 'pages/UploadPage/hooks/useVideoUpload';
import { useCheckboxComponent } from './hooks/useCheckbox';
import { useFormComponent } from './hooks/useFormInput';
import { useActiveStep } from './hooks/useActiveStepViewer';
import loadingGif from 'static/running-101-gif.gif';
import { convertRunnerGrade } from 'components/constants';

type alertStatusType = {
  open: boolean;
  status: 'OK' | 'ERROR' | 'default';
};

export const LevelUpPage = () => {
  const { accountInfo, currentRunner } = React.useContext(AccountContext);
  const [isUploading, setIsUploading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [alertStatus, setAlertStatus] = React.useState<alertStatusType>({
    open: false,
    status: 'default',
  });

  const { videoList } = React.useContext(VideoContext);

  const { activeStep, setActiveStep, UploadStepViewer } = useActiveStep();

  const onChooseVideo = React.useCallback(() => {
    setActiveStep(1);
    scrollToCheckbox();
  }, []);

  const { file, uploadButton } = useVideoUploadComponent({
    onChooseVideo: onChooseVideo,
  });

  const { isChecked, checkBox } = useCheckboxComponent();
  const { formContent, formInput } = useFormComponent();

  useEffect(() => {
    if (!file) {
      setActiveStep(0);
      return;
    }
    if (isChecked && formContent.length < 1) {
      setActiveStep(1);
      goToTags();
      return;
    }

    if (!isChecked && formContent.length > 0) {
      setActiveStep(1);
      return;
    }

    if (isChecked && formContent.length > 0) {
      setActiveStep(2);
      return;
    }
  }, [formContent, isChecked]);

  if (!accountInfo || !currentRunner) {
    return (
      <>
        <PageTitle title={'昇級に挑戦する'} />
        <img
          alt=""
          src={loadingGif}
          height="140"
          width="auto"
          style={{ marginTop: '30px' }}
        />
      </>
    );
  }

  const params = new FormData();
  if (!!file && activeStep >= 2) {
    params.append('content', file);
    params.append('fileName', file.name);
    params.append('uploaded_by', accountInfo.id);
    params.append('upload_runner', currentRunner.id);
    params.append('is_exam', 'true');
    params.append('text', formContent);
    params.append('menuText', 'デフォルト');
  }

  const sendCompleteFuncs = () => {
    setActiveStep(0);
    setAlertStatus({ open: true, status: 'OK' });
    localStorage.setItem(String(currentRunner.id), 'beforeLetter');
    setIsModalOpen(true);
  };

  const sendFailFuncs = () => {
    console.log('upload失敗');
    setIsUploading(false);
    setAlertStatus({ open: true, status: 'ERROR' });
  };

  const kyuOfRunner = convertRunnerGrade(currentRunner);

  return (
    <div>
      <PageTitle title={'昇級に挑戦する'} />

      <UploadNotification
        alertStatus={alertStatus}
        setAlertStatus={setAlertStatus}
      />

      <div
        style={{
          textAlign: 'center',
          padding: '5px',
        }}
      >
        {UploadStepViewer}
        <StickerModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          grade={kyuOfRunner}
        />
        <div>
          <div>
            {uploadButton}

            <div
              style={{
                margin: '10px',
                marginTop: '50px',
                fontSize: '12pt',
                fontWeight: 'bold',
              }}
              id="checkboxes"
            >
              <ManualViewer runner={currentRunner} />
              {!!file && <>{checkBox}</>}
            </div>

            <div
              style={{
                textAlign: 'center',
                padding: '5px',
              }}
              id="tags"
            >
              {activeStep >= 1 && <>{formInput}</>}
            </div>

            <>
              <ReplaceVideoView
                videos={videoList}
                isUploading={isUploading}
                formContent={formContent}
                activeStep={activeStep}
                params={params}
                completeFunc={sendCompleteFuncs}
                failFunc={sendFailFuncs}
              />
            </>

            <div style={{ marginTop: '200px' }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};
