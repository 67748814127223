import React, { FC, memo } from 'react';
import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Cell,
} from 'recharts';

type userGradeDict = {
  grade: string;
  割合: number;
  title: string;
};

type Props = {
  userGradeData: userGradeDict[];
  runnerGrade: string;
};

export const BarGraph: FC<Props> = memo(({ userGradeData, runnerGrade }) => {
  console.log(userGradeData);
  return (
    <div style={{ marginTop: '8px' }}>
      <div
        style={{
          textAlign: 'center',
          fontSize: '10pt',
          fontWeight: 'bold',
        }}
      >
        各級の割合（％）
      </div>

      <BarChart
        width={window.parent.screen.width * 0.9}
        height={window.parent.screen.width * 0.5}
        data={userGradeData}
      >
        <CartesianGrid strokeDasharray="3 3" />

        <YAxis
          allowDecimals={false}
          domain={[0, 100]}
          tick={{ fontSize: 14, fontWeight: 'bold' }}
          width={35}
        />
        <Tooltip />
        <Bar dataKey="割合">
          {userGradeData.map((userGrade, index: number) => (
            <Cell
              cursor="pointer"
              fill={userGrade.grade === runnerGrade ? '#c299ff' : '#8eb8ff'}
              key={`cell-${index}`}
            />
          ))}
        </Bar>
        <XAxis dataKey="title" tick={{ fontSize: 14, fontWeight: 'bold' }} />
      </BarChart>
    </div>
  );
});
