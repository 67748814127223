import React, { FC, useCallback } from 'react';
import { ModalWrapper } from 'components/modals/ModalWrapper';
import { MenuPlaylistViewer } from './components/MenuPlaylistViewer';
import { menuType } from 'pages/PracticePage/type';

export const usePlaylistWatchModal = (menu: menuType | undefined) => {
  const [open, setOpen] = React.useState(false);

  const toggleModalOpen = () => {
    setOpen(!open);
  };

  const forceModalOpen = useCallback((openOrClose: boolean) => {
    setOpen(openOrClose);
  }, []);

  return {
    forceModalOpen: forceModalOpen,
    toggleModalOpen: toggleModalOpen,
    MenuWatchModal: (!!menu && (
      <MenuWatchModal isModalOpen={open} setIsModalOpen={setOpen} menu={menu} />
    )) || <></>,
  };
};

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  menu: menuType;
};

const MenuWatchModal: FC<Props> = ({ isModalOpen, setIsModalOpen, menu }) => {
  return (
    <ModalWrapper isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div>
        <div style={{ margin: '2px 0 12px 0' }}>
          <MenuPlaylistViewer playlist={menu} />
        </div>
      </div>
    </ModalWrapper>
  );
};
