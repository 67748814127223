import React from 'react';

import { TextField } from '@material-ui/core';

export const TextInput = ({
    title,
    reibun,
    value,
    onChange
}: any) => {
    return (
        <TextField
            variant="outlined"
            label={
                <span
                    style={{
                        fontSize: '12pt',
                        color: '#99CCFF',
                    }}
                >
                    {title}
                </span>
            }
            style={{
                width: '100%',
                marginTop: '10px',
            }}
            InputProps={{
                style: {
                    backgroundColor: '#fffff9',
                    paddingRight: '1px',
                },
            }}
            multiline
            placeholder={reibun}
            value={value}
            onChange={onChange}
        />
    );
};
