import { RunnerType } from 'adapters/repositories/typeDefinition';
import { grades, grades10 } from './MenuConst/GradeGoalConst';

export const MAX_GRADE = 20;

export const calcMaxGrade = (runner: RunnerType) => {
  if (is10GradesPackage(runner)) {
    return 10;
  }
  return 20;
};

export const convertRunnerGrade = (runner: RunnerType) => {
  if (is10GradesPackage(runner)) {
    if (runner.grade > 9) {
      return '0' as grades10;
    }
    return String(10 - runner.grade) as grades10;
  }
  if (runner.grade > 19) return '0' as grades;
  return String(20 - runner.grade) as grades;
};

export const convertGradeByRunner = (runner: RunnerType, grade: number) => {
  if (is10GradesPackage(runner)) {
    if (grade > 9) {
      return '1' as grades10;
    }
    return String(10 - grade) as grades10;
  }
  if (grade > 18) return '1' as grades;
  return String(20 - grade) as grades;
};

export const returnRunnerPkg = (runner: RunnerType) => {
  if (runner.student_group === 'newtrac') {
    return runner.student_group;
  }
  if (is10GradesPackage(runner)) {
    return 'basic_10';
  }
  return 'basic_20';
};

export function is10GradesPackage(runner: RunnerType) {
  if (
    (!!runner.student_group &&
      ['dps', 'trac', 'newtrac'].includes(runner.student_group)) ||
    isCreatedBefore202301(runner)
  ) {
    return true;
  }
  return false;
}

const isCreatedBefore202301 = (runner: RunnerType) => {
  if (!runner.created_at) {
    return false;
  }
  const date2 = new Date('2023-01-12');
  const createdDate = new Date(runner.created_at);

  return date2 > createdDate;
};
