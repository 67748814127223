import React from 'react';
import posture from 'components/MenuConst/thumbnails/posture.png';
import highKneeThumb from 'components/MenuConst/thumbnails/highknee.jpg';
import y_highKneeThumb from 'components/MenuConst/thumbnails/y_sprint_6.png';
import ySprint_thumb from './thumbnails/y_sprint.png';
import kabeoshi_thumb from './thumbnails/kabeoshi.png';
import cycleDash_thumb from './thumbnails/cycle_dash.png';
import mark_run_thumb from './thumbnails/mark_run.png';
import three_step_thumb from './thumbnails/3step.png';
import arm_swing_Walk_thumb from './thumbnails/arm_swing_Walk.jpg';

type singleVideoType = {
  link: string;
  title: string;
  message: JSX.Element;
};

export type MenuType = {
  image: string;
  title: string;
  difficulty: number;
  target: string[];
  grade: string;
  video: singleVideoType[];
};

export const basicMenu = {
  title: 'ステップ1: 走りのきそ',
  target: ['姿勢'],
  grade: '10級',
  video: [
    {
      link: 'https://www.youtube.com/embed/F_RFxTAZaIE',
      title: 'いい姿勢の作り方',
      message: (
        <div>
          （動画1）「いい姿勢」を作ってみる！
          <br />
          ☆保護者さまなどにうまくできているかチェックしてもらおう！
          <br />
          ☆このメニューはおうちの中でも出来ます！
        </div>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/eMTtXbAfJ9I',
      title: 'トランポリンジャンプ',
      message: (
        <div>
          （動画2）「トランポリンジャンプ10回」× 2セット行う！
          <br />
          ☆いい姿勢だとポンポン跳ねやすいことを体感してみよう！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！※足音がなりますので、気になる方はご注意ください！
        </div>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/-fqFjaMUJSE',
      title: 'その場足踏み',
      message: (
        <div>
          （動画3）「その場足踏み20歩」× 2セット行う！
          <br />
          ☆足はうまく動かせているかな？慣れたらうでもふってみよう！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！※足音がなりますので、気になる方はご注意ください！
        </div>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/cbhe66Q-s18',
      title: 'ベースポジション',
      message: (
        <span>
          （動画4）「ベースポジション」を学ぶ
          <br />
          →「ベースポジションで15秒キープ」× 左右足2回ずつチャレンジ！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </span>
      ),
    },
  ],
  image: posture,
  difficulty: 1,
};

export const y_sprint = {
  title: 'ステップ2:Y字スプリント',
  target: ['姿勢'],
  grade: '10級',
  image: ySprint_thumb,
  difficulty: 2,
  video: [
    {
      link: 'https://www.youtube.com/embed/Ocdeu6RgRuY',
      title: 'Y字しせいの作り方',
      message: <span>（動画1）Y字しせいの作り方を確認！ </span>,
    },
    {
      link: 'https://www.youtube.com/embed/69otlz4YMvw',
      title: 'Y字しせいで走る(お手本)',
      message: (
        <div>
          （動画2）「Y字しせいで20m走る」× 3本 行う！
          <div
            style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
          >
            練習のポイント
          </div>
          1. 体がまっすぐ ☆手を高く上げて体はたおしすぎない！
          <br />
          2. ポンポン走る ☆おしりの位置はたか～くする！
          <br />
          3. なれてきた子はスピードを上げてやってみよう！
          <br />
          <br />
          ☆タオルをご用意できる方は、（動画3）を参考に、タオルを持った状態で行ってみよう！
        </div>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/5BSoqAD4_ZM',
      title: 'タオルY字姿勢',
      message: (
        <span>
          （動画3）タオルを持ってY字しせいで走る！
          <br />
          ☆とてもおすすめです！
        </span>
      ),
    },
  ],
};

export const kabeoshiBasic = {
  title: '壁おし',
  grade: '9級',
  target: ['足の動き', '姿勢'],
  image: kabeoshi_thumb,
  difficulty: 3,
  video: [
    {
      link: 'https://www.youtube.com/embed/usm_mhrZSd4',
      title: '壁押しドリル',
      message: (
        <div>
          （動画1～4）「壁押しももあげ」を正しい形で出来るようになるまで大特訓（目安10分）！！※動画2～4の注意ポイントも確認
          <div
            style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
          >
            練習のポイント
          </div>
          1. よい姿勢 ☆よこからみてまっすぐかな？かかとはういてるかな？
          <br />
          2. 足の上がり ☆ふとももが90度あがっているかな？
          <div
            style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
          >
            ～うえ2つができた子はチャレンジ！～
          </div>
          3. 「45度～60度」くらい倒してチャレンジしてみよう！
          <br />
          <br />
          ☆まずは正しい形でできる角度で！角度浅めから練習しよう！
          <br />
          ☆よろしければ「保護者様に腰をつかんで真後ろに軽く（椅子を引くくらいの力感で）引っ張ってもらいながら」壁押しをしてみてください！その際お子様はお尻が引けないように頑張って耐えてみるといい姿勢の感覚がつかみやすいですよ！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </div>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/a2XE9UnDs3g',
      title: '壁押しドリル注意点①',
      message: (
        <span>
          （動画2）「壁押し」の注意ポイント1：あしを後ろにはね上げない！
        </span>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/CVWlD11uW5U',
      title: '壁押しドリル注意点②',
      message: (
        <span>（動画3）「壁押し」の注意ポイント2：かかとは浮かせる！</span>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/M39McbpaKgs',
      title: '壁押しドリル注意点③',
      message: (
        <span>
          （動画4）「壁押し」の注意ポイント3：足は最初と同じ位置に着地する！
        </span>
      ),
    },
  ],
};

export const highKnee = {
  grade: '8級',
  title: 'ハイニー',
  target: ['足の動き', '姿勢'],
  image: highKneeThumb,
  difficulty: 3,
  video: [
    {
      link: 'https://www.youtube.com/embed/Bdfi35kBYDU',
      title: 'ベースポジションウォーク',
      message: (
        <span>
          （動画1）「ベースポジションウォーク20歩」× 3セット（目安）行う！
          <br />
          ☆1セットは2秒キープでやってみて、慣れてきたら連続で行ってみよう！
          <br />
          ☆あしを真下に踏む際、腕を下に振ってリズムをとるとやりやすいかも！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！※足音がなりますので、気になる方はご注意ください！
        </span>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/ICJshfVfBQA',
      title: '連続ハイニー',
      message: (
        <span>
          （動画2）「連続ハイニー20歩」× 3～4セット（目安）行う！
          <br />
          ☆なれてきたら下にふむ意識でやってみよう！
          <br />
          ☆よろしければ保護者様が横で手を叩いてリズムをとってあげるとやりやすいかも！目安は1秒に3～4回くらいがおススメ！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！※足音がなりますので、気になる方はご注意ください！
        </span>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/RgfOs-J-nWk',
      title: 'Y字ポジションもも上げスプリント',
      message: (
        <span>
          （動画3 - チャレンジ）「Y字ポジションもも上げスプリント20m」×
          3～4本行う！
          <br />
          ☆「あしをからだの前で動かす」感覚をおぼえよう！
        </span>
      ),
    },
  ],
};

export const cycle = {
  title: '自転車ダッシュ',
  grade: '6級',
  target: ['足の動き', '姿勢'],
  image: cycleDash_thumb,
  difficulty: 4,
  video: [
    {
      link: 'https://www.youtube.com/embed/T3L9LCHLjoM',
      title: '脚回しドリル',
      message: (
        <span>
          （動画1）「脚回しドリル」を左右足とも特訓（目安5分）！！
          <br />
          ☆あしの動かし方に慣れよう！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったらおうちでも練習してみてね！
        </span>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/GLf1842zVwE',
      title: '自転車ウォーク',
      message: (
        <span>
          （動画2）「自転車ウォーク10m」× 3本を行う！！
          <br />
          ☆あしの動かし方に慣れよう！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったらおうちでも練習してみてね！
        </span>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/oWqqSc6D_Dc',
      title: '自転車ジョグ',
      message: (
        <span>
          （動画3）「自転車ジョグ10m」× 3本を行う！！
          <br />
          ☆あしの動かし方に慣れよう！あしが自然と戻ってくる感覚がつかめたらGood！
        </span>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/ApkuMRS9CFU',
      title: '自転車ダッシュ',
      message: (
        <div>
          「自転車ダッシュ20m」× 4本を行う！！
          <div
            style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
          >
            練習のポイント
          </div>
          1. よい姿勢
          ☆体をたおしすぎていないかな？あごを引いて15m先の地面を見よう！
          <br />
          2. 足を体の前で回す
          ☆足が自然とまえにもどってきて、自然とからだの前で回る感覚がつかめたらOK！
          <div
            style={{ textAlign: 'center', fontWeight: 'bold', padding: '3px' }}
          >
            ～うえ2つができた子はチャレンジ！～
          </div>
          3. 足のつき方 ☆ぼしきゅうからつけているかな？
          <br />
          4. スピードを出す
          ☆なれてきたらチャレンジ！思いっきり走る練習はとてもだいじ！
        </div>
      ),
    },
  ],
};

export const threeStep = {
  grade: '5級',
  title: '3歩ステップ',
  target: ['足の動き', '姿勢'],
  difficulty: 4,
  image: three_step_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/1isIaXlEFZw',
      title: 'ベースポジションジャンプ移動',
      message: (
        <span>
          （動画1）「ベースポジションジャンプ移動」をたくさんやってみる（目安3～5分）！！
          <br />
          ☆両方の足でやってみよう！かっこいい姿勢の移動できるかな？
          <br />
          ☆よろしければ保護者の方は肩を軽く押してあげて下さい！
        </span>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/9cuO2W7C18w',
      title: 'ベースポジション踏みかえ',
      message: (
        <span>
          （動画2）「ベースポジションジャンプ踏みかえ」を特訓（目安3分）！！
          <br />
          ☆「空中であしを入れかえる」が連続10回出来たら合格！
          <br />
          ☆あしを入れかえる際、太鼓をたたくイメージで腕を振ってリズムをとるとやりやすいかも！
        </span>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/aVvoKl6RIgo',
      title: 'ベースポジション3歩ステップ',
      message: (
        <div>
          （動画3）「ベースポジション3歩ステップ」を練習（5分）！
          <br />
          ⇒「体をまっすぐ立てて、空中であしを入れかえる！」が連続6回出来るようなったらクリア！
          <div
            style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
          >
            練習のポイント
          </div>
          <span style={{ fontWeight: 'bold' }}>1. 頭から足までまっすぐ</span>
          <br />
          おしりはたか～い位置にあるかな？
          <br />{' '}
          <span style={{ fontWeight: 'bold' }}>
            2. ベースポジションで進めている
          </span>
          <br />
          ☆ふとももがちゃんとあがっているかな？
          <br />{' '}
          <span style={{ fontWeight: 'bold' }}>
            3. あしを体の前で入れかえる
          </span>
          <br />
          ☆腕も下に振ってリズムをとってみよう！
        </div>
      ),
    },
  ],
};

export const mark_run = {
  grade: '1級',
  title: 'マーク走',
  image: mark_run_thumb,
  target: ['足の動き'],
  difficulty: 4,
  video: [
    {
      link: 'https://www.youtube.com/embed/Bdfi35kBYDU',
      title: 'ベースポジションウォーク',
      message: (
        <span>
          （動画1）「ベースポジションウォーク20歩」× 3セット（目安）行う！
          <br />
          ☆1セットは2秒キープでやってみて、慣れてきたら連続で行ってみよう！
          <br />
          ☆あしを真下に踏む際、腕を下に振ってリズムをとるとやりやすいかも！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！※足音がなりますので、気になる方はご注意ください！
        </span>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/aas9F7szROM',
      title: 'マーク走の準備の仕方',
      message: (
        <span>
          （動画2）マーク走の準備の仕方を確認
          <br />
          ☆マークのない場合は、「地面に線を引く」や「マークに代わるものを置く（踏んでも足をひねらないような薄いものやつぶれるものを推奨）」等で代用可能です！
        </span>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/60A_GIb-laQ',
      title: 'マーク走',
      message: (
        <span>
          （動画3）空きカンを上からポンポンふんで走るイメージで「マーク走」×
          4本を行う！！
          <br />
          ⇒「マーク走⇒そのまま10mダッシュ」×4本行う！！
        </span>
      ),
    },
  ],
};

export const y_highKnee = {
  grade: '8級',
  title: 'Y字ハイニー',
  target: ['足の動き', '姿勢'],
  image: y_highKneeThumb,
  difficulty: 3,
  video: [
    {
      link: 'https://www.youtube.com/embed/Bdfi35kBYDU',
      title: 'ベースポジションウォーク',
      message: (
        <span>
          （動画1）「ベースポジションウォーク20歩」× 3セット（目安）行う！
          <br />
          ☆1セットは2秒キープでやってみて、慣れてきたら連続で行ってみよう！
          <br />
          ☆あしを真下に踏む際、腕を下に振ってリズムをとるとやりやすいかも！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！※足音がなりますので、気になる方はご注意ください！
        </span>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/ICJshfVfBQA',
      title: '連続ハイニー',
      message: (
        <span>
          （動画2）「連続ハイニー20歩」× 3～4セット（目安）行う！
          <br />
          ☆なれてきたら下にふむ意識でやってみよう！
          <br />
          ☆よろしければ保護者様が横で手を叩いてリズムをとってあげるとやりやすいかも！目安は1秒に3～4回くらいがおススメ！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！※足音がなりますので、気になる方はご注意ください！
        </span>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/RgfOs-J-nWk',
      title: 'Y字ポジションもも上げスプリント',
      message: (
        <span>
          （動画3）「Y字ポジションもも上げスプリント20m」× 3～4本行う！
          <br />
          ☆いいしせいのまま、「あしをからだの前で動かす」感覚をおぼえよう！
        </span>
      ),
    },
  ],
};

export const arm_swing_Walk = {
  title: 'うでふりフルコース',
  grade: '10~7級',
  target: ['うでふり'],
  difficulty: 3,
  image: arm_swing_Walk_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/xwRSfjwvLMs',
      title: 'その場うでふり',
      message: (
        <div>
          （動画1）「その場腕振り」を20回 × 3セット行う！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </div>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/mXkqgxMT_l0',
      title: 'リズム腕振り',
      message: (
        <div>
          （動画2）「リズム腕振り」を20回 × 3セット行う！
          <br />
          ☆保護者さまはよろしければ手をたたいてリズムをとってあげてください！はじめはゆっくり、慣れてきたら1秒に4回のリズムがおすすめです！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </div>
      ),
    },
    {
      link: 'https://www.youtube.com/embed/e4zKa-kz_2o',
      title: 'うで振りウォーク',
      message: (
        <div>
          （動画3）「うで振りウォーク15m」× 2セット（目安）行う！
          <br />
          ⇒慣れてきたら「うで振りウォークでスピードを上げる⇒そのまま15mくらいダッシュ！」×
          3セット（目安）行う！
        </div>
      ),
    },
  ],
};
