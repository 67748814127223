import React from 'react';
import axios from 'axios';
import {
  Button,
  CircularProgress,
  Box,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { HOST, setTokenToHeader } from '../../../v1apiRequest';
import loadingGif from '../../../static/running-101-gif.gif';

export const VideoSendButtonWithProgress = ({
  activeStep,
  params,
  completeFunc,
  formContent,
  failFunc,
  color,
  title = '動画を送信',
}: any) => {
  const [progress, setProgress] = React.useState(0.0);
  const [isUploading, setIsUploading] = React.useState(false);
  const sendVideo = () => {
    console.log('push');
    setIsUploading(true);
    postVideoWithProgress(
      params,
      setProgress,
      completeFunc,
      failFunc,
      setIsUploading,
    );
  };

  //console.log(params, activeStep);

  if (activeStep >= 1 && !!formContent && formContent.length > 0) {
    return (
      <div style={{ marginTop: '20px' }}>
        {isUploading ? (
          <>
            送信中 <CircularProgress size={20} />
            <div>
              <LinearProgressWithLabel progress={progress} />
            </div>
            <div>
              <img alt="ロード中" src={loadingGif} height="150" width="auto" />
            </div>
          </>
        ) : (
          <>
            <Button variant="contained" color={color} onClick={sendVideo}>
              {title}
            </Button>
          </>
        )}
      </div>
    );
  }
  return (
    <>
      <Button variant="contained" disabled>
        {title}
      </Button>
    </>
  );
};

async function postVideoWithProgress(
  params: any,
  onProgressFunc: any,
  completeFunc: any,
  failFunc: any,
  setIsUploading: any,
) {
  const config = {
    onUploadProgress: function (progressEvent: any) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 99) / progressEvent.total,
      );
      console.log(percentCompleted);
      onProgressFunc(percentCompleted);
    },
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: setTokenToHeader(),
    },
  };
  console.log(params);

  axios
    .post(`${HOST}/post-videos/`, params, config)
    .then((res) => {
      console.log('OK');
      completeFunc();
      setIsUploading(false);
    })
    .catch((res) => {
      console.log('fail');
      failFunc();
      setIsUploading(false);
    });
}

const LinearProgressWithLabel = (props: any) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={props.progress} />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color="textSecondary"
        >{`${props.progress}%`}</Typography>
      </Box>
    </Box>
  );
};
