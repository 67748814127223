import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { AccountProvider } from 'contexts/AccountContext';
import { AuthProvider } from 'contexts/AuthContext';

import { Router } from 'router/index';

export const App = () => {
  return (
    <div>
      <BrowserRouter>
        <AuthProvider>
          <AccountProvider>
            <Router />
          </AccountProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
