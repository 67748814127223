import React, { FC, memo } from 'react';
import classes from './practiceCarousel.module.css';
import joy_boy from 'static/materials/joy_boy.png';
import levelUpMenu from 'static/materials/checkLevelUpMenu.png';
import { calcMaxGrade } from 'components/constants';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { calcTarget } from 'components/MenuConst/GoalsForEachGrade/targetsForEachGrade';

type Props = {
  runner: RunnerType;
};

export const ThirdView: FC<Props> = memo(({ runner }) => {
  const maxGrade = calcMaxGrade(runner);
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '20%' }}>
          <img src={joy_boy} alt="" width="60%"></img>
        </div>
        <div style={{ width: '80%' }}>
          <div className={classes.blackBorderSmall}>
            {maxGrade - 1}級になるには
            <br />
            <span style={{ color: '#ff0000' }}>{calcTarget(runner)}</span>
            を
            <br />
            身につけよう！
          </div>
        </div>
      </div>
      <div style={{ marginTop: '16px' }}>
        <img src={levelUpMenu} alt="" width="80%"></img>
      </div>
    </>
  );
});
