import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';

export const re_kabeoshiBasicVideos = [
  {
    link: 'https://www.youtube.com/embed/usm_mhrZSd4',
    title: '壁押しドリル',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「壁押しももあげ20歩」× 4セットおこなう！
        </div>
        <div className={classes.title_important}>ふくしゅうのポイント</div>
        <div className={classes.content}>
          1. よい姿勢 ☆よこからみてまっすぐかな？かかとはういてるかな？
          <br />
          2. 足の上がり ☆ふとももが90度あがっているかな？
        </div>
        <div className={classes.title_important}>
          うえ2つができた子はチャレンジ！
        </div>
        <div className={classes.content}>
          3. 「45度～60度」くらい倒してチャレンジしてみよう！
          <br />
          <br />
          ☆よろしければ「保護者様に腰をつかんで真後ろに軽く（椅子を引くくらいの力感で）引っ張ってもらいながら」壁押しをしてみてください！その際お子様はお尻が引けないように頑張って耐えてみるといい姿勢の感覚がつかみやすいですよ！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/7VW4zxcKa5Q',
    title: 'タオルもも上げ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「タオルももあげ20歩」× 3本（目安）を行う！
        </div>
        <div className={classes.content}>
          ☆なれてきたら前に進んだり走ったりしてみよう！
        </div>
      </div>
    ),
  },
];

export const dash10m13thVideos = [
  {
    link: 'https://www.youtube.com/embed/1yD-JQKfAGQ',
    title: '倒れ込み壁押しドリル',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「壁に倒れこみ」を練習（目安2～3分）！
        </div>
        ☆保護者さんなどにうまくできているかチェックしてもらおう！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/f1xmOxjA6SA',
    title: '倒れこみダッシュ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「倒れこみダッシュ10m」× 5本（目安）を行う！
        </div>
        ☆腰が折れ曲がってないか保護者さんなどにチェックしてもらおう！
        <br />
        ☆おうちの近くにのぼり坂があったら、そこでやってみよう！！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/SbSr3_5aXTI',
    title: '1次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3:「スタートから10mまでの走り方」を学ぶ！！
        </div>
        ⇒「10mダッシュ」にたくさんチャレンジ！
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本を送信！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          昇級のポイント
        </div>
        1. （スタートの構え）前あしに体重をのせる
        ☆後ろ足のかかとはういているかな？前あしの膝が足首のだいたい真上にあるかな？
        <br />
        2. （スタートの構え）いい姿勢で体をたおす
        ☆横から見て上体と後ろ足がまっすぐかな？目線は3m先を見れているかな？
        <br />
        3. しっかり体を倒す
        ☆6歩以上体をたおして走ろう！あごをひいて、目線は5m先くらい！
        <br />
        ※のぼり坂で10mダッシュの練習をすると意識しやすいかも！近場にのぼり坂のある子は試してみてね！
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ3つができた子はチャレンジ！～
        </div>
        4. 足を前に前に ☆かべおしのときと同じ！
        <br />
        5. 腕ふり ☆前に前にふろう！
      </div>
    ),
  },
];

export const verticalJumpVideos = [
  {
    link: 'https://www.youtube.com/embed/Ue37vjq_Yf4',
    title: '垂直跳び',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「垂直跳び」を練習（目安2～3分）！
        </div>
        ☆うでも使って大きくとべるようになろう！
      </div>
    ),
  },
];

//以下10級パッケージ用（共通多い可能性あり）

export const howToStartVideos = [
  {
    link: 'https://www.youtube.com/embed/sLO-uws-XLs',
    title: 'スタート姿勢の作り方',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画1）「正しいスタート姿勢」をマスターしよう！！（動画のお手本の形をマネしてみよう！）
        </div>
        ☆保護者さんなどにうまくできているかチェックしてもらおう！
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          チェックポイント
        </div>
        1. 前あしに体重をのせる
        ☆後ろ足のかかとはういているかな？前あしの膝が足首のだいたい真上にあるかな？
        <br />
        2. いい姿勢で体をたおす
        ☆横から見て上体と後ろ足がまっすぐかな？目線は3m先を見れているかな？
        <br />
        <br />
        ☆腕は前後にひらくんでも下にたらすんでも、やりやすい形でOK！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/SbSr3_5aXTI',
    title: '1次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画2）「スタートから10mまでの走り方」を学ぶ！！
        </div>
        ⇒倒れこみダッシュと壁押しを練習！
        <br />
        ⇒「10mダッシュ」にたくさんチャレンジ！
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本を送信！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          昇級のポイント
        </div>
        1. （スタートの構え）前あしに体重をのせる
        ☆後ろ足のかかとはういているかな？前あしの膝が足首のだいたい真上にあるかな？
        <br />
        2. （スタートの構え）いい姿勢で体をたおす
        ☆横から見て上体と後ろ足がまっすぐかな？目線は3m先を見れているかな？
        <br />
        3. しっかり体を倒す
        ☆6歩以上体をたおして走ろう！あごをひいて、目線は5m先くらい！
        <br />
        ※のぼり坂で10mダッシュの練習をすると意識しやすいかも！近場にのぼり坂のある子は試してみてね！
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ3つができた子はチャレンジ！～
        </div>
        4. 足を前に前に ☆かべおしのときと同じ！
        <br />
        5. 腕ふり ☆前に前にふろう！
      </div>
    ),
  },
];

export const kabeoshiReVideos = [
  {
    link: 'https://www.youtube.com/embed/usm_mhrZSd4',
    title: '壁押しドリル',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画1～4）「壁押しももあげ15歩」× 4セット行う！
        </div>
        ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/a2XE9UnDs3g',
    title: '壁押しドリル注意点①',
    message: (
      <div className={classes.subtitle}>
        （動画2）「壁押し」の注意ポイント1：あしを後ろにはね上げない！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/CVWlD11uW5U',
    title: '壁押しドリル注意点②',
    message: (
      <div className={classes.subtitle}>
        （動画3）「壁押し」の注意ポイント2：かかとは浮かせる！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/M39McbpaKgs',
    title: '壁押しドリル注意点③',
    message: (
      <div className={classes.subtitle}>
        （動画4）「壁押し」の注意ポイント3：足は最初と同じ位置に着地する！
      </div>
    ),
  },
];

////////////////////////////////////////////////////
// TRAC
////////////////////////////////////////////////////

export const one_leg_jump = [
  {
    link: 'https://www.youtube.com/embed/cFXPn4ou5LQ',
    title: 'その場片足ジャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>その場片足ジャンプ</div>
        <div className={classes.content}>
          ☆ぜひ動画のまねをしてやってみてね！
        </div>
      </div>
    ),
  },
];

export const jigzag_one_leg_jump = [
  {
    link: 'https://www.youtube.com/embed/RrLgh44qzYI',
    title: '片足ジグザグジャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>片足ジグザグジャンプ</div>
        <div className={classes.content}>
          ☆ぜひ動画のまねをしてやってみてね！
        </div>
      </div>
    ),
  },
];

export const side_hop = [
  {
    link: 'https://www.youtube.com/embed/e7miySfsAE4',
    title: 'サイドケンケン',
    message: (
      <div>
        <div className={classes.subtitle}>サイドケンケン</div>
        <div className={classes.content}>
          ☆ぜひ動画のまねをしてやってみてね！
        </div>
      </div>
    ),
  },
];

export const side_hop_arm_raise = [
  {
    link: 'https://www.youtube.com/embed/i4QkVCBpF3s',
    title: '片足腕上げジャンプ',
    message: (
      <div>
        <div>
          <div className={classes.subtitle}>
            片足腕上げジャンプ！
            <br />
            ☆昇級テストの挑戦は右下の ➕ボタン⇒昇級に挑戦」から行えます！
          </div>
          <div className={classes.title_important}>昇級のポイント</div>
          <div className={classes.content}>
            1. 良いしせいを作る！
            <br />
            2. ひざが曲がらないように！
            <br />
            3. 腕をまっすぐ上げる！
          </div>
        </div>
      </div>
    ),
  },
];
