// 10級パッケージの8級
type ckptTypeForGrade_8_10kyu =
  | 'よい姿勢'
  | '足の上がり'
  | '空中であしを入れかえる'
  | '体をたおせているか';

export const ckptForGrade_8_10kyu: ckptTypeForGrade_8_10kyu[] = [
  'よい姿勢',
  '足の上がり',
  '空中であしを入れかえる',
  '体をたおせているか',
];

export const requirement_8_10kyu: ckptTypeForGrade_8_10kyu[] = [
  'よい姿勢',
  '足の上がり',
  '空中であしを入れかえる',
];

export type ckptDetails_8_10 =
  | '腕が地面と平行'
  | '腰の引け具合'
  | 'リーチアウト'
  | '足の上がり'
  | '足を空中で入れかえる'
  | '倒れ込み角度'
  // 採点用
  | 'かかとが浮いているか'
  | '前に進んでいるか'
  | '後ろへの蹴り上げ';

type allCheckpoints_8_10kyu_type = {
  [key in ckptTypeForGrade_8_10kyu]: ckptDetails_8_10[];
};

export const allCheckPoints_8_10kyu: allCheckpoints_8_10kyu_type = {
  よい姿勢: ['腰の引け具合', '腕が地面と平行'],
  足の上がり: ['足の上がり', 'リーチアウト'],
  空中であしを入れかえる: ['足を空中で入れかえる'],
  体をたおせているか: ['腰の引け具合', '倒れ込み角度'],
};

export const borderLine_8_10kyu: allCheckpoints_8_10kyu_type = {
  よい姿勢: ['腰の引け具合'],
  足の上がり: ['足の上がり'],
  空中であしを入れかえる: ['足を空中で入れかえる'],
  体をたおせているか: ['腰の引け具合', '倒れ込み角度'],
};
