import React from 'react';
import towelhighknee_thumb from 'components/MenuConst/thumbnails/towelhighknee.jpg';
import Skip_thumb from 'components/MenuConst/thumbnails/skip_challenge.jpg';
import skaterjump_thumb from 'components/MenuConst/thumbnails/skaterjump.jpg';
import startpose from 'components/MenuConst/thumbnails/start_posture.png';
import dash_10m_thumb from 'components/MenuConst/thumbnails/dash_10m.jpg';
import henkeiDash_thumb from 'components/MenuConst/thumbnails/henkeidash.jpg';
import verticalJump_thumb from 'components/MenuConst/thumbnails/verticaljump.jpg';
import challenge13th_thumb from 'components/MenuConst/thumbnails/challenge_13th.png';
import intro_13th_thumb from 'components/MenuConst/thumbnails/intro_13th.jpg';
import { menuType } from 'pages/PracticePage/type';
import {
  re_kabeoshiBasicVideos,
  dash10m13thVideos,
  verticalJumpVideos,
} from './videos';

import {
  skipBasicVideos,
  skaterJumpVideos,
} from '../grade_6/videos';

import {
  henkeiDashVideos,
} from '../grade_3/videos';

import {
  howToStartPoseVideos,
} from '../grade_0/videos';


import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';

// up: 準備, tech: ぎじゅつ, run: 体力, family: 家族

// プレイリスト

//up
export const re_kabeoshiBasic_13th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'タオルハイニー',
  isInside: 'able',
  target: ['足の動き', 'スタート'],
  menuVariation: ['up'],
  hitWordList: [
    'たおる',
    'かべ',
    'タオル',
    '壁',
    'はいにー',
    'ハイニー',
  ],
  grade: 7,
  image: towelhighknee_thumb,
  video: re_kabeoshiBasicVideos,
};

export const skipBasic_13th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'スキップ',
  isInside: 'unable',
  target: ['足の動き', 'スタート'],
  menuVariation: ['up'],
  hitWordList: [
    'すきっぷ',
    'スキップ',
  ],
  grade: 7,
  image: Skip_thumb,
  video: skipBasicVideos,
};

//tech

export const howToStartPose_13th: menuType = {
  duration: 5,
  title: 'スタートのかまえ',
  target: ['スタート'],
  isInside: 'able',
  menuVariation: ['tech'],
  hitWordList: [
    'スタート',
    'すたーと',
    '構え',
    'かまえ',
    'いえ',
    '家',
    'おうち',
  ],
  grade: 7,
  image: startpose,
  video: howToStartPoseVideos,
};

export const dash10m_13th: menuType = {
  duration: 5,
  title: '10mダッシュ ☆昇級テスト',
  target: ['スタート'],
  isInside: 'half',
  menuVariation: ['tech'],
  hitWordList: [
    'スタート',
    'すたーと',
    '10',
    'じゅう',
    'ダッシュ',
    'だっしゅ',
  ],
  grade: 7,
  image: dash_10m_thumb,
  video: dash10m13thVideos,
};

//run
export const skaterJump_13th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'スケータージャンプ',
  isInside: 'half',
  target: ['スタート'],
  menuVariation: ['run'],
  hitWordList: [
    'スケータージャンプ',
    'すけーたー',
  ],
  grade: 7,
  image: skaterjump_thumb,
  video: skaterJumpVideos,
};

export const henkeidash_13th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '変形ダッシュ',
  isInside: 'unable',
  target: ['スタート'],
  menuVariation: ['run'],
  hitWordList: [
    '変形',
    'スタート',
  ],
  grade: 7,
  image: henkeiDash_thumb,
  video: henkeiDashVideos,
};

//family

export const verticalJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'すいちょくとび',
  isInside: 'able',
  target: ['スタート'],
  menuVariation: ['family'],
  hitWordList: [
    'すいちょく',
    '垂直',
  ],
  grade: 7,
  image: verticalJump_thumb,
  video: verticalJumpVideos,
};






//チャレンジ・イントロ

export const challenge_13th: menuType = {
  duration: 5,
  hitWordList: ['13', '昇級', 'Y字', 'ワイ', 'Y', 'スプリント', 'タオル'],
  title: '13級の昇級用メニュー',
  grade: 7,
  menuVariation: ['levelUp'],
  image: challenge13th_thumb,
  isInside: 'unable',
  target: ['しせい'],
  video: [
    {
      link: 'https://www.youtube.com/embed/1BqgOS35y-M',
      title: '13級の昇級用メニュー',
      message: (
        <span>
          13級の昇級テスト挑戦動画はコチラ！
          <br />
          ☆動画の指示に従って撮影し、画面右下の「+ボタン」から「昇級に挑戦」をタップして、動画を送信しよう！
          <br />
          ☆詳しい練習方法やポイントは「おすすめ」から確認！
        </span>
      ),
    },
  ],
};

export const intro_13th: menuType = {
  duration: 2,
  isInside: 'able',
  target: ['しせい'],
  hitWordList: ['13', '昇級', 'メッセージ', 'めっせーじ', 'じゅう'],
  title: '13級の方へのメッセージ',
  menuVariation: ['levelUp'],
  grade: 7,
  image: intro_13th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['13'],
      title: 'intro13th',
      message: <span>13級の方へのメッセージはコチラ！ </span>,
    },
  ],
};
