import React from 'react';

import Uphill_thumb from 'components/MenuConst/thumbnails/uphill_start.jpg';
import practice_50m_thumb from 'components/MenuConst/thumbnails/practice_50m.png';
import kabeoshi_seq_thumb from 'components/MenuConst/thumbnails/kabeoshi_seq.png';
import aboveSkip from 'components/MenuConst/thumbnails/above_skip.png';
import intro_2nd_thumb from 'components/MenuConst/thumbnails/intro_2nd.jpg';
import challenge2nd_thumb from 'components/MenuConst/thumbnails/challenge_2nd.jpg';
import { kabeoshiSeqVideos, practice50mVideos, skipAdvVideos } from './videos';
import { menuType } from 'pages/PracticePage/type';

export const kabeoshiAdv: menuType = {
  grade: 8,
  duration: 15,
  title: '壁押し上級☆家でもできる！#昇級テスト',
  image: kabeoshi_seq_thumb,
  video: kabeoshiSeqVideos,
  isInside: 'able',
  target: ['スタート', '足の動き'],
  menuVariation: ['tech'],
};

export const skipAdv: menuType = {
  duration: 4,
  grade: 8,
  title: 'スキップ上級',
  image: aboveSkip,
  target: ['全力走'],
  isInside: 'unable',
  video: skipAdvVideos,
  menuVariation: ['run'],
};

export const Uphill = {
  duration: 4,
  grade: 8,
  title: 'のぼり坂チャレンジ',
  image: Uphill_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/SbSr3_5aXTI',
      title: '1次加速の練習',
      message: (
        <div>
          （動画1）「スタートから10mまでの走り方」を復習！！
          ⇒「のぼり坂10mダッシュ」にたくさんチャレンジ！
          <div
            style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
          >
            ポイントおさらい
          </div>
          1. （スタートの構え）前あしに体重をのせる
          ☆後ろ足のかかとはういているかな？前あしの膝が足首のだいたい真上にあるかな？
          <br />
          2. （スタートの構え）いい姿勢で体をたおす
          ☆横から見て上体と後ろ足がまっすぐかな？目線は3m先を見れているかな？
          <br />
          3. しっかり体を倒す
          ☆6歩以上体をたおして走ろう！あごをひいて、目線は5m先くらい！
          <div
            style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
          >
            ～うえ2つができた子はチャレンジ！～
          </div>
          4. 足を前に前に ☆かべおしのときと同じ！
          <br />
          5. 腕ふり ☆前に前にふろう！
        </div>
      ),
    },
  ],
};

export const practice_50m = {
  title: '50mの練習',
  grade: 8,
  duration: 10,
  image: practice_50m_thumb,
  video: practice50mVideos,
  target: ['全力走'],
  isInside: 'unable',
  menuVariation: ['run'],
};

export const challenge_2nd = {
  duration: 5,
  hitWordList: ['2', '昇級', 'にきゅう'],
  title: '2級の昇級用メニュー',
  grade: 8,
  image: challenge2nd_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/1jV1BqnPCyk',
      title: '2級の昇級用メニュー',
      message: <span>2級の昇級テスト挑戦動画はコチラ！</span>,
    },
  ],
};

export const intro_2nd = {
  duration: 2,
  hitWordList: ['2', '昇級', 'メッセージ', 'めっせーじ', 'にきゅう'],
  title: '2級の方へのメッセージ',
  grade: 8,
  image: intro_2nd_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/seMKF5NZbJk',
      title: '2級イントロ動画',
      message: <span>2級の方へのメッセージはコチラ！ </span>,
    },
  ],
  menu: (
    <div>
      <span style={{ textAlign: 'center' }}>練習C</span>

      <ul style={{ fontSize: '10pt', textAlign: 'left' }}>
        <li>動画1/5を見て、Y字姿勢の作り方を確認</li>
        <li>
          動画2/5～4/5を見て、「Y字しせいで20m走る」を、特に注意ポイント①を気をつけながら、(5本目安)行う！(タオルをご用意できる方は動画5/5を参考に、タオルを持った状態で行う！)
        </li>
      </ul>
    </div>
  ),
};
