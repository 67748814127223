import { ckptForGrade_0_20kyu } from './GoalsForEachGrade/CheckpointsFor20Pkg/20_kyu';
import { ckptForGrade_1_20kyu } from './GoalsForEachGrade/CheckpointsFor20Pkg/19_kyu';
import { ckptForGrade_2_20kyu } from './GoalsForEachGrade/CheckpointsFor20Pkg/grade_2';
import { ckptForGrade_3_20kyu } from './GoalsForEachGrade/CheckpointsFor20Pkg/grade_3';
import { ckptForGrade_4_20kyu } from './GoalsForEachGrade/CheckpointsFor20Pkg/grade_4';
import { ckptForGrade_5_20kyu } from './GoalsForEachGrade/CheckpointsFor20Pkg/grade_5';
import { ckptForGrade_6_20kyu } from './GoalsForEachGrade/CheckpointsFor20Pkg/grade_6';
import { ckptForGrade_7_20kyu } from './GoalsForEachGrade/CheckpointsFor20Pkg/13_kyu';
import { ckptForGrade_8_20kyu } from './GoalsForEachGrade/CheckpointsFor20Pkg/grade_8';
import { ckpt_10kyu_type } from './GoalsForEachGrade/CheckpointsForTrac10kyu/10kyu';
import { ckpt_9kyu_type } from './GoalsForEachGrade/CheckpointsForTrac10kyu/9kyu';
import { ckpt_8kyu_type } from './GoalsForEachGrade/CheckpointsForTrac10kyu/8kyu';

// 孫用の言葉（Deep）
export type checkPointListJA =
  | 'eval'
  | 'いい姿勢'
  | 'ポンポン感'
  | '接地'
  | 'うで振り'
  | '腕が体と平行である'
  | 'スピードが出せている'
  | '前後傾'
  | '腕が上を向いている'
  | '腰が落ちていない'
  | '腰が高い'
  | 'うでを下にポンポンふる'
  | '足が最低限上がっている'
  | '接地様式'
  | '倒れ込み角度'
  | '腰の引け具合'
  | 'かかとが浮いているか'
  | '前に進んでいるか'
  | '足の上がり'
  | '後ろへの蹴り上げ'
  | '腕が地面と平行'
  | 'リーチアウト'
  | '膝のつぶれ'
  | 'リズム'
  | '股関節から動かせている'
  | 'ベースポジションで止まる'
  | 'ハイニーができている'
  | '後ろに蹴っていない'
  | '下に踏む'
  | '上げた足をキープできている'
  | '足をからだの前でいれかえる'
  | '前さばき'
  | '構えで前脚に体重がのっている'
  | '構えで上体角度が45度程度'
  | '構えで横から見て上体と後ろ足がまっすぐ'
  | '走りで6歩以上体を倒せている'
  | '足を前に前に持ってくる'
  | 'うでを前に前に振る'
  | '足を空中で入れかえる'
  | '六歩以上体を倒せている'
  | '足の流れ'
  | '足を壁押しのように動かす'
  | 'よい姿勢'
  | '空中であしを入れかえる'
  | '体をたおせているか'
  | '六歩以上体を倒せている'
  | '足の流れ'
  | 'リーチアウト'
  | '足の上がり'
  | 'うでを前に前に振る'
  | '足が前に戻ってきている'
  | '後ろに蹴り上げている'
  | 'リズムが早く大股走りになっている'
  | 'はずめている'
  | '膝がつぶれている'
  | '前に進んでいる'
  | '腕が使えている'
  | 'scoredGrade'
  | ckpt_10kyu_type
  | ckpt_9kyu_type
  | ckpt_8kyu_type;

export const ckptShallowAll = [
  ...ckptForGrade_0_20kyu,
  ...ckptForGrade_1_20kyu,
  ...ckptForGrade_2_20kyu,
  ...ckptForGrade_3_20kyu,
  ...ckptForGrade_4_20kyu,
  ...ckptForGrade_5_20kyu,
  ...ckptForGrade_6_20kyu,
  ...ckptForGrade_7_20kyu,
  ...ckptForGrade_8_20kyu,
];

export type ckptShallow = typeof ckptShallowAll[number];

export type ScoreEditType = {
  [key in checkPointListJA]?: string | grades;
};

export type grades10 =
  | '10'
  | '9'
  | '8'
  | '7'
  | '6'
  | '5'
  | '4'
  | '3'
  | '2'
  | '1'
  | '0';

export type grades =
  | '20'
  | '19'
  | '18'
  | '17'
  | '16'
  | '15'
  | '14'
  | '13'
  | '12'
  | '11'
  | '10'
  | '9'
  | '8'
  | '7'
  | '6'
  | '5'
  | '4'
  | '3'
  | '2'
  | '1'
  | '0';
type numberGradeConverterType = (num: number) => grades;
export const numberGradeConverter: numberGradeConverterType = (num) => {
  if (num < 0 || num > 9) {
    return '10' as grades;
  }
  return String(10 - num) as grades;
};
export const gradeGoalDict = {
  10: ['Y字スプリントを習得せよ！'], //外
  9: ['壁押しドリルを習得せよ！'], //家
  8: ['3歩ハイニーを習得せよ！'], // 家
  7: ['足を体の前で回す！'], //外
  6: ['自転車ドリルを習得せよ！'], //外
  5: ['3ステップを習得せよ！'], //家 または外
  4: ['20m以降の走りを身に付けよ！'], // 外
  3: ['スタートの構えを習得せよ！'], // 外
  2: ['壁押し上級を習得せよ！'], // 家
  1: ['スタートを身に付けよ!'], // 外
};

export const gradeObjectDict = {
  20: 'よいしせいを身につける！', //外
  19: '足をあげるを身に付ける！', //家
  18: '足を体の前でいれかえる！', // 家
  17: '今までのおさらいをしよう！', //外
  16: 'ハイニーを走りに近づけよう！', //外
  15: 'これまでの成果を走りで見せよ！', //外
  14: '今までの総おさらいをしよう！', // 外
  13: 'スタートのかまえを身につける！', // 外
  12: '足を前に前にもってくる！', // 家
  11: 'これまでの成果を走りで見せよ！', // 外
  10: '今までのおさらいをしよう！', //外
  9: 'うしろ足をピュンッっともどす！', //外
  8: '足をすばやくいれかえる！', // 外
  7: 'マーク走をみにつけよ！', //外
  6: '今までのおさらいをしよう！', //
  5: 'かべおしマスターを目指せ！',
  4: '一歩一歩をおおきくする！', // 外
  3: '走りのアドバイスをもらおう！', // 外
  2: 'スタートをマスターしよう', // 家
  1: 'これまでの成果を走りで見せよ！', // 外,いったんここまで編集DONE！
  0: '体力を強化しよう！',
};

export const gradeObjectDict_10 = {
  10: ['よい姿勢で走る！'], //外
  9: ['壁押しドリルを習得せよ！'], //家
  8: ['3歩ハイニーを習得せよ！'], // 家
  7: ['足を体の前で回す！'], //外
  6: ['自転車ドリルを習得せよ！'], //外
  5: ['3ステップを習得せよ！'], //家 または外
  4: ['20m以降の走りを身に付けよ！'], // 外
  3: ['スタートの構えを習得せよ！'], // 外
  2: ['壁押し上級を習得せよ！'], // 家
  1: ['スタートを身に付けよ!'], // 外
  0: ['上級編にチャレンジ!'],
};

export const gradeObjectNewTrac = {
  10: ['よい姿勢をつくる！'], //外
  9: ['スタートの姿勢をおぼえる！'], //家
  8: ['足のつき方をおぼえる！'], // 家
  7: ['うでふりをおぼえる！'], //外
  6: ['地面をしっかり押す！'], //外
  5: ['足の動かし方をおぼえる'], //家 または外
  4: ['走りのチェック！'], // 外
  3: ['片足でバランスをとる！'], // 外
  2: ['足をしっかり上げる！'], // 家
  1: ['走りの総復習!'], // 外
  0: ['上級編にチャレンジ!'],
};
