import React, { FC, memo } from 'react';
import classes from './practiceCarousel.module.css';
import joy_boy from 'static/materials/joy_boy.png';

export const FirstView: FC = memo(() => {
  return (
    <>
      <div className={classes.blackBorder}>
        ここからは練習のしかたを
        <br />
        ていねいに説明していくよ！
      </div>
      <div className={classes.joyboyWrapper}>
        <img src={joy_boy} alt=""></img>
      </div>
    </>
  );
});
