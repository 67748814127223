import React, { FC, memo, Dispatch, SetStateAction } from 'react';
import { menuVariationType } from '../type';
import classes from './iconButtons.module.css';
import warmupIcon from 'static/menu_page/warm_up.png';
import familyIcon from 'static/menu_page/family.png';
import runMenuIcon from 'static/menu_page/runmenu.png';
import techniqueIcon from 'static/menu_page/technique.png';

type Props = {
  menuVariation: menuVariationType;
  setMenuVariation: Dispatch<SetStateAction<menuVariationType>>;
};

export const MenuVariationSelector: FC<Props> = memo(
  ({ menuVariation, setMenuVariation }) => {
    return (
      <div style={{ margin: '10px', display: 'flex' }}>
        {menuVariation === 'up' && (
          <div
            className={classes.selected}
            onClick={() => setMenuVariation('up')}
          >
            <div style={{ width: '9vw' }}>
              {' '}
              <div>
                <img src={warmupIcon} alt="" width="100%" />
              </div>
            </div>
          </div>
        )}
        {menuVariation !== 'up' && (
          <div
            className={classes.disabled}
            onClick={() => setMenuVariation('up')}
          >
            <div style={{ width: '9vw' }}>
              <div>
                <img src={warmupIcon} alt="" width="100%" />
              </div>
            </div>
          </div>
        )}

        {menuVariation === 'tech' && (
          <div style={{ marginLeft: '2vw' }}>
            <div
              className={classes.selected}
              onClick={() => setMenuVariation('tech')}
            >
              <div style={{ width: '11vw' }}>
                {' '}
                <div>
                  <img src={techniqueIcon} alt="" width="100%" />
                </div>
              </div>
            </div>
          </div>
        )}
        {menuVariation !== 'tech' && (
          <div style={{ marginLeft: '2vw' }}>
            <div
              className={classes.disabled}
              onClick={() => setMenuVariation('tech')}
            >
              <div style={{ width: '11vw' }}>
                {' '}
                <div>
                  <img src={techniqueIcon} alt="" width="100%" />
                </div>
              </div>
            </div>
          </div>
        )}

        {menuVariation === 'run' && (
          <div style={{ marginLeft: '2vw' }}>
            <div className={classes.selected}>
              <div style={{ width: '12vw' }}>
                {' '}
                <div>
                  <img src={runMenuIcon} alt="" width="100%" />
                </div>
              </div>
            </div>
          </div>
        )}

        {menuVariation !== 'run' && (
          <div style={{ marginLeft: '2vw' }}>
            <div
              className={classes.disabled}
              onClick={() => setMenuVariation('run')}
            >
              <div style={{ width: '12vw' }}>
                {' '}
                <div>
                  <img src={runMenuIcon} alt="" width="100%" />
                </div>
              </div>
            </div>
          </div>
        )}

        <div style={{ marginLeft: '2vw' }}>
          {menuVariation !== 'family' && (
            <div
              className={classes.disabled}
              onClick={() => setMenuVariation('family')}
            >
              <div style={{ width: '12vw' }}>
                {' '}
                <div>
                  <img src={familyIcon} alt="" width="110%" />
                </div>
              </div>
            </div>
          )}
          {menuVariation === 'family' && (
            <div className={classes.selected}>
              <div style={{ width: '12vw' }}>
                {' '}
                <div>
                  <img src={familyIcon} alt="" width="110%" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);
