import React from 'react';
import { postDauByUser } from '../v1apiRequest';

export const useGetDaus = (runnerId: string) => {
  React.useEffect(() => {
    if (!runnerId) {
      return;
    }
    postDauByUser(runnerId)
      .then((_) => {
        return;
      })
      .catch((e) => {
        console.log(e);
        return;
      });
  }, []);
};
