// TRAC10級パッケージの9級

export const ckptFor9kyu_trac10 = [
  '前足のひざ',
  'うで',
  'かまえのしせい',
  'うしろあし',
  'せなか',
] as const;

export type ckpt_9kyu_type = typeof ckptFor9kyu_trac10[number];

export const requirement_9kyu_trac: ckpt_9kyu_type[] = [
  '前足のひざ',
  'うで',
  'かまえのしせい',
];

type options = {
  [key in ckpt_9kyu_type]: string[];
};

export const optionDict_9kyu_trac: options = {
  前足のひざ: ['◯適度に曲がっている', '×過度に曲がっている', '×曲がっていない'],
  うで: ['◯前足と反対側の腕が前', '×前足と同じ側の腕が前'],
  かまえのしせい: ['◯前に倒れている', '×起き上がっている'],
  うしろあし: ['◯浮いていない', '×浮いている'],
  せなか: ['◯まっすぐ', '×丸くなっている'],
};

type complimentType = {
  [key in ckpt_9kyu_type]: string;
};

// まずxxくんの良い点として、に続く文章を書く
export const complimentDict_9: complimentType = {
  前足のひざ:
    '前足のひざがちょうどよく曲がっている点があります！構えの一番大事なところはバッチリです！',
  うで: '前足と反対の腕をしっかり前に出せているところがあります！スタートのきそがよくできていますね！',
  かまえのしせい:
    'からだをしっかり前に倒せている点があります！スタートで飛び出す準備がしっかりできていますね！',
  うしろあし:
    '後ろあしでもしっかり地面を捉えられている点があります！良いスタートを切る準備がしっかりできていますね！',
  せなか:
    '背中がしっかりとまっすぐになっている点があります！プロ選手のようなかっこいい姿勢ができていますよ！',
};

export const nobishiroOptions_9_trac = Object.values(optionDict_9kyu_trac)
  .flat()
  .filter((value) => !value.includes('◯'));

type nobishiroDict = {
  [key in typeof nobishiroOptions_9_trac[number]]?: string;
};

// そんな〇〇くんの伸びしろポイントはズバリ、に続く文章を書く
export const nobishiroDict_9: nobishiroDict = {
  '×過度に曲がっている':
    '前あしのひざが曲がりすぎってしまっていることです。もう少しだけ腰の位置を高くするイメージを持ってみましょう！',
  '×曲がっていない':
    '前あしのひざがまっすぐになってしまっていることです。もう一度ひざに手をついて体重をかけるところからやってみましょう！',
  '×前脚と同じ側の腕が前に出ている':
    '前脚と同じ側の手足が前に出てしまっていることです！腕だけ、もしくは足だけをいれかえてみましょう',
  '×浮いている':
    '後ろ足がういてしまっていることです！もう少しだけ後ろ足にも体重をかけてみましょう！',
  '×丸くなっている':
    '「背中がまるくなっている」ことです！みぞおちを前に出すようなイメージで背中をまっすぐにしてみましょう！',
};
