// 10級パッケージの7級
type ckptTypeForGrade_3_20kyu =
  | 'よい姿勢'
  | '足を体の前で回す'
  | '足のつき方'
  | 'うで振り'
  | 'スピードを出す';

export const ckptForGrade_3_20kyu: ckptTypeForGrade_3_20kyu[] = [
  'よい姿勢',
  '足を体の前で回す',
  '足のつき方',
  'うで振り',
  'スピードを出す',
];

export const requirement_3_20kyu: ckptTypeForGrade_3_20kyu[] = [
  'よい姿勢',
  '足を体の前で回す',
];

export type ckptDetails_3_20 =
  | '前後傾'
  | '腰の引け具合'
  | '下に踏む'
  | '足の上がり'
  | '接地様式'
  | 'うで振り'
  | 'スピードが出せている'
  // 採点用
  | '腰が落ちていない'
  | '後ろに蹴っていない';

type allCheckpoints_3_20kyu_type = {
  [key in ckptTypeForGrade_3_20kyu]: ckptDetails_3_20[];
};

export const allCheckPoints_3_20kyu: allCheckpoints_3_20kyu_type = {
  よい姿勢: ['前後傾', '腰の引け具合'],
  足を体の前で回す: ['足の上がり', '下に踏む'],
  足のつき方: ['接地様式'],
  うで振り: ['うで振り'],
  スピードを出す: ['スピードが出せている'],
};

export const borderLine_3_20kyu: allCheckpoints_3_20kyu_type = {
  よい姿勢: ['前後傾'],
  足を体の前で回す: ['足の上がり'],
  足のつき方: ['接地様式'],
  うで振り: ['うで振り'],
  スピードを出す: ['スピードが出せている'],
};
