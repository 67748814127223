import React, { FC, memo } from 'react';
import joy_boy from 'static/materials/joy_boy.png';
import classes from './onboardingCarousel.module.css';

export const FourthView: FC = memo(() => {
  return (
    <>
      <div className={classes.blackBorder}>
        ここからは昇級テスト挑戦まで
        <br />
        のながれを説明するよ！
      </div>
      <div className={classes.joyboyWrapper}>
        <img src={joy_boy} alt=""></img>
      </div>
    </>
  );
});
