import React, { FC, memo } from 'react';
// import { useNavigate } from 'react-router-dom';
import { usePopover } from 'hooks/usePopover';
import { LetterPopover } from './popovers/LetterPopover';
import { onboardType } from './type';
import classes from './guidanceTools.module.css';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';

type Props = {
  onClickFunc: () => void;
  onboardState: onboardType;
};

export const Letter: FC<Props> = memo(({ onClickFunc, onboardState }) => {
  const isActive = onboardState === 'beforeRead';
  const isBefore = false;
  const innerComponent = LetterPopover(isActive, onClickFunc, isBefore);
  const { handleClick, CustomPopover } = usePopover(innerComponent);

  return (
    <>
      <div className={classes.letterPosition}>
        <div onClick={handleClick}>
          {isActive && (
            <div className={classes.shouldBeTouched}>
              <div className={classes.iconContent}>
                <EmailTwoToneIcon
                  style={{
                    fontSize: '22vw',
                    color: '#ff69b4',
                  }}
                />
              </div>
            </div>
          )}
          {!isActive && (
            <div className={classes.disabled}>
              <div className={classes.iconContent}>
                <EmailTwoToneIcon
                  style={{
                    fontSize: '16vw',
                    color: '#db7093',
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {CustomPopover}
      </div>
    </>
  );
});
