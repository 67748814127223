import React, { FC, memo } from 'react';
import { Card } from '@material-ui/core';

import {
  checkPointListJA,
  ScoreEditType,
  //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { VideoType } from 'adapters/repositories/typeDefinition';
import { ScoreInput } from 'pages/adminPage/parttimePages/components/ScoreInput';
import { optionsForEachCkpt } from 'components/MenuConst/optionsForEachCheckPoint/CheckpointOptions';

type onChangeScoreType = (
  key: string,
) => (e: React.ChangeEvent<HTMLInputElement>) => void;

type Props = {
  chosenVideo: VideoType;
  onChangeScore: onChangeScoreType;
  score: ScoreEditType;
};

export const ManualScoringField: FC<Props> = memo((props) => {
  const { chosenVideo, onChangeScore, score } = props;

  const options = optionsForEachCkpt(
    chosenVideo.upload_runner,
    chosenVideo.grade_before,
  );
  console.log(options);
  const checkpointList = Object.keys(options) as checkPointListJA[]; //集合として一致すれば良い？

  const student_group = chosenVideo.upload_runner.student_group;

  return (
    <div>
      <video
        controls
        width="90%"
        src={`https://idaten-compressed-video.s3.amazonaws.com/video/${chosenVideo.file}`}
        loop
      ></video>
      <>
        {!!chosenVideo.is_exam ? (
          <Card>
            {checkpointList.map((ckpt, _) => {
              return (
                <ScoreInput
                  label={ckpt}
                  currentValue={score[ckpt] || ''}
                  onChangeScore={onChangeScore(ckpt)}
                  options={options[ckpt]}
                  key={_}
                />
              );
            })}
            {['newtrac'].includes(student_group || '') && (
              <ScoreInput
                label={'合否'}
                currentValue={score.eval || ''}
                onChangeScore={onChangeScore('eval')}
                options={['◯合格', '×不合格']}
              />
            )}

            <div style={{ padding: '10px' }}>今回の出来栄え：{score.eval}</div>
          </Card>
        ) : null}
      </>
    </div>
  );
});
