// TRAC10級パッケージの2級

export const ckptFor2kyu_trac10 = [
  'しせい',
  'あげたあしの形',
  '地面についている方のあし',
  'うでふり',
] as const;

export type ckpt_2kyu_type = typeof ckptFor2kyu_trac10[number];

export const requirement_2kyu_trac: ckpt_2kyu_type[] = [
  'しせい',
  'あげたあしの形',
  '地面についている方のあし',
  'うでふり',
];

type options = {
  [key in ckpt_2kyu_type]: string[];
};

export const optionDict_2kyu_trac: options = {
  しせい: ['◯良い', '×腰が落ちている', '×前屈している'],
  あげたあしの形: [
    '◯Zに近い',
    '×つま先が下がっている',
    '×膝が開いている',
    '×上げすぎている',
    '×低すぎる',
  ],
  地面についている方のあし: [
    '◯膝が曲がっていない(許容範囲内)',
    '×大きく曲がっている',
  ],
  うでふり: ['◯できている', '×大きすぎる', '×小さすぎる'],
};

type complimentType = {
  [key in ckpt_2kyu_type]: string;
};

// まずxxくんの良い点として、に続く文章を書く
export const complimentDict_2: complimentType = {
  しせい:
    '「良いしせいのままハイニーができている」点があります！姿勢については文句なし、完璧です！',
  あげたあしの形:
    '上げたあしの形がしっかり「Z」にできています！つま先の向きにまで意識がいきとどいているのがとっても良いですね！',
  地面についている方のあし:
    '「地面についている方の足がしっかり伸ばせている」という点があります。片足で自分の体を支えると、フラフラしたりひざが曲がったりしてしまいますが、よくできていますね！その調子です！',
  うでふり:
    '腕ふりがとても上手にできている点があります！この練習は腕ふりを忘れがちですが、細かいところまでしっかり意識できていてとても良いですね！',
};

export const nobishiroOptions_2_trac = Object.values(optionDict_2kyu_trac)
  .flat()
  .filter((value) => !value.includes('◯'));

type nobishiroDict = {
  [key in typeof nobishiroOptions_2_trac[number]]?: string;
};

// そんな〇〇くんの伸びしろポイントはズバリ、に続く文章を書く
export const nobishiroDict_2: nobishiroDict = {
  '×腰が落ちている':
    '「少し腰が落ちてしまっていること」です！最初はあしを高くあげなくて良いので、こしをまっすぐにすることだけ意識してみましょう！',
  '×前屈している':
    '「体を前にかたむけすぎている」ことです。まずは足の高さは出さなくて良いので、まっすぐな姿勢のまま弾むくらいのイメージでやってみましょう！',
  '×つま先が下がっている':
    '「少しつま先が下がってしまっている」ことです！つま先をもう少しだけあげて「Z」の形を作ってみましょう！',
  '×上げすぎている':
    '「足を上げすぎてしまっている」ことです。足を高く上げられるのはとても良いことですが、走るときは「太ももが地面と平行」より高く上げなくて大丈夫ですよ！',
  '×低すぎる':
    '「ひざの高さが少し低い」ことです。上げたあしの足首がひざのとなりに来るくらい、を目標に足を上げてみましょう！',
  '×大きく曲がっている':
    '「地面についている方のひざが曲がってしまっている」ことです。あしを上げることに慣れたら今度は「支えている方の足のひざをまっすぐにする」ことを意識しよう！あと少し！',
  '×大きすぎる':
    '「腕ふりが大きすぎる」ことです。腕をしっかり触れているのはとっても良いことですが、あまり大きく振ると力んでしまうので、もう少し小さくでも良いですよ！',
  '×小さすぎる':
    '「腕ふりが小さい」ことです。あしの動きはよくできているので、あとはもう少しだけ腕を大きく振ってみましょう！',
};
