import { ckptDetails_8_10 } from 'components/MenuConst/GoalsForEachGrade/CheckpointsFor10Pkg/grade_8';

type options = {
  [key in ckptDetails_8_10]: string[];
};

export const optionsForGrade_8_10pkg: options = {
  倒れ込み角度: ['◯:ちょうど良い（45-60度）', '×:前傾しすぎ', '×:起きすぎ'],
  腰の引け具合: ['◯:良い', '×:ひけている', '×:反っている'],
  かかとが浮いているか: ['◯:浮いている', '×:ついてしまっている'],
  前に進んでいるか: ['◯:進んでいない', '×:進んでいる'],
  足の上がり: ['◯:90度程度上がっている（上げ過ぎは許容）', '×:上がっていない'],
  後ろへの蹴り上げ: ['◯:蹴り上げていない', '×:蹴り上げている'],
  足を空中で入れかえる: ['◯:出来ている', '×:出来ていない'],
  リーチアウト: [
    '◯:していない（足首が膝より前に出ていない）',
    '×:足が開いている',
  ],
  腕が地面と平行: ['◯:平行である', '×:平行でない'],
};
