import React, { FC, useEffect, useState } from 'react';
import { Card, Grid } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import classes from './navigator.module.css';
import { VideoType } from 'adapters/repositories/typeDefinition';

import { VideoContext } from 'contexts/VideoContext';

type Props = {
  videoId: string;
  setCurrentVideo: React.Dispatch<React.SetStateAction<VideoType | null>>;
};
export const VideoNavigator: FC<Props> = ({ videoId, setCurrentVideo }) => {
  const { videoList } = React.useContext(VideoContext);
  const [videoIdx, setVideoIdx] = useState<number>(-1);
  useEffect(() => {
    if (!!videoId && !!videoList && videoList.length > 0) {
      console.log(videoId);
      setVideoIdx(getIdxOfVideo(videoList, videoId));
    }
  }, []);

  if (!videoId || !videoList || videoList.length < 1 || videoIdx < 0) {
    return <></>;
  }

  const currentThumb = videoList[videoIdx].thumbnail;
  const beforeThumb = videoIdx > 0 ? videoList[videoIdx - 1].thumbnail : 'no';
  const nextThumb =
    videoIdx < videoList.length - 1 ? videoList[videoIdx + 1].thumbnail : 'no';

  const handleBeforeClick = () => {
    setVideoIdx(videoIdx - 1);
    setCurrentVideo(videoList[videoIdx - 1]);
  };

  const handleNextClick = () => {
    setVideoIdx(videoIdx + 1);
    setCurrentVideo(videoList[videoIdx + 1]);
  };

  return (
    <Grid container alignItems="flex-end">
      <Grid item xs={3}>
        <div style={{ textAlign: 'center', padding: '4px' }}>
          {beforeThumb !== 'no' ? (
            <>
              <div>
                {'<<'} 次へ
                <br />
              </div>
              <img
                id="iconButton"
                onClick={handleBeforeClick}
                alt=""
                width="95%"
                src={`${beforeThumb}`}
                style={{ boxShadow: '6px 6px 4px gray' }}
              ></img>
            </>
          ) : (
            <div style={{ color: '#dddddd' }}>
              {'<<'} 次へ
              <br />
            </div>
          )}
        </div>
      </Grid>

      <Grid item xs={6}>
        <div style={{ textAlign: 'center' }}>
          <img alt="" width="80%" src={`${currentThumb}`}></img>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div style={{ textAlign: 'center', paddingRight: '6px' }}>
          {nextThumb !== 'no' ? (
            <>
              <div>
                前へ {'>>'}
                <br />
              </div>
              <img
                id="iconButton"
                alt=""
                onClick={handleNextClick}
                width="95%"
                src={`${nextThumb}`}
                style={{ boxShadow: '6px 6px 4px gray' }}
              ></img>
            </>
          ) : (
            <div style={{ color: '#dddddd' }}>
              次へ {'>>'}
              <br />
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

type getIdxOfVideo = (videoList: VideoType[], videoId: string) => number;
export const getIdxOfVideo: getIdxOfVideo = (videoList, videoId) => {
  for (const [idx, video] of videoList.entries()) {
    if (video.id !== videoId) {
      continue;
    }
    console.log(idx);
    return idx;
  }
  return null;
};
