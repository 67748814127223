import React, { FC, memo } from 'react';
import { Button } from '@material-ui/core';
import choose from 'static/lp/second_choose.png';
import trimming from 'static/lp/trimming.png';
import { useSnackBar } from 'hooks/useSnackBar';

type Props = {
  handleNextClick: () => void;
  handleBackClick: () => void;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  file: File | null;
};

export const UploadScreen: FC<Props> = memo(
  ({ handleNextClick, handleBackClick, setFile, file }) => {
    const { forceSnackOpen, CustomSnackbar } = useSnackBar(
      '動画が読みこまれました。「次へ」をクリックしてください',
    );

    const chooseVideo = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFile = !!e.target.files && e.target.files[0];
        if (!uploadedFile) {
          return;
        }
        setFile(uploadedFile);
        forceSnackOpen(true);
      },
      [file],
    );

    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <img src={choose} width="90%" alt="動画を選択" />
        <div style={{ fontWeight: 'bold', fontSize: '12pt', color: '#424242' }}>
          <span>自信のある1本を選択！</span>
          <br />
          <div
            style={{
              fontWeight: 'normal',
              fontSize: '10pt',
              marginTop: '10px',
            }}
          >
            &nbsp;※ 2人分以上を送る場合、まずはお一人様分を選択して下さい。
          </div>
          <div style={{ marginTop: '16px' }}>
            {' '}
            <img src={trimming} width="90%" alt="トリミング案内" />
          </div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <label className="inputwrapper">
            動画を選択
            <input type="file" accept="video/*" onChange={chooseVideo} />
          </label>
        </div>
        {CustomSnackbar}
        <div style={{ display: 'flex', marginTop: '24px' }}>
          <div style={{ width: '44%' }}>
            <Button onClick={handleBackClick} variant="outlined">
              {'<< 戻る'}
            </Button>
          </div>

          <div style={{ width: '12%' }}>
            <br />
          </div>
          <div style={{ width: '44%' }}>
            {(!!file && (
              <Button
                onClick={handleNextClick}
                variant="contained"
                style={{ backgroundColor: '#1e90ff', color: 'white' }}
              >
                {' 次へ >> '}
              </Button>
            )) || (
              <Button variant="contained" disabled>
                {' 次へ >> '}
              </Button>
            )}
          </div>
        </div>
        <div style={{ marginTop: '24px' }}>
          {!!file ? (
            <div>
              <div
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: ` <video
                  width="60%"
                  muted
                  playsinline
                >
                <source src=${
                  URL.createObjectURL(file) + '#t=0.01'
                } type="video/mp4" />
                </video>`,
                }}
              />
              <canvas id="c" width="0px" height="0px" />
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div
          style={{
            marginBottom: '100px',
            fontSize: '8pt',
            padding: '24px',
            textAlign: 'left',
          }}
        >
          ※お使いのブラウザや動画のサイズが原因でサムネイルが表示されないことがございますが、
          <br />
          {'「次へ >>」'}
          のボタンが<span style={{ color: '#1e90ff' }}>青く光って</span>
          いる状態であれば、動画の読み込みは完了しておりますので次のページにお進みください。
        </div>
      </div>
    );
  },
);
