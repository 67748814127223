import React, { useMemo } from 'react';
import { VideoType } from 'adapters/repositories/typeDefinition';

type ContextType = {
  videoList: VideoType[] | undefined;
  setVideoList: React.Dispatch<React.SetStateAction<VideoType[]>>;
};

export const VideoContext = React.createContext<ContextType>({
  videoList: [],
  setVideoList: () => [],
});

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const VideoProvider = ({ children }: Props): JSX.Element => {
  const [videoList, setVideoList] = React.useState([]);

  return useMemo(
    () => (
      <VideoContext.Provider
        value={{
          videoList: videoList,
          setVideoList: setVideoList,
        }}
      >
        {children}
      </VideoContext.Provider>
    ),
    [videoList],
  );
};
