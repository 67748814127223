import React, { FC, memo } from 'react';

import { Grid, GridSize } from '@material-ui/core';

import { RunnerType, VideoType } from 'adapters/repositories/typeDefinition';
import { VerticalLine, SpaceLine } from './Lines';
import { MessageBar } from './MessageBar';
import { UpOrStayBar } from './UpOrStayBar';
import { grades10, ScoreEditType } from 'components/MenuConst/GradeGoalConst';
import { convertGradeByRunner } from 'components/constants';
import { checkPointDictTrac } from 'components/MenuConst/GoalsForEachGrade/CheckpointsForTrac10kyu/checkPointsForEachGrade';

type Props = {
  video: VideoType;
  runner: RunnerType;
};

export const ScoreViewForTrac: FC<Props> = memo(({ video, runner }) => {
  if (!video.score || !runner) {
    return <div>スコアがありません</div>;
  }

  // ビデオに対応する級を用意
  const grade = convertGradeByRunner(runner, video.grade_before) as grades10;
  console.log(grade);
  const detailCheckPoints = JSON.parse(video.score.details) as ScoreEditType;
  console.log(detailCheckPoints);
  const checkPointList = checkPointDictTrac[grade];
  const evaluation = checkPointList.map((ckpt) => {
    return {
      title: ckpt,
      isBorderPassed: detailCheckPoints[ckpt]?.includes('◯') || false,
      isAllPassed: detailCheckPoints[ckpt]?.includes('◯') || false,
    };
  });
  const finalResult = crtFinalResultDict(detailCheckPoints);

  return (
    <>
      {evaluation.map((ckpt, idx: number) => (
        <>
          <Grid container>
            <VerticalLine idx={idx} />
            <Grid item xs={(12 - idx) as GridSize}>
              <MessageBar ckpt={ckpt} necessities={[]} />
            </Grid>
          </Grid>
          {/* 間隔分の縦線 */}
          <SpaceLine idx={idx} maxLen={evaluation.length} />
        </>
      ))}
      <div style={{ marginTop: '16px' }}>
        <UpOrStayBar result={finalResult} />
      </div>
    </>
  );
});

const crtFinalResultDict = (evaluation: ScoreEditType) => {
  if (evaluation['eval']?.includes('◯')) {
    return { title: '合格', isAllPassed: true, isBorderPassed: true };
  }

  return { title: 'もう一回', isAllPassed: false, isBorderPassed: false };
};
