import React, { FC, memo } from 'react';
import { Box } from '@mui/material';
import nasTopImage from 'static/lp/nas/nasTopImage.png';
// import classes from './naspage.module.css';
import { AppliStrength } from 'pages/PurchasePage/components/AppliStrength';
import { SalesPoints } from 'pages/PurchasePage/components/SalesPoints';
import { CustomerReview } from 'pages/PurchasePage/components/CustomerReview';

export const ExplanationPagePC: FC = memo(() => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '18vw' }}>
        <br />
      </div>
      <div>
        <img src={nasTopImage} alt="" width="100%" />
        <Box style={{ margin: '4px 0px 0px 4px' }}>
          <AppliStrength />
          <div style={{ marginTop: '20px' }}>
            <SalesPoints isSmartPhone={false} />
          </div>
          <CustomerReview isSmartPhone={false} />
        </Box>
      </div>
      <div style={{ width: '18vw' }}>
        <br />
      </div>
    </div>
  );
});
