import React from 'react';
import classes from '../guidanceTools.module.css';
import { onboardType } from '../type';

export const DumbbellPopover = (onboardState: onboardType) => {
  if (onboardState === 'beforePractice') {
    return (
      <div className={classes.popover}>
        つづいて練習のじかんだ！がめん下の「トレーニング」からおすすめの練習を見て、じっさいにやってみよう！
      </div>
    );
  }
  if (onboardState === 'beforeMsg') {
    return (
      <div className={classes.popover}>
        宝箱はあけたかな？
        <br />
        練習の前にはぜひ先生からのメッセージを見ておこう！
      </div>
    );
  }
  return (
    <>
      <div className={classes.popover}>
        練習はばっちりかな？もし昇級テスト前にもう少し練習したい場合は、がめん下の「トレーニング」からおすすめの練習を見てみよう！
      </div>
    </>
  );
};
