import React from 'react';
import spring_thumb from 'components/MenuConst/thumbnails/spring.jpg';
import arm_swing_Walk_thumb from 'components/MenuConst/thumbnails/arm_swing_Walk.jpg';
import highknee_dash_thumb from 'components/MenuConst/thumbnails/highknee_dash.jpg';
import henkeiDash_thumb from 'components/MenuConst/thumbnails/henkeidash.jpg';
import diagnose_thumb from 'components/MenuConst/thumbnails/diagnose_thumb.png';
import towelhighknee_thumb from 'components/MenuConst/thumbnails/towelhighknee.jpg';
import intro_17th_thumb from 'components/MenuConst/thumbnails/intro_17th.jpg';

import {
  springVideos,
  armSwingWalkVideos,
  highKneeDash_17th_Videos,
  towelhighkneeVideos,
  henkeiDashVideos,

} from './videos';

import {
  fiftyTrialVideos,
} from '../grade_0/videos';

import { menuType } from 'pages/PracticePage/type';
import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';


export const spring_17th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'バネ人間チャレンジ',
  isInside: 'unable',
  target: ['しせい'],
  menuVariation: ['run'],
  hitWordList: [
    'バネ',
    'ばね',
  ],
  grade: 3,
  image: spring_thumb,
  video: springVideos,
};

export const arm_swing_Walk: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'うでふりウォーク',
  isInside: 'unable',
  target: ['腕ふり'],
  menuVariation: ['run'],
  hitWordList: [
    'うで',
    '腕',
  ],
  grade: 3,
  image: arm_swing_Walk_thumb,
  video: armSwingWalkVideos,
};

export const highKneeDash_17th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'たくさん走ろう！！',
  isInside: 'unable',
  target: ['足の動き'],
  menuVariation: ['run'],
  hitWordList: [
    'ダッシュ',
    'だっしゅ',
  ],
  grade: 3,
  image: highknee_dash_thumb,
  video: highKneeDash_17th_Videos,
};

export const trial_50m_17th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'かけっこ診断！☆おすすめ',
  isInside: 'unable',
  target: ['しせい', '足の動き', '腕ふり'],
  menuVariation: ['family'],
  hitWordList: [
    'かけっこ',
    '診断',
  ],
  grade: 3,
  image: diagnose_thumb,
  video: fiftyTrialVideos,
};

export const towelhighknee: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'タオルももあげ',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['family'],
  hitWordList: [
    'たおる',
    'タオル',
  ],
  grade: 3,
  image: towelhighknee_thumb,
  video: towelhighkneeVideos,
};

export const henkeidash: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '変形ダッシュ',
  isInside: 'unable',
  target: ['スタート'],
  menuVariation: ['family'],
  hitWordList: [
    '変形',
    'スタート',
  ],
  grade: 3,
  image: henkeiDash_thumb,
  video: henkeiDashVideos,
};

export const intro_17th: menuType = {
  duration: 3,
  hitWordList: ['17', '昇級', 'メッセージ', 'めっせーじ', 'きゅう'],
  title: '17級の方へのメッセージ',
  menuVariation: ['levelUp'],
  target: ['足の動き'],
  isInside: 'able',
  grade: 3,
  image: intro_17th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['17'],
      title: 'intro17th',
      message: <span>17級の方へのメッセージはコチラ！ </span>,
    },
  ],
};
