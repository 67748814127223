import React, { FC } from 'react';
import { PageTitle } from '../../components/common/PageTitle';
import { AccountContext } from 'contexts/AccountContext';
import { Card, Grid, Divider } from '@material-ui/core';
import { VideoContext } from 'contexts/VideoContext';
import { sortVideoBySpeed } from './func';
import { calcFiftyTime } from 'pages/ResultPage/components/TimeViewer';
import { ImgWithLoading } from 'pages/LogPage/components/ImgWithLoading';
import { useNavigate } from 'react-router-dom';

import shojo from 'static/shojo.png';
import classes from './trialpage.module.css';
import { formatDateFromString } from 'utils/funcs/formatTime';

export const TrialResultPage: FC = () => {
  const navigate = useNavigate();

  const { currentRunner } = React.useContext(AccountContext);
  const { videoList } = React.useContext(VideoContext);
  const videosWithSpeed =
    (!!videoList &&
      videoList.filter(
        (v) => !!v.score && !!v.score.real_speed && v.score.real_speed > 0,
      )) ||
    [];
  const bestThreeVideos = sortVideoBySpeed(videosWithSpeed).slice(0, 3);
  console.log(bestThreeVideos);

  const bestTime = calcFiftyTime(
    (!!bestThreeVideos[0] && bestThreeVideos[0].score?.real_speed) || 0,
  );
  const byoBest = Math.floor(bestTime);
  const shosuBest = Math.floor((bestTime - byoBest) * 100);
  console.log(byoBest, shosuBest);

  const changeVideoId = (vId: string) => () => {
    navigate(`/logs/result/${vId}`);
  };

  return (
    <div>
      <PageTitle title={'50mトライアルのせいせき'} />
      <br />
      <div className={classes.shojoWrapper}>
        <div className={classes.shojoIllust}>
          <img src={shojo} width="99%" alt=""></img>
        </div>
        <div className={classes.header}>
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            きろくしょう
          </div>
          {(bestThreeVideos.length > 0 && (
            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {byoBest} 秒 {shosuBest < 10 ? `0${shosuBest}` : shosuBest}
            </div>
          )) || (
            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
              ？ 秒 ？？
            </div>
          )}
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            {(!!currentRunner?.username && currentRunner.username) || ''} くん
          </div>
          <div className={classes.devider}></div>
          <div className={classes.content}>
            あなたはIDATEN-Kids50m測定会において、力一杯走り、上の記録を作りました。これからも記録が伸びるようにがんばっていきましょう！
          </div>
          <div className={classes.ending}>IDATEN-Kids 運営一同</div>
        </div>
      </div>
      {/* ここから表にする */}
      <div style={{ marginTop: '20px', textAlign: 'center', padding: '6px' }}>
        {(bestThreeVideos.length > 0 && (
          <>
            <div>あなたのベスト3</div>
            <Card style={{ padding: '2px' }}>
              <div>
                {bestThreeVideos.map((v, i) => {
                  const fiftyTime = calcFiftyTime(
                    (v.score?.real_speed && v.score?.real_speed) || 0,
                  );
                  const byo = Math.floor(fiftyTime);
                  const shosu = Math.floor((fiftyTime - byo) * 100);
                  return (
                    <>
                      {i > 0 && <Divider />}
                      <Grid container style={{ marginTop: '8px' }}>
                        <Grid item xs={1}>
                          {i === 0 && (
                            <div style={{ marginTop: '48%', fontSize: '16pt' }}>
                              🥇
                            </div>
                          )}
                          {i === 1 && (
                            <div style={{ marginTop: '48%', fontSize: '16pt' }}>
                              🥈
                            </div>
                          )}
                          {i === 2 && (
                            <div style={{ marginTop: '48%', fontSize: '16pt' }}>
                              🥉
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={4} style={{ padding: '4px' }}>
                          <ImgWithLoading
                            src={`${v.thumbnail}`}
                            width="92%"
                            onClickFunc={changeVideoId(v.id)}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ fontSize: '10pt', marginTop: '20%' }}>
                            {
                              formatDateFromString(v.uploaded_date).split(
                                '日',
                              )[0]
                            }
                            日
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div style={{ fontSize: '14pt', marginTop: '20%' }}>
                            <span>{byo}</span>秒
                            <span>{shosu < 10 ? `0${shosu}` : shosu}</span>
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </div>
            </Card>
          </>
        )) || (
          <>
            <div
              style={{
                marginTop: '12px',
                textAlign: 'left',
                padding: '12px',
                borderRadius: '10px',
                border: 'solid 1px #c0c0c0',
              }}
            >
              まだ50mトライアルの動画がありません。
              <br />
              トライアルには、<span style={{ color: '#4169e1' }}>＋ボタン</span>
              を押して、
              <br />
              「🏁トライアル」を押すと挑戦できます。
            </div>
          </>
        )}
      </div>
      <div style={{ marginTop: '100px' }}>
        <br />
      </div>
    </div>
  );
};
