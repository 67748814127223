import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';

////
//up
////

export const re_kabeoshiAdeVideos = [
  {
    link: 'https://www.youtube.com/embed/7VW4zxcKa5Q',
    title: 'タオルもも上げ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「タオルももあげ20歩」× 3本（目安）を行う！
        </div>
        <div className={classes.content}>
          ☆なれてきたら前に進んだり走ったりしてみよう！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/67JZVwNB7rc',
    title: '連続壁押しドリル',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「連続壁押しドリル」を復習（目安5分）！！
        </div>
        ☆まずは正しい形でできる角度で練習しよう！
        <br />
        ⇒出来たら「連続壁押しドリル10歩」× 3セット行う！
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          復習のポイント
        </div>
        1. よい姿勢 ☆よこからみてまっすぐかな？かかとはういてるかな？
        <br />
        2. 足の上がり ☆ふとももが90度あがっているかな？
        <br />
        3. 空中であしを入れかえる
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ2つができた子はチャレンジ！～
        </div>
        4. 「45度～60度」くらい倒してチャレンジしてみよう！
        <br />
        <br />
        ☆まずは正しい形でできる角度で！角度浅めから練習しよう！
        <br />
        ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！これがうまくなるとスタートがとっても速くなるよ！
        <br />
        ☆このメニューはおうちの中でも出来るが、少し音がしてしまうので注意！
      </div>
    ),
  },
];

export const taorekomidash11thVideos = [
  {
    link: 'https://www.youtube.com/embed/f1xmOxjA6SA',
    title: '倒れこみダッシュ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「倒れこみダッシュ15m」× 5本（目安）を行う！
        </div>
        ☆しっかり体を倒して、足を前に前にもってこよう！
        <br />
        ☆腰が折れ曲がってないか保護者さんなどにチェックしてもらおう！
        <br />
        ☆おうちの近くにのぼり坂があったら、そこでやってみよう！！
      </div>
    ),
  },
];

////
//tech
////

//スケータージャンプは14級から

export const dash10m11thVideos = [
  {
    link: 'https://www.youtube.com/embed/SbSr3_5aXTI',
    title: '1次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「スタートから10mまでの走り方」を復習！！
        </div>
        ⇒「10mダッシュ」にたくさんチャレンジ！
        <br />
        ☆スタートの構えを忘れてしまった子は動画2-4を見なおそう！！
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本を送信！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          昇級のポイント
        </div>
        1. （スタートの構え）前あしに体重をのせる
        ☆後ろ足のかかとはういているかな？前あしの膝が足首のだいたい真上にあるかな？
        <br />
        2. （スタートの構え）いい姿勢で体をたおす
        ☆横から見て上体と後ろ足がまっすぐかな？目線は3m先を見れているかな？
        <br />
        3. しっかり体を倒す
        ☆6歩以上体をたおして走ろう！あごをひいて、目線は5m先くらい！
        <br />
        ※のぼり坂で10mダッシュの練習をすると意識しやすいかも！近場にのぼり坂のある子は試してみてね！
        <br />
        4. 足を前に前に
        ☆連続かべおしのときと同じ！砂が後ろにとばないように気をつけよう！
        <br />
        5. 腕ふり ☆前に前にふろう！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/OUNYO_6z5mQ',
    title: 'スタートの構え',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2: スタートの構えを復習しよう！
        </div>
        <div className={classes.content}>
          ☆この動画はいったん見るだけで大丈夫です！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/Yc3vnf-dbck',
    title: 'スタートの前足の決め方',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3: スタートの前足を決めよう！
        </div>
        <div className={classes.content}>☆おうちのなかでも出来ます！</div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/p94g4g_NmHc',
    title: 'スタートの構えの作り方',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画4: スタートのかまえをつくれるようになろう！
        </div>
        <div className={classes.content}>
          ☆動画のお手本の形をマネして、保護者さんなどにうまくできているかチェックしてもらおう！
          <br />
          ☆おうちのなかでも出来ます！
        </div>
      </div>
    ),
  },
];

////
//run
////

//3歩スキップは12級から

export const howto50m_11thVideos = [
  {
    link: 'https://www.youtube.com/embed/6HdnLynUPT4',
    title: '2次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「10mから20mまでの走り方」を学ぶ！！
        </div>
        ⇒「20mダッシュ」× 3本を行う！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/lFQtglK4VFI',
    title: '中間走の走り方',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「20mいこうの走り方」を学ぶ！！
        </div>
        ⇒「30mダッシュ」× 3本を行う！
      </div>
    ),
  },
];

////
//family
////

export const teoshigurumaVideos = [
  {
    link: 'https://www.youtube.com/embed/rkT6fBn3GDs',
    title: '手押し車',
    message: (
      <div className={classes.subtitle}>
        動画1:「手押し車10m」× 3本（目安）を行う！
      </div>
    ),
  },
];

////
//以下10級パッケージ用
////

export const dash10mVideos = [
  {
    link: 'https://www.youtube.com/embed/SbSr3_5aXTI',
    title: '1次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画1）「スタートから10mまでの走り方」を復習！！
        </div>
        ⇒「10mダッシュ」にたくさんチャレンジ！
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本を送信！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          昇級のポイント
        </div>
        1. しっかり体を倒す
        ☆6歩以上体をたおして走ろう！あごをひいて、目線は5m先くらい！
        <br />
        2. 足を前に前に ☆かべおしのときと同じ！
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ2つができた子はチャレンジ！～
        </div>
        <br />
        3. 腕ふり ☆前に前にふろう！その方があしも動かしやすいよ！
      </div>
    ),
  },
];

export const straightLegVideos = [
  {
    link: 'https://www.youtube.com/embed/xP_qS5e9sFU',
    title: 'ストレートレッグランの説明',
    message: (
      <div className={classes.subtitle}>
        （動画1）「ストレートレッグラン」のイメージをつかむ！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/4WxNs0ayYzQ',
    title: 'ストレートレッグジャンプ',
    message: (
      <div className={classes.subtitle}>
        （動画2）「ストレートレッグジャンプ」を左右足10回2セットずつ行う！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/A_wtts5X5T0',
    title: 'ストレートレッグジャンプ入れ替え',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画3）「3入れ替えステップ」を特訓（目安2分）！
        </div>
        ⇒同じく「連続で入れ替える」を特訓！
        <br />
        ☆いずれも足を振り下ろせるようになる＆足音が「地面をする音（シャッシャッという音）」でなく「ポンポン」となるようになったら合格！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/Ly7EjJ5Nm0M',
    title: 'ストレートレッグランゆっくり',
    message: (
      <div className={classes.subtitle}>
        （動画4）「ストレートレッグランゆっくりペース15m」× 2セット行う！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/39gKyjCrIpY',
    title: 'ストレートレッグラン速く',
    message: (
      <div className={classes.subtitle}>
        （動画5）「ストレートレッグランはやく15m」× 3セット行う！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/A1nUb9QWHZM',
    title: 'ストレートレッグラン速く',
    message: (
      <div className={classes.subtitle}>
        （動画6）「ストレートレッグラン⇒そのまま走る15m」× 3セット行う！
      </div>
    ),
  },
];

export const markRunVideos = [
  {
    link: 'https://www.youtube.com/embed/aas9F7szROM',
    title: 'マーク走の準備の仕方',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画1）マーク走の準備の仕方を確認
        </div>
        ☆マークのない場合は、「地面に線を引く」や「マークに代わるものを置く（踏んでも足をひねらないような薄いものやつぶれるものを推奨）」等で代用可能です！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/60A_GIb-laQ',
    title: 'マーク走',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画2）「マーク走」× 4本を行う！！
        </div>
        ⇒「マーク走⇒そのまま10mダッシュ」×4本行う！！
      </div>
    ),
  },
];

export const howto50mVideos = [
  {
    link: 'https://www.youtube.com/embed/6HdnLynUPT4',
    title: '2次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「10mから20mまでの走り方」を学ぶ！！
        </div>
        ⇒「20mダッシュ」× 3本を行う！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/lFQtglK4VFI',
    title: '中間走の走り方',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「20mいこうの走り方」を学ぶ！！
        </div>
        ⇒「30mダッシュ」× 3本を行う！
      </div>
    ),
  },
];

export const high_knee_1kyu = [
  {
    link: 'https://www.youtube.com/embed/XNE-dI1FW-U',
    title: 'その場ハイニー',
    message: (
      <div>
        <div className={classes.subtitle}>その場行進</div>
        <div className={classes.content}>
          ☆ぜひ動画のまねをしてやってみてね！
        </div>
      </div>
    ),
  },
];

export const six_sec_dash = [
  {
    link: 'https://www.youtube.com/embed/h65pRaOLPS8',
    title: '6秒間ダッシュ',
    message: (
      <div>
        <div>
          <div className={classes.subtitle}>
            6秒間ダッシュ
            <br />
            ☆昇級テストの挑戦は右下の ➕ボタン⇒昇級に挑戦」から行えます！
          </div>
          <div className={classes.title_important}>昇級のポイント</div>
          <div className={classes.content}>
            1. 良いしせいを作る！
            <br />
            2. あしをしっかり上げる！(お手本のマネをしよう)
            <br />
            3. ぼしきゅうから足をつく！
          </div>
        </div>
      </div>
    ),
  },
];
