import React from 'react';
import train_thumb from 'components/MenuConst/thumbnails/train.jpg';
import intro_16th_thumb from 'components/MenuConst/thumbnails/intro_16th.jpg';
import arm_swing_Walk_thumb from 'components/MenuConst/thumbnails/arm_swing_Walk.jpg';
import towelhighknee_thumb from 'components/MenuConst/thumbnails/towelhighknee.jpg';
import mark_16th_thumb from 'components/MenuConst/thumbnails/mark_16th.jpg';
import touritsu_thumb from 'components/MenuConst/thumbnails/touritsu.jpg';
import challenge16th_thumb from 'components/MenuConst/thumbnails/challenge_16th.png';

import {
  trainVideos,
  markRunVideos,
  touritsuVideos,

} from './videos';

import {
  armSwingWalkVideos,
  towelhighkneeVideos,
} from '../grade_3/videos';


import { menuType } from 'pages/PracticePage/type';
import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';

export const arm_swing_Walk_16th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'うでふりウォーク',
  isInside: 'unable',
  target: ['腕ふり'],
  menuVariation: ['up'],
  hitWordList: [
    'うで',
    '腕',
  ],
  grade: 4,
  image: arm_swing_Walk_thumb,
  video: armSwingWalkVideos,
};

export const towelhighknee_16th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'タオルももあげ',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['up'],
  hitWordList: [
    'たおる',
    'タオル',
  ],
  grade: 4,
  image: towelhighknee_thumb,
  video: towelhighkneeVideos,
};

export const train: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '列車',
  isInside: 'unable',
  target: ['足の動き'],
  menuVariation: ['tech'],
  hitWordList: [
    'れっしゃ',
    '列車',
  ],
  grade: 4,
  image: train_thumb,
  video: trainVideos,
};

export const markRun_16th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'マーク走 ☆昇級テスト',
  isInside: 'unable',
  target: ['しせい', '足の動き'],
  menuVariation: ['tech'],
  hitWordList: [
    'まーく',
    'マーク',
  ],
  grade: 4,
  image: mark_16th_thumb,
  video: markRunVideos,
};

export const touritsu: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'ペア倒立',
  isInside: 'able',
  target: ['しせい'],
  menuVariation: ['family'],
  hitWordList: [
    '倒立',
    'さかだち',
  ],
  grade: 4,
  image: touritsu_thumb,
  video: touritsuVideos,
};



export const intro_16th: menuType = {
  duration: 3,
  hitWordList: ['16', '昇級', 'メッセージ', 'めっせーじ', 'きゅう'],
  title: '16級の方へのメッセージ',
  menuVariation: ['levelUp'],
  target: ['足の動き'],
  isInside: 'able',
  grade: 4,
  image: intro_16th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['16'],
      title: 'intro16th',
      message: <span>16級の方へのメッセージはコチラ！ </span>,
    },
  ],
};

export const challenge_16th: menuType = {
  grade: 1,
  duration: 5,
  title: '16級の昇級用メニュー',
  menuVariation: ['levelUp'],
  target: ['足の動き'],
  isInside: 'able',
  hitWordList: [
    '16',
    '昇級',
    'かべ',
    '壁',
    'おし',
    '押し',
    '家',
    'ろく',
    'ろっきゅう',
  ],
  image: challenge16th_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/OjoXytKKCo8',
      title: '[お手本] 狭めのマーク走',
      message: <span>16級の昇級テスト挑戦動画はコチラ！</span>,
    },
  ],
};
