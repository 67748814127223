import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';

export const henkei10thVideos = [
  {
    link: 'https://www.youtube.com/embed/6a4_35tpqq0',
    title: '変形ダッシュ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「変形ダッシュ15m」× 5本（目安）やってみる！！
        </div>
      </div>
    ),
  },
];

export const markRun10thVideos = [
  {
    link: 'https://www.youtube.com/embed/y7yY_FMYS28',
    title: '狭めのマーク走準備',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: マーク走の準備の仕方を確認
        </div>
        ☆マーク間の距離は、中学年くらいの子でおおよそ1m程度です！
        <br />
        ☆マークとなるものがない場合は、「地面に線を引く」等で代用可能です！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/k1F7AH2u14k',
    title: '狭めのマーク走',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「せまめのマーク走」を5本以上行う！
        </div>
        <div className={classes.title_important}>ふくしゅうのポイント</div>
        <div className={classes.content}>
          1. よい姿勢 ☆おしりはたか～い位置にあるかな？
          <br />
          2. ハイニーができている ☆ふとももがちゃんとあがっているかな？ぼしきゅうから足をつけているかな？
        </div>
        <div className={classes.title_important}>
          うえ2つができた子はチャレンジ！
        </div>
        <div className={classes.content}>
          3. うでを下にポンポンふろう！
        </div>
      </div>
    ),
  },
];
