import React, { FC } from 'react';
import { TextField, MenuItem } from '@material-ui/core';

type ScoreInputProps = {
  label: string;
  currentValue: number | string | null | undefined;
  onChangeScore: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  options: string[];
};

export const ScoreInput: FC<ScoreInputProps> = (props) => {
  const { label, currentValue, onChangeScore, options } = props;

  return (
    <TextField
      style={{ width: '80%' }}
      select
      label={label}
      value={currentValue}
      onChange={onChangeScore}
    >
      {options.map((option: string) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};
