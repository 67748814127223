// TRAC10級パッケージの4級

export const ckptFor4kyu_trac10 = [
  'しせい',
  '地面を押す',
  '足のつく位置',
  'すねの角度',
] as const;

export type ckpt_4kyu_type = typeof ckptFor4kyu_trac10[number];

export const requirement_4kyu_trac: ckpt_4kyu_type[] = [
  'しせい',
  '地面を押す',
  '足のつく位置',
  'すねの角度',
];

type options = {
  [key in ckpt_4kyu_type]: string[];
};

export const optionDict_4kyu_trac: options = {
  しせい: ['◯良い', '×前屈している', '×起き上がりすぎ'],
  地面を押す: ['◯押せている', '×ちょこちょこしている'],
  足のつく位置: ['◯体の真下', '×前すぎ', '×後ろすぎ'],
  すねの角度: ['◯倒れている', '×垂直'],
};

type complimentType = {
  [key in ckpt_4kyu_type]: string;
};

// まずxxくんの良い点として、に続く文章を書く
export const complimentDict_4: complimentType = {
  しせい:
    '「頭からかかとまでまっすぐな良いしせいを作れている」点があります！このしせいを取れているのはとっても素晴らしいことですよ！',

  地面を押す:
    '「しっかり地面を押せている」という点があります！動きが速すぎることもなく、しっかり地面に力を加えられていると思います。',
  足のつく位置:
    '体の真下近くにしっかり足をつけている、という点があります。足のつき方はバッチリですね！',
  すねの角度:
    '「足をつくときにしっかりすねを倒せている」点があります！前に進むための脚の使い方が大変よくできています！',
};

export const nobishiroOptions_4_trac = Object.values(optionDict_4kyu_trac)
  .flat()
  .filter((value) => !value.includes('◯'));

type nobishiroDict = {
  [key in typeof nobishiroOptions_4_trac[number]]?: string;
};

// そんな〇〇くんの伸びしろポイントはズバリ、に続く文章を書く
export const nobishiroDict_4: nobishiroDict = {
  '×前屈している':
    '「スタートの後のしせい」です！低くスタートしようとしてくれている点は良いのですが、腰がおれてしまい、おじぎをするようなしせいになってしまっています。体を一本の棒のようにしたまま傾ける意識を持ってみましょう！',
  '×起き上がりすぎ':
    '「スタート後に体が起きてしまっている」ことです。スタート後はもう少しだけおへそを下に向けて走るように意識してみてください！',
  '×ちょこちょこしている':
    '「足を小さく動かしてしまっている」ことです！リズムはもう少しゆっくりで良いので、しっかり地面を押してあげるようにしてみましょう。',
  '×前すぎ':
    '「足のつく位置が体から遠い」ことです。前に進みたい気持ちが強いと1歩が大きくなり、足を遠く前の方につきたくなりますが、足を前につくのはブレーキになってしまいます。もう少し歩幅を小さくした方が逆に速く走れますよ！',
  '×後ろすぎ':
    '「足をつく位置が体の後ろになっている」ことです。もう少しだけ前に足をつくようにしてみましょう！',
  '×垂直':
    '「足をついたときにすねが上を向いてしまっている」ことです！もう少しだけすねが斜め前を向くようになるとより良いです！',
};
