import React from 'react';

export const PageTitle = (props: any) => {
    return (
        <div
            style={{
                backgroundColor: '#EEEEEE',
                fontSize: '11pt',
                fontWeight: 'bold',
                padding: '3px',
                textAlign: 'center',
            }}
        >
            {props.title}
        </div>
    );
};
