import React, { FC } from 'react';
import { VideoType } from 'adapters/repositories/typeDefinition';
type Props = {
  chosenVideo: VideoType;
};
export const StudentCommentPart: FC<Props> = ({ chosenVideo }) => {
  return (
    <>
      <div>生徒からのコメント</div>
      <div
        style={{
          padding: '10px',
          borderRadius: '10px',
          border: 'solid 2px #aaaaaa',
          width: '90%',
        }}
      >
        {!!chosenVideo && chosenVideo.video_memo
          ? chosenVideo.video_memo
          : 'コメントなし'}
      </div>
    </>
  );
};
