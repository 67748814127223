export type evalKeys = '姿勢' | '足の動き' | 'うでふり';
export type evalNumber =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10';

type commentDictType = {
  [key in evalKeys]: {
    [eNumber in evalNumber]: string;
  };
};

export const CommentForEachPoint: commentDictType = {
  足の動き: {
    1: '伸びしろポイントは少し足の動きが小さいこと。もっとダイナミックに、「走っているときしっかり足が上がっているかな？」と意識してみましょう! ⇒壁おし（IDATEN-Kids 9級）やハイニー（IDATEN-Kids 8級）がおススメ',
    2: '走りの中で足を上げるのがまだ苦手なようです。「走っているときしっかり足が上がっているかな?」というのを意識して走ってみましょう! ⇒壁おし（IDATEN-Kids 9級）やハイニー（IDATEN-Kids 8級）がおススメ',
    3: 'もう少し足があがればバッチリ!つい地面を後ろに蹴ってしまっていませんか？。後ろに砂が飛ばないようイメージすると楽に足が前に戻ってきますよ！ ⇒自転車（IDATEN-Kids 6級）がおススメ',
    4: 'もう少し足があがるとバッチリ!まずは「足があがった姿勢を空中でいっしゅんキープする」というのを意識してみよう!なれてきたら「空きカンを上からポンポンふんで走るイメージで」走ってみよう！ ⇒ハイニー（IDATEN-Kids 8級）がおススメ',
    5: '足が上がりすぎて少し前に進みずらいかも。「空きカンを上からポンポンふんで走るイメージで」走ってみよう! ⇒マーク走（IDATEN-Kids 4級）がおススメ',
    6: '足のあがりはバッチリ！伸びしろポイントとして、つい地面を後ろに蹴ってしまっていませんか？。後ろに砂が飛ばないようイメージすると楽に足が前に戻ってきますよ！ ⇒自転車（IDATEN-Kids 6級）がおススメ',
    7: 'とてもきれいに足を動かせています!あとはよりスパンッと体の前で足を入れかえられるようになるとさらにはやく走れますよ！⇒3歩ステップ（IDATEN-Kids 5級）がおススメ',
    8: 'なし',
    9: 'なし',
    10: 'なし',
  },
  姿勢: {
    1: '体を前に倒しすぎています。体をもう少しだけ起こしてみましょう。⇒Y字スプリント（IDATEN-Kidsの10級）やY字ハイニー（IDATEN-Kidsの8級）がおススメ',
    2: '少しだけ体がまだ前のめりになっています。もう少しだけ体を起こした方が足も前に返ってきやすくなりますよ! ⇒Y字ハイニー（IDATEN-Kidsの8級）がおススメ',
    3: '上体が少し後ろに傾いています。あごを少し引いて10m先の地面を見ながら走ってみましょう!一気にスイスイ進むようになりますよ! ⇒自転車（IDATEN-Kids 6級）がおススメ',
    4: '上体が少し後ろに傾いています。あごが少し引いて10m先の地面を見ながら走ってみましょう!一気に前にスイスイ進むようになりますよ! ⇒自転車（IDATEN-Kids 6級）がおススメ',
    5: '腰が引けてしまっていませんか？おしりの位置を高くしておへその下に力を入れて走ってみましょう！後ろに砂が飛ばないようイメージして走ると腰が引けづらいですよ！⇒Y字スプリント（IDATEN-Kidsの10級）やY字ハイニー（IDATEN-Kidsの8級）がおススメ',
    6: 'とてもよい姿勢で走れています！あとはひざも空気がパンパンに入ったボールの様にかたく使えるようになるとよりポンポン走れますよ！⇒全力ケンケン（IDATEN-Kids 7級）がおススメ',
    7: '体を少し前に倒しすぎています。スピードに乗ったあとは体をもう少しだけ起こしてみるとよりポンポン走れますよ！。⇒Y字ハイニー（IDATEN-Kidsの8級）がおススメ',
    8: 'なし',
    9: 'なし',
    10: 'なし',
  },
  うでふり: {
    1: '腕ふりをもうちょっとふれるといいですね。まずはひじを90度に！手はあごの高さから下にポンポン振るようにして走ってみましょう！足も楽に動くようになりますよ！ ⇒その場腕ふり（IDATEN-Kids 10級）がおススメ',
    2: '腕ふりをもうちょっとふれるといいですね。ひじから先でなく、まず肩から前後に動かすようにしてみよう！慣れてきたら太鼓をたたくイメージで下にポンポン振ってみよう！⇒その場腕ふり（IDATEN-Kids 10級）がおススメ',
    3: '伸びしろポイントは腕ふりの小ささ。太鼓をたたくイメージで下にポンポン振るようにして走ってみましょう！足も楽に動くようになりますよ！ ⇒その場腕ふり（IDATEN-Kids 10級）がおススメ',
    4: '腕ふりがちょっと大きすぎて力んでいるかも。ひじを90度に！手はあごの高さから下にポンポンとコンパクトに振ろう！リズムが取れて走りやすくなりますよ！⇒リズム腕ふり（IDATEN-Kids 10級）や腕振りウォーク（IDATEN-Kids 7級）がおススメ',
    5: '腕ふりがちょっと大きすぎて力んでいるかも。手はあごの高さから下にポンポンとコンパクトに振ろう！その時ひじが後ろで伸びないように気をつけて！⇒リズム腕ふり（IDATEN-Kids 10級）や腕振りウォーク（IDATEN-Kids 7級）がおススメ',
    6: '腕ふりがちょっと大きすぎて力んでいるかも。太鼓をたたくイメージで下にポンポンとコンパクトに振ろう！リズムが取れて走りやすくなりますよ！⇒腕振りウォーク（IDATEN-Kids 7級）がおススメ',
    7: '腕ふりの大きさはばっちり！ひじが伸びたまま走っているので、ひじを90度に！手はあごの高さから下にポンポン振るようにして走ってみましょう！⇒リズム腕ふり（IDATEN-Kids 10級）がおススメ',
    8: '腕ふりの大きさはばっちり！ひじが後ろで伸びないように気をつけて！⇒腕振りウォーク（IDATEN-Kids 7級）がおススメ',
    9: 'ひじをかかえこんでしまっていませんか？ひじをリラックスして、下にポンポンと振ろう！その時ひじが後ろで伸びないように気をつけて！⇒リズム腕ふり（IDATEN-Kids 10級）がおススメ',
    10: '腕ふりはばっちりですね！あとはうでと足の動きをより合わせる練習をしておきましょう！⇒スキップ（IDATEN-Kids 5級）がおススメ',
  },
};
