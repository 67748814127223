import React from 'react';
import highkneeup_thumb from 'components/MenuConst/thumbnails/yHighkneeSprint.jpg';
import dash_30m_15th_thumb from 'components/MenuConst/thumbnails/dash_30m.jpg';
import henkeiDash_thumb from 'components/MenuConst/thumbnails/henkeidash.jpg';
import intro_15th_thumb from 'components/MenuConst/thumbnails/intro_15th.jpg';
import challenge15th_thumb from 'components/MenuConst/thumbnails/challenge_15th.png';

import {
  highKneeupVideos,
  dash30m15thVideos,
} from './videos';

import {
  henkeiDashVideos,
} from '../grade_3/videos';

import { menuType } from 'pages/PracticePage/type';
import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';



export const highkneeup_15th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'ハイニー復習',
  isInside: 'half',
  target: ['しせい', '足の動き',],
  menuVariation: ['up'],
  hitWordList: [
    'ハイニー',
    'はいにー',
  ],
  grade: 5,
  image: highkneeup_thumb,
  video: highKneeupVideos,
};

export const dash_30m_15th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '30mダッシュ ☆昇級テスト',
  isInside: 'unable',
  target: ['しせい', '足の動き', '腕ふり',],
  menuVariation: ['tech'],
  hitWordList: [
    '30',
    'さんじゅう',
  ],
  grade: 5,
  image: dash_30m_15th_thumb,
  video: dash30m15thVideos,
};

export const henkeidash_15th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '変形ダッシュ',
  isInside: 'unable',
  target: ['スタート'],
  menuVariation: ['run'],
  hitWordList: [
    '変形',
    'スタート',
  ],
  grade: 5,
  image: henkeiDash_thumb,
  video: henkeiDashVideos,
};

export const intro_15th: menuType = {
  duration: 3,
  hitWordList: ['15', '昇級', 'メッセージ', 'めっせーじ', 'きゅう'],
  title: '15級の方へのメッセージ',
  menuVariation: ['levelUp'],
  target: ['足の動き'],
  isInside: 'able',
  grade: 5,
  image: intro_15th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['15'],
      title: 'intro15th',
      message: <span>15級の方へのメッセージはコチラ！ </span>,
    },
  ],
};

export const challenge_15th: menuType = {
  grade: 1,
  duration: 5,
  title: '15級の昇級用メニュー',
  menuVariation: ['levelUp'],
  target: ['足の動き'],
  isInside: 'able',
  hitWordList: [
    '15',
    '昇級',
    'かべ',
    '壁',
    'おし',
    '押し',
    '家',
    'ご',
    'ごきゅう',
  ],
  image: challenge15th_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/Cta-XP0f0xE',
      title: '[お手本] 30mダッシュ',
      message: <span>15級の昇級テスト挑戦動画はコチラ！</span>,
    },
  ],
};

