import { ckptDetails } from 'components/MenuConst/GoalsForEachGrade/CheckpointsFor10Pkg/10_kyu';

type options = {
  [key in ckptDetails]: string[];
};

export const optionsForGrade0_10pkg: options = {
  前後傾: ['◯:ちょうど良い', '×:前傾しすぎ'],
  腕が体と平行である: ['◯:平行', '×:前にある'],
  腰が落ちていない: ['◯:OK', '×:NG'],
  足が最低限上がっている: ['◯:OK', '×:NG'],
  接地様式: [
    '◯:ちょうど良い',
    '×:かかとからついている',
    '×:つま先だけでついている',
  ],
  スピードが出せている: ['◯:OK', '×:遅すぎる'],
};
