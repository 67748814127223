import React, { useState } from 'react';
import { UploadStepViewer } from 'pages/LevelUpPage/components/UploadStepViewer';

export const useActiveStep = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  return {
    activeStep: activeStep,
    UploadStepViewer: <UploadStepViewer activeStep={activeStep} />,
    setActiveStep: setActiveStep,
  };
};
