import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

type StepProps = {
  activeStep: number;
  // eslint-disable-next-line
  steps?: string[];
};

const defaultSteps = ['動画を選ぶ', 'メモを書く', '送信'];

export const UploadStepViewer: FC<StepProps> = ({
  activeStep,
  steps = defaultSteps,
}) => {
  return (
    <div
      style={{
        position: 'sticky',
        width: '100%',
        top: '0px',
        backgroundColor: 'white',
      }}
    >
      <Box
        sx={{
          width: '92%',
          position: 'sticky',
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            if (index === activeStep) {
              return (
                <Step key={label} {...stepProps} style={{ padding: '4px' }}>
                  <StepLabel>
                    <Typography
                      style={{
                        color: '#00608d',
                        fontSize: '9pt',
                        fontWeight: 'bold',
                      }}
                    >
                      {' '}
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>
                  {' '}
                  <Typography
                    style={{
                      fontSize: '9pt',
                      fontWeight: 'bold',
                    }}
                  >
                    {' '}
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </div>
  );
};
