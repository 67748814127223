import React, { FC, memo } from 'react';
import { InputLabel, FormControl, MenuItem, Select } from '@material-ui/core';

type Props = {
  height: number;
  handleHeightChange: (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => void;
};

export const HeightInput: FC<Props> = memo(({ height, handleHeightChange }) => {
  return (
    <FormControl style={{ width: '80%', backgroundColor: 'white' }}>
      <InputLabel>&nbsp;身長（cm）</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={height}
        label="Age"
        defaultValue={140}
        onChange={handleHeightChange}
      >
        {/* @ts-expect-error ts-migrate(2802) FIXME: Type 'IterableIterator<number>' can only be iterat... Remove this comment to see the full error message */}
        {[...Array(81).keys()].map((_, idx) => {
          return (
            <MenuItem key={idx} value={idx + 100}>
              {idx + 100}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
});
