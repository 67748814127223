import React from 'react';
import threeStep_thumb from 'components/MenuConst/thumbnails/3step.png';
import arm_swing_Walk_thumb from 'components/MenuConst/thumbnails/arm_swing_Walk.jpg';
import dash_30m_7th_thumb from 'components/MenuConst/thumbnails/dash_30m.jpg';
import highknee_dash_thumb from 'components/MenuConst/thumbnails/highknee_dash.jpg';
import intro_7th_thumb from 'components/MenuConst/thumbnails/intro_7th.jpg';
import challenge7th_thumb from 'components/MenuConst/thumbnails/challenge_7th.jpg';
import {
  armSwingWalkVideos,
  highKneeDashVideos,
  dash30m7thVideos,
} from './videos';

export const arm_swing_Walk = {
  title: 'うでふりウォーク',
  grade: 3,
  duration: 8,
  hitWordList: ['うで', '腕', 'ふり', '振り'],
  menuVariation: ['up'],
  image: arm_swing_Walk_thumb,
  video: armSwingWalkVideos,
};

export const highknee_dash = {
  title: 'ハイニーダッシュ☆これまでの総まとめ！',
  grade: 3,
  duration: 10,
  image: highknee_dash_thumb,
  menuVariation: ['tech', 'run'],
  video: highKneeDashVideos,
};

export const dash_30m_7th = {
  title: '30mダッシュ#昇級テスト',
  grade: 3,
  duration: 10,
  menuVariation: ['run', 'family'],
  image: dash_30m_7th_thumb,
  video: dash30m7thVideos,
};

export const challenge_7th = {
  duration: 5,
  hitWordList: ['7', '昇級', 'なな'],
  title: '7級の昇級用メニュー',
  grade: 3,
  image: challenge7th_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/F5G0l_GqKkc',
      title: '7級の昇級用メニュー',
      message: <span>7級の昇級テスト挑戦動画はコチラ！</span>,
    },
  ],
};

export const intro_7th = {
  duration: 2,
  hitWordList: ['7', '昇級', 'メッセージ', 'めっせーじ', 'なな', 'ななきゅう'],
  title: '7級の方へのメッセージ',
  grade: 3,
  image: intro_7th_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/KDRUp0TnCBI',
      title: '7級イントロ動画',
      message: <span>7級の方へのメッセージはコチラ！ </span>,
    },
  ],
};
