import React from 'react';

import train_thumb from 'components/MenuConst/thumbnails/train.jpg';
import cycle_dash_thumb from 'components/MenuConst/thumbnails/cycle_dash.png';
import threeStep_thumb from 'components/MenuConst/thumbnails/3step.png';
import markrun_thumb from 'components/MenuConst/thumbnails/mark_run.png';
import dash_30m_thumb from 'components/MenuConst/thumbnails/dash_30m.jpg';

import challenge7th_thumb from 'components/MenuConst/thumbnails/challenge_markrun.png'; //done
import intro_7th_thumb from 'components/MenuConst/thumbnails/intro_7th.jpg'; //done
import { menuType } from 'pages/PracticePage/type';

import {
  cycleDash7thVideos,
  threeStep7thVideos,
  markRun7thVideos,
  dash30m7thVideos,
} from './videos';

import { trainVideos } from '../grade_4/videos';

import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';
// up: 準備, tech: ぎじゅつ, run: 体力, family: 家族

// プレイリスト

////
//up
////

export const train_7th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '列車',
  isInside: 'unable',
  target: ['足の動き'],
  menuVariation: ['up'],
  hitWordList: ['れっしゃ', '列車'],
  grade: 13,
  image: train_thumb,
  video: trainVideos,
};

export const cycleDash_7th: menuType = {
  duration: 5,
  title: '自転車ダッシュ',
  target: ['足の動き'],
  isInside: 'unable',
  menuVariation: ['up'],
  hitWordList: ['じてんしゃ', '自転車'],
  grade: 13,
  image: cycle_dash_thumb,
  video: cycleDash7thVideos,
};

////
//tech
////

export const threestep_7th: menuType = {
  duration: 5,
  title: '3ステップ',
  target: ['しせい', '足の動き'],
  isInside: 'unable',
  menuVariation: ['tech'],
  hitWordList: ['3', 'さん', 'ステップ', 'すてっぷ'],
  grade: 13,
  image: threeStep_thumb,
  video: threeStep7thVideos,
};

export const markrun_7th: menuType = {
  duration: 5,
  title: 'マーク走 ☆昇級テスト',
  target: ['足の動き'],
  isInside: 'unable',
  menuVariation: ['tech'],
  hitWordList: ['まーく', 'マーク'],
  grade: 13,
  image: markrun_thumb,
  video: markRun7thVideos,
};

////
//run
////

export const dash30m_7th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '30mダッシュ',
  isInside: 'unable',
  target: ['スタート', '全力走'],
  menuVariation: ['run'],
  hitWordList: ['30', 'さん', 'だっしゅ', 'ダッシュ'],
  grade: 13,
  image: dash_30m_thumb,
  video: dash30m7thVideos,
};

//ばね人間は過去から

////
//family
////

//過去のsterterより

////
//levelup
////

export const challenge_7th: menuType = {
  duration: 5,
  hitWordList: ['7', '昇級', '3ステップ', 'さん'],
  title: '7級の昇級用メニュー',
  grade: 13,
  menuVariation: ['levelUp'],
  image: challenge7th_thumb,
  isInside: 'unable',
  target: ['しせい'],
  video: [
    {
      link: 'https://www.youtube.com/embed/o6eDB768UMg',
      title: '7級の昇級用メニュー',
      message: (
        <span>
          7級の昇級テスト挑戦動画はコチラ！
          <br />
          ☆動画の指示に従って撮影し、画面右下の「+ボタン」から「昇級に挑戦」をタップして、動画を送信しよう！
          <br />
          ☆詳しい練習方法やポイントは「おすすめ」から確認！
        </span>
      ),
    },
  ],
};

//introVideoDictFor20Gradesの中身はまだ変更してない
export const intro_7th: menuType = {
  duration: 2,
  isInside: 'able',
  target: ['しせい'],
  hitWordList: ['7', '昇級', 'メッセージ', 'めっせーじ', 'きゅう'],
  title: '7級の方へのメッセージ',
  menuVariation: ['levelUp'],
  grade: 13,
  image: intro_7th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['7'],
      title: 'intro7th',
      message: <span>7級の方へのメッセージはコチラ！ </span>,
    },
  ],
};
