import React, { FC, memo } from 'react';
import { InputLabel, FormControl, MenuItem, Select } from '@material-ui/core';
import { AccountInfoType, infoKeys } from 'features/auth/types';

type inputEvent = React.ChangeEvent<HTMLInputElement>;

type handleInputChange = (keyName: infoKeys) => (e: inputEvent) => void;

type Props = {
  accountInfo: AccountInfoType;
  handleInfoChange: handleInputChange;
};

export const BirthdayInput: FC<Props> = memo(
  ({ accountInfo, handleInfoChange }) => {
    return (
      <div style={{ display: 'flex' }}>
        <FormControl style={{ width: '35%', backgroundColor: 'white' }}>
          <InputLabel>生まれた年</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={accountInfo.birthYear}
            label="Age"
            defaultValue={2010}
            onChange={handleInfoChange('birthYear')}
          >
            {/* @ts-expect-error ts-migrate(2802) FIXME: Type 'IterableIterator<number>' can only be iterat... Remove this comment to see the full error message */}
            {[...Array(40).keys()].map((_, idx) => {
              return (
                <MenuItem key={idx} value={2020 - idx}>
                  {2020 - idx}年
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl
          style={{ marginLeft: '5%', width: '20%', backgroundColor: 'white' }}
        >
          <InputLabel>月</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={accountInfo.birthMonth}
            label="Age"
            defaultValue={1}
            onChange={handleInfoChange('birthMonth')}
          >
            {/* @ts-expect-error ts-migrate(2802) FIXME: Type 'IterableIterator<number>' can only be iterat... Remove this comment to see the full error message */}
            {[...Array(12).keys()].map((_, idx) => {
              return (
                <MenuItem key={idx} value={1 + idx}>
                  {1 + idx}月
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl
          style={{ marginLeft: '5%', width: '20%', backgroundColor: 'white' }}
        >
          <InputLabel>日</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={accountInfo.birthDate}
            label="Age"
            defaultValue={2010}
            onChange={handleInfoChange('birthDate')}
          >
            {/* @ts-expect-error ts-migrate(2802) FIXME: Type 'IterableIterator<number>' can only be iterat... Remove this comment to see the full error message */}
            {[...Array(31).keys()].map((_, idx) => {
              return (
                <MenuItem key={idx} value={1 + idx}>
                  {1 + idx}日
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    );
  },
);
