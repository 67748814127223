import React from 'react';
import yHighkneeSprint_thumb from 'components/MenuConst/thumbnails/yHighkneeSprint.jpg';
import skip_challenge_thumb from 'components/MenuConst/thumbnails/skip_challenge.jpg';
import cycle_dash_thumb from 'components/MenuConst/thumbnails/cycle_dash.png';
import dash_30m_thumb from 'components/MenuConst/thumbnails/dash_30m.jpg';
import spring_thumb from 'components/MenuConst/thumbnails/spring.jpg';
import singleLeg_cross_thumb from 'components/MenuConst/thumbnails/singleLeg_cross.jpg';
import toweldrag_thumb from 'components/MenuConst/thumbnails/toweldrag.jpg';

import challenge9th_thumb from 'components/MenuConst/thumbnails/challenge_bicycle.png'; //done
import intro_9th_thumb from 'components/MenuConst/thumbnails/intro_9th.jpg'; //done
import { menuType } from 'pages/PracticePage/type';

import {
  re_yHighKneeVideos,
  skipAndDashVideos,
  cycleDashVideos,
  dash30m9thVideos,
  towelDragVideos,
} from './videos';

import { springVideos } from '../grade_0/videos';

import { singleLegTrainingVideos } from '../grade_6/videos';

import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';

// up: 準備, tech: ぎじゅつ, run: 体力, family: 家族

// プレイリスト

////
//up
////
export const yHighKnee_9th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'Y字ももあげスプリント',
  isInside: 'half',
  target: ['しせい', '足の動き'],
  menuVariation: ['up'],
  hitWordList: ['Y字', 'ももあげスプリント'],
  grade: 11,
  image: yHighkneeSprint_thumb,
  video: re_yHighKneeVideos,
};

export const skipAndDash_9th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'スキップからダッシュ',
  isInside: 'unable',
  target: ['足の動き'],
  menuVariation: ['up'],
  hitWordList: ['スキップ', 'すきっぷ'],
  grade: 11,
  image: skip_challenge_thumb,
  video: skipAndDashVideos,
};

////
//tech
////

export const cycleDash_9th: menuType = {
  duration: 5,
  title: '自転車ダッシュ ☆昇級テスト',
  target: ['足の動き'],
  isInside: 'half',
  menuVariation: ['tech'],
  hitWordList: ['じてんしゃ', '自転車'],
  grade: 11,
  image: cycle_dash_thumb,
  video: cycleDashVideos,
};

////
//run
////

export const dash30m_9th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '30mダッシュ',
  isInside: 'unable',
  target: ['スタート', '全力走'],
  menuVariation: ['run'],
  hitWordList: ['30', 'さん', 'だっしゅ', 'ダッシュ'],
  grade: 11,
  image: dash_30m_thumb,
  video: dash30m9thVideos,
};

export const spring_9th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'バネ人間チャレンジ',
  isInside: 'unable',
  target: ['しせい', '全力走'],
  menuVariation: ['run'],
  hitWordList: ['ばね', 'ババ'],
  grade: 11,
  image: spring_thumb,
  video: springVideos,
};

////
//family
////

export const towelDrag: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'タオルドラッグ',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['family'],
  hitWordList: ['たおる', 'タオル'],
  grade: 11,
  image: toweldrag_thumb,
  video: towelDragVideos,
};

export const singleLegTraining_9th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '片足トレーニング',
  isInside: 'able',
  target: ['しせい'],
  menuVariation: ['family'],
  hitWordList: ['かたあし', '片足'],
  grade: 11,
  image: singleLeg_cross_thumb,
  video: singleLegTrainingVideos,
};

////
//levelup
////

export const challenge_9th: menuType = {
  duration: 5,
  hitWordList: ['9', '昇級', 'じてんしゃ', '自転車'],
  title: '9級の昇級用メニュー',
  grade: 11,
  menuVariation: ['levelUp'],
  image: challenge9th_thumb,
  isInside: 'unable',
  target: ['しせい'],
  video: [
    {
      link: 'https://www.youtube.com/embed/2kWvq8aO5Co',
      title: '9級の昇級用メニュー',
      message: (
        <span>
          9級の昇級テスト挑戦動画はコチラ！
          <br />
          ☆動画の指示に従って撮影し、画面右下の「+ボタン」から「昇級に挑戦」をタップして、動画を送信しよう！
          <br />
          ☆詳しい練習方法やポイントは「おすすめ」から確認！
        </span>
      ),
    },
  ],
};

export const intro_9th: menuType = {
  duration: 2,
  isInside: 'able',
  target: ['しせい'],
  hitWordList: ['9', '昇級', 'メッセージ', 'めっせーじ', 'きゅう'],
  title: '9級の方へのメッセージ',
  menuVariation: ['levelUp'],
  grade: 11,
  image: intro_9th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['9'],
      title: 'intro9th',
      message: <span>9級の方へのメッセージはコチラ！ </span>,
    },
  ],
};
