import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  InputAdornment,
  IconButton,
  Input,
  TextField,
  InputLabel,
  FormControl,
  CircularProgress,
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { sendLoginInfo } from 'features/auth/api/login';
import { AuthContext } from 'contexts/AuthContext';
import { RegisterStepper } from './Register/Stepper';
import ux_explanation from 'static/lp/ux_after_login.png';
import joy_family from 'static/materials/joy_family.png';
import idaten_kids from 'static/materials/idaten_kids_logo.png';

export const ReLoginPage = (props: any) => {
  const navigate = useNavigate();
  const [accountFormInfo, setAccountFormInfo] = React.useState({
    email: '',
    password: '',
    showPassword: false,
  });
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'setIsAuthenticated' does not exist on ty... Remove this comment to see the full error message
  const { setIsAuthenticated } = React.useContext(AuthContext);
  const [isUploading, setIsUploading] = React.useState(false);

  const handleEmailChange = (e: any) => {
    let _info = { ...accountFormInfo };
    _info['email'] = e.target.value;
    setAccountFormInfo(_info);
  };
  const handleSendClick = () => {
    setIsUploading(true);
    sendLoginInfo(accountFormInfo)
      .then((res) => {
        localStorage.setItem('access', res.data.access);
        setIsAuthenticated(true);
        setIsUploading(false);
        navigate('/');
      })
      .catch((res) => {
        console.log(res);
        console.log(res.message);
        if (res.message === 'Network Error') {
          alert('申し訳ありません。サーバーエラーです');
        } else if (res.message.indexOf('400')) {
          alert('認証情報が正しくありません');
        }

        setIsUploading(false);
      });
  };

  const handleClickShowPassword = () => {
    setAccountFormInfo({
      ...accountFormInfo,
      showPassword: !accountFormInfo.showPassword,
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handlePasswordChange = (e: any) => {
    let _info = { ...accountFormInfo };
    _info['password'] = e.target.value;
    setAccountFormInfo(_info);
  };

  return (
    <div style={{ padding: '5px', textAlign: 'center' }}>
      <RegisterStepper activeStep={2} />
      <div style={{ textAlign: 'center' }}>
        <img src={joy_family} alt="" width="80%"></img>
        <div>
          <img src={idaten_kids} alt="" width="80%"></img>
          <br />
          <span
            style={{ fontWeight: 'bold', fontSize: '12pt', color: '#424242' }}
          >
            かけっこを楽しく学ぼう！
          </span>
        </div>
      </div>
      <div style={{ padding: '24px 0 0 4px' }}>
        <div style={{ fontSize: '12pt', textAlign: 'center' }}>
          ご登録頂いたメールアドレスとパスワードを
          <br />
          再度入力してください。
        </div>
        <div>
          <TextField
            style={{ marginTop: '8px', width: '70%' }}
            value={accountFormInfo.email}
            label="email"
            onChange={handleEmailChange}
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <FormControl style={{ width: '70%' }}>
            <InputLabel htmlFor="standard-adornment-password">
              パスワード
            </InputLabel>
            <Input
              id="standard-adornment-password"
              value={accountFormInfo.password}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; value: string; label: string; ... Remove this comment to see the full error message
              label="パスワード"
              type={accountFormInfo.showPassword ? 'text' : 'password'}
              onChange={handlePasswordChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {accountFormInfo.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        {isUploading ? (
          <Button variant="contained" disabled style={{ marginTop: '30px' }}>
            ログイン <CircularProgress size={20} />
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '30px' }}
            onClick={handleSendClick}
          >
            ログイン
          </Button>
        )}
      </div>
      <div style={{ margin: '10px 0 40px 0' }}>
        {' '}
        <img src={ux_explanation} width="100%" alt=""></img>
      </div>
    </div>
  );
};
