import React, { FC } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import explanation from 'static/stripe_explanation.png';

export const MypageQA = () => {
  return (
    <div style={{ padding: '4px', textAlign: 'left' }}>
      <div
        style={{
          fontWeight: 'bold',
          fontSize: '12pt',
          marginBottom: '8px',
          textAlign: 'center',
        }}
      >
        よくあるご質問
      </div>

      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ marginLeft: '6px' }}
        >
          <Typography>兄弟姉妹でのご利用について</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ textAlign: 'left', padding: '8px' }}>
          <div>
            <Typography>
              本アプリケーションはご兄弟でももちろんご利用いただけます。無料体験期間中はもちろんのこと、有料パッケージをご購入になる際も、決済ページにて、ご利用になる人数分の値段をお支払い頂ければ何人でもご利用が可能です。
            </Typography>
            <div style={{ marginTop: '6px' }}>
              <img src={explanation} alt="数量の変更方法" width="100%" />
            </div>
          </div>
        </AccordionDetails>
      </Accordion> */}

      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ marginLeft: '6px' }}
        >
          <Typography>お支払い完了後の流れについて</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ textAlign: 'left' }}>
          <Typography>
            お支払い後に「領収書」「IDATENへようこそ」という2つのメールが送信されます。「IDATENへようこそ」の方にアカウントの設定手順などが書いてありますのでそちらをご参照の上アカウントを作成してくださいますと幸いです。また、Gmailをお使いの方に対して、たまにメールが「プロモーション」や「迷惑メール」フォルダに保存されるというケースがございますので、もしメールが来ない際は当該フォルダをご確認いただけますと幸いです。
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ marginLeft: '6px' }}
        >
          <Typography>どのように進めていけば良いの？</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ textAlign: 'left' }}>
          <div>
            IDATEN-Kidsの進め方については、画面右下の
            <span
              style={{ color: '#4169e1' }}
              onClick={() => console.log('as')}
            >
              「🔔おしらせ」
            </span>
            を押していただくと「今後の進め方」のセクションに記載がございます。
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ marginLeft: '6px' }}
        >
          <Typography>利用規約について</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ textAlign: 'left' }}>
          <div>
            利用規約については
            <span
              style={{ color: '#4169e1', textDecoration: 'underline' }}
              onClick={() => console.log('as')}
            >
              <a
                href={
                  'https://detailed-jaborosa-b22.notion.site/e9b4395c578e4e8684fb61c00a5e6e00'
                }
                target="_blank"
                style={{ color: '#4169e1', textDecoration: 'underline' }}
                rel="noreferrer"
              >
                こちらのページ
              </a>
            </span>
            をご覧ください。
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ marginLeft: '6px' }}
        >
          <Typography>ご解約の手続きについて</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ textAlign: 'left' }}>
          <div>
            ご解約のお手続きは、下記のフォームにご回答いただくことで完了となります。ご解約をご希望の方は
            <span
              style={{ color: '#4169e1', textDecoration: 'underline' }}
              onClick={() => console.log('as')}
            >
              <a
                href={'https://forms.gle/sKNu7RC7uYFuuKa89'}
                target="_blank"
                style={{ color: '#4169e1', textDecoration: 'underline' }}
                rel="noreferrer"
              >
                ご解約フォーム
              </a>
            </span>
            をご覧ください。
            <br />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export const HowToPlayTaiken: FC = () => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography>体験版の遊び方をチェック！</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ textAlign: 'left' }}>
        <div>
          <span style={{ fontWeight: 'bold' }}>
            ☆体験版のおすすめの楽しみ方
          </span>
          <div>
            <ol>
              <li>
                画面左下「ホーム」をタップし、「コーチからのメッセージ」を見る！
              </li>
              <li style={{ marginTop: '10px' }}>
                ホーム画面右下の
                <span style={{ color: '#4169e1', fontWeight: 'bold' }}>
                  ＋ボタン
                </span>
                から「今から練習」をタップし、実際の練習動画を見てみる。
              </li>
              <li style={{ marginTop: '10px' }}>
                画面左下「ホーム」をタップし、成績表（サンプル）を見る！
              </li>
              <li style={{ marginTop: '10px' }}>
                もし練習動画を元に練習をした方は、右下の
                <span style={{ color: '#4169e1', fontWeight: 'bold' }}>
                  ＋ボタン
                </span>
                から「練習を報告」をタップ！練習を報告してスタンプを押してみよう！
              </li>
              <li style={{ marginTop: '10px' }}>
                あとは色々な画面を触ってみてね！
              </li>
            </ol>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
