import { ckptDetails_13_20 } from 'components/MenuConst/GoalsForEachGrade/CheckpointsFor20Pkg/grade_13';
import { ckptDetails_4_20 } from 'components/MenuConst/GoalsForEachGrade/CheckpointsFor20Pkg/grade_4';

type options = {
  [key in ckptDetails_13_20]: string[];
};

export const optionsForGrade_13_20pkg: options = {
  前後傾: ['◯:ちょうど良い', '×:前傾しすぎ', '×:後傾しすぎ'],
  腰が高い: ['◯:腰高い', '×:落ちている（膝のつぶれ）/ 引けている'],
  足の上がり: ['◯:足が上がっている', '×:足が上がっていない'],
  後ろに蹴っていない: ['◯:できている', '×:蹴ってしまっている'],
  下に踏む: ['◯:できている', '×:体の遠くに接地'],
  接地様式: [
    '◯:ちょうど良い',
    '×:かかとからついている',
    '×:つま先だけでついている',
  ],
  うでを下にポンポンふる: ['◯:できている', '×:悪い'],
};
