// メールアドレスを受け取ってバリデーションメッセージを返す
export const emailValidator = (input: string): string => {
  const reg =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
  if (input.length < 1) return '';
  if (!reg.test(input)) {
    return '有効なメールアドレスを入力してください';
  }
  return '';
};
