import React, { FC, useCallback } from 'react';
import { CustomizedModalWrapper } from 'components/modals/ModalWrapper';
import { MessageMovieView } from 'pages/MemberHome/components/MessageMovieView';
import treasure_box_open from 'static/materials/treasure_box_open.png';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { calcMaxGrade, convertRunnerGrade } from 'components/constants';

export const useMessageVideoModal = (
  runner: RunnerType,
  handleBoxClose: () => void,
) => {
  const [open, setOpen] = React.useState(false);

  const toggleModalOpen = () => {
    setOpen(!open);
  };

  const forceModalOpen = useCallback((openOrClose: boolean) => {
    setOpen(openOrClose);
  }, []);

  return {
    forceModalOpen: forceModalOpen,
    toggleModalOpen: toggleModalOpen,
    MessageMovieModal: (
      <MessageMovieModal
        isModalOpen={open}
        setIsModalOpen={setOpen}
        runner={runner}
        handleBoxClose={handleBoxClose}
      />
    ),
  };
};

type Props = {
  isModalOpen: boolean;
  runner: RunnerType;
  handleBoxClose: () => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MessageMovieModal: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  runner,
  handleBoxClose,
}) => {
  const messagePrefix = createPrefix(runner);

  return (
    <CustomizedModalWrapper
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <div
        style={{
          fontWeight: 'bold',
          padding: '4px',
          fontSize: '12pt',
        }}
      >
        {messagePrefix}メッセージ
      </div>
      <MessageMovieView runner={runner} />
      <div
        style={{ textAlign: 'center', width: '40%', marginLeft: '30%' }}
        onClick={handleBoxClose}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '12px',
            border: '#ffa500 solid 2px',
            borderRadius: '40px',
            background: 'linear-gradient(to bottom, #ffd700, #ffa500)',
          }}
        >
          <div style={{ color: '#ffffff', fontWeight: 'bold' }}>
            とじる&nbsp;
          </div>
          <div>
            <img src={treasure_box_open} alt="" width="40px"></img>
          </div>
        </div>
      </div>
    </CustomizedModalWrapper>
  );
};

const createPrefix = (runner: RunnerType) => {
  const maxGrade = calcMaxGrade(runner);
  if (!runner || runner.grade === 0) {
    return `初めての方への`;
  }
  if (runner.grade >= maxGrade) {
    return '1級をクリアした方への';
  }

  return convertRunnerGrade(runner) + '級の方への';
};
