export type GenderType = '男性' | '女性' | 'その他' | undefined;

export type RunnerInfoType = {
  gender: GenderType;
  height: number;
  email: string;
  username: string;
};

export type infoKeys = 'gender' | 'height' | 'email' | 'username';

export type inputEvent = React.ChangeEvent<HTMLInputElement>;
export type handleInputChange = (keyName: infoKeys) => (e: inputEvent) => void;
export type heightChangeFunc = (
  event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
) => void;

export type handleChangeType = (e: inputEvent) => void;

export const defaultRunner = {
  gender: undefined as GenderType,
  height: 140,
  email: '',
  username: '',
} as RunnerInfoType;
