import {
  ScoreEditType, //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { RunnerType } from 'adapters/repositories/typeDefinition';
import { commentLogic_15th } from './15kyuComment';
import { commentLogic_16th } from './16kyuComment';
import { commentLogic_18th } from './18kyuComment';
import { commentLogic_19th } from './19kyuComment';
import { commentLogic_20th } from './20kyuComment';
import { gradeTargetDict_20 } from 'components/MenuConst/GoalsForEachGrade/targetsForEachGrade';
import { commentLogic_8kyu_20th } from './8kyuComment';
import { commentLogic_9kyu_20th } from './9kyuComment';
import { commentLogic_7kyu_20th } from './7kyuComment';

export function crtComment6th_20(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  const setence1 = crt_line1(score, yobina, runnerInfo);
  const sentence2 = crt_line2(runnerInfo, score, yobina);
  const sentence3 = crt_line3(score);
  return (
    setence1 +
    '<<paragraph>>' +
    sentence2 +
    '<<paragraph>>' +
    sentence3 +
    '<<paragraph>>'
  );
}

function crt_line1(
  score: ScoreEditType,
  yobina: string,
  runnerInfo: RunnerType,
) {
  const head1 = yobina + '、練習は楽しくできているかな？';
  const head2 = `今回の動画は6級の目標である「${
    gradeTargetDict_20[runnerInfo.weak_point ?? '20']
  }」`;
  let evaluation =
    score.eval === '◯合格'
      ? 'が大変良くできています！5級昇級おめでとうございます！'
      : 'に少しだけ課題があります。';
  return head1 + head2 + evaluation;
}

function crt_line2(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  console.log(score);
  return commentLogic(score, yobina, runnerInfo);
}

const commentLogic = (
  score: ScoreEditType,
  yobina: string,
  runnerInfo: RunnerType,
) => {
  if (runnerInfo.weak_point === '7') {
    return commentLogic_7kyu_20th(score, yobina);
  }
  if (runnerInfo.weak_point === '8') {
    return commentLogic_8kyu_20th(score, yobina);
  }
  if (runnerInfo.weak_point === '9') {
    return commentLogic_9kyu_20th(score, yobina);
  }
  if (runnerInfo.weak_point === '15') {
    return commentLogic_15th(score, yobina);
  }
  if (runnerInfo.weak_point === '16') {
    return commentLogic_16th(score, yobina);
  }
  if (runnerInfo.weak_point === '18') {
    return commentLogic_18th(score, yobina);
  }
  if (runnerInfo.weak_point === '19') {
    return commentLogic_19th(score, yobina);
  }
  return commentLogic_20th(score, yobina);
};

function crt_line3(score: ScoreEditType) {
  let evaluation =
    score.eval === '◯合格'
      ? `5級では「壁おし3歩ハイニー」という練習に取り組んでもらいます！この調子で頑張りましょう！`
      : '####いい具合になぐさめる####';
  return evaluation;
}
