import React, { FC } from 'react';
import board from 'static/7761.png';
import classes from './board.module.css';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { returnStandardsForTheRunner } from 'components/MenuConst/GoalsForEachGrade/standardsForEachGrade';

type Props = {
  runner: RunnerType | undefined;
};

export const LevelUpCheckpoint: FC<Props> = ({ runner }) => {
  if (!runner) {
    return <></>;
  }
  console.log(runner);
  const checkPoints = returnStandardsForTheRunner(runner, runner.grade);
  return (
    <>
      <div className={classes.boardWrapper}>
        <div className={classes.boardImage}>
          <img src={board} alt="" width="99%"></img>
        </div>
        <div className={classes.boardInnerText}>
          <div
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '10pt',
              marginLeft: '15vw',
              width: '70vw',
            }}
          >
            「今週の練習」に取り組んだら、
            <br />
            「昇級テスト」にチャレンジしよう！
          </div>
          <div
            style={{
              textAlign: 'left',
              marginTop: '14px',
              fontWeight: 'bold',
              fontSize: '10pt',
              width: '76vw',
              marginLeft: '12vw',
            }}
          >
            {checkPoints.map((ckpt) => {
              return (
                <div style={{ paddingTop: '2px', paddingLeft: '10vw' }}>
                  ✅ &nbsp;{ckpt}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
