import React, { useEffect, FC, useState } from 'react';
import { AccountContext } from 'contexts/AccountContext';

import { PageTitle } from '../../components/common/PageTitle';
import { MenuVariationSelector } from './components/MenuVariationSelector';
import { MenuListViewer } from './components/MenuListViewer';
import { LevelUpCheckpoint } from './components/LevelUpCheckpoint';
import { LevelUpMenu } from './components/LevelUpMenu';
import { decideMenu } from '../../components/MenuConst/DecideMenu';
import { menuType, menuVariationType } from './type';
import { usePracticeOnboardingModal } from 'hooks/PracticeOnboarding/usePracticeOnboardModal';
import { TabInfo } from 'components/TabInfo';
import { AnnounceBar } from './components/AnnounceBar';
import { AllMenu } from './components/AllMenu';
import { TRACMenuTab } from './components/TracMenuTab';

export const PracticePage: FC = () => {
  const { currentRunner } = React.useContext(AccountContext);

  const [menuVariation, setMenuVariation] = useState<menuVariationType>('up');

  const { forceModalOpen, OnboardModal } =
    usePracticeOnboardingModal(currentRunner);

  useEffect(() => {
    if (!currentRunner) {
      return;
    }
    const isPracticePageSeen =
      localStorage.getItem(String(currentRunner.id)) || 'yet';
    if (!!currentRunner && currentRunner.grade > 0) {
      return;
    }
    if (['yet', 'beforePractice'].includes(isPracticePageSeen)) {
      forceModalOpen(true);
    }
  }, [currentRunner]);

  const menuList: menuType[] = decideMenu(currentRunner);

  if (currentRunner?.student_group === 'newtrac') {
    return (
      <>
        <PageTitle title={'練習のときに見るページ'} />
        <TabInfo labels={['昇級メニュー', '今週の練習', 'すべて']}>
          {/* 昇級メニューのタブ */}
          <div style={{ textAlign: 'center' }}>
            <AnnounceBar currentPage="levelup" />
            <LevelUpMenu runner={currentRunner} />
            <div style={{ marginTop: '100px' }}>
              <br />
            </div>
          </div>

          {/* 今週の練習のタブ */}
          <div>
            <AnnounceBar currentPage="practice" />
            <TRACMenuTab runner={currentRunner} />
          </div>

          {/* すべての練習のタブ */}
          <div>
            {!!currentRunner && <AllMenu runner={currentRunner} />}
            <div style={{ marginTop: '100px' }}></div>
          </div>
        </TabInfo>
      </>
    );
  }
  return (
    <div>
      <PageTitle title={'練習のときに見るページ'} />
      {OnboardModal}
      <TabInfo labels={['昇級メニュー', '今週の練習', 'すべて']}>
        <div style={{ textAlign: 'center' }}>
          <AnnounceBar currentPage="levelup" />
          <LevelUpMenu runner={currentRunner} />
          <LevelUpCheckpoint runner={currentRunner} />
          <div style={{ marginTop: '100px' }}>
            <br />
          </div>
        </div>
        <>
          <div>
            <AnnounceBar currentPage="practice" />
            <MenuVariationSelector
              menuVariation={menuVariation}
              setMenuVariation={setMenuVariation}
            />
          </div>
          <div>
            <MenuListViewer
              menuVariation={menuVariation}
              menuPlaylists={menuList}
            />
          </div>
          <div style={{ marginTop: '100px' }}></div>
        </>
        <>
          <div>
            {!!currentRunner && <AllMenu runner={currentRunner} />}
            <div style={{ marginTop: '100px' }}></div>
          </div>
        </>
      </TabInfo>
    </div>
  );
};
