import React, { FC, memo } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';

export const IntroductionVideo: FC = () => {
  // ここのgradeには現在の級が入る
  const size = useWindowSize();
  console.log(size);
  const { width, height } = calculateVideoScreen(size);

  return (
    <>
      <iframe
        width={width}
        height={height}
        src={
          'https://www.youtube.com/embed/B7kicjlUFt4' +
          '?rel=0&modestbranding=1'
        }
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ marginBottom: '8px' }}
      ></iframe>
    </>
  );
};

type sizeType = number[];
export const calculateVideoScreen = (size: sizeType) => {
  return {
    width: Math.trunc(size[0] * 0.85),
    height: Math.trunc(size[0] * 0.582),
  };
};
