import React, { FC, memo } from 'react';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import classes from './onboardingCarousel.module.css';
import joy_boy from 'static/materials/joy_boy.png';
import basic_ux from 'static/materials/basic_ux.png';
import { crtYobina } from 'utils/funcs/crtYobina';

type Props = {
  runner: RunnerType;
};
export const ThirdView: FC<Props> = memo(({ runner }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '20%' }}>
          <img src={joy_boy} alt="" width="60%"></img>
        </div>
        <div style={{ width: '80%' }}>
          <div className={classes.blackBorderSmall}>
            {crtYobina(runner)}には
            <br />
            1級めざして昇級テストに
            <br />
            チャレンジしてもらうよ！
          </div>
        </div>
      </div>
      <div style={{ marginTop: '16px' }}>
        <img src={basic_ux} alt="" width="90%"></img>
      </div>
    </>
  );
});
