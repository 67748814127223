import {
  gradeObjectNewTrac,
  ScoreEditType, //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { RunnerType } from 'adapters/repositories/typeDefinition';

import {
  complimentDict_6,
  nobishiroDict_6,
  nobishiroOptions_6_trac,
  optionDict_6kyu_trac,
} from 'components/MenuConst/GoalsForEachGrade/CheckpointsForTrac10kyu/6kyu';

export function crtComment6th(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  const setence1 = crt_line1(score, yobina);
  const sentence2 = crt_line2(runnerInfo, score, yobina);
  const sentence3 = crt_line3(score);
  return (
    setence1 +
    '<<paragraph>>' +
    sentence2 +
    '<<paragraph>>' +
    sentence3 +
    '<<paragraph>>'
  );
}

function crt_line1(score: ScoreEditType, yobina: string) {
  const head1 = yobina + '、練習が続いていて素晴らしいですね！';
  const head2 = `今回の動画は6級の目標である「${gradeObjectNewTrac['6']}」`;
  let evaluation =
    score.eval === '◯合格'
      ? 'が大変良くできています！5級昇級おめでとうございます！'
      : 'に少しだけ課題があります。';
  return head1 + head2 + evaluation;
}

function crt_line2(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  return commentLogic(score, yobina);
}

const commentLogic = (score: ScoreEditType, yobina: string) => {
  const compliment = crtCompliment(score, yobina);
  const growthPotential = crtGrowhPotential(score, yobina);
  return compliment + growthPotential;
};

function crtCompliment(score: ScoreEditType, yobina: string) {
  const ckptKeys = Object.keys(optionDict_6kyu_trac);
  const goodPointList = ckptKeys.filter(
    (ckpt) => !!score[ckpt] && score[ckpt]?.indexOf('◯') !== -1,
  );
  let compliment = '';
  if (goodPointList.length < 1) {
    compliment += `今回の昇級テストはすこし難しかったかな？ただ、それはその分のびしろが大きいってこと！今からコーチが言う伸びしろポイントをしっかり復習すれば次はきっと合格できるよ！`;
    return compliment;
  }
  compliment += `まず${yobina}の良い点として${
    complimentDict_6[goodPointList[0]]
  }`;
  if (goodPointList.length < 2) {
    return compliment;
  }
  compliment += `また、さらに良かった点として${
    complimentDict_6[goodPointList[goodPointList.length - 1]]
  }`;
  return compliment;
}

function crt_line3(score: ScoreEditType) {
  let evaluation =
    score.eval === '◯合格'
      ? 'それでは5級では足の動かし方を練習していきます！新しい練習も楽しみながらがんばってみてください！'
      : '####いい具合になぐさめる####';
  return evaluation;
}

function crtGrowhPotential(score: ScoreEditType, yobina: string) {
  const shortcoming = nobishiroOptions_6_trac.find((nobishiro) =>
    Object.values(score).includes(nobishiro),
  );
  let message = '';
  if (!shortcoming) {
    message += `今回の昇級テストは文句なしの満点合格です！5級以降でも${yobina}のいい動きを見るのを楽しみにしていますよ！`;
    return message;
  }
  message += `そんな${yobina}の伸びしろポイントはズバリ${[
    nobishiroDict_6[shortcoming],
  ]}`;

  return message;
}
