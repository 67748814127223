import React, { FC, memo } from 'react';
import classes from './fukidashi.module.css';
type Props = {
  currentPage: 'levelup' | 'practice';
};
export const AnnounceBar: FC<Props> = memo(({ currentPage }) => {
  if (currentPage === 'levelup') {
    return (
      <div className={classes.fukidashi}>
        今週の練習を見るにはここをタップ！
      </div>
    );
  }
  return <div className={classes.fukidashi2}>今週の昇級メニューはコチラ！</div>;
});
