import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getVideoForShareAdapter } from 'adapters/getVideosAdapter';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { FiftyStepper } from './components/Stepper';
import { FirstScreen } from './components/FirstScreen';
import { ResultScreen } from './components/ResultScreen';
import { TimeScreen } from './components/TimeViewer';
import { TrainingMenuScreen } from './components/TrainingMenuScreen';
import { InvitationScreen } from './components/InvitationScreen';

type currentVideoType = VideoType | null;

export const FiftyResultPage = () => {
  const { videoId } = useParams();
  const [currentVideo, setCurrentVideo] = useState<currentVideoType>(null);
  const [screenNum, setScreenNum] = useState<number>(0);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  // 動画のIDを元にビデオを持ってくる
  React.useEffect(() => {
    console.log('loaded');
    getVideoForShareAdapter(videoId || '').then((video) => {
      setCurrentVideo(video[0]);
      // FIXME: threshold決め作業用
      if (!!video[0]?.score?.ai_eval) {
        console.log('score=', JSON.parse(video[0].score.ai_eval));
      }
    });
  }, [videoId]);

  const handleUpdate = useCallback(() => {
    setIsRefreshing(true);
    getVideoForShareAdapter(videoId || '').then((video) => {
      setCurrentVideo(video[0]);
      console.log(video[0]);
      setIsRefreshing(false);
    });
  }, []);

  const handleNextClick = useCallback(() => {
    setScreenNum(screenNum + 1);
  }, [screenNum]);

  const handleBackClick = useCallback(() => {
    setScreenNum(screenNum - 1);
  }, [screenNum]);

  return (
    <div style={{ textAlign: 'center' }}>
      {(screenNum > 0 && screenNum < 4 && (
        <FiftyStepper activeStep={screenNum - 1} />
      )) || <></>}
      {screenNum === 0 && (
        <>
          <FirstScreen
            handleClick={handleNextClick}
            video={currentVideo}
            isRefreshing={isRefreshing}
            handleUpdate={handleUpdate}
          />
        </>
      )}
      {screenNum === 1 && !!currentVideo && (
        <>
          <ResultScreen
            video={currentVideo}
            handleNextClick={handleNextClick}
            handleBackClick={handleBackClick}
          />
        </>
      )}
      {screenNum === 2 && !!currentVideo && (
        <>
          <TimeScreen
            video={currentVideo}
            handleNextClick={handleNextClick}
            handleBackClick={handleBackClick}
          />
        </>
      )}
      {screenNum === 3 && !!currentVideo && (
        <>
          <TrainingMenuScreen
            video={currentVideo}
            handleNextClick={handleNextClick}
            handleBackClick={handleBackClick}
          />
        </>
      )}
      {screenNum === 4 && !!currentVideo && (
        <>
          <InvitationScreen
            handleBackClick={handleBackClick}
            video={currentVideo}
          />
        </>
      )}
    </div>
  );
};
