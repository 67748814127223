import { ckptDetails_5_20 } from 'components/MenuConst/GoalsForEachGrade/CheckpointsFor20Pkg/grade_5';

type options = {
  [key in ckptDetails_5_20]: string[];
};

export const optionsForGrade_5_20pkg: options = {
  前後傾: [
    '◯:ちょうど良い',
    '×:前傾しすぎ',
    '×:後傾しすぎ',
    '◯:やや前傾',
    '◯:あご上がり',
  ],
  腰が落ちていない: ['◯:腰高い', '×:落ちている（膝のつぶれ）'],
  足の上がり: [
    '◯:足が上がっている',
    '×:足が上がっていない',
    '◯:もう少し上がるとベスト',
  ],
  後ろに蹴っていない: ['◯:できている', '×:蹴ってしまっている'],
  下に踏む: ['◯:できている', '×:体の遠くに接地'],
  接地様式: [
    '◯:ちょうど良い',
    '×:かかとからついている',
    '×:つま先だけでついている',
  ],
  うで振り: ['◯:できている', '×:悪い'],
  スピードが出せている: ['◯:OK', '×:遅すぎる'],
};
