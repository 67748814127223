import React from 'react';

import classes from './aftercheckout.module.css';
import top_image from 'static/top_image.png';
import Button from '@mui/material/Button';

export const AfterCheckoutPage = () => {
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <div className={classes.backgroundImage}>
          <img src={top_image} alt="" width="100%" />
          <div className={classes.innerText}>
            <span style={{ fontWeight: 'bolder', fontSize: '1.1rem' }}>
              この度は
            </span>
            <br />
            <span style={{ fontWeight: 'bolder', fontSize: '1.5rem' }}>
              子供の足を速くするプログラム
            </span>
            <br />
            <span style={{ fontWeight: 'bolder', fontSize: '1.1rem' }}>
              をご購入頂き誠に有難うございます。
            </span>
          </div>
        </div>

        <div style={{ marginTop: '16px', padding: '10px', color: '#424242' }}>
          <div style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>
            ここからの流れ
          </div>
          <div style={{ textAlign: 'left' }}>
            <ol style={{ fontSize: '1rem' }}>
              <li style={{ fontWeight: 'bold' }}>メールを確認する</li>
              <div style={{ marginBottom: '10px' }}>
                お支払い後、決済時にご入力頂いたメールアドレス宛に「領収書」「IDATENへようこそ」の2件のメールが送信されます。受信ができているかをご確認ください。
                <div
                  style={{
                    marginTop: '10px',
                    fontSize: '0.9rem',
                    color: '#424242',
                  }}
                >
                  ★Gmailをお使いの方の場合、まれに「プロモーション」や「迷惑メール」フォルダに入ってしまうことがあります。メールが届いていない場合、そちらもご確認ください。
                </div>
              </div>
              <li style={{ fontWeight: 'bold' }}>アカウントを作成する</li>
              <div style={{ marginBottom: '10px' }}>
                メール内に「アカウントの作成方法」が記載されておりますのでそちらに沿ってお進みください。
                <div
                  style={{
                    marginTop: '10px',
                    fontSize: '0.9rem',
                    color: '#424242',
                  }}
                >
                  ★何かご不明な点がございましたら{' '}
                  <a href="https://lin.ee/OhF30wQ">IDATEN-Kids公式LINE</a>
                  よりお問い合わせくださいますと幸いです。
                </div>
              </div>
            </ol>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: '50px' }}>
        <br />
      </div>
    </>
  );
};
