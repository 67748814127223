import { menuType } from 'pages/PracticePage/type';
import { grades } from '../GradeGoalConst';
import {
  dribbleJump,
  basePositinJump,
  pongpongJog,
  y_sprint,
  y_practice,
  spring,
  trial_50m,
  arm_swing,
  howToStartPose,
  challenge_20th,
  intro_20th,
} from '../menus/grade_0/starter_20th';
import {
  re_basePositinJump,
  basePositionWalk_19th,
  kabeoshiBasic,
  howToStartPose_19th,
  arm_swing_19th,
  yHighkneeSprint,
  singleLegRotation,
  intro_19th,
  challenge_19th,
} from '../menus/grade_1/starter_19th';
import {
  re_kabeoshi,
  darumasan,
  threeHighKnee,
  highKneeDash,
  stepQuick,
  intro_18th,
  challenge_18th,
} from '../menus/grade_2/starter_18th';
import {
  spring_17th,
  arm_swing_Walk,
  highKneeDash_17th,
  trial_50m_17th,
  towelhighknee,
  henkeidash,
  intro_17th,
} from '../menus/grade_3/starter_17th';
import {
  arm_swing_Walk_16th,
  towelhighknee_16th,
  train,
  markRun_16th,
  touritsu,
  intro_16th,
  challenge_16th,
} from '../menus/grade_4/starter_16th';
import {
  intro_15th,
  highkneeup_15th,
  dash_30m_15th,
  henkeidash_15th,
  challenge_15th,
} from '../menus/grade_5/starter_15th';
import {
  intro_14th,
  skipBasic_14th,
  skaterJump,
  singleLegTraining,
  trial_50m_14th,
  howToStartPose_14th,
  singleLegPlay,
} from '../menus/grade_6/starter_14th';

import {
  intro_13th,
  re_kabeoshiBasic_13th,
  skipBasic_13th,
  howToStartPose_13th,
  dash10m_13th,
  skaterJump_13th,
  henkeidash_13th,
  verticalJump,
  challenge_13th,
} from '../menus/grade_7/starter_13th';

import {
  intro_12th,
  singleLegUp_12th,
  kabeoshiAde,
  SkipAde,
  dash10m_12th,
  challenge_12th,
} from '../menus/grade_8/starter_12th';

import {
  intro_11th,
  re_kabeoshiAde,
  inclinationdash_11th,
  skaterJump_11th,
  dash10m_11th,
  SkipAde_11th,
  howto50m_11th,
  teoshiguruma,
  challenge_11th,
} from '../menus/grade_9/starter_11th';

import {
  intro_10th,
  henkeidash10th,
  howto50m_10th,
  trial_50m_10th,
  markRun_10th,
  spring_10th,
} from '../menus/grade_10/starter_10th';

import {
  intro_9th,
  yHighKnee_9th,
  skipAndDash_9th,
  cycleDash_9th,
  dash30m_9th,
  spring_9th,
  towelDrag,
  singleLegTraining_9th,
  challenge_9th,
} from '../menus/grade_11/starter_9th';

import {
  intro_8th,
  singleLegTraining_8th,
  towelhighknee_8th,
  threeHighKnee_8th,
  threestep_8th,
  threeStepAndDash_8th,
  dash30m_8th,
  challenge_8th,
} from '../menus/grade_12/starter_8th';

import {
  intro_7th,
  train_7th,
  cycleDash_7th,
  threestep_7th,
  markrun_7th,
  dash30m_7th,
  challenge_7th,
} from '../menus/grade_13/starter_7th';

import {
  intro_6th,
  spring_6th,
  skaterJump_6th,
  dash30m_6th,
  fiftyTrial_6th,
  henkeiDash_6th,
  singleLegTraining_6th,
} from '../menus/grade_14/starter_6th';

import {
  intro_5th,
  kabeoshimarch,
  re_threehighknee_5th,
  re_kabeoshiAde5th,
  kabeoshi3highknee,
  lungecross,
  skaterJump5th,
  dash10m_5th,
  challenge_5th,
} from '../menus/grade_15/starter_5th';

import {
  dash_10m_1st,
  howto50m,
  mark_run,
  straight_leg,
} from '../menus/grade_9/1kyu_for_10pkg';
import { bounding, spring_4 } from '../menus/grade_16/starter_4th';
import { kakekkoDiagnoseTest, twoSteps } from '../menus/grade_17/starter_3th';
import {
  dash10m_2kyu_20,
  re_howToStartPose,
  re_threeHighKnee,
} from '../menus/grade_18/2kyu_20_menu';

export const decideMenuFor20grades = (grade: grades): menuType[] => {
  if (grade === '20') {
    return [
      intro_20th,
      trial_50m,
      dribbleJump,
      basePositinJump,
      pongpongJog,
      y_sprint,
      y_practice,
      spring,
      arm_swing,
      howToStartPose,
      challenge_20th,
    ];
  }

  if (grade === '19') {
    return [
      intro_19th,
      trial_50m,
      re_basePositinJump,
      basePositionWalk_19th,
      kabeoshiBasic,
      howToStartPose_19th,
      arm_swing_19th,
      yHighkneeSprint,
      spring,
      singleLegRotation,
      challenge_19th,
    ];
  }

  if (grade === '18') {
    return [
      intro_18th,
      trial_50m,
      re_kabeoshi,
      darumasan,
      threeHighKnee,
      highKneeDash,
      stepQuick,
      challenge_18th,
    ];
  }

  if (grade === '17') {
    return [
      intro_17th,
      spring_17th,
      arm_swing_Walk,
      highKneeDash_17th,
      trial_50m_17th,
      towelhighknee,
      henkeidash,
    ];
  }

  if (grade === '16') {
    return [
      intro_16th,
      trial_50m,
      arm_swing_Walk_16th,
      towelhighknee_16th,
      train,
      markRun_16th,
      highKneeDash_17th,
      touritsu,
      henkeidash,
      challenge_16th,
    ];
  }

  if (grade === '15') {
    return [
      intro_15th,
      trial_50m,
      highkneeup_15th,
      arm_swing_Walk_16th,
      dash_30m_15th,
      henkeidash_15th,
      spring,
      stepQuick,
      howToStartPose,
      challenge_15th,
    ];
  }

  if (grade === '14') {
    return [
      intro_14th,
      trial_50m_14th,
      skipBasic_14th,
      skaterJump,
      singleLegTraining,
      howToStartPose_14th,
      singleLegPlay,
    ];
  }

  if (grade === '13') {
    return [
      intro_13th,
      trial_50m,
      re_kabeoshiBasic_13th,
      skipBasic_13th,
      howToStartPose_13th,
      dash10m_13th,
      skaterJump_13th,
      henkeidash_13th,
      verticalJump,
      singleLegPlay,
      singleLegRotation,
      challenge_13th,
    ];
  }

  if (grade === '12') {
    return [
      intro_12th,
      trial_50m,
      singleLegUp_12th,
      re_kabeoshiBasic_13th,
      kabeoshiAde,
      SkipAde,
      dash10m_12th,
      spring,
      henkeidash,
      challenge_12th,
    ];
  }

  if (grade === '11') {
    return [
      intro_11th,
      trial_50m,
      re_kabeoshiAde,
      inclinationdash_11th,
      skaterJump_11th,
      dash10m_11th,
      SkipAde_11th,
      howto50m_11th,
      teoshiguruma,
      touritsu,
      challenge_11th,
    ];
  }

  if (grade === '10') {
    return [
      intro_10th,
      henkeidash10th,
      howto50m_10th,
      trial_50m_10th,
      markRun_10th,
      spring_10th,
    ];
  }

  if (grade === '9') {
    return [
      intro_9th,
      trial_50m,
      yHighKnee_9th,
      skipAndDash_9th,
      cycleDash_9th,
      dash30m_9th,
      spring_9th,
      towelDrag,
      singleLegTraining_9th,
      challenge_9th,
    ];
  }

  if (grade === '8') {
    return [
      intro_8th,
      trial_50m,
      singleLegTraining_8th,
      towelhighknee_8th,
      threeHighKnee_8th,
      threestep_8th,
      threeStepAndDash_8th,
      dash30m_8th,
      henkeidash,
      stepQuick,
      challenge_8th,
    ];
  }

  if (grade === '7') {
    return [
      intro_7th,
      trial_50m,
      train_7th,
      cycleDash_7th,
      threestep_7th,
      markrun_7th,
      dash30m_7th,
      spring_9th,
      towelDrag,
      touritsu,
      challenge_7th,
    ];
  }

  if (grade === '6') {
    return [
      intro_6th,
      fiftyTrial_6th,
      spring_6th,
      skaterJump_6th,
      dash30m_6th,
      henkeiDash_6th,
      singleLegTraining_6th,
    ];
  }

  if (grade === '5') {
    return [
      intro_5th,
      fiftyTrial_6th,
      kabeoshimarch,
      re_threehighknee_5th,
      re_kabeoshiAde5th,
      kabeoshi3highknee,
      lungecross,
      skaterJump5th,
      dash10m_5th,
      henkeiDash_6th,
      challenge_5th,
    ];
  }

  if (grade === '4') {
    return [
      bounding,
      lungecross,
      spring_4,
      singleLegTraining_6th,
      SkipAde_11th,
    ];
  }

  if (grade === '3') {
    return [
      twoSteps,
      kakekkoDiagnoseTest,
      singleLegTraining_8th,
      arm_swing_19th,
    ];
  }
  if (grade === '2') {
    return [
      re_howToStartPose,
      re_threeHighKnee,
      dash10m_2kyu_20,
      lungecross,
      skaterJump5th,
      henkeiDash_6th,
    ];
  }
  if (grade === '1') {
    return [
      twoSteps,
      kakekkoDiagnoseTest,
      singleLegTraining_8th,
      arm_swing_19th,
      henkeidash_13th,
    ];
  }

  //ここまで

  return [
    re_kabeoshi,
    trial_50m,
    dash_10m_1st,
    howto50m,
    mark_run,
    straight_leg,
  ];

  //   throw new Error('gradeのバグ');
};
