import React, { memo, FC } from 'react';
import { Button, Card } from '@material-ui/core';
import { VideoPlayer } from 'pages/ResultPage/components/VideoPlayerWithoutFrame';
import { TimeViewer } from 'pages/ResultPage/components/TimeViewer';
import { Excuse } from 'pages/ResultPage/BasicInfo';

import { VideoType } from 'adapters/repositories/typeDefinition';

type Props = {
  handleNextClick: () => void;
  handleBackClick: () => void;
  video: VideoType;
};

export const TimeScreen: FC<Props> = memo(
  ({ handleNextClick, handleBackClick, video }) => {
    console.log(video);
    // eslint-disable-next-line
    const username =
      (!!video?.no_account_info &&
        // eslint-disable-next-line
        (JSON.parse(video.no_account_info).username as string)) ||
      ('挑戦者' as string);

    return (
      <div style={{ marginTop: '16px' }}>
        {' '}
        <div style={{ width: '80%', marginLeft: '10%' }}>
          <Card>
            {' '}
            <VideoPlayer isLoading={false} videoInfo={video} />
          </Card>
        </div>
        <div>
          <TimeViewer video={video} name={username} />
        </div>
        <div
          style={{ display: 'flex', marginTop: '24px', marginBottom: '24px' }}
        >
          <div style={{ width: '44%' }}>
            <Button onClick={handleBackClick} variant="outlined">
              {'<< 戻る'}
            </Button>
          </div>
          <div style={{ width: '12%' }}>
            <br />
          </div>
          <div style={{ width: '44%' }}>
            <Button
              onClick={handleNextClick}
              variant="contained"
              color="primary"
            >
              {' 次へ >> '}
            </Button>
          </div>
        </div>
        <div style={{ margin: '0 0 70px 0' }}>
          {' '}
          <Excuse />
        </div>
      </div>
    );
  },
);
