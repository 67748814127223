import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button } from '@material-ui/core';
import { HOST } from '../../v1apiRequest';
import { CircularProgress } from '@mui/material';
import { RegisterStepper } from './Register/Stepper';

export const MailAuthenticationPage = () => {
  const [isUploading, setIsUploading] = React.useState(false);

  const navigate = useNavigate();

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'token' does not exist on type '{}'.
  const { token, uid } = useParams();

  const handleVarifyClick = () => {
    setIsUploading(true);
    console.log(token, uid);
    axios
      .post(`${HOST}/auth/users/activation/`, {
        uid: token,
        token: uid,
      })
      .then((res) => {
        setIsUploading(false);
        localStorage.setItem('access', token);
        console.log('asdf');
        navigate('/relogin');
      })
      .catch((res) => {
        setIsUploading(false);
        alert('運営に連絡してください');
        console.log(res.data);
      });
  };

  return (
    <>
      <RegisterStepper activeStep={1} />

      <div style={{ padding: '20px', textAlign: 'center' }}>
        <div>
          {isUploading ? (
            <Button style={{ marginTop: '12px' }} variant="contained" disabled>
              クリックしてメールを認証する
              <CircularProgress size={20} />
            </Button>
          ) : (
            <Button
              style={{ marginTop: '12px' }}
              onClick={handleVarifyClick}
              variant="contained"
              color="primary"
            >
              クリックしてメールを認証する
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
