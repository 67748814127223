import { grades, ScoreEditType } from 'components/MenuConst/GradeGoalConst';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { crtComment20th_20 } from './20kyuComment';
import { crtComment19th_20 } from './19kyuComment';
import { crtComment18th_20 } from './18kyuComment';
import { crtComment17th_20 } from './17kyuComment';
import { crtComment16th_20 } from './16kyuComment';
import { crtComment15th_20 } from './15kyuComment';
import { crtComment14th_20 } from './14kyuComment';
import { crtComment13th_20 } from './13kyuComment';
import { crtComment12th_20 } from './12kyuComment';
import { crtComment11th_20 } from './11kyuComment';
import { crtComment10th_20 } from './10kyuComment';
import { crtComment9th_20 } from './9kyuComment';
import { crtComment8th_20 } from './8kyuComment';
import { crtComment7th_20 } from './7kyuComment';
import { crtComment5th_20 } from './5kyuComment';
import { crtComment6th_20 } from './6kyuComment';
import { crtComment4th_20 } from './4kyuComment';
import { crtComment3kyu_20 } from './3kyuComment';
import { crtComment2kyu_20 } from './2kyuComment';
import { crtComment1kyu_20 } from './1kyuComment';

export const autoCommentFor20thPkgs = (
  chosenVideo: VideoType,
  score: ScoreEditType,
  kyuOfVideo: grades,
) => {
  console.log(kyuOfVideo);
  const runnerInfo = chosenVideo.upload_runner;
  const runnerName = runnerInfo.username;
  const keisyo = runnerInfo.gender === '男性' ? 'くん' : 'ちゃん';
  const yobina = runnerName + keisyo;
  let comment = '';

  if (kyuOfVideo === '20') {
    comment = crtComment20th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '19') {
    comment = crtComment19th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '18') {
    comment = crtComment18th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '17') {
    comment = crtComment17th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '16') {
    comment = crtComment16th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '15') {
    comment = crtComment15th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '14') {
    comment = crtComment14th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '13') {
    comment = crtComment13th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '12') {
    comment = crtComment12th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '11') {
    comment = crtComment11th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '10') {
    comment = crtComment10th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '9') {
    comment = crtComment9th_20(runnerInfo, score, yobina);
  }
  if (kyuOfVideo === '8') {
    comment = crtComment8th_20(runnerInfo, score, yobina);
  }
  if (kyuOfVideo === '7') {
    comment = crtComment7th_20(runnerInfo, score, yobina);
  }
  if (kyuOfVideo === '6') {
    comment = crtComment6th_20(runnerInfo, score, yobina);
  }
  if (kyuOfVideo === '5') {
    comment = crtComment5th_20(runnerInfo, score, yobina);
  }
  if (kyuOfVideo === '4') {
    comment = crtComment4th_20(runnerInfo, score, yobina);
  }
  if (kyuOfVideo === '3') {
    comment = crtComment3kyu_20(runnerInfo, score, yobina);
  }
  if (kyuOfVideo === '2') {
    comment = crtComment2kyu_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '1') {
    comment = crtComment1kyu_20(runnerInfo, score, yobina);
  }

  return comment + crt_line4(chosenVideo.video_memo);
};

function crt_line4(video_memo: string | undefined) {
  if (!video_memo) {
    return '来週からはよかったら質問してね！！';
  }

  return `質問してくれた「${video_memo}」という点ですが、`;
}
