import React, { FC } from 'react';
import { TextField, Grid } from '@material-ui/core';

type heightChangeFunc = (event: React.ChangeEvent<HTMLInputElement>) => void;

type Props = {
  value: string;
  handleChange: heightChangeFunc;
  helperText: string;
  placeHolder: string;
  label: string;
};

export const CustomizedTextInput: FC<Props> = ({
  value,
  handleChange,
  helperText,
  placeHolder,
  label,
}) => {
  return (
    <Grid container style={{ alignItems: 'center', marginTop: '16px' }}>
      {/* <Grid item xs={2}>
        <Typography>{title}</Typography>
      </Grid> */}
      <Grid item xs={12}>
        {' '}
        <div>
          <TextField
            id="standard-basic"
            style={{
              width: '70%',
              backgroundColor: 'white',
            }}
            value={value}
            size="small"
            label={label}
            onChange={handleChange}
            helperText={helperText}
            placeholder={placeHolder}
          />
        </div>
      </Grid>
    </Grid>
  );
};
