import {
  getVideoByRunner,
  getAllVideo,
  getAllVideoByPage,
  getExamVideosByPage,
  getVideoByParttime,
  getVideoById,
  getVideoForShare,
} from 'v1apiRequest';
import { VideoType } from './repositories/typeDefinition';

type VideoPaginationResponse = {
  results: VideoType[];
  count: number;
  next: string;
  previous: any;
};

type VideoDetector = (response: unknown) => VideoType[];
type VideoPaginationDetector = (
  response: VideoPaginationResponse,
) => VideoPaginationResponse;

function sortVideoByDate(videoList: VideoType[]) {
  return videoList.sort((a: VideoType, b: VideoType) => {
    if (a.uploaded_date < b.uploaded_date) return 1;
    if (a.uploaded_date > b.uploaded_date) return -1;
    return 0;
  });
}

const isVideoResponse: VideoDetector = (response) => {
  if (!response) {
    throw new Error('ビデオ取得失敗');
  }
  const videos = response as VideoType[];
  if (videos.length < 1) return [];
  const aVideoForCheck = videos[0];
  if (
    typeof aVideoForCheck.id === 'string' &&
    typeof aVideoForCheck.upload_runner.username === 'string'
  ) {
    return sortVideoByDate(videos);
  }

  throw new Error('ビデオ取得失敗');
};

const isVideoByPageResponse: VideoPaginationDetector = (response) => {
  if (!response) {
    throw new Error('ビデオ取得失敗');
  }
  const videos = response['results'];
  if (videos.length < 1) return [];

  const aVideoForCheck = videos[0];
  if (
    typeof aVideoForCheck.id === 'string' &&
    typeof aVideoForCheck.upload_runner.username === 'string'
  ) {
    return response;
  }

  throw new Error('ビデオ取得失敗');
};

export const getVideoAdapter = (runnerId: string) =>
  getVideoByRunner(runnerId).then((res) => {
    console.log(res.data);
    const videos = isVideoResponse(res.data);
    console.log(videos);
    return videos;
  });

export const getAllVideoAdapter = () =>
  getAllVideo().then((res) => {
    const videos = isVideoResponse(res.data);
    console.log(videos);
    return videos;
  });

export const getAllVideoByPageAdapter = (limit: number, offset: number) =>
  getAllVideoByPage(limit, offset).then((res) => {
    const videos = isVideoByPageResponse(res.data);
    console.log(videos);
    return videos;
  });

export const getExamVideoByPageAdapter = (limit: number, offset: number) =>
  getExamVideosByPage(limit, offset).then((res) => {
    const videos = isVideoByPageResponse(res.data);
    console.log(videos);
    return videos;
  });

export const getVideoByParttimeAdapter = (pId: string) =>
  getVideoByParttime(pId).then((res) => {
    const videos = isVideoResponse(res.data);
    console.log(videos);
    return videos;
  });

export const getVideoByIdAdapter = (vId: string) =>
  getVideoById(vId).then((res) => {
    const video = isVideoResponse(res.data);
    console.log(video);
    return video;
  });

export const getVideoForShareAdapter = (vId: string) =>
  getVideoForShare(vId).then((res) => {
    const video = isVideoResponse(res.data);
    console.log(video);
    return video;
  });
