// 走りの各項目（key）に対して評価値を返す

type outputKeys = 'posture' | 'legScore' | 'armSwing';
type levels = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10';

type aiScoreKeys =
  | 'Ja_elF_max_shF_ex_raw'
  | 'Ja_elF_max_shF_flex_raw'
  | 'inclination_raw'
  | 'knee_height_raw'
  | 'gp_updown'
  | 'recovery_raw'
  | 'max_shF_flex'
  | 'shF_ex_raw';

export type AIScore = {
  [key in aiScoreKeys]: number;
};

export const thresholdMapper = (key: outputKeys, score: AIScore) => {
  const rawValue = score['inclination_raw'];
  const recovery = Math.abs(score['recovery_raw']);
  const kneeHeight = Math.abs(score['knee_height_raw']);

  // 前傾角度に関する閾値
  if (key === 'posture') {
    // 前傾しすぎ
    if (rawValue > 18.0) {
      // かつ足流れ気味（前傾角度判定が意外と弱かったため、かつにした）
      if (recovery > 36) {
        return '1';
      }
      return '7';
    }
    // 前傾やや強めかつ足流れ気味
    if (rawValue > 14 && recovery > 36) return '2';
    //後傾
    if (rawValue < 2) {
      //足あがってる
      if (kneeHeight > 80) {
        return '3';
      }
      return '4';
    }
    // 前後傾はいいが足がめちゃ流れ気味（⇒腰が引けている）
    if (recovery > 40) return '5';
    // よさげ
    // めちゃ前傾と出ているが流れてはない人（ゆうりちゃんパターン）どうする？
    return '6';
  }

  if (key === 'legScore') {
    // 全然上がってない
    if (kneeHeight < 47) {
      // &動き小さい
      if (recovery < 30) {
        return '1';
      }
      return '2';
    }
    // ちょっと上がってない
    if (kneeHeight < 65) {
      //&流れてる
      if (recovery > 40) {
        return '3';
      }
      return '4';
    }
    //上がりすぎ
    if (kneeHeight > 85) return '5';
    // 上がり具合はよいが流れ
    if (recovery > 40) return '6';
    // パーフェクト
    return '7';
  }

  if (key === 'armSwing') {
    // 腕振りの評価式はここに
    const shF_ex = Math.abs(score['shF_ex_raw']);
    const el_shF_ex = Math.abs(score['Ja_elF_max_shF_ex_raw']);
    const shF_flex = Math.abs(score['max_shF_flex']);
    const el_shF_flex = Math.abs(score['Ja_elF_max_shF_flex_raw']);

    // 小さすぎ
    if (shF_ex + shF_flex < 60) {
      //&前でひじ伸びてる
      if (el_shF_flex < 60) {
        return '1';
      }
      //&後ろでひじ伸びてる
      if (el_shF_flex < 30) {
        return '2';
      }
      return '3';
    }
    // 大きすぎ
    if (shF_ex + shF_flex > 140) {
      //&前でひじ伸びてる
      if (el_shF_flex < 60) {
        return '4';
      }
      //&後ろでひじ伸びてる
      if (el_shF_flex < 30) {
        return '5';
      }
      return '6';
    }
    // ちょうどいいが前でひじ伸びてる
    if (el_shF_flex < 60) return '7';
    // ちょうどいいが後ろでひじ伸びてる
    if (el_shF_flex < 30) return '8';
    // ひじを抱え込みすぎている
    if (el_shF_flex > 110 && el_shF_flex > 110) return '9';
    return '10';
  }

  return '3';
};

export const levelMapper = (level: levels, key: outputKeys) => {
  if (key === 'posture') {
    if (level === '1') {
      return 'C';
    }
    if (
      level === '2' ||
      level === '3' ||
      level === '4' ||
      level === '5' ||
      level === '7'
    ) {
      return 'B';
    }
    return 'A';
  }

  if (key === 'legScore') {
    if (level === '1' || level === '2') {
      return 'C';
    }
    if (level === '3' || level === '4' || level === '5' || level === '6') {
      return 'B';
    }
    return 'A';
  }
  if (key === 'armSwing') {
    if (level === '1' || level === '2') {
      return 'C';
    }
    if (
      level === '3' ||
      level === '4' ||
      level === '5' ||
      level === '6' ||
      level === '7' ||
      level === '9'
    ) {
      return 'B';
    }
    return 'A';
  }
  return 'A';
};
