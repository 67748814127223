import React, { FC, memo } from 'react';
import classes from './practiceCarousel.module.css';
import joy_boy from 'static/materials/joy_boy.png';
import goPractice from 'static/materials/howToGoPractice.png';

export const SecondView: FC = memo(() => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '20%' }}>
          <img src={joy_boy} alt="" width="60%"></img>
        </div>
        <div style={{ width: '80%' }}>
          <div className={classes.blackBorderSmall}>
            練習動画は
            <br />
            画面下<span style={{ color: '#ff0000' }}>「トレーニング」</span>
            から
            <br />
            みられるよ！
          </div>
        </div>
      </div>
      <div style={{ marginTop: '16px' }}>
        <img src={goPractice} alt="" width="80%"></img>
      </div>
    </>
  );
});
