import React, { FC } from 'react';
import { Grid, Card } from '@material-ui/core';
import posture from 'static/posture_murai.png';
import leg_movement from 'static/leg_murai.png';
import arm_swing from 'static/armSwing_murai.png';
import { thresholdMapper, AIScore } from 'components/MenuConst/thresholds';
import {
  CommentForEachPoint,
  evalKeys,
  evalNumber,
} from 'components/MenuConst/CommentForEachPoint';
import { ScoreLabel } from './ScoreLevel';
import { FormScore } from './FormScore';
import { calcFiftyTime } from 'pages/ResultPage/components/TimeViewer';

//eslint-disable-next-line

type Props = {
  score: AIScore;
  speed: number;
};

export const EachScoreViewer: FC<Props> = ({ score, speed }) => {
  const postureScore = thresholdMapper('posture', score);
  const legScore = thresholdMapper('legScore', score);
  const armScore = thresholdMapper('armSwing', score);
  const estTime = calcFiftyTime(Number(speed));

  return (
    <div
      style={{
        marginTop: '16px',
      }}
    >
      <div>
        <FormScore
          posture={postureScore}
          leg={legScore}
          arm={armScore}
          estTime={estTime}
        />
      </div>
      <ScoreViewer title="姿勢" level={postureScore} picture={posture} />
      <ScoreViewer title="足の動き" level={legScore} picture={leg_movement} />
      <ScoreViewer title="うでふり" level={armScore} picture={arm_swing} />
    </div>
  );
};

type ScoreViewerProps = {
  title: evalKeys;
  level: evalNumber;
  picture: string;
};
const ScoreViewer: FC<ScoreViewerProps> = ({ title, level, picture }) => {
  return (
    <Card
      style={{
        width: '95%',
        marginLeft: '2%',
        marginTop: '8px',
        padding: '4px',
      }}
    >
      <Grid container>
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <img width="70%" alt="" src={picture} />
        </Grid>
        <Grid item xs={8} style={{ textAlign: 'left' }}>
          <ScoreLabel title={title} level={level} />
          <div style={{ marginTop: '6px', fontSize: '10pt' }}>
            {CommentForEachPoint[title][level]}
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
