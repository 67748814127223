import { grades10 } from 'components/MenuConst/GradeGoalConst';
import { optionsForGrade0_10pkg } from './grade_0';
import { optionsForGrade_1_10pkg } from './grade_1';
import { optionsForGrade_2_10pkg } from './grade_2';
import { optionsForGrade_3_10pkg } from './grade_3';
import { optionsForGrade_4_10pkg } from './grade_4';
import { optionsForGrade_5_10pkg } from './grade_5';
import { optionsForGrade_6_10pkg } from './grade_6';
import { optionsForGrade_7_10pkg } from './grade_7';
import { optionsForGrade_8_10pkg } from './grade_8';
import { optionsForGrade_9_10pkg } from './grade_9';

type outputType =
  | typeof optionsForGrade0_10pkg
  | typeof optionsForGrade_1_10pkg
  | typeof optionsForGrade_2_10pkg
  | typeof optionsForGrade_3_10pkg
  | typeof optionsForGrade_4_10pkg
  | typeof optionsForGrade_5_10pkg
  | typeof optionsForGrade_6_10pkg
  | typeof optionsForGrade_7_10pkg
  | typeof optionsForGrade_8_10pkg
  | typeof optionsForGrade_9_10pkg;

type options_10_type = {
  [key in grades10]: outputType;
};

export const optionsFor10Grades: options_10_type = {
  10: optionsForGrade0_10pkg,
  9: optionsForGrade_1_10pkg,
  8: optionsForGrade_2_10pkg,
  7: optionsForGrade_3_10pkg,
  6: optionsForGrade_4_10pkg,
  5: optionsForGrade_5_10pkg,
  4: optionsForGrade_6_10pkg,
  3: optionsForGrade_7_10pkg,
  2: optionsForGrade_8_10pkg,
  1: optionsForGrade_9_10pkg,
};
