import React from 'react';
import challenge12th_thumb from 'components/MenuConst/thumbnails/challenge_12th.png';
import intro_12th_thumb from 'components/MenuConst/thumbnails/intro_12th.jpg';
import skaterjump_thumb from 'components/MenuConst/thumbnails/skaterjump.jpg';
import kabeoshi_Ade_thumb from 'components/MenuConst/thumbnails/kabeoshi_seq.png';
import aboveSkip from 'components/MenuConst/thumbnails/above_skip.png';
import dash_10m_thumb from 'components/MenuConst/thumbnails/dash_10m.jpg';

import { menuType } from 'pages/PracticePage/type';

import {
  singleLegUpVideos,
  kabeoshiAdeVideos,
  skipAdvVideos,
  dash10m12thVideos,
} from './videos';

import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';

// up: 準備, tech: ぎじゅつ, run: 体力, family: 家族

// プレイリスト

////
//up
////
export const singleLegUp_12th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '片足トレーニング',
  isInside: 'able',
  target: ['スタート'],
  menuVariation: ['up'],
  hitWordList: [
    'かたあし',
    '片足',
  ],
  grade: 8,
  image: skaterjump_thumb,
  video: singleLegUpVideos,
};

//タオルももあげは13級と一緒

////
//tech
////

export const kabeoshiAde: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'かべおし上級 ☆昇級テスト',
  isInside: 'able',
  target: ['スタート'],
  menuVariation: ['tech'],
  hitWordList: [
    'かべおし',
    '壁',
  ],
  grade: 8,
  image: kabeoshi_Ade_thumb,
  video: kabeoshiAdeVideos,
};

export const SkipAde: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '3歩スキップ',
  isInside: 'unable',
  target: ['スタート'],
  menuVariation: ['tech'],
  hitWordList: [
    '3',
    'さん',
    'スキップ',
    'すきっぷ',
  ],
  grade: 8,
  image: aboveSkip,
  video: skipAdvVideos,
};

////
//run
////

export const dash10m_12th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '10mダッシュ',
  isInside: 'unable',
  target: ['スタート'],
  menuVariation: ['run'],
  hitWordList: [
    'スタート',
    'すたーと',
    '10',
    'じゅう',
    'ダッシュ',
    'だっしゅ',
  ],
  grade: 8,
  image: dash_10m_thumb,
  video: dash10m12thVideos,
};

//ばね人間は他の級から


////
//family
////

//かけっこ診断、変形ダッシュは他の級より

////
//levelup
////

export const challenge_12th: menuType = {
  duration: 5,
  hitWordList: ['12', '昇級', 'Y字', 'ワイ', 'Y', 'スプリント', 'タオル'],
  title: '12級の昇級用メニュー',
  grade: 8,
  menuVariation: ['levelUp'],
  image: challenge12th_thumb,
  isInside: 'unable',
  target: ['しせい'],
  video: [
    {
      link: 'https://www.youtube.com/embed/1jV1BqnPCyk',
      title: '12級の昇級用メニュー',
      message: (
        <span>
          12級の昇級テスト挑戦動画はコチラ！
          <br />
          ☆動画の指示に従って撮影し、画面右下の「+ボタン」から「昇級に挑戦」をタップして、動画を送信しよう！
          <br />
          ☆詳しい練習方法やポイントは「おすすめ」から確認！
        </span>
      ),
    },
  ],
};

export const intro_12th: menuType = {
  duration: 2,
  isInside: 'able',
  target: ['しせい'],
  hitWordList: ['12', '昇級', 'メッセージ', 'めっせーじ', 'じゅう'],
  title: '12級の方へのメッセージ',
  menuVariation: ['levelUp'],
  grade: 8,
  image: intro_12th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['12'],
      title: 'intro12th',
      message: <span>12級の方へのメッセージはコチラ！ </span>,
    },
  ],
};
