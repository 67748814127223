// TRAC10級パッケージの3級

export const ckptFor3kyu_trac10 = [
  'しせい',
  'ひざの曲がり',
  '安定感',
  'うでの位置',
] as const;

export type ckpt_3kyu_type = typeof ckptFor3kyu_trac10[number];

export const requirement_3kyu_trac: ckpt_3kyu_type[] = [
  'しせい',
  'ひざの曲がり',
  '安定感',
  'うでの位置',
];

type options = {
  [key in ckpt_3kyu_type]: string[];
};

export const optionDict_3kyu_trac: options = {
  しせい: ['◯良い', '×腰が抜けている', '×反っている'],
  ひざの曲がり: ['◯あまり曲がっていない', '×曲がりすぎている'],
  安定感: ['◯フラフラしていない', '×フラフラしている'],
  うでの位置: ['◯腕が耳の横にある', '×腕が耳より前', '×腕が耳より後ろ'],
};

type complimentType = {
  [key in ckpt_3kyu_type]: string;
};

// まずxxくんの良い点として、に続く文章を書く
export const complimentDict_3: complimentType = {
  しせい:
    '「良いしせいを作れている」点があります！ここまでの練習の成果が出ていますね！',

  ひざの曲がり:
    '「膝を曲げずにできている」という点があります！しっかり片足で身体を支えることができていて素晴らしいですよ！',
  安定感:
    '「動きが安定している」という点があります。片足なのにフラフラせずにできているのがとっても良いです！',
  うでの位置:
    '腕がしっかり耳の横にあり、指先からかかとまでがまっすぐになっている点があります！難しいことなのによく頑張りましたね！',
};

export const nobishiroOptions_3_trac = Object.values(optionDict_3kyu_trac)
  .flat()
  .filter((value) => !value.includes('◯'));

type nobishiroDict = {
  [key in typeof nobishiroOptions_3_trac[number]]?: string;
};

// そんな〇〇くんの伸びしろポイントはズバリ、に続く文章を書く
export const nobishiroDict_3: nobishiroDict = {
  '×腰が抜けている':
    '「腰が曲がってしまっていること」です！しせいを保つのは難しいですが、最初は高くジャンプしなくて良いので、背中、腰、ひざをまっすぐに保つことだけ意識してみましょう！',
  '×反っている':
    '「腰とおなかが少し前に出てしまっている」ことです。はじめのうちは高くジャンプしなくて良いので、背中、腰、ひざをまっすぐに保つことだけ意識してみましょう！',
  '×曲がりすぎている':
    '「少しひざが曲がってしまっている」ことです！まずはひざを曲げないように意識するのは少し難しいので、まずは軽く弾みながらひざをまっすぐにする意識をしてみましょう！',
  '×フラフラしている':
    '「前後左右に揺れてしまっている」ことです。動きの大きさも大事ですが、まずは「ていねいさ」を重視してみましょう！',
  '×腕が耳より前':
    '「腕が耳より前に出ている」ことです。一度かがみの前で自分のしせいを見てみましょう！',
  '×腕が耳より後ろ':
    '「腕が耳より後ろになっている」ことです。腕が後ろになってしまうと姿勢もそりやすくなってしまいます。一度かがみの前で自分のしせいを見てみましょう！',
};
