import React from 'react';
import { useNavigate } from 'react-router-dom';

import { VideoContext } from 'contexts/VideoContext';
import 'index.css';
import 'App.css';
import { CardForFirstLogin } from 'features/videoLogs/components/VideoList/CardForFirstLogin';
import { VideoCard } from './VideoCard';

export const VideoListPage = () => {
  const navigate = useNavigate();
  const { videoList } = React.useContext(VideoContext);

  const changeVideoId = (vId: string) => () => {
    navigate(`/logs/result/${vId}`);
    //どうやらスラッシュ入れるのがabsolutepathらしい
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <div
        style={{
          margin: '3px',
          overflowY: 'scroll',
          height: `${window.parent.screen.height * 0.75}px`,
          border: '3px solid #EEEEEE',
        }}
      >
        {!!videoList && videoList.length > 0 ? (
          <>
            {videoList.map((vObj) => {
              return (
                <VideoCard
                  key={vObj.id}
                  video={vObj}
                  handleClick={changeVideoId}
                />
              );
            })}
            <CardForFirstLogin />
          </>
        ) : (
          <div>
            <p>動画がまだ投稿されていません</p>
            <CardForFirstLogin />
          </div>
        )}
      </div>
    </div>
  );
};
