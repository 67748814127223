import { ResultPage } from './ResultPage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LogPage } from './LogPage';

export const VideoLogRoutes = () => {
  return (
    <Routes>
      <Route path="calendar" element={<LogPage />} />
      <Route path="result/:videoId" element={<ResultPage />} />
    </Routes>
  );
};
