import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';

export const highKneeupVideos = [
  {
    link: 'https://www.youtube.com/embed/AvMfxvRFdIw',
    title: '小刻み腿上げ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: 「こきざみもも上げ20回」× 左右足2セットずつチャレンジ！
        </div>
        <div className={classes.content}>
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/ICJshfVfBQA',
    title: '連続ハイニー',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2: 「連続ハイニー20歩」× 3セット（目安）行う！
        </div>
        <div className={classes.content}>
          ☆なれてきたら下にふむ意識でやってみよう！その際は腕も下にポンポンふろう！
          <br />
          ☆よろしければ保護者様が横で手を叩いてリズムをとってあげるとやりやすいかも！目安は1秒に3～4回くらいがおススメ！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！※足音がなりますので、気になる方はご注意ください！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/RgfOs-J-nWk',
    title: 'Y字ポジションもも上げスプリント',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3:「Y字ポジションもも上げスプリント10m⇒そのままうでをおろして15mダッシュ」×
          3本行う！
        </div>
        <div className={classes.content}>
          ☆「あしをからだの前で動かす」感覚のままダッシュしてみよう！
        </div>
      </div>
    ),
  },
];

export const dash30m15thVideos = [
  {
    link: 'https://www.youtube.com/embed/k1F7AH2u14k',
    title: '狭めのマーク走',
    message: (
      <div>
        動画1:「せまめのマーク走」を3本以上行う！
        <br />
        ⇒「30mダッシュ」にとにかくたくさんチャレンジ！
        <br />
        ☆かけっこ診断をうまく活用しよう！
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本を送信！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          昇級のポイント
        </div>
        1. よい姿勢 ☆たおしすぎていないかな？あごを引いて15m先に地面を見よう！
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆うまくいかない子は動画2を復習！
        </div>
        2. 足を体の前で回す
        ☆ふとももがちゃんとあがっているかな？ハイニーダッシュのときのように「あしをからだの前で動かす」感覚のままダッシュしてみよう！
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆うまくいかない子は動画3,4を復習！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ2つができた子はチャレンジ！～
        </div>
        3. 足のつき方 ☆ぼしきゅうからつけているかな？
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆うまくいかない子は動画5を復習！
        </div>
        4. うで振り ☆下にポンポンふろう！
        <br />
        5. スピードを出す
        ☆なれてきたらチャレンジ！思いっきり走る練習はとてもだいじ！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/69otlz4YMvw',
    title: 'Y字しせいで走る(お手本)',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「Y字しせいで10m走る⇒腕をおろして15走る」× 3本（目安）行う！
        </div>
        <div className={classes.content}>
          ☆腕をおろした後もしせいをくずさずポンポン走ろう！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/zayX9cfq0O0',
    title: '3歩ハイニー',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3:「3歩ハイニー」を楽しみながら復習！
          <br />
          ☆足の上がった姿勢でピタッと1秒以上とまろう！
        </div>
        <div className={classes.title_important}>3歩ハイニーのポイント</div>
        <div className={classes.content}>
          1. よい姿勢 ☆おしりはたか～い位置にあるかな？
          <br />
          2. ハイニーができている ☆ふとももがちゃんとあがっているかな？
          <br />
          3. ベースポジションでとまる
          ☆足の上がった姿勢でピタッと1秒以上とまろう！
          <br />
          <br />
          ☆よろしければ保護者様が「1・2・ピタッ！（2秒あけて）1・2・ピタッ！・・・」といった感じで声をかけてあげて下さい！やりやすくなるし練習が楽しくなりますよ！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/Yi3w66j82jo',
    title: '連続ハイニー前進',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画4: 「連続ハイニー前進10m⇒そのまま15mダッシュ」を3～5本おこなう！！
        </div>
        <div className={classes.content}>
          ☆連続ハイニーの時のいい姿勢や足の動かし方のままダッシュしてみよう！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/P9R-SGDGgIM',
    title: '列車ランニング',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画5:「列車ランニング20m」× 4本（目安）を行う！
        </div>
        ☆かかとから足をつきやすい子におすすめ！
      </div>
    ),
  },
];

//////////////////////////
//10級パッケージ用
/////////////////////////
export const threeStepVideos = [
  {
    link: 'https://www.youtube.com/embed/1isIaXlEFZw',
    title: 'ベースポジションジャンプ移動',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画1）「ベースポジションジャンプ移動」をたくさんやってみる（目安3～5分）！！
        </div>
        ☆両方の足でやってみよう！かっこいい姿勢の移動できるかな？
        <br />
        ☆よろしければ保護者の方は肩を軽く押してあげて下さい！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/9cuO2W7C18w',
    title: 'ベースポジション踏みかえ',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画2）「ベースポジションジャンプ踏みかえ」を特訓（目安3分）！！
        </div>
        ☆「空中であしを入れかえる！」が連続10回出来たら合格！
        <br />
        ☆あしを入れかえる際、太鼓をたたくイメージで腕を振ってリズムをとるとやりやすいかも！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/aVvoKl6RIgo',
    title: 'ベースポジション3歩ステップ',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画3）「ベースポジション3歩ステップ」を楽しみながら特訓（目安5～8分）！
        </div>
        ⇒「体をまっすぐ立てて、空中であしを入れかえる！」が連続6回出来るようなったらゲームクリア！昇級テストにチャレンジしてみてね！
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本（あしのいれかえが5歩程度うつっている動画）を送信！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          昇級のポイント
        </div>
        1. 頭から足までまっすぐ
        ☆これがとにかく一番大事！おしりはたか～い位置にあるかな？1歩1歩で前に進もうとしすぎないこと！
        <br />
        2. （1.2のとき）ベースポジションで進めている
        ☆ふとももがちゃんとあがっているかな？
        <br />
        3. （3のとき）あしを体の前で入れかえる
        ☆腕も下に振ってリズムをとってみよう！
      </div>
    ),
  },
];

export const trainVideos = [
  {
    link: 'https://www.youtube.com/embed/P9R-SGDGgIM',
    title: '列車ランニング',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画1）「列車ランニング20m」× 4本（目安）を行う！
        </div>
        ☆かかとから足をつきやすい子におすすめ！
      </div>
    ),
  },
];

export const firstAccelVideos = [
  {
    link: 'https://www.youtube.com/embed/sLO-uws-XLs',
    title: 'スタート姿勢の作り方',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画1）「正しいスタート姿勢」をマスターしよう！！（動画のお手本の形をマネしてみよう！）
        </div>
        ☆保護者さんなどにうまくできているかチェックしてもらおう！
        <br />
        ※チェックポイントは①後ろ足を軽く地面につけているか（前あしの膝が足首のだいたい真上にあるか）③目線は3m先を見れているか
        <br />
        ☆腕は前後にひらくんでも下にたらすんでも、やりやすい形でOK！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/SbSr3_5aXTI',
    title: '1次加速の練習',
    message: (
      <div className={classes.subtitle}>
        （動画2）「スタートから10mまでの走り方」を学ぶ！！
      </div>
    ),
  },
];

export const inclineDashVideos = [
  {
    link: 'https://www.youtube.com/embed/1yD-JQKfAGQ',
    title: '倒れ込み壁押しドリル',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画1）「壁に倒れこみ」を練習（目安2～3分）！
        </div>
        ☆保護者さんなどにうまくできているかチェックしてもらおう！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/f1xmOxjA6SA',
    title: '倒れこみダッシュ',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画2）「倒れこみダッシュ10m」× 5本（目安）を行う！
        </div>
        ☆腰が折れ曲がってないか保護者さんなどにチェックしてもらおう！
        <br />
        ☆おうちの近くにのぼり坂があったら、そこでやってみよう！！
      </div>
    ),
  },
];

export const intro5thVideos = [
  {
    link: 'https://www.youtube.com/embed/6Gc0mYgVfwY',
    title: '5級イントロ動画',
    message: (
      <div className={classes.subtitle}>5級の方へのメッセージはコチラ！ </div>
    ),
  },
];

////////////////////////////////////////////////////
// TRAC
////////////////////////////////////////////////////

export const mountain_walker = [
  {
    link: 'https://www.youtube.com/embed/NVZOFS5ZKZA',
    title: 'マウンテンウォーカー',
    message: (
      <div>
        <div className={classes.subtitle}>マウンテンウォーカー</div>
        <div className={classes.content}>
          ☆ぜひ動画のまねをしてやってみてね！
        </div>
      </div>
    ),
  },
];

export const mountain_climber = [
  {
    link: 'https://www.youtube.com/embed/k4mR60MQH4Y',
    title: 'マウンテンウォーカー',
    message: (
      <div>
        <div className={classes.subtitle}>マウンテンクライマー</div>
        <div className={classes.content}>
          ☆ぜひ動画のまねをしてやってみてね！
        </div>
      </div>
    ),
  },
];

export const kabe_walk = [
  {
    link: 'https://www.youtube.com/embed/LxmW0koU2Fc',
    title: '壁押しウォーク',
    message: (
      <div>
        <div className={classes.subtitle}>壁押しウォーク</div>
        <div className={classes.content}>
          ☆ぜひ動画のまねをしてやってみてね！
        </div>
      </div>
    ),
  },
];

export const sequential_kabe = [
  {
    link: 'https://www.youtube.com/embed/LGbiE4st5dA',
    title: '連続壁押し',
    message: (
      <div>
        <div>
          <div className={classes.subtitle}>
            連続壁押しをやってみる！
            <br />
            ☆昇級テストの挑戦は右下の ➕ボタン⇒昇級に挑戦」から行えます！
          </div>
          <div className={classes.title_important}>昇級のポイント</div>
          <div className={classes.content}>
            1. 体をまっすぐにする！
            <br />
            2. 足をしっかり上げる！
            <br />
            3. ひざをしっかりたたむ！
          </div>
        </div>
      </div>
    ),
  },
];
