import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Modal, Fade, Backdrop } from '@material-ui/core';
import LinkIcon from '@mui/icons-material/Link';
import TwitterIcon from '@mui/icons-material/Twitter';

const useStylesForModal = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#EEEEEE',
    border: '3px solid #87cefa',
    borderRadius: '10%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
}));

export const ShareModal = (props: any) => {
  const classes = useStylesForModal();

  const handleClose = () => {
    props.setIsModalOpen(false);
  };

  const tweetLink = new URL('https://twitter.com/intent/tweet');

  tweetLink.searchParams.set('text', 'IDATEN kidsに見てもらったよ！');
  tweetLink.searchParams.set(
    'url',
    `https://www.idaten-app.com/result-share/${
      (props.currentVideo && props.currentVideo?.id) || 'sample'
    }`,
  );

  return (
    <Modal
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.isModalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.isModalOpen}>
        <div className={classes.paper}>
          <h3 id="transition-modal-title">ともだちに見せてあげよう！</h3>
          <div id="transition-modal-description">
            <div>
              <div>
                <Button color="primary" variant="outlined">
                  <TwitterIcon size="medium" />
                  <a
                    href={tweetLink.toString()}
                    target="_blank"
                    rel="noreferrer"
                  >
                    このページをツイート
                  </a>
                </Button>
              </div>
              <div style={{ marginTop: '10px' }}>
                <Button
                  onClick={props.onCopyClick}
                  variant="outlined"
                  style={{ width: '200px', color: '#333333' }}
                >
                  <LinkIcon size="medium" />
                  リンクをコピー
                </Button>
              </div>

              <div style={{ marginTop: '30px' }}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  閉じる
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
