import { ckptDetails_11_20 } from 'components/MenuConst/GoalsForEachGrade/CheckpointsFor20Pkg/9_kyu';

type options = {
  [key in ckptDetails_11_20]: string[];
};

export const optionsForGrade_11_20pkg: options = {
  前後傾: ['◯:ちょうど良い', '×:前傾しすぎ', '×:後傾しすぎ', '◯:やや前傾'],
  腰が落ちていない: ['◯:腰高い', '×:落ちている（膝のつぶれ）'],
  足の上がり: ['◯:足が上がっている', '×:足が上がっていない', '◯:もう少し'],
  後ろに蹴っていない: ['◯:できている', '×:蹴ってしまっている'],
  下に踏む: ['◯:できている', '×:体の遠くに接地'],
  接地様式: [
    '◯:ちょうど良い',
    '×:かかとからついている',
    '×:つま先だけでついている',
  ],
  前さばき: ['◯:できている', '×:出来ていない'],
  スピードが出せている: ['◯:OK', '×:遅すぎる'],
  腰の引け具合: ['◯:OK', '×:引けている'],
};
