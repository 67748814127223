import { ckptDetails_2_20 } from 'components/MenuConst/GoalsForEachGrade/CheckpointsFor20Pkg/grade_2';

type options = {
  [key in ckptDetails_2_20]: string[];
};

export const optionsForGrade_2_20pkg: options = {
  前後傾: ['◯:ちょうど良い', '×:前傾しすぎ', '×:後傾しすぎ'],
  腰が落ちていない: ['◯:腰高い', '×:落ちている（膝のつぶれ）'],
  リズム: ['◯:正しい', '×:おかしい'],
  ハイニーができている: ['◯:できている', '×:足が上がっていない'],
  股関節から動かせている: ['◯:できている', '×:膝下だけ動いている'],
  ベースポジションで止まる: [
    '◯:できている',
    '×:止まれていない',
    '◯:止まれているが姿勢が悪い',
  ],
  うで振り: ['◯:できている', '×:悪い', '×:逆である'],
  リーチアウト: [
    '◯:していない（足首が膝より前に出ていない）',
    '×:足が開いている',
  ],
  膝のつぶれ: ['◯:できている', '×:悪い'],
};
