import React from 'react';
import { RouteObject } from 'react-router-dom';
import { ScoringRoutes } from 'features/scoring/routes';
import { AuthRoutes } from 'features/auth/routes';
import { VideoLogRoutes } from 'features/videoLogs/routes';

export const routes: RouteObject[] = [
  {
    path: '/scoring/*',
    element: <ScoringRoutes />,
  },
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
  {
    path: '/logs/*',
    element: <VideoLogRoutes />,
  },
];
