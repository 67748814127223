// 10級パッケージの10級

export const ckptFor10kyu_trac10 = [
  '良いしせい',
  'つま先の向き',
  'しゃがんだ時のかかと',
  '動きのはやさ',
] as const;

export type ckpt_10kyu_type = typeof ckptFor10kyu_trac10[number];

export const requirement_10kyu_trac: ckpt_10kyu_type[] = [
  '良いしせい',
  'つま先の向き',
];

type options_10_type = {
  [key in ckpt_10kyu_type]: string[];
};

export const optionDict_10kyu_trac: options_10_type = {
  良いしせい: ['◯背筋が伸びている', '×背筋が伸びている'],
  つま先の向き: ['◯90度', '×開きすぎている', '×狭すぎる'],
  しゃがんだ時のかかと: ['◯浮いている', '△浮いていない'],
  動きのはやさ: ['◯ちょうど良い', '△速い'],
};

type complimentType = {
  [key in ckpt_10kyu_type]: string;
};

// まずxxくんの良い点として、に続く文章を書く
export const complimentDict_10: complimentType = {
  良いしせい:
    '「きれいな姿勢」があります。まっすぐな姿勢が作れていてとても良いですね！',
  つま先の向き:
    'つま先を正しい方向に向けられている点があります！細かいところをよく見逃さずにできましたね！',
  しゃがんだ時のかかと:
    'しっかりとかかとが浮いたままできている、と言う点があります！かかとを浮かせるのはむずかしかったと思いますがよくがんばってくれましたね！',
  動きのはやさ:
    '動きのスピードがあります。今回はちょうど良いスピードで丁寧にやってくれていてとてもグッドです！',
};

export const nobishiroOptions_10_trac = Object.values(optionDict_10kyu_trac)
  .flat()
  .filter((value) => !value.includes('◯'));

type nobishiroDict = {
  [key in typeof nobishiroOptions_10_trac[number]]?: string;
};

// そんな〇〇くんの伸びしろポイントはズバリ、に続く文章を書く
export const nobishiroDict_10: nobishiroDict = {
  '×背筋が伸びている':
    '少し背中が丸くなってしまっていることです。なるべく背を高くするイメージを持ってみましょう！',
  '×開きすぎている':
    '少し足先を開きすぎってしまっていることです！足を開く角度は90度にするのを目安にやってみましょう！',
  '×狭すぎる':
    '少し足先の開きが狭いことです！もう少し足を開いて、90度くらいを目安にすると良い姿勢が作りやすいですよ！',
  '△浮いていない':
    '「脚の裏全体が地面についてしまっている」点です！少しグラグラしやすくなりますが、練習だと思って少しかかとを上げてみましょう！！',
  '△速い':
    '「少し動きが速すぎる」ことです！この練習はもう少しやった方が効果があるので、次からは少しゆっくりやってみましょうね！',
};
