import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';

////tech

export const trainVideos = [
  {
    link: 'https://www.youtube.com/embed/P9R-SGDGgIM',
    title: '列車ランニング',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「列車ランニング20m」× 4本（目安）を行う！
        </div>
        ☆かかとから足をつきやすい子におすすめ！
      </div>
    ),
  },
];

export const markRunVideos = [
  {
    link: 'https://www.youtube.com/embed/y7yY_FMYS28',
    title: '狭めのマーク走準備',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: マーク走の準備の仕方を確認
        </div>
        ☆マーク間の距離は、中学年くらいの子でおおよそ1m程度です！
        <br />
        ☆マークとなるものがない場合は、「地面に線を引く」等で代用可能です！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/k1F7AH2u14k',
    title: '狭めのマーク走',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「せまめのマーク走」を5本以上行う！
          <br />
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本（5歩程度うつっている動画）を送信！
        </div>
        <div className={classes.title_important}>昇級のポイント</div>
        <div className={classes.content}>
          1. よい姿勢 ☆おしりはたか～い位置にあるかな？
          <br />
          2. ハイニーができている
          ☆ふとももがちゃんとあがっているかな？ぼしきゅうから足をつけているかな？
        </div>
        <div className={classes.title_important}>
          うえ2つができた子はチャレンジ！
        </div>
        <div className={classes.content}>3. うでを下にポンポンふろう！</div>
      </div>
    ),
  },
];

////run

//マーク走

////family

export const touritsuVideos = [
  {
    link: 'https://www.youtube.com/embed/j9lZAht0gc8',
    title: 'ペア倒立',
    message: (
      <div>
        <div className={classes.subtitle}>動画1: ペア倒立をやってみよう！</div>
        ☆このメニューはおうちの中でも出来るので、よかったらおうちでも練習してみてね！
      </div>
    ),
  },
];

///////////////////////////////////////////////
//以下10級パッケージ用
/////////////////////////////////////////////

export const cycleDashVideos = [
  {
    link: 'https://www.youtube.com/embed/T3L9LCHLjoM',
    title: '脚回しドリル',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画1）「脚回しドリル」を左右足とも特訓（目安5分）！！
        </div>
        ☆あしの動かし方に慣れよう！
        <br />
        ☆このメニューはおうちの中でも出来るので、よかったらおうちでも練習してみてね！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/GLf1842zVwE',
    title: '自転車ウォーク',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画2）「自転車ウォーク10m」× 3本を行う！！
        </div>
        ☆あしの動かし方に慣れよう！
        <br />
        ☆このメニューはおうちの中でも出来るので、よかったらおうちでも練習してみてね！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/oWqqSc6D_Dc',
    title: '自転車ジョグ',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画3）「自転車ジョグ10m」× 3本を行う！！
        </div>
        ☆あしの動かし方に慣れよう！あしが自然と戻ってくる感覚がつかめたらGood！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/ApkuMRS9CFU',
    title: '自転車ダッシュ',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画4）「自転車ダッシュ20m」× 4本を行う！！
        </div>
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本（5歩程度うつっている動画）を送信！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          昇級のポイント
        </div>
        1. よい姿勢 ☆たおしすぎていないかな？あごを引いて15m先に地面を見よう！
        <br />
        2. 足を体の前で回す
        ☆足が自然とまえにもどってきて、自然とからだの前で回る感覚がつかめたらGood！
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ2つができた子はチャレンジ！～
        </div>
        3. 足のつき方 ☆ぼしきゅうからつけているかな？
        <br />
        4. スピードを出す
        ☆なれてきたらチャレンジ！思いっきり走る練習はとてもだいじ！
      </div>
    ),
  },
];

export const skipBasicVideos = [
  {
    link: 'https://www.youtube.com/embed/xSFx-aErnDI',
    title: '上方向スキップ',
    message: (
      <div className={classes.subtitle}>
        （動画1）「上方向スキップ10m」× 2本（目安）を行う！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/fFd2h1hNSRs',
    title: '前方向スキップ',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画2）「前方向スキップ20m」× 3本（目安）を行う！
        </div>
        ☆20mを何歩で行けるかチャレンジしてみよう！ポイントはうでも大きく使うこと！
        <br />
        ☆なれてきたら「前になるべく速く進むスキップ」や「前方向スキップ10m⇒そのまま15mダッシュ」にもチャレンジしてみよう！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/VynfHOQ6CUc',
    title: '前方向に速いスキップ',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画3）「前方向スキップ20m」× 3本（目安）を行う！
        </div>
        ☆「前方向スキップ10m⇒そのまま15mダッシュ」にもチャレンジしてみよう！
      </div>
    ),
  },
];

export const ySprint6Videos = [
  {
    link: 'https://www.youtube.com/embed/aVvoKl6RIgo',
    title: 'ベースポジション3歩ステップ',
    message: (
      <span>
        （動画1）「ベースポジション3歩ステップ10歩」× 3セット行う！
        <br />
        ☆あしを入れかえる際、太鼓をたたくイメージで腕を下に振ってリズムをとるとやりやすいかも！
      </span>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/RgfOs-J-nWk',
    title: 'Y字ポジションもも上げスプリント',
    message: (
      <span>
        （動画2）「Y字ポジションもも上げスプリント20m」× 4本行う！
        <br />
        ☆「あしをからだの前で動かす（入れ替える）」感覚をおぼえよう！
        <br />
        ⇒「Y字ポジションもも上げスプリント10m⇒腕を下ろして15m走る」× 4本行う！
        <br />
        ☆腕を下ろした後は、太鼓をたたくイメージで腕を下に振ると走りやすいかも！
      </span>
    ),
  },
];

export const henkei6thVideos = [
  {
    link: 'https://www.youtube.com/embed/6a4_35tpqq0',
    title: '変形ダッシュ',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画1）「変形ダッシュ15m」× 4本（目安）やってみる！！
        </div>
        スタート練習の予習をしておこう！
      </div>
    ),
  },
];

export const intro6thVideos = [
  {
    link: 'https://www.youtube.com/embed/6of0Mt6Q53s',
    title: '6級イントロ動画',
    message: (
      <div className={classes.subtitle}>6級の方へのメッセージはコチラ！ </div>
    ),
  },
];

////////////////////////////////////////////////////
// TRAC
////////////////////////////////////////////////////

export const surisuri_walk = [
  {
    link: 'https://www.youtube.com/embed/Ptjqs5HnWDU',
    title: 'すりすり走',
    message: (
      <div>
        <div className={classes.subtitle}>すりすり走</div>
        <div className={classes.content}>
          ☆ぜひ動画のまねをしてやってみてね！
        </div>
      </div>
    ),
  },
];

export const lunge_squat = [
  {
    link: 'https://www.youtube.com/embed/tMd1XwVt1E8',
    title: 'ランジスクワット',
    message: (
      <div>
        <div className={classes.subtitle}>ランジスクワット</div>
        <div className={classes.content}>
          ☆ぜひ動画のまねをしてやってみてね！
        </div>
      </div>
    ),
  },
];

export const lunge_walk = [
  {
    link: 'https://www.youtube.com/embed/g5q8WHPklJg',
    title: 'ランジウォーク',
    message: (
      <div>
        <div>
          <div className={classes.subtitle}>
            ランジウォークをやってみる！
            <br />
            ☆昇級テストの挑戦は右下の ➕ボタン⇒昇級に挑戦」から行えます！
          </div>
          <div className={classes.title_important}>昇級のポイント</div>
          <div className={classes.content}>
            1. 良いしせいを作る！
            <br />
            2. 体を安定させる！
            <br />
            3. うでもしっかりあわせる！
          </div>
        </div>
      </div>
    ),
  },
];
