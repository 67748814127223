import React from 'react';
import darumasan_thumb from 'components/MenuConst/thumbnails/darumasan_thumb.jpg';
import threeHighKnee_thumb from 'components/MenuConst/thumbnails/threeHighKnee_thumb.jpg';
import highknee_dash_thumb from 'components/MenuConst/thumbnails/highknee_dash.jpg';
import re_kabeoshi_thumb from 'components/MenuConst/thumbnails/kabeoshi_re.jpg';
import stepQuick_thumb from 'components/MenuConst/thumbnails/stepQuick.jpg';
import intro_18th_thumb from 'components/MenuConst/thumbnails/intro_18th.jpg';
import challenge18th_thumb from 'components/MenuConst/thumbnails/challenge_18th.jpg';
import {
  re_kabeoshiBasicVideos,
  darumasanVideos,
  threeHighKneeVideos,
  highKneeDashVideo,
  stepQuickVideo,
} from './videos';

import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';

import { menuType } from 'pages/PracticePage/type';

export const re_kabeoshi: menuType = {
  grade: 2,
  duration: 10,
  title: 'かべおし復習',
  target: ['足の動き'],
  isInside: 'able',
  menuVariation: ['up'],
  hitWordList: ['かべ', '壁',],
  image: re_kabeoshi_thumb,
  video: re_kabeoshiBasicVideos,
};

export const darumasan: menuType = {
  duration: 10,
  title: 'だるまさんがころんだハイニー',
  target: ['足の動き'],
  menuVariation: ['tech'],
  isInside: 'able',
  grade: 2,
  hitWordList: [
    'はいにー',
    'ハイニー',
    'だるまさん',
    'ダルマ',
    'いえ',
    '家',
    'おうち',
  ],
  image: darumasan_thumb,
  video: darumasanVideos,
};
export const threeHighKnee: menuType = {
  grade: 2,
  duration: 15,
  title: '3歩ハイニー☆昇級テスト',
  target: ['足の動き'],
  isInside: 'able',
  menuVariation: ['tech'],
  hitWordList: ['はいにー', 'ハイニー', '3', 'さんほ', 'いえ', '家', 'おうち'],
  image: threeHighKnee_thumb,
  video: threeHighKneeVideos,
};

export const highKneeDash: menuType = {
  grade: 2,
  duration: 10,
  title: 'たくさん走ろう！',
  target: ['足の動き'],
  isInside: 'unable',
  hitWordList: ['ももあげ', 'だっしゅ', 'ダッシュ', 'ハイニー'],
  menuVariation: ['run'],
  image: highknee_dash_thumb,
  video: highKneeDashVideo,
};

export const stepQuick: menuType = {
  grade: 2,
  duration: 10,
  title: '階段クイック',
  target: ['足の動き'],
  isInside: 'able',
  hitWordList: [' かいだん', '階段'],
  menuVariation: ['family'],
  image: stepQuick_thumb,
  video: stepQuickVideo,
};

export const intro_18th: menuType = {
  duration: 2,
  hitWordList: ['18', '昇級', 'メッセージ', 'めっせーじ', 'きゅう'],
  title: '18級の方へのメッセージ',
  menuVariation: ['levelUp'],
  target: ['足の動き'],
  isInside: 'able',
  grade: 2,
  image: intro_18th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['18'],
      title: 'intro18th',
      message: <span>18級の方へのメッセージはコチラ！ </span>,
    },
  ],
};

export const challenge_18th: menuType = {
  grade: 1,
  duration: 5,
  title: '18級の昇級用メニュー',
  menuVariation: ['levelUp'],
  target: ['足の動き'],
  isInside: 'able',
  hitWordList: [
    '18',
    '昇級',
    'かべ',
    '壁',
    'おし',
    '押し',
    '家',
    'はち',
    'はっきゅう',
  ],
  image: challenge18th_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/ctJ6sFe9rjg',
      title: 'お手本動画3歩ハイニー',
      message: <span>18級の昇級テスト挑戦動画はコチラ！</span>,
    },
  ],
};
