import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';



////
//run
////

export const spring6thVideos = [
  {
    link: 'https://www.youtube.com/embed/1isIaXlEFZw',
    title: 'ベースポジションジャンプ移動',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「ベースポジションジャンプ移動」をたくさんやってみる（目安3～4分）！！
        </div>
        <div className={classes.content}>
          ☆両方の足で前や左右にやってみよう！かっこいい姿勢の移動できるかな？
          <br />
          ☆よろしければ保護者の方は肩を軽く押してあげて下さい！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/QYWtDk9aCuM',
    title: '全力ケンケン前進',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「全力ケンケン」にチャレンジ！
        </div>
        <div className={classes.content}>
          ☆何歩で行けたかな？よかったら毎練習自己ベストに挑戦しよう！
          <br />
          ☆両方の足でやってみよう！負荷が大きいのでやりすぎ注意！（目安は左右3回くらい）！
        </div>
      </div>
    ),
  },
];

export const skaterJump6thVideos = [
  {
    link: 'https://www.youtube.com/embed/VwaoB18_7ms',
    title: '片足物ひろい',
    message: (
      <div className={classes.subtitle}>
        動画1:「片足物ひろいを両足とも10回」× 2セット（目安）を行う！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/xLOrneQXn2s',
    title: 'スケータージャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「スケータージャンプ10回」× 3セット（目安）を行う！
        </div>
        ☆なれてきたらななめ前にとんでみよう！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/4w6vjwk3O3M',
    title: 'スケータージャンプからのダッシュ',
    message: (
      <div className={classes.subtitle}>
        動画3:「スケータージャンプからのダッシュ」にチャレンジしてみよう！
      </div>
    ),
  },
];

export const dash30m6thVideos = [

  {
    link: 'https://www.youtube.com/embed/p94g4g_NmHc',
    title: 'スタートの構えの作り方',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: スタートの構えから30mダッシュをやってみよう！
          <br />
          スタートはぐいぐい走ったうえで、20m過ぎから下にふんで足を体のまえで入れかえて走れるかな？？
          <br />
          うごきをていねいに意識して走るのも、とにかく思いっきり走るのも、どっちもやってみよう！
        </div>
        <div className={classes.content}>
          ☆復習用に動画1:スタートの構えの作り方、動画2:10mまでの走り方、動画3:10mから20mまでの走り方、動画45:20mいこうの走り方をおいてあります！ご活用ください！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/SbSr3_5aXTI',
    title: '1次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「スタートから10mまでの走り方」の解説動画です！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          復習のポイント
        </div>
        1. （スタートの構え）前あしに体重をのせる
        ☆後ろ足のかかとはういているかな？前あしの膝が足首のだいたい真上にあるかな？
        <br />
        2. （スタートの構え）いい姿勢で体をたおす
        ☆横から見て上体と後ろ足がまっすぐかな？目線は3m先を見れているかな？
        <br />
        3. しっかり体を倒す
        ☆6歩以上体をたおして走ろう！あごをひいて、目線は5m先くらい！
        <br />
        ※のぼり坂で10mダッシュの練習をすると意識しやすいかも！近場にのぼり坂のある子は試してみてね！
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ3つができた子はチャレンジ！～
        </div>
        4. 足を前に前に ☆かべおしのときと同じ！
        <br />
        5. 腕ふり ☆前に前にふろう！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/6HdnLynUPT4',
    title: '2次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3:「10mから20mまでの走り方」の解説動画です！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/lFQtglK4VFI',
    title: '中間走の走り方',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画4:「20mいこうの走り方」の解説動画です！
        </div>
      </div>
    ),
  },
];


////
//family
////

export const singleLegTraining6thVideos = [
  {
    link: 'https://www.youtube.com/embed/o4uQYa_S2fk',
    title: '片足回転ジャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「片足回転ジャンプ」を楽しみながらやってみる！
        </div>
        ☆けっこうしんどいトレーニングなので疲れすぎないようほどほどに！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/GzJNMzpECI4',
    title: '片足十字ケンケン',
    message: (
      <div className={classes.subtitle}>
        動画2:「片足十字ケンケン」を両足とも楽しみながらやってみる！
      </div>
    ),
  },
];





