// 10級パッケージの1級
type ckptTypeForGrade_9_10kyu = 'しっかり体を倒す' | '足を前に前に' | '腕ふり';

export const ckptForGrade_9_10kyu: ckptTypeForGrade_9_10kyu[] = [
  'しっかり体を倒す',
  '足を前に前に',
  '腕ふり',
];

export const requirement_9_10kyu: ckptTypeForGrade_9_10kyu[] = [
  'しっかり体を倒す',
  '足を前に前に',
];

export type ckptDetails_9_10 =
  | '六歩以上体を倒せている'
  | '足の流れ'
  | 'リーチアウト'
  | '足の上がり'
  | 'うでを前に前に振る'
  //採点用
  | '足を壁押しのように動かす';

type allCheckpoints_9_10kyu_type = {
  [key in ckptTypeForGrade_9_10kyu]: ckptDetails_9_10[];
};

export const allCheckPoints_9_10kyu: allCheckpoints_9_10kyu_type = {
  しっかり体を倒す: ['六歩以上体を倒せている'],
  足を前に前に: ['足の流れ', '足の上がり', 'リーチアウト'],
  腕ふり: ['うでを前に前に振る'],
};

export const borderLine_9_10kyu: allCheckpoints_9_10kyu_type = {
  しっかり体を倒す: ['六歩以上体を倒せている'],
  足を前に前に: ['足の流れ', '足の上がり'],
  腕ふり: ['うでを前に前に振る'],
};
