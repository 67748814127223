import React, { FC, memo } from 'react';
import classes from './onboardingCarousel.module.css';
import joy_boy from 'static/materials/joy_boy.png';

export const NinthView: FC = memo(() => {
  return (
    <>
      <div className={classes.blackBorder}>さあ、IDATENの世界を楽しもう！</div>
      <div className={classes.joyboyWrapper}>
        <img src={joy_boy} alt=""></img>
      </div>
    </>
  );
});
