import {
  gradeGoalDict,
  ScoreEditType, //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { RunnerType } from 'adapters/repositories/typeDefinition';

export function crtComment1st(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  const setence1 = crt_line1(score, yobina);
  const sentence2 = crt_line2(runnerInfo, score, yobina);
  const sentence3 = crt_line3(score, yobina);
  return (
    setence1 +
    '<<paragraph>>' +
    sentence2 +
    '<<paragraph>>' +
    sentence3 +
    '<<paragraph>>'
  );
}

function crt_line1(score: ScoreEditType, yobina: string) {
  const head1 =
    yobina +
    `、ついにこの時がやってきましたね。ここに合格すれば走りの基本全て身につけたことになります！`;
  const head2 = `さて、今回の動画は1級の目標である「${gradeGoalDict['1']}」`;
  let evaluation =
    score.eval === '◯合格'
      ? 'が大変良くできています！おめでとうございます！'
      : 'に少しだけ課題があります。';
  return head1 + head2 + evaluation;
}

function crt_line2(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  console.log(score);
  return commentLogic(score, yobina);
}

const commentLogic = (score: ScoreEditType, yobina: string) => {
  if (score['六歩以上体を倒せている'] === '×:すぐ起きてしまっている') {
    return `${yobina}の伸びしろポイントとしては「もう少しだけ前傾する区間を長くする」とよりスピードに乗りやすくなりますよ！「スタートして6歩目までは5m先の地面を見る」という意識で走ってみましょう！あるいは倒れ込みダッシュを復習してみてもよいですね！体を前に倒してグイグイ加速できるのはいわばボーナスタイムです（マリオカートのスターの状態みたいなものです）、ここを長い距離とれるようになるとスタートで回りと差をつけられるようになりますよ！`;
  }

  if (score['足の流れ'] === '×:後ろで大きく回っている') {
    return `${yobina}伸びしろポイントとしては「あしが体の後ろで大きく回ってしまっている」ことです！6歩目までは「足（膝）を前に前にもってくる」ことを意識してみましょう！イメージは砂の公園で後ろに砂が飛ばないように走る、あるいは砂浜ですべらない様に走るようなイメージです！また連続壁押しも是非復習してみて下さいね！ `;
  }

  if (
    score['足を壁押しのように動かす'] ===
    '×:無理に体の前で漕ぐようにうごかしている'
  ) {
    return `${yobina}伸びしろポイントとしては「無理にからだの前で回すような動き」をしていまっていることです！スタートでは自転車の様に足をまわすというよりは、壁押しの時のように足を直線的に動かすとスピードが出やすいです！お手本動画を参考にしながら、6歩目までは「足（膝）を前に前にもってくる」ことだけを意識してみましょう！`;
  }

  if (score['足の上がり'] === '×:上がっていない') {
    return `${yobina}の伸びしろポイントとしては「より足を前に前に大きく持ってこれる」とよいです！6歩目までは「足（膝）を前に前にもってくる」ことを意識してみましょう！壁押しのときのような太ももの上がった姿勢でほんの一瞬タメが作れたら完璧です（動画をコマ送りにしたら毎歩1コマしっかり足の上がったかっこいい走りがうつっているようなイメージです）！これが出来るとグングン前に進むようになりますよ！`;
  }

  if (score['うでを前に前に振る'] === '×:振れていない') {
    return `${yobina}にさらなる伸びしろポイントをお伝えすると「腕を前に前に大きく振れる」とよいです！実はうで振りって、リラックスして大きく振ったほうが一歩一歩が大きくなるんです。試しにおすすめ練習のスキップに取り組んでみて、腕をリラックスさせたほうが遠くに飛べることをぜひ実感してみてください！`;
  }

  if (score['リーチアウト'] === '×:足が開いている') {
    return `${yobina}に陸上選手に教えるような超上級者向け伸びしろポイントをお伝えすると「足が上がっているときに膝がひらかない」ように出来るとさらによいです！6歩目くらいまでは目安として一番足が上がったときの膝の角度が90度くらいになるとよいです！ただいきなり走る中で意識するのはかなりむずかしいので、まずは連続壁押しを「一番足が上がったときの膝の角度が90度」を意識して練習してみましょう！ `;
  }

  return 'これはもう文句なしですね、すばらしいです！';
};

function crt_line3(score: ScoreEditType, yobina: string) {
  let evaluation =
    score.eval === '◯合格'
      ? `これにて今回のかけっこ教室は修了となります。${yobina}はここまででかけっこの基本を一通りマスターしました！ここまで本当によく頑張りましたね！`
      : '####いい具合になぐさめる####';
  return evaluation;
}
