import React, { FC } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';

type TransitionProps = Omit<SlideProps, 'direction'>;

const TransitionRight = (props: TransitionProps) => {
  return <Slide {...props} direction="up" timeout={{ enter: 100 }} />;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type alertStatusType = {
  open: boolean;
  status: 'OK' | 'ERROR' | 'default';
};

type Props = {
  alertStatus: alertStatusType;
  setAlertStatus: React.Dispatch<React.SetStateAction<alertStatusType>>;
};

export const UploadNotification: FC<Props> = ({
  alertStatus,
  setAlertStatus,
}) => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertStatus({ open: false, status: 'default' });
  };

  return (
    <Snackbar
      open={alertStatus.open}
      autoHideDuration={2000}
      onClose={handleClose}
      TransitionComponent={TransitionRight}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <div>
        {/* {alertStatus.status === 'OK' && (
          <Alert
            onClose={handleClose}
            severity="success"
            style={{ backgroundColor: '#32cd32' }}
          >
            <span style={{ fontSize: '12pt', fontWeight: 'bold' }}>
              ほうこく完了！{' '}
            </span>
          </Alert>
        )} */}
        {alertStatus.status === 'ERROR' && (
          <Alert onClose={handleClose} severity="warning">
            アップロードに失敗しました。{' '}
          </Alert>
        )}
      </div>
    </Snackbar>
  );
};
