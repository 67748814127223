import React from 'react';
import inclineDash_thumb from 'components/MenuConst/thumbnails/inclination_dash.png';
import kabeoshi_thumb from 'components/MenuConst/thumbnails/kabeoshi.png';
import dash_10m_thumb from 'components/MenuConst/thumbnails/dash_10m.jpg';
import henkei_thumb from 'components/MenuConst/thumbnails/henkeidash.jpg';
import intro_3rd_thumb from 'components/MenuConst/thumbnails/intro_3rd.jpg';
import challenge3rd_thumb from 'components/MenuConst/thumbnails/challenge_3rd.jpg';
import { howToStartVideos, kabeoshiReVideos } from './videos';
import { menuType } from 'pages/PracticePage/type';

export const howtostart: menuType = {
  duration: 10,
  grade: 7,
  title: '10mダッシュ#昇級テスト',
  image: dash_10m_thumb,
  video: howToStartVideos,
  isInside: 'unable',
  target: ['スタート', '全力走'],
  menuVariation: ['tech'],
};

export const kabeoshiBasic_re = {
  grade: 7,
  duration: 15,
  title: '壁押し復習☆家でも可',
  image: kabeoshi_thumb,
  video: kabeoshiReVideos,
  menuVariation: ['up'],
};

export const challenge_3rd = {
  duration: 5,
  hitWordList: ['3', '昇級', 'さん'],
  title: '3級の昇級用メニュー',
  grade: 7,
  image: challenge3rd_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/1BqgOS35y-M',
      title: '3級の昇級用メニュー',
      message: <span>3級の昇級テスト挑戦動画はコチラ！</span>,
    },
  ],
};

export const intro_3rd = {
  duration: 2,
  hitWordList: ['3', '昇級', 'メッセージ', 'めっせーじ', 'さんきゅう'],
  title: '3級の方へのメッセージ',
  grade: 7,
  image: intro_3rd_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/LP45AVeINE8',
      title: '3級イントロ動画',
      message: <span>3級の方へのメッセージはコチラ！ </span>,
    },
  ],
  menu: (
    <div>
      <span style={{ textAlign: 'center' }}>練習C</span>

      <ul style={{ fontSize: '10pt', textAlign: 'left' }}>
        <li>動画1/5を見て、Y字姿勢の作り方を確認</li>
        <li>
          動画2/5～4/5を見て、「Y字しせいで20m走る」を、特に注意ポイント①を気をつけながら、(5本目安)行う！(タオルをご用意できる方は動画5/5を参考に、タオルを持った状態で行う！)
        </li>
      </ul>
    </div>
  ),
};
