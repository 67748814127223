import React, { FC, useState, memo } from 'react';
import { Button } from '@material-ui/core';
import classes from './coachComment.module.css';

type Props = {
  comment: string;
};

export const CommentBody: FC<Props> = memo(({ comment }) => {
  const [isCommentOpen, setIsCommentOpen] = useState(false);

  return (
    <>
      {!!comment ? (
        <>
          {!isCommentOpen && (
            <div onClick={() => setIsCommentOpen(true)}>
              {comment.substring(0, 14)}
              ...
              <div style={{ color: '#ff69b4', textAlign: 'center' }}>
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  //   onClick={() => setIsCommentOpen(true)}
                >
                  タップして続きを読む
                </Button>
              </div>
            </div>
          )}
          {!!isCommentOpen && (
            <div onClick={() => setIsCommentOpen(false)}>
              {comment}

              <div style={{ color: '#ff69b4', textAlign: 'center' }}>
                <Button variant="text" color="primary" size="small">
                  閉じる
                </Button>
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  );
});
