import React, { FC } from 'react';
import { Card } from '@material-ui/core';
import { convertRunnerGrade } from 'components/constants';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { decideSampleVideoLink } from 'components/MenuConst/VideoLinks/sampleVideoLinks';

type Props = {
  runner: RunnerType;
};

export const ManualViewer: FC<Props> = ({ runner }) => {
  const grade = convertRunnerGrade(runner);
  const sampleVideoLink = decideSampleVideoLink(runner);

  return (
    <>
      <span>
        {grade}
        級で送る動画（送る前に確認！）
      </span>
      <Card
        style={{
          padding: '5px',
          backgroundColor: '#fff',
          width: '99%',
          height: `${window.parent.screen.width * 0.55}px`,
        }}
      >
        <iframe
          width="95%"
          height="95%"
          src={sampleVideoLink + '?rel=0&modestbranding=1'}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ marginBottom: '8px' }}
        ></iframe>
      </Card>
    </>
  );
};
