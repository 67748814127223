import {
  ScoreEditType, //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { RunnerType } from 'adapters/repositories/typeDefinition';
import { gradeTargetDict_20 } from 'components/MenuConst/GoalsForEachGrade/targetsForEachGrade';

export function crtComment15th_20(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  const setence1 = crt_line1(score, yobina);
  const sentence2 = crt_line2(runnerInfo, score, yobina);
  const sentence3 = crt_line3(score);
  return (
    setence1 +
    '<<paragraph>>' +
    sentence2 +
    '<<paragraph>>' +
    sentence3 +
    '<<paragraph>>'
  );
}

function crt_line1(score: ScoreEditType, yobina: string) {
  const head1 = yobina + '、ポンポン走りには少しずつなれてきたかな？';
  const head2 = `今回の動画は15級の目標である「${gradeTargetDict_20['15']}」`;
  let evaluation =
    score.eval === '◯合格'
      ? 'が大変良くできています！14級昇級おめでとうございます！'
      : 'に少しだけ課題があります。';
  return head1 + head2 + evaluation;
}

function crt_line2(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  console.log(score);
  return commentLogic_15th(score, yobina);
}

export const commentLogic_15th = (score: ScoreEditType, yobina: string) => {
  if (score['前後傾'] === '×:前傾しすぎ') {
    return `${yobina}の一番の伸びしろポイントとして「体を少し前に倒しすぎている」ことがあります。体を前に倒すことは大事ですが、「こぶし半個分」というのを意識して、もう少しだけ体を起こすと、より走りやすくなると思います！目安として15m先の地面を見ながら走ってみましょう！これが出来ると足も体の前に返ってきやすくなり「あしがあがっている」姿勢が作りやすくなりますよ！いろいろならって混乱することもあるかと思いますが、とにかくすべての基礎は姿勢です、丁寧に意識して練習してみてくださいね！`;
  }

  if (score['腰が落ちていない'] === '×:落ちている（膝のつぶれ）') {
    return `まず${yobina}のよかった点としては、「腰をおらずに」走ることが出来ていますね！そんな${yobina}の伸びしろポイントとして「もっと背を高くして」走れるとよいですね！走り出す前の足踏みの時から走っている間まで、「キーホルダーになった気分で頭のてっぺんを上から紐でつられている」ようなイメージを持ってみて下さい！いい姿勢から背伸びした腰やお尻が高ーい位置のままポンポン走れるようになりますよ！`;
  }

  if (score['足の上がり'] === '×:足が上がっていない') {
    if (score['後ろに蹴っていない'] === '×:蹴ってしまっている') {
      return `まず${yobina}のよかった点としては、「よい姿勢で」走ることが出来ていますね！そんな${yobina}の伸びしろポイントとして「後ろに地面を蹴って走っている」ことがあります。速く走りたいと思うとつい地面を後ろに蹴ってしまいがちですが、それをしてしまうと足が前で回らなくなってしまいます！まずは「足が体の後ろにある時、足の裏が空を向かないように」あるいは「砂の公園で後ろに砂が飛ばないように走る」イメージしてみましょう！慣れてきたら「走っているときしっかり足が上がっているかな？」というのも意識できるととてもよいです！一気に楽に体が前に進むようになりますよ！`;
    }

    return `まず${yobina}のよかった点としては、「腰をおらずに背の高いまま」走ることが出来ていますね！そんな${yobina}の伸びしろポイントとして「足のあがりが少し小さい」ことがあります。先生のお手本を見ると分かりますが、走るときは思った以上にダイナミックに足を動かします！はじめはなかなか慣れないかもしれませんが、壁押しや3歩ハイニーなどを復習して、足が上がっている状態に慣れてみましょう！その上で「走っているときしっかり足が上がっているかな？」というのを意識して走ってみましょう！一気に楽に体が前に進むようになりますよ！`;
  }

  if (score['前後傾'] === '×:後傾しすぎ') {
    return `まず${yobina}のよかった点として、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！3歩ハイニーの成果が出ていて素晴らしいですよ！そんな${yobina}の伸びしろポイントとして「からだが少し後ろに傾いてしまっている」ことがあります。足を頑張ってあげようと思うと、どうしても上半身が後ろにいってしまいますが、20級で習った「こぶし半個分だけ前に倒す」を意識しましょう（目安として15m先の地面を見ながら走ってみるとよいですよ）！体が新幹線のように前にスーッと進むようになりますよ！`;
  }

  if (score['下に踏む'] === '×:体の遠くに接地') {
    return `まず${yobina}のよかった点として、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！3歩ハイニーの成果が出ていて素晴らしいですよ！姿勢もばっちりです！そんな${yobina}に上級者向けの伸びしろポイントをお伝えすると「足を体の遠くについてしまっている」ことがあります。足を腰から遠く前に着いてしまうと1歩1歩ブレーキがかかってしまいます！上がっている足を「真下にポンポンおとして走る」ことを意識してみましょう！ベースポジションウォークや連続ハイニーなどからはじめて感覚をつかんでみて下さい！その際、腕も下にポンポンふるともっと意識しやすくなりますよ！`;
  }

  if (score['接地様式'] === '×:かかとからついている') {
    return `まず${yobina}のよかった点としては、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！そんな${yobina}に一番の伸びしろポイントをお伝えすると「足をかかとからついてしまっている」ことがあります。トランポリンジャンプやその場足踏みでもかかとからつくとポンポンしづらいですよね？目安として「母趾球（ぼしきゅう）」あたりから地面につけるとよいです！ また足を腰から遠く前に着いてしまうと1歩1歩ブレーキがかかってしまいます！上がっている足を「真下にポンポンおとして走る」ことを意識してみましょう！「上から空き缶をつぶすように地面を踏む」ようなイメージです（つぶす・踏むというとつい地団駄の様になりがちですが、腰は高ーく保ったままですよ）！`;
  }

  if (score['接地様式'] === '×:つま先だけでついている') {
    return `まず${yobina}のよかった点としては、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！そんな${yobina}の一番の伸びしろポイントとして「足をつま先からつきすぎている」ことがあります。目安として「母趾球（ぼしきゅう）」あたりから地面につけるとよいです！これに関してはべースポジションの時のように「つま先をあげる」というのをできるようになると一気にうまくなる子が多いです！「足が上がっているときはつま先を上げよう」、というのを意識してみましょう！その上で上がっている足を「真下にポンポンおとして走る」ことが出来れば、自然と「母趾球（ぼしきゅう）」あたりから地面につけると思います！まずはベースポジションウォークなどで感覚をつかんでみて下さい！`;
  }

  if (score['うで振り'] === '×:悪い') {
    return `まず${yobina}のよかった点としては、「いい姿勢であしを前で回して」走ることが出来ていますね！よく練習を頑張ってくれたのが伝わります！そんな${yobina}の伸びしろポイントとしてずばり「腕振り」です！肩の力を抜いて下にポンポン振るようにして走ってみましょう！腕を正しく動かせると足もより楽に前で回せるようになりますし、体もポンポン楽に進むようになりますよ！腕振りはおうちでも取り組みやすい練習ですので、ペットボトルうでふりなどぜひ習慣にしてみて下さい！`;
  }

  if (score['スピードが出せている'] === '×:遅すぎる') {
    return `まず${yobina}のよかった点としては、「いい姿勢で脚を前で回して」走ることが出来ていますね！よく練習を頑張ってくれたのが伝わります！15級までばっちり頑張ってくれた${yobina}には、「本気で走ってもいいフォームで走れるか！？」にトライしていただければと思います！①保護者様が横で手拍子をしてリズムをとる（初めは1秒に4歩弱くらいのリズムで、なれてきたら1秒に4.5歩くらいまで行けるとすごいです！）②〇〇は手拍子に接地と腕振りを合わせてポンポン走るというのにトライしてみて下さい！ポイントは「上がっている足を上からポンポン足を落とす」ことです！`;
  }

  if (score['前後傾'] === '◯:やや前傾') {
    return `まず${yobina}のよかった点としては、ばっちり「空中であしを入れかえる」ことができていますね！よく練習してくれたのが伝わります！そんな${yobina}に上級者向けの伸びしろポイントをお伝えすると、ずばり「少し体が前傾しすぎている」ことです！ 体を前に倒しすぎてしまうと足が上がりにくくなってしまうので「良い姿勢」を作れるようにY字スプリントなど思い出してみてください！`;
  }

  if (score['前後傾'] === '◯:あご上がり') {
    return `まず${yobina}のよかった点としては、ばっちり「空中であしを入れかえる」ことができていますね！よく練習してくれたのが伝わります！そんな${yobina}に上級者向けの伸びしろポイントをお伝えすると、ずばり「あごが上がってしまっていること」です！あごが上がってしまうとどうしても良い姿勢を作りづらくなってしまいます。あごは少し引いて、15m下の地面を見るように意識してみましょう！`;
  }
  if (score['足の上がり'] === '◯:もう少し上がるとベスト') {
    return `今回の動画は基本的なことは全てマスターできていてとっても良い走りですね！その上で${yobina}に上級者向けの伸びしろポイントをお伝えすると、「もう少しだけ足を上げた方が弾むように走れる」ということがあります！「壁押し」の練習を思い出して、走りの中でも同じくらい足を上げるような意識をしてみましょう！`;
  }

  return 'これはもう文句なしの満点合格ですね、すばらしいです！ここまでのフォームが身に付いたら、これからは体の「キレ」が大事になってきます！スキップやケンケンで「どうすれば一歩一歩が大きくなるかな？」というのを考えながら練習してみましょう！';
};

function crt_line3(score: ScoreEditType) {
  let evaluation =
    score.eval === '◯合格'
      ? `14級ではふたたび今までの復習をしてもらいます！ここを乗り切ったら13級からはスタートの練習です、最後のひと踏んばり頑張りましょう！`
      : '####いい具合になぐさめる####';
  return evaluation;
}
