import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';

////
//up
////

//片足系はgrade_6から

export const re_towelHighKneeVideos = [
  {
    link: 'https://www.youtube.com/embed/WqPD2RWdb70',
    title: 'ペットボトル腕振り',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「ペットボトル腕振り」を20回 × 3セット行う！
        </div>
        <div className={classes.content}>
          ☆動画では500mlのペットボトルに1/3～1/2程度水をいれています！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/7VW4zxcKa5Q',
    title: 'タオルもも上げ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「タオルももあげ20歩」× 3本（目安）を行う！
        </div>
        <div className={classes.content}>
          ☆なれてきたら前に進んだり走ったりしてみよう！
          <br />
          ☆うでを振ることをわすれずに！
        </div>
      </div>
    ),
  },
];

////
//tech
////

export const threeHighKneeVideos = [
  {
    link: 'https://www.youtube.com/embed/youybYscJHU',
    title: 'だるまさんが転んだハイニー',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: 「だるまさんが転んだハイニー」を楽しみながら練習！
        </div>
        <div className={classes.content}>
          ☆ピタッととまれるようになったらゲームクリア！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！※足音がなりますので、気になる方はご注意ください！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/zayX9cfq0O0',
    title: '3歩ハイニー',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「3歩ハイニー」を楽しみながら練習！
          <br />
          ⇒ピタッととまれるようになったらゲームクリア！
        </div>
        <div className={classes.title_important}>ふくしゅうのポイント</div>
        <div className={classes.content}>
          1. よい姿勢 ☆おしりはたか～い位置にあるかな？
          <br />
          2. ハイニーができている ☆ふとももがちゃんとあがっているかな？
          <br />
          3. ベースポジションでとまる
          ☆足の上がった姿勢でピタッと1秒以上とまろう！
          <br />
          <br />
          ☆よろしければ保護者様が「1・2・ピタッ！（2秒あけて）1・2・ピタッ！・・・」といった感じで声をかけてあげて下さい！やりやすくなるし練習が楽しくなりますよ！
        </div>
      </div>
    ),
  },
];

export const threeStepVideos = [
  {
    link: 'https://www.youtube.com/embed/1isIaXlEFZw',
    title: 'ベースポジションジャンプ移動',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「ベースポジションジャンプ移動」をたくさんやってみる（目安3～5分）！！
        </div>
        ☆両方の足でやってみよう！かっこいい姿勢の移動できるかな？
        <br />
        ☆よろしければ保護者の方は肩を軽く押してあげて下さい！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/9cuO2W7C18w',
    title: 'ベースポジション踏みかえ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「ベースポジションジャンプ踏みかえ」を特訓（目安3分）！！
        </div>
        ☆「空中であしを入れかえる！」が連続10回出来たら合格！
        <br />
        ☆あしを入れかえる際、太鼓をたたくイメージで腕を振ってリズムをとるとやりやすいかも！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/aVvoKl6RIgo',
    title: 'ベースポジション3歩ステップ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3:「ベースポジション3歩ステップ」を楽しみながら特訓（目安5～8分）！
        </div>
        ⇒「体をまっすぐ立てて、空中であしを入れかえる！」が連続6回出来るようなったらゲームクリア！昇級テストにチャレンジしてみてね！
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本（あしのいれかえが5歩程度うつっている動画）を送信！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          昇級のポイント
        </div>
        1. 頭から足までまっすぐ
        ☆これがとにかく一番大事！おしりはたか～い位置にあるかな？1歩1歩で前に進もうとしすぎないこと！
        <br />
        2. （1.2のとき）ベースポジションで進めている
        ☆ふとももがちゃんとあがっているかな？
        <br />
        3. （3のとき）あしを体の前で入れかえる
        ☆腕も下に振ってリズムをとってみよう！
      </div>
    ),
  },
];


////
//run
////

export const threeStepAndDashVideos = [

  {
    link: 'https://www.youtube.com/embed/aVvoKl6RIgo',
    title: 'ベースポジション3歩ステップ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「ベースポジション3歩ステップ10m⇒そのまま15mダッシュ」を3～5本おこなう！！
        </div>
        ☆ダッシュの時も体の前で足をスパンスパンいれかえられるかな？？
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/Yi3w66j82jo',
    title: '連続ハイニー前進',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2: 「連続ハイニー前進10m⇒そのまま15mダッシュ」を3本おこなう！！
        </div>
        <div className={classes.content}>
          ☆連続ハイニーの時のいい姿勢や足の動かし方のままダッシュしてみよう！
        </div>
      </div>
    ),
  },
];


export const dash30m8thVideos = [

  {
    link: 'https://www.youtube.com/embed/p94g4g_NmHc',
    title: 'スタートの構えの作り方',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: スタートの構えから30mダッシュをやってみよう！
          <br />
          スタートはぐいぐい走ったうえで、20m過ぎから3ステップの時のように体の前で足をスパンスパンいれかえて走れるかな？？
        </div>
        <div className={classes.content}>
          ☆復習用に動画1:スタートの構えの作り方、動画2:10mまでの走り方、動画3:10mから20mまでの走り方、動画45:20mいこうの走り方をおいてあります！ご活用ください！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/SbSr3_5aXTI',
    title: '1次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「スタートから10mまでの走り方」の解説動画です！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          復習のポイント
        </div>
        1. （スタートの構え）前あしに体重をのせる
        ☆後ろ足のかかとはういているかな？前あしの膝が足首のだいたい真上にあるかな？
        <br />
        2. （スタートの構え）いい姿勢で体をたおす
        ☆横から見て上体と後ろ足がまっすぐかな？目線は3m先を見れているかな？
        <br />
        3. しっかり体を倒す
        ☆6歩以上体をたおして走ろう！あごをひいて、目線は5m先くらい！
        <br />
        ※のぼり坂で10mダッシュの練習をすると意識しやすいかも！近場にのぼり坂のある子は試してみてね！
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ3つができた子はチャレンジ！～
        </div>
        4. 足を前に前に ☆かべおしのときと同じ！
        <br />
        5. 腕ふり ☆前に前にふろう！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/6HdnLynUPT4',
    title: '2次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3:「10mから20mまでの走り方」の解説動画です！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/lFQtglK4VFI',
    title: '中間走の走り方',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画4:「20mいこうの走り方」の解説動画です！
        </div>
      </div>
    ),
  },
];

////
//family
////

//過去のstarterをそのまま使える

