// 10級パッケージの10級
type ckptTypeForGrade_10_20kyu =
  | '体がまっすぐ'
  | 'ポンポン走る'
  | 'スピードを出す';

export const ckptForGrade_10_20kyu: ckptTypeForGrade_10_20kyu[] = [
  '体がまっすぐ',
  'ポンポン走る',
  'スピードを出す',
];

export const requirement_10_20kyu: ckptTypeForGrade_10_20kyu[] = [
  '体がまっすぐ',
  'ポンポン走る',
];
