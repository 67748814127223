import {
  gradeGoalDict,
  ScoreEditType, //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { RunnerType } from 'adapters/repositories/typeDefinition';

export function crtComment3rd(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  const setence1 = crt_line1(score, yobina);
  const sentence2 = crt_line2(runnerInfo, score, yobina);
  const sentence3 = crt_line3(score, yobina);
  return (
    setence1 +
    '<<paragraph>>' +
    sentence2 +
    '<<paragraph>>' +
    sentence3 +
    '<<paragraph>>'
  );
}

function crt_line1(score: ScoreEditType, yobina: string) {
  const head1 = yobina + '、初めてのスタート練習はどうでしたか？';
  const head2 = `今回の動画は3級の目標である「${gradeGoalDict['3']}」`;
  let evaluation =
    score.eval === '◯合格'
      ? 'が大変良くできています！2級昇級おめでとうございます！'
      : 'に少しだけ課題があります。';
  return head1 + head2 + evaluation;
}

function crt_line2(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  console.log(score);
  return commentLogic(score, yobina);
}

const commentLogic = (score: ScoreEditType, yobina: string) => {
  if (score['構えで前脚に体重がのっている'] === '×:足首が膝より前') {
    return `${yobina}の一番の伸びしろポイントとしては構えのとき「もっと前脚に体重を乗せられる」とよいです！スタートの構えをとったときに、後ろ足は地面に軽くつける程度にしましょう！おすすめの練習としては、スタートの構えをとったときに保護者様が腰を両手でつかんで真後ろに軽く引っ張ってあげてみてください（椅子を引くくらいの力加減で大丈夫です）！その時後ろによろけてしまう子は前脚に体重を載せれていない証拠です！引っ張られる力に耐えやすい姿勢を探してみて下さい！`;
  }

  if (score['構えで上体角度が45度程度'] === '×:倒しすぎ') {
    return `${yobina}の一番の伸びしろポイントとしては構えのとき「上体を倒しすぎてしまっている」ことです！スタートの構えをとったときに、上体は目安45度くらいまで起こしましょう！そのためには①あごを少し引く⇒②そのまま3mくらい先の地面を見る、というのを意識してみて下さい！後ろ足と上体がまっすぐ一本の棒のようになっていたらいい感じです（腰で折れ曲がらないように注意）！正しい姿勢で構えられるとスタートはとても走りやすくなりますよ！`;
  }

  if (score['構えで上体角度が45度程度'] === '×:起きすぎ') {
    return `${yobina}の一番の伸びしろポイントとしては構えのとき「上体が起きすぎてしまっている」ことです！スタートの構えをとったときに、上体は目安45度くらい倒れるようにしましょう！そのためには①あごが上がらないようにする⇒②そのまま3mくらい先の地面を見る、というのを意識してみて下さい！後ろ足と上体がまっすぐ一本の棒のようになっていたらいい感じです（腰が反らないように注意）！正しい姿勢で構えられるとスタートはとても走りやすくなりますよ！`;
  }

  if (score['構えで横から見て上体と後ろ足がまっすぐ'] === '×:腰が引けている') {
    return `${yobina}の一番の伸びしろポイントとしては構えのとき「腰が引けてしまっている」ことです！上体のたおし具合はばっちりなので、あとは後ろ足と上体がまっすぐ一本の棒のようになっていたらばっちりです！${yobina}の場合はもう少し後ろ足を後ろについてみてください！正しい姿勢で構えられるとスタートはとても走りやすくなりますよ！`;
  }

  if (score['構えで横から見て上体と後ろ足がまっすぐ'] === '×:腰が反っている') {
    return `${yobina}の一番の伸びしろポイントとしては構えのとき「腰が反ってしまっている」ことです！
        上体のたおし具合はばっちりなので、あとは後ろ足と上体がまっすぐ一本の棒のようになっていたらばっちりです！${yobina}の場合は少し後ろ足を後ろにつきすぎているので、もう少し近くについてみましょう！正しい姿勢で構えられるとスタートはとても走りやすくなりますよ！`;
  }

  if (score['走りで6歩以上体を倒せている'] === '×:すぐ起きてしまっている') {
    return `${yobina}の伸びしろポイントとしては「もう少しだけ前傾する区間を長くする」とよりスピードに乗りやすくなりますよ！「スタートして6歩目までは5m先の地面を見る」という意識で走ってみましょう！あるいは倒れ込みダッシュを復習してみてもよいですね！体を前に倒してグイグイ加速できるのはいわばボーナスタイムです（マリオカートのスターの状態みたいなものです）、ここを長い距離とれるようになるとスタートで回りと差をつけられるようになりますよ！`;
  }

  if (score['足を前に前に持ってくる'] === '×:出来ていない') {
    return `${yobina}のさらなる伸びしろポイントとしては「より足を前に前に大きく持ってこれる」とよいです！これはまさに2級・1級の練習がうってつけなので頑張って取り組んでみましょう！これが出来るとグイグイ走れるうえ、体もより前に倒しやすくなりますよ！ `;
  }

  if (score['うでを前に前に振る'] === '×:振れていない') {
    return `${yobina}のさらなる伸びしろポイントとしては「腕を前に前に大きく振れる」とよいです！実はうで振りって、リラックスして大きく振ったほうが一歩一歩が大きくなるんです。試しにおすすめ練習のスキップに取り組んでみて、腕をリラックスさせたほうが遠くに飛べることをぜひ実感してみてください！`;
  }

  return 'これはもう文句なしですね、すばらしいです！';
};

function crt_line3(score: ScoreEditType, yobina: string) {
  let evaluation =
    score.eval === '◯合格'
      ? 'それでは2級からは足の動かし方を練習していきます！がんばってみてください！'
      : '####いい具合になぐさめる####';
  return evaluation;
}
