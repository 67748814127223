import {
  ScoreEditType, //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { RunnerType } from 'adapters/repositories/typeDefinition';
import { gradeTargetDict_20 } from 'components/MenuConst/GoalsForEachGrade/targetsForEachGrade';

export function crtComment16th_20(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  const setence1 = crt_line1(score, yobina);
  const sentence2 = crt_line2(runnerInfo, score, yobina);
  const sentence3 = crt_line3(score);
  return (
    setence1 +
    '<<paragraph>>' +
    sentence2 +
    '<<paragraph>>' +
    sentence3 +
    '<<paragraph>>'
  );
}

function crt_line1(score: ScoreEditType, yobina: string) {
  const head1 = yobina + '、そろそろ練習にも慣れてきたかな？';
  const head2 = `今回の動画は16級の目標である「${gradeTargetDict_20['16']}」`;
  let evaluation =
    score.eval === '◯合格'
      ? 'が大変良くできています！15級昇級おめでとうございます！'
      : 'に少しだけ課題があります。';
  return head1 + head2 + evaluation;
}

function crt_line2(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  console.log(score);
  return commentLogic_16th(score, yobina);
}

export const commentLogic_16th = (score: ScoreEditType, yobina: string) => {
  if (score['前後傾'] === '×:前傾しすぎ') {
    return `${yobina}の一番の伸びしろポイントとして「体を少し前に倒しすぎている」ことがあります。走る前にいい姿勢を作って背伸びしたら、あとは体をたおすのはこぶし半個分だけでだいじょうぶです！あごを少し引いて15m先の地面を見てみましょう！ そこができるとバランスがとりやすくなり足も高くキープしやすくなりますよ！Y字スプリントやY字ももあげスプリントをぜひ復習してみてください！`;
  }

  if (score['足の上がり'] === '×:足が上がっていない') {
    if (score['後ろに蹴っていない'] === '×:蹴ってしまっている') {
      return `まず${yobina}のよかった点としては、「体を前に倒しすぎず」走ることが出来ていますね！そんな${yobina}の伸びしろポイントとして「後ろに地面を蹴って走っている」ことがあります。ハイニーで大事なのは足を体の前でスパンスパンいれかえることです！「砂の公園でも後ろに砂が飛ばないように走る」ようイメージしてみましょう！足が楽に前に戻ってくるようになってハイニーのポイントである「下にポンポン踏む」が意識しやすくなり、一気に楽に体が前に進むようになりますよ！`;
    }

    return `まず${yobina}のよかった点としては、「体を前に倒しすぎず」走ることが出来ていますね！そんな${yobina}の伸びしろポイントとして「足のあがりが少し小さい」ことがあります。先生のお手本を見ると分かりますが、走るときは思った以上にダイナミックに足を動かします！習得の流れとしてはまずはその場で行える壁押しや連続ハイニーから⇒慣れてきたら少しずつ前に進む動きを足していく中でもハイニーの動きができるかチャレンジ、という形で進めてみてください！その際「腰をたかーくたもつ」ことを忘れずに！難しい課題ですが、ここでしっかり頑張って身につけれると一気に楽に体が前に進むようになりますよ！`;
  }

  if (score['腰が高い'] === '×:落ちている（膝のつぶれ）/ 引けている') {
    return `${yobina}、ハイニーの足の動きにだいぶ慣れてきましたね！そんな${yobina}の一番の伸びしろポイントとしては「少し腰が落ちてしまっている」ことがあります。腰をたか～い位置に保つような意識をしてみましょう！イメージとしては「キーホルダーになった気分で頭のてっぺんを上から紐でつられている」ような感じです（背伸びする感じです）！いいリズムで軽やかにポンポン走れるようになりますよ！`;
  }

  if (score['前後傾'] === '×:後傾しすぎ') {
    return `まず${yobina}のよかった点としては、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！そんな${yobina}の伸びしろポイントとして「からだが少し後ろにのけぞってしまっている」ことがあります。足を頑張ってあげようと頑張りすぎると、どうしても上半身が後ろにいってしまいがちです。${yobina}の場合「あごを引いて15m先の地面を見ながら走る」＋「足は下にポンポン踏む/うでも下にポンポンふる」といった意識で走ってみましょう！体が新幹線のように前にスーッと進むようになりますよ！`;
  }

  if (score['接地様式'] === '×:かかとからついている') {
    return `まず${yobina}のよかった点としては、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！そんな${yobina}に一番の伸びしろポイントをお伝えすると「足をかかとからついてしまっている」ことがあります。トランポリンジャンプやその場足踏みでもかかとからつくとポンポンしづらいですよね？目安として「母趾球（ぼしきゅう）」あたりから地面につけるとよいです！ また足を腰から遠く前に着いてしまうと1歩1歩ブレーキがかかってしまいます！まずはマーク間を10cmくらいせまくして、上がっている足を「真下にポンポンおとして走る」ことを意識してみましょう！列車の練習もおすすめです！`;
  }

  if (score['接地様式'] === '×:つま先だけでついている') {
    return `まず${yobina}のよかった点としては、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！そんな${yobina}の一番の伸びしろポイントとして「足をつま先からつきすぎている」ことがあります。目安として「母趾球（ぼしきゅう）」あたりから地面につけるとよいです！これに関してはべースポジションの時のように「つま先をあげる」というのをできるようになると一気にうまくなる子が多いです！まずはベースポジションやその場での連続ハイニーから「足が上がっているときはつま先を上げよう」、というのを意識して練習してみましょう！`;
  }

  if (score['うでを下にポンポンふる'] === '×:悪い') {
    return `まず${yobina}のよかった点としては、「いい姿勢であしを前で回して」走ることが出来ていますね！よく練習を頑張ってくれたのが伝わります！そんな${yobina}の上級者向け伸びしろポイントとしてずばり「腕振り」です！肩の力を抜いて下にポンポン振るようにして走ってみましょう！腕を正しく動かせると足もより楽に前で回せるようになりますし、体もポンポン楽に進むようになりますよ！腕振りはおうちでも取り組みやすい練習ですので、ぜひ習慣にしてみて下さい！`;
  }

  if (score['下に踏む'] === '×:体の遠くに接地') {
    return `まず${yobina}のよかった点としては、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！姿勢もばっちりです！そんな${yobina}に超上級者向けの伸びしろポイントをお伝えすると「足を体の遠くについてしまっている」ことがあります。足を腰から遠く前に着いてしまうと1歩1歩ブレーキがかかってしまいます！「上がっている足を真下にポンポンおとして走る/うでも下にポンポンふる」ことをとにかく意識してみましょう！まずはマーク間を10cmくらいせまくして、慣れてきたら徐々にマーク間を全力疾走の歩幅近くまで広げていきながら感覚をつかんでみて下さい！`;
  }

  return 'これはもう文句なしですね、すばらしいです！';
};

function crt_line3(score: ScoreEditType) {
  let evaluation =
    score.eval === '◯合格'
      ? `15級はいままでの総まとめとして、「これまで習ったことを実際の走りの中でできるか」に挑戦してもらいますよ！ちょうどよいタイミングですので今までの練習を一度ふりかえって見ましょう！`
      : '####いい具合になぐさめる####';
  return evaluation;
}
