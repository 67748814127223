export type networkError = {
    config:unknown,
    request:unknown,
    response:{data:{error:string}},
    isAxiosError:boolean,
    toJSON:unknown
}

type ApiError = networkError;


export const handleRegisterError = (error: ApiError) => {
    if (!error.response){
        return "サーバーが落ちています。運営に連絡してください。"
    }

    const accountCreateError = 'アカウントを作成';
    const pwSimpleError = 'パスワードが単純';
    const addressInvalid = 'メールアドレスが無効';
    console.log(error.response)

    const msg = error.response.data.error;
    const mailErrorContent = error.response.data.email || false;

    if (!!mailErrorContent && mailErrorContent[0].indexOf("既に存在") !== -1) {
        return 'このメールアドレスは既に存在します';
    }
    // if (msg.indexOf(pwSimpleError) !== -1) {
    //     return 'パスワードが単純すぎます';
    // }
    // if (msg.indexOf(addressInvalid) !== -1) {
    //     return 'このメールアドレスは無効です';
    // }
    return 'おそらくパスワードが単純すぎるか、メールアドレスと類似しています。パスワードを修正頂き、それでも直らない場合は運営に連絡して下さい';
};
