// どの級ではどの項目が昇級基準かを記述したファイル

import { RunnerType } from 'adapters/repositories/typeDefinition';
import { convertGradeByRunner, is10GradesPackage } from 'components/constants';
import { grades, grades10 } from '../GradeGoalConst';
import {
  allCheckpoints_10_kyu,
  requirementsToPass_10_kyu,
  standardsFor10grades,
  toGetGoodRequirements_10,
} from './CheckpointsFor10Pkg/starndardsFor10Grades';
import {
  allCheckpoints_20_kyu,
  requirementsToPass_20_kyu,
  standardsFor20grades,
  toGetGoodRequirements_20,
} from './CheckpointsFor20Pkg/standardsFor20Grades';
import { nonFukushuGrades } from './targetsForEachGrade';

export const returnStandardsForTheRunner = (
  runner: RunnerType,
  grade: number,
) => {
  if (is10GradesPackage(runner)) {
    if (grade > 9) {
      return standardsFor10grades['1'];
    }
    const kyu = String(10 - grade) as grades10;
    return standardsFor10grades[kyu];
  }
  if (runner.grade > 18) return standardsFor20grades['1'];
  const kyu20 = String(20 - grade) as grades;
  return standardsFor20grades[kyu20];
};

export const requirementsToGetStar = (runner: RunnerType, grade: number) => {
  if (is10GradesPackage(runner)) {
    if (grade > 9) {
      return allCheckpoints_10_kyu['1'];
    }
    const kyu = String(10 - grade) as grades10;
    return allCheckpoints_10_kyu[kyu];
  }
  if (runner.grade > 18) return allCheckpoints_20_kyu['1'];
  const kyu20 = convertGradeByRunner(runner, grade);
  console.log('ランナーの級', kyu20);

  if (!nonFukushuGrades.includes(kyu20)) {
    return toGetGoodRequirements_20[runner.weak_point ?? '20'];
  }
  return allCheckpoints_20_kyu[kyu20];
};

export const starndardsToGetGood = (runner: RunnerType, grade: number) => {
  if (is10GradesPackage(runner)) {
    if (grade > 9) {
      return toGetGoodRequirements_10['1'];
    }
    const kyu = String(10 - grade) as grades10;
    return toGetGoodRequirements_10[kyu];
  }
  if (runner.grade > 18) return toGetGoodRequirements_20['1'];
  const kyu20 = convertGradeByRunner(runner, grade);
  if (!nonFukushuGrades.includes(kyu20)) {
    return toGetGoodRequirements_20[runner.weak_point ?? '20'];
  }
  return toGetGoodRequirements_20[kyu20];
};

export const requirementsToPass = (runner: RunnerType, grade: number) => {
  if (is10GradesPackage(runner)) {
    if (grade > 9) {
      return requirementsToPass_10_kyu['1'];
    }
    const kyu = String(10 - grade) as grades10;
    return requirementsToPass_10_kyu[kyu];
  }
  if (runner.grade > 18) return requirementsToPass_20_kyu['1'];
  const kyu20 = convertGradeByRunner(runner, grade);
  if (!nonFukushuGrades.includes(kyu20)) {
    return requirementsToPass_20_kyu[runner.weak_point ?? '20'];
  }
  return requirementsToPass_20_kyu[kyu20];
};
