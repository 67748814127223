import React from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

type Props = { activeStep: number };

export const FiftyStepper = (props: Props) => {
  const { activeStep } = props;
  const steps = ['フォーム分析', '予想タイム', 'おすすめ練習'];
  return (
    <div
      style={{
        width: '99%',
        top: '0px',
      }}
    >
      <Box
        sx={{
          width: '99%',
          padding: '4px !important',
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            if (index === activeStep) {
              return (
                <Step key={label} {...stepProps} style={{ padding: '4px' }}>
                  <StepLabel>
                    <Typography
                      style={{
                        color: '#00608d',
                        fontSize: '10pt',
                        fontWeight: 'bold',
                      }}
                    >
                      {' '}
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>
                  {' '}
                  <Typography
                    style={{
                      fontSize: '7pt',
                      fontWeight: 'bold',
                    }}
                  >
                    {' '}
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </div>
  );
};
