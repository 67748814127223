import { RunnerType } from 'adapters/repositories/typeDefinition';

export const crtYobina = (runner: RunnerType): string => {
  if (!!runner.gender && !!runner.username) {
    const keisyo =
      (runner.gender === '男性' && 'くん') ||
      (runner.gender === '女性' && 'ちゃん') ||
      'さん';
    return String(runner.username + keisyo);
  }
  return 'きみ';
};
