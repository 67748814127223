import React from 'react';

import singleLeg_cross_thumb from 'components/MenuConst/thumbnails/singleLeg_cross.jpg';
import towelhighknee_thumb from 'components/MenuConst/thumbnails/towelhighknee.jpg';
import threeHighKnee_thumb from 'components/MenuConst/thumbnails/threeHighKnee_thumb.jpg';
import threeStep_thumb from 'components/MenuConst/thumbnails/3step.png';
import highknee_dash_thumb from 'components/MenuConst/thumbnails/highknee_dash.jpg';
import dash_30m_thumb from 'components/MenuConst/thumbnails/dash_30m.jpg';

import challenge8th_thumb from 'components/MenuConst/thumbnails/challenge_threestep.png';//done
import intro_8th_thumb from 'components/MenuConst/thumbnails/intro_8th.jpg';//done
import { menuType } from 'pages/PracticePage/type';

import {
  re_towelHighKneeVideos,
  threeHighKneeVideos,
  threeStepVideos,
  threeStepAndDashVideos,
  dash30m8thVideos,
} from './videos';


import {
  singleLegTrainingVideos,
} from '../grade_6/videos';

import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';

// up: 準備, tech: ぎじゅつ, run: 体力, family: 家族

// プレイリスト

////
//up
////

export const singleLegTraining_8th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '片足トレーニング',
  isInside: 'able',
  target: ['しせい'],
  menuVariation: ['up'],
  hitWordList: [
    'かたあし',
    '片足',
  ],
  grade: 12,
  image: singleLeg_cross_thumb,
  video: singleLegTrainingVideos,
};


export const towelhighknee_8th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'タオルハイニー',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['up'],
  hitWordList: [
    'たおる',
    'タオル',
    'はいにー',
    'ハイニー',
  ],
  grade: 12,
  image: towelhighknee_thumb,
  video: re_towelHighKneeVideos,
};


////
//tech
////

export const threeHighKnee_8th: menuType = {
  duration: 5,
  title: '3歩ハイニー',
  target: ['しせい', '足の動き'],
  isInside: 'half',
  menuVariation: ['tech'],
  hitWordList: [
    '3',
    'さん',
    'はいにー',
    'ハイニー',
  ],
  grade: 12,
  image: threeHighKnee_thumb,
  video: threeHighKneeVideos,
};

export const threestep_8th: menuType = {
  duration: 5,
  title: '3ステップ ☆昇級テスト',
  target: ['しせい', '足の動き'],
  isInside: 'half',
  menuVariation: ['tech'],
  hitWordList: [
    '3',
    'さん',
    'ステップ',
    'すてっぷ',
  ],
  grade: 12,
  image: threeStep_thumb,
  video: threeStepVideos,
};


////
//run
////

export const threeStepAndDash_8th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'ハイニーからのダッシュ！',
  isInside: 'unable',
  target: ['しせい', '足の動き'],
  menuVariation: ['run'],
  hitWordList: [
    'はいにー',
    'ハイニー',
    'だっしゅ',
    'ダッシュ',
  ],
  grade: 12,
  image: highknee_dash_thumb,
  video: threeStepAndDashVideos,
};


export const dash30m_8th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '30mダッシュ',
  isInside: 'unable',
  target: ['スタート', '全力走'],
  menuVariation: ['run'],
  hitWordList: [
    '30',
    'さん',
    'だっしゅ',
    'ダッシュ',
  ],
  grade: 12,
  image: dash_30m_thumb,
  video: dash30m8thVideos,
};



////
//family
////

//過去のsterterより


////
//levelup
////


export const challenge_8th: menuType = {
  duration: 5,
  hitWordList: ['8', '昇級', '3ステップ', 'さん'],
  title: '8級の昇級用メニュー',
  grade: 12,
  menuVariation: ['levelUp'],
  image: challenge8th_thumb,
  isInside: 'unable',
  target: ['しせい'],
  video: [
    {
      link: 'https://www.youtube.com/embed/hZ3S_9bIKCg',
      title: '8級の昇級用メニュー',
      message: (
        <span>
          8級の昇級テスト挑戦動画はコチラ！
          <br />
          ☆動画の指示に従って撮影し、画面右下の「+ボタン」から「昇級に挑戦」をタップして、動画を送信しよう！
          <br />
          ☆詳しい練習方法やポイントは「おすすめ」から確認！
        </span>
      ),
    },
  ],
};

//introVideoDictFor20Gradesの中身はまだ変更してない
export const intro_8th: menuType = {
  duration: 2,
  isInside: 'able',
  target: ['しせい'],
  hitWordList: ['8', '昇級', 'メッセージ', 'めっせーじ', 'きゅう'],
  title: '8級の方へのメッセージ',
  menuVariation: ['levelUp'],
  grade: 12,
  image: intro_8th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['8'],
      title: 'intro8th',
      message: <span>8級の方へのメッセージはコチラ！ </span>,
    },
  ],
};
