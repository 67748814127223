import React, { FC } from 'react';
import classes from './timeviewer.module.css';
import { levelMapper } from 'components/MenuConst/thresholds';

type levels = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10';
type Props = {
  level: levels;
  title: string;
};

export const ScoreLabel: FC<Props> = ({ level, title }) => {
  const key = titleKeymapper(title);
  const evaluation = levelMapper(level, key);
  console.log(title, evaluation, level);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ width: '34%' }}>
        <div style={{ fontWeight: 'bold', fontSize: '12pt' }}>{title}</div>
      </div>

      <div style={{ width: '22%', fontSize: '8pt', textAlign: 'center' }}>
        {(evaluation === 'A' && (
          <div className={classes.labelVeryGood}>大変良い</div>
        )) || <div className={classes.labelInactive}>大変良い</div>}
      </div>

      <div style={{ width: '22%', fontSize: '8pt', textAlign: 'center' }}>
        {(evaluation === 'B' && (
          <div className={classes.labelGood}>うまい</div>
        )) || <div className={classes.labelInactive}>うまい</div>}
      </div>

      <div style={{ width: '22%', fontSize: '8pt', textAlign: 'center' }}>
        {(evaluation === 'C' && (
          <div className={classes.labelNobi}>のびしろ</div>
        )) || <div className={classes.labelInactive}>のびしろ</div>}
      </div>
    </div>
  );
};

const titleKeymapper = (title: string) => {
  if (title === '姿勢') return 'posture';
  if (title === '足の動き') return 'legScore';
  return 'armSwing';
};
