import React, { FC } from 'react';

import { SalesPoints } from './components/SalesPoints';
import { CustomerReview } from './components/CustomerReview';

import { ProgramExplanation } from './components/ProgramExplanation';
import { SkyBackgroundSummary } from './components/SkyBackgroundSummary';
import { PurchaseQA } from 'pages/UnmemberPages/components/PurchasePageQA';
import { Schedule } from './components/Schedule';
import { FixBarButton } from './components/FixBar';
import { ApplyExplanation } from './components/ApplyExplanation';
import { Inquiry } from './components/Inquiry';
import { AppliStrength } from './components/AppliStrength';
import { Grid } from '@material-ui/core';

type Props = {
  size: number[];
};

export const WiderPurchasePage: FC<Props> = ({ size }) => {
  const handleGoToApply = () => {
    scrollToApplyForm();
  };

  return (
    <div style={{ textAlign: 'center', fontFamily: 'Arial' }}>
      <Grid container>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <ProgramExplanation isSmartPhone={false} />
          {/* <YouAreTarget /> */}

          <SkyBackgroundSummary isSmartPhone={false} />

          <div style={{ marginTop: '20px' }}>
            <AppliStrength isSmartPhone={false} />
          </div>

          <div style={{ marginTop: '20px' }}>
            <SalesPoints isSmartPhone={false} />
          </div>
          <CustomerReview isSmartPhone={false} />
          {/* <TryFree isSmartPhone={false} /> */}

          <div style={{ marginTop: '50px' }}>
            <Schedule isSmartPhone={false} />
          </div>

          {/* <div id="applyForm" style={{ backgroundColor: '#ffffff' }}>
            <ApplyExplanation isSmartPhone={false} />
          </div> */}

          {/* <div style={{ marginTop: '50px' }}>
            <PurchaseQA />
          </div> */}
          <div style={{ marginTop: '20px', width: '100%' }}>
            <Inquiry />
          </div>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      <FixBarButton />
    </div>
  );
};

type sizeType = number[];
export const calculateVideoScreen = (size: sizeType) => {
  return {
    width: Math.trunc(size[0] * 0.9),
    height: Math.trunc(size[0] * 0.6),
  };
};

const scrollToApplyForm = () => {
  const targetElement = document.getElementById('applyForm');

  // 画面上部から要素までの距離
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const rectTop = targetElement.getBoundingClientRect().top;
  // 現在のスクロール距離
  const offsetTop = window.pageYOffset;
  // スクロール位置に持たせるバッファ
  const buffer = 20;

  const top = rectTop + offsetTop - buffer;

  window.scrollTo({
    top: top,
    behavior: 'smooth',
  });
};
