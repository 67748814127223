import React from 'react';
import { MockUploadPage } from './components/MockUploadPage';
import { AccountContext } from 'contexts/AccountContext';
import { PageTitle } from '../../components/common/PageTitle';
import { StickerModal } from '../../components/Sticker/StickerModal';
import { VideoSendButtonWithProgress } from './components/UploadWithProgress';
import { alertStatusType } from 'pages/UploadPage/VideoUploadPage';
import { UploadNotification } from 'components/Notifications';

import { TextInput } from './components/TextInput';
import '../../index.css';
import { AuthContext } from 'contexts/AuthContext';
import { convertRunnerGrade } from 'components/constants';

type inputEvent = React.ChangeEvent<HTMLInputElement>;
type handleInputChange = (e: inputEvent) => void;

export const ManualUploadPage = () => {
  const { accountInfo, currentRunner } = React.useContext(AccountContext);
  const { isAuthenticated } = React.useContext(AuthContext);
  const [isUploading, setIsUploading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [formContent, setFormContent] = React.useState('');
  const [menuContent, setMenuContent] = React.useState('');
  const [alertStatus, setAlertStatus] = React.useState<alertStatusType>({
    open: false,
    status: 'default',
  });

  const handleFormChange: handleInputChange = (e) => {
    setFormContent(e.target.value);
  };

  const handleMenuChange: handleInputChange = (e) => {
    setMenuContent(e.target.value);
  };

  if (!isAuthenticated || !accountInfo || !currentRunner) {
    return <MockUploadPage />;
  }

  const params = new FormData();

  params.append('uploaded_by', accountInfo.id);
  params.append('upload_runner', currentRunner.id);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
  params.append('is_exam', false);
  params.append('text', formContent);
  params.append('menuText', menuContent);

  const sendCompleteFuncs = () => {
    setAlertStatus({ open: true, status: 'OK' });

    setIsModalOpen(true);
    // setIsModalOpen(true);
  };

  const sendFailFuncs = () => {
    console.log('upload失敗');
    setIsUploading(false);
    setAlertStatus({ open: true, status: 'ERROR' });
  };

  const menuExample = '（例）30mダッシュを3本。「壁押しドリル」を練習。';
  const memoExample =
    '（例）気持ちよく走れたが、足を上げられているか自信がない。';

  return (
    <div>
      <PageTitle title={'練習を報告！'} />
      <UploadNotification
        alertStatus={alertStatus}
        setAlertStatus={setAlertStatus}
      />

      <div
        style={{
          textAlign: 'center',
          padding: '5px',
        }}
      >
        <StickerModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          grade={convertRunnerGrade(currentRunner)}
        />

        <div>
          <div
            style={{
              textAlign: 'center',
              padding: '5px',
              marginBottom: '16px',
            }}
          >
            <TextInput
              title="🏃‍♂️
今日はどんな練習をやったかな？🏃‍♀️"
              reibun={menuExample}
              value={menuContent}
              onChange={handleMenuChange}
            />
          </div>

          <div
            style={{
              textAlign: 'center',
              padding: '5px',
            }}
          >
            <TextInput
              title="✏️
練習の感想を書こう"
              reibun={memoExample}
              value={formContent}
              onChange={handleFormChange}
            />
          </div>

          <VideoSendButtonWithProgress
            isUploading={isUploading}
            activeStep={1}
            params={params}
            completeFunc={sendCompleteFuncs}
            failFunc={sendFailFuncs}
            formContent={formContent}
            color="primary"
            title="練習をほうこく"
          />

          <div style={{ marginTop: '500px' }}></div>
        </div>
      </div>
    </div>
  );
};
