import { RunnerType } from 'adapters/repositories/typeDefinition';
import React, { FC, memo } from 'react';
import joy_boy from 'static/materials/joy_boy.png';
import classes from './onboardingCarousel.module.css';
import { crtYobina } from 'utils/funcs/crtYobina';
import { calcMaxGrade } from 'components/constants';

type Props = {
  runner: RunnerType;
};

export const SecondView: FC<Props> = memo(({ runner }) => {
  return (
    <>
      <div className={classes.blackBorder}>
        {crtYobina(runner)} はいま <br />
        かけっこ{calcMaxGrade(runner)}級！
        <br />
        かけっこ1級を目指す中で
        <br />
        <span style={{ color: '#ff0000' }}>「やればできた！」</span>
        を体験しよう！
      </div>
      <div className={classes.joyboyWrapper}>
        <img src={joy_boy} alt=""></img>
      </div>
    </>
  );
});
