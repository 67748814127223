import React, { FC, memo } from 'react';
import { TextField, MenuItem } from '@material-ui/core';

type inputEvent = React.ChangeEvent<HTMLInputElement>;
type Props = {
  gender: string | undefined;
  handleChange: (e: inputEvent) => void;
};

export const GenderInput: FC<Props> = memo(({ gender, handleChange }) => {
  return (
    <TextField
      style={{ width: '80%', backgroundColor: 'white' }}
      select
      label="&nbsp;性別"
      value={gender}
      onChange={handleChange}
    >
      {['男性', '女性', 'その他'].map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
});
