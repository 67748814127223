import React from 'react';
import { getVideoAdapter } from 'adapters/getVideosAdapter';
import { VideoContext } from 'contexts/VideoContext';

import { RunnerType } from 'adapters/repositories/typeDefinition';

export const useGetVideoByRunner = (runner: RunnerType | undefined) => {
  const { videoList, setVideoList } = React.useContext(VideoContext);

  const [isLoading, setIsLoading] = React.useState(false);
  const [fetchedVideos, setFetchedVideos] = React.useState(videoList);

  React.useEffect(() => {
    console.log(runner);
    if (!!runner) {
      setIsLoading(true);
      getVideoAdapter(runner.id)
        .then((videos) => {
          setVideoList(videos);
          setFetchedVideos(videos);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  }, [runner]);

  return {
    isLoading: isLoading,
    videoList: fetchedVideos,
  };
};
