import React, { FC, memo } from 'react';
import { Button } from '@material-ui/core';

type NextButtonProps = {
  handleClick: () => void;
};

export const NextButton: FC<NextButtonProps> = memo(({ handleClick }) => {
  return (
    <Button
      onClick={handleClick}
      style={{
        width: '80vw',
        backgroundColor: '#0000ff',
        color: '#FFF',
        fontWeight: 'bold',
        fontSize: '12pt',
      }}
      variant="contained"
    >
      {'つぎへ'}
    </Button>
  );
});

type NextBackProps = {
  handleForwardClick: () => void;
  handleBackClick: () => void;
};

export const NextAndBackButton: FC<NextBackProps> = memo(
  ({ handleForwardClick, handleBackClick }) => {
    return (
      <div style={{ display: 'flex', width: '80vw' }}>
        <div style={{ width: '40%', textAlign: 'center' }}>
          <Button
            onClick={handleBackClick}
            style={{
              width: '90%',
              fontWeight: 'bold',
              fontSize: '12pt',
            }}
            variant="contained"
          >
            {'もどる'}
          </Button>
        </div>
        <div style={{ width: '18%' }}>
          <br />
        </div>
        <div style={{ width: '40%', textAlign: 'center' }}>
          <Button
            onClick={handleForwardClick}
            style={{
              width: '90%',
              backgroundColor: '#0000ff',
              color: '#FFF',
              fontWeight: 'bold',
              fontSize: '12pt',
            }}
            variant="contained"
          >
            {'つぎへ'}
          </Button>
        </div>
      </div>
    );
  },
);

type FinalButtonProps = {
  handleFinalClick: () => void;
  handleBackClick: () => void;
  message: string;
};

export const FinalButton: FC<FinalButtonProps> = memo(
  ({ handleBackClick, handleFinalClick, message }) => {
    return (
      <div style={{ display: 'flex', width: '90vw' }}>
        <div style={{ width: '40%', textAlign: 'center' }}>
          <Button
            onClick={handleBackClick}
            style={{
              width: '90%',
              fontWeight: 'bold',
              fontSize: '12pt',
            }}
            variant="contained"
          >
            {'もどる'}
          </Button>
        </div>
        <div style={{ width: '18%' }}>
          <br />
        </div>
        <div style={{ width: '40%', textAlign: 'center' }}>
          <Button
            onClick={handleFinalClick}
            style={{
              width: '90%',
              backgroundColor: '#0000ff',
              color: '#FFF',
              fontWeight: 'bold',
              fontSize: '12pt',
            }}
            variant="contained"
          >
            {message}
          </Button>
        </div>
      </div>
    );
  },
);
