import React, { FC, memo, useState, useEffect } from 'react';
import { Card, Button } from '@material-ui/core';
import { menuType, menuVideo } from 'pages/PracticePage/type';
import smoke from '../../static/smoke.gif';

type Props = {
  menuList: menuType;
  setMenuKey: React.Dispatch<React.SetStateAction<number>>;
  menuKey: number;
  width: number;
};

export const MenuViewer: FC<Props> = memo(
  ({ menuList, setMenuKey, menuKey, width = 0.9 }) => {
    if (!menuList) {
      throw new Error('メニューが与えられてません！');
    }
    if (isNaN(menuKey)) {
      throw new Error('menuKeyが与えられてません！');
    }
    const playList = menuList.video; //ここには再生リストが入る

    const handleForward = () => {
      let _menuKey = menuKey;
      setMenuKey(_menuKey + 1);
    };
    const handleBackward = () => {
      let _menuKey = menuKey;
      setMenuKey(_menuKey - 1);
    };
    return (
      <div
        style={{
          marginBottom: '16px',
          textAlign: 'center',
          padding: '1px',
        }}
      >
        <div style={{ paddingBottom: '20px' }}>
          <MenuTextViewer
            text={playList[menuKey] ? playList[menuKey].message : ''}
            menuNum={menuKey}
          />
        </div>
        <Card
          style={{
            width: `${window.parent.screen.width * width}px`,
            height: `${window.parent.screen.width * 0.7}px`,
          }}
        >
          <div>
            <VideoViewer menuKey={menuKey} videoList={playList} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {menuKey !== 0 ? (
              <Button
                size="small"
                style={{
                  fontSize: '8pt',
                  fontWeight: 'bold',
                }}
                onClick={handleBackward}
              >
                前の動画を見直す
                <br /> {'<<'}
              </Button>
            ) : (
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              <Button
                size="small"
                style={{
                  fontSize: '8pt',
                  color: '#CCCCCC',
                }}
                disbled="true"
              >
                前の動画を見直す
                <br /> {'<<'}
              </Button>
            )}
            <p>
              {menuKey + 1}&nbsp;/&nbsp;{playList.length}
            </p>
            {menuKey < playList.length - 1 ? (
              <Button
                size="small"
                style={{ fontSize: '8pt', fontWeight: 'bold' }}
                onClick={handleForward}
              >
                次の動画へ <br /> {'>>'}
              </Button>
            ) : (
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              <Button
                size="small"
                style={{ fontSize: '8pt', color: '#CCCCCC' }}
                disbled
              >
                次の動画へ
                <br /> {'>>'}
              </Button>
            )}
          </div>
        </Card>
      </div>
    );
  },
);

type VideoViewerProps = {
  menuKey: number;
  videoList: menuVideo[];
};

const VideoViewer: FC<VideoViewerProps> = memo(({ menuKey, videoList }) => {
  const [menuNum, setMenuNum] = useState(0);
  useEffect(() => {
    setTimeout(() => setMenuNum(menuKey), 800);
  }, [menuKey]);

  return (
    <div>
      {menuNum === menuKey ? (
        <Card
          style={{
            width: '99%',
            height: `${window.parent.screen.width * 0.55}px`,
            backgroundColor: '#AAAAAA',
          }}
        >
          {!!videoList[menuKey] ? (
            <iframe
              width="95%"
              height="95%"
              src={videoList[menuKey].link + '?rel=0&modestbranding=1'}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ marginBottom: '8px' }}
            ></iframe>
          ) : null}
        </Card>
      ) : (
        // </Zoom>
        <img
          src={smoke}
          alt=""
          style={{
            width: '99%',
            height: `${window.parent.screen.width * 0.55}px`,
          }}
        />
      )}
    </div>
  );
});

type MenuTextViewerProps = {
  text: string | JSX.Element;
  menuNum: number;
};

const MenuTextViewer: FC<MenuTextViewerProps> = memo(({ text, menuNum }) => {
  if (text)
    return (
      <div className="menuText">
        <div className={`menu-${menuNum}`}>{text}</div>
      </div>
    );
  return null;
});
