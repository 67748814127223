import { grades } from 'components/MenuConst/GradeGoalConst';
import { optionsForGrade0_20pkg } from './20_kyu';
import { optionsForGrade_1_20pkg } from './19_kyu';
import { optionsForGrade_11_20pkg } from './9_kyu';
import { optionsForGrade_2_20pkg } from './18_kyu';
import { optionsForGrade_3_20pkg } from './17_kyu';
import { optionsForGrade_4_20pkg } from './16_kyu';
import { optionsForGrade_5_20pkg } from './15_kyu';
import { optionsForGrade_6_20pkg } from './14_kyu';
import { optionsForGrade_7_20pkg } from './13_kyu';
import { optionsForGrade_8_20pkg } from './12_kyu';
import { optionsForGrade_9_20pkg } from './11_kyu';
import { optionsForGrade_12_20pkg } from './8_kyu';
import { optionsForGrade_13_20pkg } from './7_kyu';
import { optionsForGrade_15_20pkg } from './5_kyu';
import { optionsForGrade_16_20pkg } from './4_kyu';
import { optionsForGrade_17_20pkg } from './3_kyu';
import { optionsFor2kyu_20pkg } from './2_kyu';

type outputType =
  | typeof optionsForGrade0_20pkg
  | typeof optionsForGrade_1_20pkg
  | typeof optionsForGrade_2_20pkg
  | typeof optionsForGrade_3_20pkg
  | typeof optionsForGrade_4_20pkg
  | typeof optionsForGrade_5_20pkg
  | typeof optionsForGrade_6_20pkg
  | typeof optionsForGrade_7_20pkg
  | typeof optionsForGrade_9_20pkg
  | typeof optionsForGrade_11_20pkg
  | typeof optionsForGrade_12_20pkg
  | typeof optionsForGrade_16_20pkg;

type options_20_type = {
  [key in grades]?: outputType;
};

export const optionsFor20Grades: options_20_type = {
  20: optionsForGrade0_20pkg,
  19: optionsForGrade_1_20pkg,
  18: optionsForGrade_2_20pkg,
  17: optionsForGrade_3_20pkg,
  16: optionsForGrade_4_20pkg,
  15: optionsForGrade_5_20pkg,
  14: optionsForGrade_6_20pkg,
  13: optionsForGrade_7_20pkg,
  12: optionsForGrade_8_20pkg,
  11: optionsForGrade_9_20pkg,
  9: optionsForGrade_11_20pkg,
  8: optionsForGrade_12_20pkg,
  7: optionsForGrade_13_20pkg,
  5: optionsForGrade_15_20pkg,
  4: optionsForGrade_16_20pkg,
  3: optionsForGrade_17_20pkg,
  2: optionsFor2kyu_20pkg,
  1: optionsForGrade_17_20pkg,
};
