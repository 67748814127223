import React, { FC } from 'react';

import { Grid } from '@material-ui/core';
import { convertRunnerGrade } from 'components/constants';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { formatDateFromString } from 'utils/funcs/formatTime';

type Props = {
  video: VideoType;
  onClickFunc: any;
};
export const VideoCardAdmin: FC<Props> = ({ video, onClickFunc }) => {
  // props.の中には上から降りてきてる引数が入ってる

  if (!video.thumbnail) {
    return null;
  }

  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; variant: string; id: st... Remove this comment to see the full error message
    <div variant="outlined" id="videoCard">
      <Grid container>
        <Grid item xs={2}>
          <div onClick={onClickFunc(video)}>
            <img alt="" width="90%" src={`${video.thumbnail}`}></img>
          </div>
        </Grid>

        <Grid item xs={2}>
          <div style={{ textAlign: 'left' }}>
            <div style={{ fontSize: '9pt' }}>
              <div>{formatDateFromString(video.uploaded_date)}</div>
              <div>
                現在の級:
                {convertRunnerGrade(video.upload_runner)}
              </div>

              <div>名前:{video.upload_runner.username}</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div style={{ textAlign: 'left', fontSize: '8pt' }}>
            ----コメント----
            <div style={{ fontSize: '8pt' }}>{video.video_memo}</div>
          </div>
        </Grid>
        <Grid item xs={5} style={{ padding: '10px' }}>
          <div style={{ textAlign: 'left', fontSize: '8pt' }}>
            ----コーチからの返信----
            <div style={{ fontSize: '8pt' }}>
              {!!video.score ? video.score.coach_comment : 'なし'}
            </div>
          </div>
        </Grid>
        <Grid item xs={1}>
          <div style={{ marginLeft: '7px' }}>
            {/* マニュアル制御の時はこっち */}
            {!video.is_exam ? (
              <div
                style={{
                  fontWeight: 'bold',
                  backgroundColor: '#87cefa',
                  color: 'white',
                  fontSize: '8pt',
                  borderRadius: '10px',
                }}
              >
                &nbsp;練習用&nbsp;
              </div>
            ) : (
              <>
                {video.analysis_status === 'done' ? (
                  <div
                    style={{
                      fontWeight: 'bold',
                      backgroundColor: '#33CC33',
                      color: 'white',
                      fontSize: '8pt',
                      borderRadius: '10px',
                    }}
                  >
                    &nbsp;添削完了&nbsp;
                  </div>
                ) : (
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: '8pt',
                      borderRadius: '10px',
                      backgroundColor: '#EEEEEE',
                    }}
                  >
                    &nbsp;解析待ち&nbsp;
                  </div>
                )}
              </>
            )}
          </div>
          <div style={{ fontSize: '8pt' }}>
            <div>
              ビデオの級:
              {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message */}
              {parseInt(10 - video.grade_before)}
              <br />
              ビデオのST:
              {String(video.stay_times)}
            </div>
            <div>
              stay time：
              {video.upload_runner.stay_times}
            </div>
            <div>
              {(video.grade === 2 && video.stay_times === 0) ||
              (video.grade === 1 && video.stay_times > 0) ? (
                <span style={{ color: 'red' }}>BEFOREのやつ！！</span>
              ) : null}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
