// TRAC10級パッケージの5級

export const ckptFor5kyu_trac10 = [
  'しせい',
  'ひざのたかさ',
  'ひざのかくど',
  'いち',
  'うで',
] as const;

export type ckpt_5kyu_type = typeof ckptFor5kyu_trac10[number];

export const requirement_5kyu_trac: ckpt_5kyu_type[] = [
  'しせい',
  'ひざのたかさ',
  'ひざのかくど',
];

type options = {
  [key in ckpt_5kyu_type]: string[];
};

export const optionDict_5kyu_trac: options = {
  しせい: ['◯まっすぐ', '×腰が反っている', '×腰が抜けている'],
  ひざのたかさ: ['◯遊脚のくるぶしが支持脚の膝の前', '×低い', '×高すぎる'],
  ひざのかくど: ['◯閉じている', '×開いている'],
  いち: ['◯その場でできている', '×前進している', '後ろに下がっている'],
  うで: ['◯地面と平行', '×斜め'],
};

type complimentType = {
  [key in ckpt_5kyu_type]: string;
};

// まずxxくんの良い点として、に続く文章を書く
export const complimentDict_5: complimentType = {
  しせい:
    'とてもよい姿勢でできている点があります！頭からかかとまでのラインがしっかりまっすぐになっていて素晴らしいです！',

  ひざのたかさ:
    '脚をちょうどよい高さまであげられている点があります！よく動画を見て頑張ってくれたのが伝わります！',
  ひざのかくど:
    'あげた足のひざをしっかり閉じられていることです！走りの中でもこの動かし方ができれば足をコンパクトに速く回せますよ！',
  いち: '「この練習をその場でできている」点があります！前にも後ろにも進まずにできているのはしっかり体をコントロールできているしょうこですよ！',
  うで: '「うでを地面と平行にできている」点があります！基本の姿勢はバッチリですね！',
};

export const nobishiroOptions_5_trac = Object.values(optionDict_5kyu_trac)
  .flat()
  .filter((value) => !value.includes('◯'));

type nobishiroDict = {
  [key in typeof nobishiroOptions_5_trac[number]]?: string;
};

// そんな〇〇くんの伸びしろポイントはズバリ、に続く文章を書く
export const nobishiroDict_5: nobishiroDict = {
  '×腰が反っている':
    '「腰がそってしまっていること」です！もう少しだけお尻をあげるイメージを持ってみましょう！',
  '×腰が抜けている':
    '「お尻が上がりすぎてしまっている」ことです。「く」の字のような姿勢になっているので、もう少しだけお尻に力を入れて、まっすぐな姿勢を作ってみましょう！',
  '×低い':
    '「足のあがりが低い」ことです！もう少しだけ膝の高さを上げて大きく脚が入れ替わるようにしてみましょう!',
  '×高すぎる':
    '「少し膝をあげすぎてしまっている」ことです！上げた足の足首が、地面についている足の膝の近くにくるくらいの高さが理想です！',
  '×開いている':
    '「上げた足の膝が開いてしまっている」ことです！何かをはさむイメージでしっかり膝を閉じるようにしてみましょう！',
  '×前進している':
    '「少しずつ前に進んでしまっている」ことです！あせらずその場で足を切り替えることをイメージしてみましょう！',
  '×後ろに下がっている':
    '「少しずつ後ろに下がってしまっている」ことです！あせらずその場で足を切り替えることをイメージしてみましょう！',
  '×斜め':
    '「地面に対してうでが斜めになってしまっている」ことです！基本の姿勢をもう一度みなおしてみましょう！',
};
