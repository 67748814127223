import {
  ScoreEditType, //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { RunnerType } from 'adapters/repositories/typeDefinition';
import { gradeTargetDict_20 } from 'components/MenuConst/GoalsForEachGrade/targetsForEachGrade';

export function crtComment20th_20(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  const setence1 = crt_line1(score, yobina);
  const sentence2 = crt_line2(runnerInfo, score, yobina);
  const sentence3 = crt_line3(score);
  return (
    setence1 +
    '<<paragraph>>' +
    sentence2 +
    '<<paragraph>>' +
    sentence3 +
    '<<paragraph>>'
  );
}

function crt_line1(score: ScoreEditType, yobina: string) {
  const head1 =
    yobina + '、さっそく昇級テストにチャレンジしてくれて素晴らしいですね！';
  const head2 = `今回の動画は20級の目標である「${gradeTargetDict_20['20']}」`;
  let evaluation =
    score.eval === '◯合格'
      ? 'が大変良くできています！19級昇級おめでとうございます！'
      : 'に少しだけ課題があります。';
  return head1 + head2 + evaluation;
}

function crt_line2(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  console.log(score);
  return commentLogic_20th(score, yobina);
}

export const commentLogic_20th = (score: ScoreEditType, yobina: string) => {
  if (score['前後傾'] !== '◯:ちょうど良い') {
    if (score['腕が体と平行である'] === '×:前にある') {
      return `${yobina}の一番の伸びしろポイントとして「体を少し前に倒しすぎている」ことがあります。 この練習をするときは、「腕が耳の横になるように高くバンザイする 」よう意識してみましょう！タオルを持ってのY字スプリントがおすすめです！`;
    }

    return `${yobina}の一番の伸びしろポイントとして「体を少し前に倒しすぎている」ことがあります。体を前に倒すことは大事ですが、「こぶし半個分」というのを意識して、もう少しだけ体を起こすと、より走りやすくなると思います！目安として15m先の地面を見ながら走ってみましょう！`;
  }
  if (score['腰が落ちていない'] === '×:NG') {
    return `まず${yobina}のまずよかった点としては、「腰を折らずに」走ることが出来ていることです。ただ、${yobina}の伸びしろポイントとして「もっと背を高くして」走れるとよいですね。走り出す前の足踏みの時から走っている間まで、「キーホルダーになった気分で頭のてっぺんを上から紐でつられている」ようなイメージを持ってみて下さい！いい姿勢から背伸びした腰やお尻が高ーい位置のままポンポン走れるようになりますよ！`;
  }

  if (score['足が最低限上がっている'] === '×:NG') {
    return `まず${yobina}のよかった点としては「腰を折らずに背の高いまま走る」というのがばっちりできていますね！すばらしいです！ ${yobina}の伸びしろポイントとして「少しだけ足の動きが小さい」ことがあります。もう少しだけ膝を高くあげると、さらにかっこいい走りに近づきますよ！足を上げることについては19級と18級の練習がうってつけですので、是非楽しみにしていてください！`;
  }
  if (score['接地様式'] === '×:かかとからついている') {
    return `まず${yobina}のよかった点としては「腰を折らずに背の高いまま走る」というのがばっちりできていますね！すばらしいです！そんな${yobina}の伸びしろポイントとして「足をかかとからついてしまっている」ことがあります。その場足ぶみをするとき、足の前側からついた方がポンポン跳ねやすかったですよね？走る時も一緒です！足は前側から、ポンポンつくイメージで走ってみましょう！`;
  }

  if (score['接地様式'] === '×:つま先だけでついている') {
    return `まず${yobina}のよかった点としては「腰を折らずに背の高いまま走る」というのがばっちりできていますね！すばらしいです！そんな${yobina}の伸びしろポイントとして「足をつま先からつきすぎている」ことがあります。トランポリンジャンプでもつま先からつきすぎるとポンポンしづらいですよね？目安として「母趾球（ぼしきゅう）」あたりから地面につけるとよいです！またこれに関しては19級で練習する「べースポジション」のうち「つま先をあげる」というのを走るときも意識できるようになると一気にうまくなる子が多いです！是非19級の練習をした後に「つま先をあげる」ことを意識してY字スプリントを復習してみて下さい！`;
  }

  if (score['腕が体と平行である'] === '×:前にある') {
    return `Y字をして「腰の高い姿勢」をとれているおかげで、あしの前側からつくことが出来ていてポンポンと軽やかな走りです！！すばらしいです！そんな${yobina}へのさらなる伸びしろポイントとしては「より手を高くバンザイ」できるといいですね、タオルを持ってのY字スプリントにぜひ取り組んでみて下さい！ ポイントは「真上に」タオルを掲げることです！`;
  }

  if (score['スピードが出せている'] === '×:遅すぎる') {
    return `Y字をして「腰の高い姿勢」をとれているおかげで、あしの前側からつくことが出来ておりポンポンと軽やかな走りです！！すばらしいです！また、慣れてきたらこの動きをさらにスピードをあげてやってみましょう！保護者様が隣で1秒に4歩くらいのリズムで手を叩いてあげて、それに合わせて走るようにするのもよいですよ！`;
  }

  return 'Y字をして「腰の高い姿勢」をとれているおかげで、あしの前側からつくことが出来ておりポンポンと軽やかな走りです！！これはもう文句なしですね、すばらしいです！Y字をしながらなのにとてもスピードを出せていて本当に文句なしの出来栄えです！';
};

function crt_line3(score: ScoreEditType) {
  let evaluation =
    score.eval === '◯合格'
      ? 'それでは19級からは足の動かし方を練習していきます！新しい練習も20級の復習もどちらも楽しみながらがんばってみてください！'
      : '####いい具合になぐさめる####';
  return evaluation;
}
