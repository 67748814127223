import React, { FC, useState } from 'react';
import { Checkbox } from '@material-ui/core';

export const useCheckboxComponent = () => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  return {
    isChecked: isChecked,
    checkBox: (
      <CustomCheckbox isChecked={isChecked} setIsChecked={setIsChecked} />
    ),
  };
};

type checkBoxProps = {
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
};

const CustomCheckbox: FC<checkBoxProps> = ({ isChecked, setIsChecked }) => {
  return (
    <div>
      {isChecked ? (
        <Checkbox checked onClick={() => setIsChecked(false)} color="primary" />
      ) : (
        <Checkbox
          checked={false}
          onClick={() => setIsChecked(true)}
          color="primary"
        />
      )}
      <span
        style={{
          fontSize: '11pt',
          fontWeight: 'bold',
        }}
      >
        動画がマニュアル通りに撮影されている
      </span>
    </div>
  );
};
