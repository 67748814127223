import {
  intro_10th,
  dribbleJump,
  basePositinJump,
  pongpongJog,
  y_sprint,
  y_practice,
  spring,
  trial_50m,
  arm_swing,
  howToStartPose,
  challenge_10th,
} from '../menus/grade_0/10kyu_for_10pkg';
import {
  re_basePositinJump,
  basePositionWalk_9th,
  kabeoshiBasic,
  howToStartPose_9th,
  arm_swing_9th,
  yHighkneeSprint,
  singleLegRotation,
  intro_9th,
} from '../menus/grade_1/9kyu_for_10pkg';
import {
  re_kabeoshi,
  darumasan,
  threeHighKnee,
  highKneeDash,
  stepQuick,
  intro_8th,
  challenge_8th,
} from '../menus/grade_2/8kyu_for_10pkg';
import {
  arm_swing_Walk,
  highknee_dash,
  dash_30m_7th,
} from '../menus/grade_3/7kyu_for_10pkg';
import {
  cycle,
  skipBasic,
  intro_6th,
  y_sprint_6,
  henkei_6th,
} from '../menus/grade_4/6kyu_for_10pkg';
import {
  threeStep,
  train,
  firstAccel,
  inclineDash5,
  intro_5th,
} from '../menus/grade_5/5kyu_for_10pkg';
import { cycle_dash, dash_30m_4th } from '../menus/grade_6/4kyu_for_10pkg';
import { howtostart } from '../menus/grade_7/3kyu_for_10pkg';
import {
  kabeoshiAdv,
  Uphill,
  skipAdv,
  practice_50m,
} from '../menus/grade_8/2kyu_for_10pkg';
import {
  dash_10m_1st,
  howto50m,
  mark_run,
  straight_leg,
} from '../menus/grade_9/1kyu_for_10pkg';

export const decideMenuFor10grades = (grade: number) => {
  if (!grade || grade === 0) {
    return [
      intro_10th,
      trial_50m,
      dribbleJump,
      basePositinJump,
      pongpongJog,
      y_sprint,
      y_practice,
      spring,
      arm_swing,
      howToStartPose,
      challenge_10th,
    ];
  }

  if (grade === 1) {
    return [
      trial_50m,
      intro_9th,
      re_basePositinJump,
      basePositionWalk_9th,
      kabeoshiBasic,
      howToStartPose_9th,
      arm_swing_9th,
      yHighkneeSprint,
      spring,
      singleLegRotation,
    ];
  }

  if (grade === 2) {
    return [
      trial_50m,
      re_kabeoshi,
      darumasan,
      threeHighKnee,
      highKneeDash,
      stepQuick,
      intro_8th,
      challenge_8th,
    ];
  }

  if (grade === 3) {
    return [trial_50m, arm_swing_Walk, highknee_dash, dash_30m_7th];
  }

  if (grade === 4) {
    return [trial_50m, cycle, skipBasic, y_sprint_6, intro_6th, henkei_6th];
  }

  if (grade === 5) {
    return [trial_50m, threeStep, train, firstAccel, inclineDash5, intro_5th];
  }

  if (grade === 6) {
    return [trial_50m, cycle_dash, dash_30m_4th, train, arm_swing_Walk];
  }

  if (grade === 7) {
    return [trial_50m, howtostart, inclineDash5, henkei_6th, skipBasic];
  }

  if (grade === 8) {
    return [
      re_kabeoshi,
      trial_50m,
      kabeoshiAdv,
      skipAdv,
      Uphill,
      practice_50m,
      henkei_6th,
    ];
  }

  if (grade >= 9) {
    return [
      re_kabeoshi,
      trial_50m,
      dash_10m_1st,
      howto50m,
      mark_run,
      straight_leg,
    ];
  }
  throw new Error('gradeのバグ');
};
