import React from 'react';

import { PageTitle } from 'components/common/PageTitle';
import { StickerModal } from 'components/Sticker/StickerModal';
import { Button } from '@material-ui/core';
import { alertStatusType } from 'pages/UploadPage/VideoUploadPage';
import { UploadNotification } from 'components/Notifications';

import { TextInput } from './TextInput';

type inputEvent = React.ChangeEvent<HTMLInputElement>;
type handleInputChange = (e: inputEvent) => void;

export const MockUploadPage = () => {
  const [isUploading, setIsUploading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [formContent, setFormContent] = React.useState('');
  const [menuContent, setMenuContent] = React.useState('');
  const [alertStatus, setAlertStatus] = React.useState<alertStatusType>({
    open: false,
    status: 'default',
  });

  const handleMockClick = () => {
    setAlertStatus({ open: true, status: 'OK' });

    setIsModalOpen(true);
    // setIsModalOpen(true);
  };

  const handleFormChange: handleInputChange = (e) => {
    setFormContent(e.target.value);
  };

  const handleMenuChange: handleInputChange = (e) => {
    setMenuContent(e.target.value);
  };

  const menuExample = '（例）30mダッシュを3本。「壁押しドリル」を練習。';
  const memoExample =
    '（例）気持ちよく走れたが、足を上げられているか自信がない。';

  return (
    <div>
      <PageTitle title={'練習を報告！'} />
      <UploadNotification
        alertStatus={alertStatus}
        setAlertStatus={setAlertStatus}
      />

      <div
        style={{
          textAlign: 'center',
          padding: '5px',
        }}
      >
        <StickerModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          grade={String(10)}
        />

        <div>
          <div
            style={{
              textAlign: 'center',
              padding: '5px',
              marginBottom: '16px',
            }}
          >
            <TextInput
              title="🏃‍♂️
今日はどんな練習をやったかな？🏃‍♀️"
              reibun={menuExample}
              value={menuContent}
              onChange={handleMenuChange}
            />
          </div>

          <div
            style={{
              textAlign: 'center',
              padding: '5px',
            }}
          >
            <TextInput
              title="✏️
練習の感想を書こう"
              reibun={memoExample}
              value={formContent}
              onChange={handleFormChange}
            />
          </div>

          <div style={{ marginTop: '20px' }}>
            {!formContent || !menuContent ? (
              <>
                <Button disabled variant="contained" onClick={handleMockClick}>
                  送信！
                </Button>
                <div
                  style={{
                    marginTop: '10px',
                    fontSize: '9pt',
                    color: '#444444',
                  }}
                >
                  練習メニューと練習の感想を記入すると
                  <br />
                  送信ができます
                </div>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleMockClick}
                >
                  送信！
                </Button>
              </>
            )}
          </div>

          <div style={{ marginTop: '100px' }}></div>
        </div>
      </div>
    </div>
  );
};
