import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { TrainingComponent } from './TrainingComponent';
import nextIsLast from 'static/lp/next_is_last.png';

import { VideoType } from 'adapters/repositories/typeDefinition';

//eslint-disable-next-line

type Props = {
  handleBackClick: () => void;
  handleNextClick: () => void;
  video: VideoType;
};

export const TrainingMenuScreen: FC<Props> = ({
  handleBackClick,
  handleNextClick,
  video,
}) => {
  return (
    <div style={{ marginTop: '16px' }}>
      {' '}
      <TrainingComponent video={video} />
      <div style={{ margin: '30px 0 10px 0' }}>
        <img src={nextIsLast} width="96%" alt=""></img>
      </div>
      <div style={{ display: 'flex', marginBottom: '100px' }}>
        <div style={{ width: '44%' }}>
          <Button onClick={handleBackClick} variant="outlined">
            {'<< 戻る'}
          </Button>
        </div>

        <div style={{ width: '12%' }}>
          <br />
        </div>
        <div style={{ width: '44%' }}>
          <Button onClick={handleNextClick} variant="contained" color="primary">
            {' 次へ >> '}
          </Button>
        </div>
      </div>
    </div>
  );
};
