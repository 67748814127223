import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';

export const re_kabeoshiAde5thVideos = [
  {
    link: 'https://www.youtube.com/embed/67JZVwNB7rc',
    title: '連続壁押しドリル',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「連続壁押しドリル」を復習（目安2～3分）！！{' '}
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ふくしゅうのポイント
        </div>
        1. よい姿勢 ☆よこからみてまっすぐかな？かかとはういてるかな？
        <br />
        2. 足の上がり ☆ふとももが90度あがっているかな？
        <br />
        3. 空中であしを入れかえる
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ3つができた子はチャレンジ！～
        </div>
        4. 「45度～60度」くらい倒してチャレンジしてみよう！
        <br />
        <br />
        ☆まずは正しい形でできる角度で！角度浅めから練習しよう！
      </div>
    ),
  },
];

export const re_kabeoshi3highkneeVideos = [
  {
    link: 'https://www.youtube.com/embed/t25uFoSDlfs',
    title: '壁押し3歩ハイニー',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「壁押し3歩ハイニー」を練習！
        </div>
        1. よい姿勢 ☆体はまっすぐかな？かかとはういてるかな？
        <br />
        2. 足の上がり ☆ふとももが90度あがっているかな？
        <br />
        3. 空中であしを入れかえる
        <br />
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ4つができた子はチャレンジ！～
        </div>
        5. 「45度～60度」くらい倒してチャレンジしてみよう！
        <br />
      </div>
    ),
  },
];

export const dash10m2kyuVideos = [
  {
    link: 'https://www.youtube.com/embed/SbSr3_5aXTI',
    title: '1次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「スタートから10mまでの走り方」を復習！！
        </div>
        ⇒「10mダッシュ」にたくさんチャレンジ！
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '2px' }}
        >
          昇級のポイント
        </div>
        1. （スタートの構え）前あしに体重をのせる。
        <br />
        2. （スタートの構え）いい姿勢で体をたおす。
        <br />
        3. しっかり体を倒す
        ☆6歩以上体をたおして走ろう！あごをひいて、目線は5m先くらい！
        <br />
        4. 足を前に前に ☆連続かべおしのときと同じ！
        <br />
        5. 腕ふり：足とタイミングを合わせて大きく前にふろう！
      </div>
    ),
  },
];
