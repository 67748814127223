// 10級パッケージの6級
type ckptTypeForGrade_13_20kyu =
  | 'よい姿勢'
  | '足を体の前で回す'
  | '足のつき方'
  | 'うでふり';

// ここから下をいじる！

// 各級のポイント
export const ckptForGrade_13_20kyu: ckptTypeForGrade_13_20kyu[] = [
  'よい姿勢',
  '足を体の前で回す',
  '足のつき方',
  'うでふり',
];

// 合格に必要なもの
export const requirement_13_20kyu: ckptTypeForGrade_13_20kyu[] = [
  'よい姿勢',
  '足を体の前で回す',
];

export type ckptDetails_13_20 =
  | '前後傾'
  | '腰が高い'
  | '足の上がり'
  | '後ろに蹴っていない'
  | '下に踏む'
  | '接地様式'
  | 'うでを下にポンポンふる';

type allCheckpoints_13_20kyu_type = {
  [key in ckptTypeForGrade_13_20kyu]: ckptDetails_13_20[];
};

//星とるためのやつ
export const allCheckPoints_13_20kyu: allCheckpoints_13_20kyu_type = {
  よい姿勢: ['前後傾', '腰が高い'],
  足を体の前で回す: ['後ろに蹴っていない', '足の上がり', '下に踏む'],
  足のつき方: ['接地様式'],
  うでふり: ['うでを下にポンポンふる'],
};

// グッドマークをもらうための要件
export const borderLine_13_20kyu: allCheckpoints_13_20kyu_type = {
  よい姿勢: ['前後傾'],
  足を体の前で回す: ['後ろに蹴っていない', '足の上がり'],
  足のつき方: ['接地様式'],
  うでふり: ['うでを下にポンポンふる'],
};
