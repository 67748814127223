import { menuType } from 'pages/PracticePage/type';
import { jumpAndRun, senobiJump, supportJump, toeJump } from './videos';
import support_jump_thumb from 'components/MenuConst/thumbnails/support_jump_thumb.png';
import senobi_jump_thumb from 'components/MenuConst/thumbnails/senobi_jump_thumb.png';
import toe_jump_thumb from 'components/MenuConst/thumbnails/toe_jump_thumb.png';
import jump_run_thumb from 'components/MenuConst/thumbnails/jump_run_thumb.png';

export const SupportJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'サポートジャンプ',
  isInside: 'able',
  target: ['しせい'],
  menuVariation: ['tech'],
  hitWordList: ['サポート', 'ジャンプ'],
  grade: 2,
  image: support_jump_thumb,
  video: supportJump,
};

export const ToeJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'つまさきジャンプ',
  isInside: 'able',
  target: ['しせい'],
  menuVariation: ['tech'],
  hitWordList: ['つまさき', 'ジャンプ'],
  grade: 2,
  image: toe_jump_thumb,
  video: toeJump,
};

export const SenobiJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '背のびジャンプ',
  isInside: 'able',
  target: ['しせい'],
  menuVariation: ['tech'],
  hitWordList: ['姿勢', 'しせい'],
  grade: 2,
  image: senobi_jump_thumb,
  video: senobiJump,
};

export const JumpAndRun: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'ジャンプ+ダッシュ #昇級テスト',
  isInside: 'unable',
  target: ['しせい'],
  menuVariation: ['tech'],
  hitWordList: ['ジャンプアンド', 'ダッシュ'],
  grade: 2,
  image: jump_run_thumb,
  video: jumpAndRun,
};
