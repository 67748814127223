import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  InputAdornment,
  IconButton,
  Input,
  TextField,
  InputLabel,
  FormControl,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { postResetPassword } from '../../v1apiRequest';

export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'token' does not exist on type '{}'.
  const { token, uid } = useParams();

  const [accountInfo, setAccountInfo] = React.useState({
    email: '',
    password: '',
    confirmPassword: '',
    showPassword: false,
  });
  const [isUploading, setIsUploading] = React.useState(false);
  const [validationMessage, setValidationMessage] = React.useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [isFormComplete, setIsFormComplete] = React.useState(false);

  React.useEffect(() => {
    const formValues = Object.values({
      ...accountInfo,
      showPassword: 'as',
    });
    const isAllFilled = formValues.every((f: any) => String(f).length > 0);
    const isNoValidation = Object.values(validationMessage).every(
      (f: any) => f.length === 0,
    );

    if (isAllFilled && isNoValidation) {
      setIsFormComplete(true);
    } else setIsFormComplete(false);
  }, [accountInfo, validationMessage]);

  const handleInfoChange = (props: any) => (e: any) => {
    let _info = { ...accountInfo };
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    _info[props] = e.target.value;
    setAccountInfo(_info);

    if (props === 'email') {
      let message = '';
      const reg =
        /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
      if (reg.test(e.target.value)) {
        setValidationMessage({
          ...validationMessage,
          email: '',
        });
      } else {
        message = 'emailアドレスが不適切です';
        setValidationMessage({
          ...validationMessage,
          email: message,
        });
      }
    }

    if (props === 'password') {
      if (e.target.value.length <= 7) {
        setValidationMessage({
          ...validationMessage,
          password: 'パスワードは8文字以上入力してください',
        });
      } else if (
        //confirmPasswordが入力された後に再度PWが変更されたら

        e.target.value !== accountInfo.confirmPassword &&
        accountInfo.confirmPassword.length > 0
      ) {
        setValidationMessage({
          ...validationMessage,
          ...{
            confirmPassword: 'パスワードが一致していません',
            password: '',
          },
        });
      } else {
        setValidationMessage({
          ...validationMessage,
          password: '',
        });
      }
    }
    if (props === 'confirmPassword') {
      let message = '';
      if (e.target.value === accountInfo.password) {
        setValidationMessage({
          ...validationMessage,
          confirmPassword: '',
        });
      } else {
        message = 'パスワードが一致していません';
        setValidationMessage({
          ...validationMessage,
          confirmPassword: message,
        });
      }
    }
  };

  const handleSendClick = () => {
    const sendInfo = {
      email: accountInfo.email,
      token: uid,
      uid: token,
      new_password: accountInfo.password,
    };
    console.log(sendInfo);
    setIsUploading(true);
    postResetPassword(sendInfo)
      .then((res) => {
        //alert('account created');
        setIsUploading(false);
        console.log(res.data);
        alert('認証メールを送信しました');
        navigate('/relogin');
      })
      .catch((error) => {
        console.log(error);
        setIsUploading(false);
      });
  };

  const handleClickShowPassword = () => {
    setAccountInfo({
      ...accountInfo,
      showPassword: !accountInfo.showPassword,
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const gotoLogin = () => {
    navigate('/login');
  };

  return (
    <div style={{ textAlign: 'center' }}>
      パスワード再設定
      <div>
        <TextField
          style={{ marginTop: '20px', width: '70%' }}
          value={accountInfo.email}
          label="email"
          onChange={handleInfoChange('email')}
          helperText={validationMessage.email}
        />
      </div>
      <div style={{ marginTop: '20px' }}>
        <FormControl style={{ width: '70%' }}>
          <InputLabel htmlFor="standard-adornment-password">
            新しいパスワード
          </InputLabel>
          <Input
            id="standard-adornment-password"
            value={accountInfo.password}
            type={accountInfo.showPassword ? 'text' : 'password'}
            onChange={handleInfoChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {accountInfo.showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText
            id="standard-adornment-password"
            style={{ color: 'red' }}
          >
            {validationMessage.password}
          </FormHelperText>
        </FormControl>

        <FormControl style={{ width: '70%', marginTop: '5%' }}>
          <InputLabel htmlFor="standard-adornment-password">
            パスワード再入力
          </InputLabel>
          <Input
            id="standard-adornment-password2"
            value={accountInfo.confirmPassword}
            type={accountInfo.showPassword ? 'text' : 'password'}
            onChange={handleInfoChange('confirmPassword')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {accountInfo.showPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText
            id="standard-adornment-password2"
            style={{ color: 'red' }}
          >
            {validationMessage.confirmPassword}
          </FormHelperText>
        </FormControl>
      </div>
      {isFormComplete ? (
        isUploading ? (
          <Button variant="contained" disabled style={{ marginTop: '30px' }}>
            送信 <CircularProgress size={20} />
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '30px' }}
            onClick={handleSendClick}
          >
            送信
          </Button>
        )
      ) : (
        <Button variant="contained" disabled style={{ marginTop: '30px' }}>
          送信
        </Button>
      )}
      <div style={{ marginTop: '30px' }}>
        <span className="textlink" onClick={gotoLogin}>
          ログインページへ戻る
        </span>
      </div>
    </div>
  );
};
