import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { ResultComponent } from './ResultComponent';

import { VideoType } from 'adapters/repositories/typeDefinition';

//eslint-disable-next-line

type Props = {
  handleNextClick: () => void;
  handleBackClick: () => void;
  video: VideoType;
};

export const ResultScreen: FC<Props> = ({
  handleNextClick,
  handleBackClick,
  video,
}) => {
  return (
    <div style={{ marginTop: '16px' }}>
      {' '}
      <ResultComponent video={video} />
      <div
        style={{ display: 'flex', marginTop: '24px', marginBottom: '100px' }}
      >
        <div style={{ width: '44%' }}>
          <Button onClick={handleBackClick} variant="outlined">
            {'<< 戻る'}
          </Button>
        </div>

        <div style={{ width: '12%' }}>
          <br />
        </div>
        <div style={{ width: '44%' }}>
          <Button onClick={handleNextClick} variant="contained" color="primary">
            {' 次へ >> '}
          </Button>
        </div>
      </div>
    </div>
  );
};
