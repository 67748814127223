// 10級パッケージの4級（自転車）
type ckptTypeForGrade_11_20kyu =
  | 'よい姿勢'
  | '足を体の前で回す'
  | '足のつき方'
  | 'スピードを出す';

export const ckptForGrade_11_20kyu: ckptTypeForGrade_11_20kyu[] = [
  'よい姿勢',
  '足を体の前で回す',
  '足のつき方',
  'スピードを出す',
];

export const requirement_11_20kyu: ckptTypeForGrade_11_20kyu[] = [
  'よい姿勢',
  '足を体の前で回す',
  '足のつき方',
];

export type ckptDetails_11_20 =
  | '前後傾'
  | '腰の引け具合'
  | '下に踏む'
  | '足の上がり'
  | '前さばき'
  | '接地様式'
  | 'スピードが出せている'
  //採点用
  | '腰が落ちていない'
  | '後ろに蹴っていない';

type allCheckpoints_11_20kyu_type = {
  [key in ckptTypeForGrade_11_20kyu]: ckptDetails_11_20[];
};

export const allCheckPoints_11_20kyu: allCheckpoints_11_20kyu_type = {
  よい姿勢: ['前後傾', '腰の引け具合'],
  足を体の前で回す: ['足の上がり', '下に踏む', '前さばき'],
  足のつき方: ['接地様式'],
  スピードを出す: ['スピードが出せている'],
};

export const borderLine_11_20kyu: allCheckpoints_11_20kyu_type = {
  よい姿勢: ['前後傾'],
  足を体の前で回す: ['足の上がり', '下に踏む'],
  足のつき方: ['接地様式'],
  スピードを出す: ['スピードが出せている'],
};
