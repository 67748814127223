import { getAccountInfo } from "v1apiRequest";
import { AccountType } from "./repositories/typeDefinition";

export const getAccountInfoAdapter = () =>
    getAccountInfo().then(res=>{
const account = isAccountResponse(res.data)
return account
    })


type AccountDetector = (response:unknown) => AccountType
export const isAccountResponse:AccountDetector = (response) =>{
    //console.log(response)

    if(!response ){    throw new Error("アカウント取得失敗")}
    const accountObj = response as AccountType

    if(
        typeof accountObj.id === "string" &&
        typeof accountObj.username === "string" &&
        !!accountObj.runners &&
        accountObj.runners.length > 0
    ){return accountObj}

    throw new Error("アカウント取得失敗")

}


