import React, { FC, useState } from 'react';
import { TextInput } from 'pages/UploadPage/components/TextInput';
import { handleInputChange } from '../types';

export const useFormComponent = () => {
  const [formContent, setFormContent] = useState<string>('');
  const handleFormChange: handleInputChange = (e) => {
    setFormContent(e.target.value);
  };

  return {
    formContent: formContent,
    formInput: (
      <FormComponent
        formContent={formContent}
        handleFormChange={handleFormChange}
      />
    ),
    setFormContent: setFormContent,
  };
};

type Props = {
  formContent: string;
  handleFormChange: handleInputChange;
};

const FormComponent: FC<Props> = ({ formContent, handleFormChange }) => {
  const reibun = '（例）気持ちよく走れたが、足を上げられているか自信がない。';

  return (
    <TextInput
      title="✏️先生への質問や練習の感想を書こう！"
      reibun={reibun}
      value={formContent}
      onChange={handleFormChange}
    />
  );
};
