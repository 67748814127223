import React, { FC, memo } from 'react';
import { PageTitle } from '../../components/common/PageTitle';

import { Card } from '@material-ui/core';
import { useGetGrade } from '../../hooks/useGetGrade';
import { useVideoUploadData } from '../../hooks/useVideoUploadData';
import { AccountContext } from 'contexts/AccountContext';
import { BarGraph } from './components/BarGraph';
import { numberGradeConverter } from 'components/MenuConst/GradeGoalConst';
import 'App.css';

export const ScorePage: FC = () => {
  const { currentRunner } = React.useContext(AccountContext);
  const runnerGroup = (!!currentRunner && currentRunner.student_group) || '';
  const { userGradeData, userData } = useGetGrade(runnerGroup);
  const { userVideoUploadData, videoUndone } = useVideoUploadData(runnerGroup);

  console.log(userVideoUploadData);
  console.log(userGradeData);
  console.log(currentRunner);

  return (
    <div>
      <PageTitle title={'みんなの成績'} />
      <div
        style={{
          padding: '15px',
          overflowY: 'scroll',
          height: '100vh',
          backgroundColor: '#ddffff',
        }}
      >
        <Card style={{ padding: '10px' }}>
          <BarGraph
            userGradeData={userGradeData}
            runnerGrade={numberGradeConverter(currentRunner?.grade || 0)}
          />
        </Card>
      </div>
    </div>
  );
};
