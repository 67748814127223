import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Divider,
} from '@material-ui/core';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { AccountType } from 'adapters/repositories/typeDefinition';

type Props = {
  account: AccountType;
};
export const SwipableMenu: FC<Props> = ({ account }) => {
  const [isDrawerOoen, setIsDrawerOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsDrawerOpen(open);
  };

  const handleInquiryClick = () => {
    navigate(`/inquiry`);
  };

  const handleMyPageClick = () => {
    navigate(`/mypage`);
  };

  const list = () => (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem>
          {' '}
          <span style={{ fontSize: '12pt', fontWeight: 'bold' }}>メニュー</span>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key={'mypage'}>
          <ListItemIcon>
            <ContactMailIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={'マイページ'} onClick={handleMyPageClick} />
        </ListItem>

        {account.email === 'f.akihiro.protein@gmail.com' && (
          <ListItem button key={'coach-video'}>
            <ListItemIcon>
              <VideoCameraBackIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={'生徒動画送信'}
              onClick={() => {
                navigate('/coach-video');
              }}
            />
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        <ListItem button key={'inquiry'}>
          <ListItemText primary={'お問い合わせ'} onClick={handleInquiryClick} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <Button
        size="small"
        onClick={toggleDrawer(true)}
        style={{ paddingTop: '5px' }}
      >
        <span style={{ fontSize: '9pt' }}>メニュー</span>
      </Button>
      {/* @ts-expect-error ts-migrate(2786) FIXME: 'SwipeableDrawer' cannot be used as a JSX componen... Remove this comment to see the full error message */}
      <SwipeableDrawer
        open={isDrawerOoen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        // style={{ marginTop: '40px' }}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
};
