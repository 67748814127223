import React, { useState } from 'react';
import { getUsersGrade } from '../v1apiRequest';
import {
  numberGradeConverter,
  grades,
} from 'components/MenuConst/GradeGoalConst';

type userDataType = {
  id: string;
  username: string;
  grade: number;
  student_group: string | null;
  runner_courses?: any;
};

type BarGradeDataType = {
  grade: grades;
  割合: number;
  title: string;
};

// student_groupが入ってない時はrunnerGroupに"空文字"が入る。
export const useGetGrade = (runnerGroup: string) => {
  const [userGradeData, setUserGradeData] = useState<BarGradeDataType[]>([]);
  const [userData, setUserData] = React.useState<userDataType[]>([]);
  React.useEffect(() => {
    getUsersGrade()
      .then((res) => {
        const { gradeBar, userWithSameClass } = processGradeData(
          res.data as userDataType[],
          runnerGroup,
        );
        setUserGradeData(gradeBar);
        setUserData(userWithSameClass);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return {
    userGradeData: userGradeData,
    userData: userData,
  };
};

function processGradeData(list: userDataType[], groupName: string) {
  console.log(groupName);
  console.log(list);
  let retList = [];
  let data = {};
  const listByClass = list.filter(
    (user) => user.student_group === groupName || !groupName,
  );
  // student_groupが空だったらどっちもhitする。
  console.log(listByClass);

  listByClass.forEach((user) => {
    const keys = Object.keys(data);
    if (keys.includes(String(user.grade))) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      data[String(user.grade)] += 1;
      return;
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    data[String(user.grade)] = 1;
    return;
  });

  const gradeList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  gradeList.forEach((grade) => {
    if (Object.keys(data).includes(grade)) {
      return;
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    data[grade] = 0;
  });

  // この時点で 級:
  // data={0:3, 1:2, 1:0, ..., 的な感じ}
  for (const [key, value] of Object.entries(data)) {
    retList.push({
      grade: numberGradeConverter(Number(key)),
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      割合: parseInt(((value as any) / listByClass.length) * 100),
      title: String(10 - parseInt(key) + '級'),
    });
  }
  return { gradeBar: retList, userWithSameClass: listByClass };
}
