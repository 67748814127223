import React from 'react';

import intro_10th_thumb from 'components/MenuConst/thumbnails/intro_10th.jpg';
import henkeiDash_thumb from 'components/MenuConst/thumbnails/henkeidash.jpg';
import howto_50m_thumb from 'components/MenuConst/thumbnails/howto50m.jpg';
import diagnose_thumb from 'components/MenuConst/thumbnails/diagnose_thumb.png';
import mark_16th_thumb from 'components/MenuConst/thumbnails/mark_16th.jpg';
import spring_thumb from 'components/MenuConst/thumbnails/spring.jpg';

import { menuType } from 'pages/PracticePage/type';
import {
  henkei10thVideos,
  markRun10thVideos,
} from './videos';
import {
  howto50m_11thVideos,
} from '../grade_9/videos';
import {
  fiftyTrialVideos,
  springVideos,
} from '../grade_0/videos';

import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';

// up: 準備, tech: ぎじゅつ, run: 体力, family: 家族

// プレイリスト

export const henkeidash10th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '変形ダッシュ',
  isInside: 'unable',
  target: ['スタート'],
  menuVariation: ['run'],
  hitWordList: [
    '変形',
    'スタート',
  ],
  grade: 10,
  image: henkeiDash_thumb,
  video: henkei10thVideos,
};

export const howto50m_10th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '10m以降の走り方',
  isInside: 'unable',
  target: ['スタート', '全力走'],
  menuVariation: ['run'],
  hitWordList: [
    '10',
    '50',
    'はしりかた',
    '走り方',
  ],
  grade: 10,
  image: howto_50m_thumb,
  video: howto50m_11thVideos,
};

export const trial_50m_10th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'かけっこ診断！☆おすすめ',
  isInside: 'unable',
  target: ['しせい', '足の動き', '腕ふり'],
  menuVariation: ['family'],
  hitWordList: [
    'かけっこ',
    '診断',
  ],
  grade: 10,
  image: diagnose_thumb,
  video: fiftyTrialVideos,
};

export const markRun_10th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'マーク走',
  isInside: 'unable',
  target: ['しせい', '足の動き'],
  menuVariation: ['family'],
  hitWordList: [
    'まーく',
    'マーク',
  ],
  grade: 10,
  image: mark_16th_thumb,
  video: markRun10thVideos,
};

export const spring_10th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'バネ人間チャレンジ',
  isInside: 'unable',
  target: ['しせい'],
  menuVariation: ['family'],
  hitWordList: [
    'バネ',
    'ばね',
  ],
  grade: 10,
  image: spring_thumb,
  video: springVideos,
};






export const intro_10th: menuType = {
  duration: 2,
  isInside: 'able',
  target: ['しせい'],
  hitWordList: ['10', '昇級', 'メッセージ', 'めっせーじ', 'じゅう'],
  title: '10級の方へのメッセージ',
  menuVariation: ['levelUp'],
  grade: 10,
  image: intro_10th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['10'],
      title: 'intro10th',
      message: <span>10級の方へのメッセージはコチラ！ </span>,
    },
  ],
};
