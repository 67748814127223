import React from 'react';
import posture_thumb from 'components/MenuConst/thumbnails/posture.png';
import basePositinJump_thumb from 'components/MenuConst/thumbnails/basePositinJump.jpg';
import pongpong_thumb from 'components/MenuConst/thumbnails/pongpongJog_thumb.png';
import ySprint_thumb from 'components/MenuConst/thumbnails/y_sprint.png';
import ySkip_thumb from 'components/MenuConst/thumbnails/ySkip.jpg';
import spring_thumb from 'components/MenuConst/thumbnails/spring.jpg';
import armSwing from 'components/MenuConst/thumbnails/arm_swing.png';
import startpose from 'components/MenuConst/thumbnails/start_posture.png';
import diagnose from 'components/MenuConst/thumbnails/diagnose_thumb.png';
import challenge20th_thumb from 'components/MenuConst/thumbnails/challenge_20th.jpg';
import intro_20th_thumb from 'components/MenuConst/thumbnails/intro_20th.png';
import { menuType } from 'pages/PracticePage/type';
import {
  dribbleJumpVideos,
  basePositinJumpVideos,
  pongpongJogVideos,
  ySprintVideos,
  yPracticeVideos,
  springVideos,
  fiftyTrialVideos,
  armSwingVideos,
  howToStartPoseVideos,
} from './videos';
import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';

// up: 準備, tech: ぎじゅつ, run: 体力, family: 家族

// プレイリスト
export const dribbleJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'いい姿勢の作り方',
  isInside: 'able',
  target: ['しせい'],
  menuVariation: ['up'],
  hitWordList: [
    'よい',
    'いい',
    '姿勢',
    'しせい',
    'トランポリン',
    'ジャンプ',
    'とらんぽ',
    'じゃんぷ',
    'いえ',
    '家',
    'おうち',
  ],
  grade: 0,
  image: posture_thumb,
  video: dribbleJumpVideos,
};

export const basePositinJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'ベースポジションジャンプ',
  isInside: 'able',
  target: ['しせい', '足の動き'],
  menuVariation: ['up'],
  hitWordList: [
    'べーす',
    'ベース',
    'ジャンプ',
    'じゃんぷ',
    'いえ',
    '家',
    'おうち',
  ],
  grade: 0,
  image: basePositinJump_thumb,
  video: basePositinJumpVideos,
};

export const pongpongJog: menuType = {
  duration: 8, // 所要時間（分）を入れる
  title: 'ポンポンジョグ',
  target: ['しせい'],
  menuVariation: ['tech'],
  hitWordList: [
    'その場',
    'その',
    'あし',
    'ふみ',
    'ぶみ',
    '踏み',
    'ポンポン',
    'ぽん',
    'ジョギング',
    'ジョグ',
    'いえ',
    '家',
    'おうち',
  ],
  grade: 0,
  isInside: 'unable',
  image: pongpong_thumb,
  video: pongpongJogVideos,
};

export const y_sprint: menuType = {
  duration: 8,
  hitWordList: ['Y字', 'ワイ', 'Y', 'スプリント', 'タオル'],
  title: 'Y字スプリント ☆昇級テスト',
  target: ['しせい', '足の動き'],
  menuVariation: ['tech'],
  property: '昇級テスト',
  grade: 0,
  isInside: 'unable',
  image: ySprint_thumb,
  video: ySprintVideos,
};

export const y_practice: menuType = {
  duration: 5,
  title: 'Y字うごきづくり',
  target: ['しせい', '足の動き'],
  hitWordList: ['Y字', 'ワイ', 'Y', 'うごき', '動き', '作り', 'づくり'],
  isInside: 'unable',
  grade: 0,
  menuVariation: ['run'],
  image: ySkip_thumb,
  video: yPracticeVideos,
};

export const spring: menuType = {
  duration: 6,
  hitWordList: ['バネ', 'ばね', 'けんけん', 'ケンケン', 'ベース'],
  title: 'バネ人間チャレンジ',
  target: ['しせい'],
  menuVariation: ['run'],
  property: '昇級テスト',
  grade: 0,
  image: spring_thumb,
  isInside: 'unable',
  video: springVideos,
};

export const trial_50m: menuType = {
  duration: 5,
  hitWordList: ['診断', 'しんだん', 'かけっこ'],
  title: 'かけっこ診断！☆おすすめ',
  menuVariation: ['family'],
  isInside: 'unable',
  target: ['全力走'],
  grade: 0,
  image: diagnose,
  video: fiftyTrialVideos,
};

export const arm_swing: menuType = {
  duration: 5,
  title: 'ペットボトルうでふり',
  target: ['腕ふり'],
  isInside: 'able',
  menuVariation: ['family'],
  hitWordList: ['うで', '腕', 'ふり', '振り', 'いえ', '家', 'おうち'],
  grade: 0,
  image: armSwing,
  video: armSwingVideos,
};

export const howToStartPose: menuType = {
  duration: 5,
  title: 'スタートのかまえ',
  target: ['スタート'],
  isInside: 'able',
  menuVariation: ['family'],
  hitWordList: [
    'スタート',
    'すたーと',
    '構え',
    'かまえ',
    'いえ',
    '家',
    'おうち',
  ],
  grade: 0,
  image: startpose,
  video: howToStartPoseVideos,
};

export const challenge_20th: menuType = {
  duration: 5,
  hitWordList: ['20', '昇級', 'Y字', 'ワイ', 'Y', 'スプリント', 'タオル'],
  title: '20級の昇級用メニュー',
  grade: 0,
  menuVariation: ['levelUp'],
  image: challenge20th_thumb,
  isInside: 'unable',
  target: ['しせい'],
  video: [
    {
      link: 'https://www.youtube.com/embed/nl3qpYJ-wUs',
      title: '20級の昇級用メニュー',
      message: (
        <span>
          20級の昇級テスト挑戦動画はコチラ！
          <br />
          ☆動画の指示に従って撮影し、画面右下の「+ボタン」から「昇級に挑戦」をタップして、動画を送信しよう！
          <br />
          ☆詳しい練習方法やポイントは「おすすめ」から確認！
        </span>
      ),
    },
  ],
};

export const intro_20th: menuType = {
  duration: 2,
  isInside: 'able',
  target: ['しせい'],
  hitWordList: ['20', '昇級', 'メッセージ', 'めっせーじ', 'じゅう'],
  title: '20級の方へのメッセージ',
  menuVariation: ['levelUp'],
  grade: 0,
  image: intro_20th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['20'],
      title: 'intro20th',
      message: <span>20級の方へのメッセージはコチラ！ </span>,
    },
  ],
};
