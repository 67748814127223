import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Fade,
  Backdrop,
} from '@material-ui/core';
import { AccountContext } from 'contexts/AccountContext';

import 'index.css';
import 'App.css';
import { putRunnerInfo } from '../../v1apiRequest';
import { PageTitle } from '../../components/common/PageTitle';
import { MypageQA } from './MypageQA';
import { convertRunnerGrade } from 'components/constants';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#EEEEEE',
    border: '2px solid #0033CC',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const UserAccountPage = () => {
  const classes = useStyles();

  const { accountInfo, currentRunner } = React.useContext(AccountContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [chosenRunner, setChosenRunner] = React.useState(currentRunner);
  const [runnerInfoEdit, setRunnerInfoEdit] = React.useState(currentRunner);

  const handleEditProfile = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (prop: any) => (e: any) => {
    let _info = { ...runnerInfoEdit };
    console.log(_info);
    _info[prop] = e.target.value;
    //console.log(e.target.value);
    setRunnerInfoEdit(_info);
  };

  const handleSaveOrCancel = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsEditing(false);
  };

  const handleUpadateProfile = () => {
    console.log(runnerInfoEdit);
    setIsUploading(true);
    putRunnerInfo(runnerInfoEdit)
      .then((res) => {
        console.log(res);
        alert('更新完了！');
      })
      .catch((e) => {
        console.log(e);
      });
    setIsModalOpen(false);
    setIsEditing(false);
  };

  return (
    <div
      style={{
        textAlign: 'center',
        width: '99%',
        overflowY: 'scroll',
        height: `${window.parent.screen.height * 0.85}px`,
      }}
    >
      <PageTitle title={'マイページ'} />
      <div>
        <Modal
          className={classes.modal}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isModalOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={isModalOpen}>
            <div className={classes.paper}>
              <h3 id="transition-modal-title">
                アカウント情報を更新しますか？
              </h3>
              <div id="transition-modal-description">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button onClick={handleCancel} variant="outlined">
                    キャンセル
                  </Button>
                  <Button
                    onClick={handleUpadateProfile}
                    variant="outlined"
                    color="primary"
                  >
                    変更を保存
                  </Button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
        {/* <TabInfo labels={accountLables}> */}
        <ProfileView
          accountInfo={accountInfo}
          runner={runnerInfoEdit}
          setChosenRunner={setChosenRunner}
          handleSaveOrCancel={handleSaveOrCancel}
          handleEditProfile={handleEditProfile}
          isEditing={isEditing}
          handleChange={handleChange}
        />
      </div>
      <div style={{ marginTop: '20px', marginBottom: '80px' }}>
        <MypageQA />
      </div>
      {/* <Button onClick={handleTest}>追加</Button> */}
    </div>
  );
};

const ProfileView = (props: any) => {
  const account = props.accountInfo;
  const runner = props.runner;

  return (
    <div style={{ padding: '10px' }}>
      {props.isEditing ? (
        <div>
          <div style={{ textAlign: 'right' }}>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={props.handleSaveOrCancel}
            >
              変更保存/キャンセル
            </Button>
          </div>

          <div style={{ marginTop: '20px' }}>
            <table id="accountTable">
              <tbody>
                <tr>
                  <th>アカウント名</th>
                  <td>{runner.username}</td>
                </tr>
                <tr>
                  <th>性別</th>
                  <td>
                    <select onChange={props.handleChange('gender')}>
                      <option value="">性別を選択</option>
                      <option value="男性">男性</option>
                      <option value="女性">女性</option>
                      <option value="その他">その他</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>email</th>
                  <td>{account.email}</td>
                </tr>
                <tr>
                  <th>身長</th>
                  <td>
                    <FormControl style={{ width: '80%' }}>
                      <InputLabel>身長（cm）</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={runner.height}
                        label="Age"
                        defaultValue={140}
                        onChange={props.handleChange('height')}
                      >
                        {/* @ts-expect-error ts-migrate(2802) FIXME: Type 'IterableIterator<number>' can only be iterat... Remove this comment to see the full error message */}
                        {[...Array(81).keys()].map((_, idx) => {
                          return (
                            <MenuItem key={_} value={idx + 100}>
                              {idx + 100}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div>
          <div style={{ textAlign: 'right' }}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={props.handleEditProfile}
            >
              プロフィールを編集
            </Button>
          </div>
          <div style={{ marginTop: '20px' }}>
            <table id="accountTable">
              <tbody>
                <tr>
                  <th>ランナー名</th>
                  <td>{runner.username}</td>
                </tr>
                <tr>
                  <th>性別</th>
                  <td>{runner.gender}</td>
                </tr>
                <tr>
                  <th>email</th>
                  <td>{account.email}</td>
                </tr>
                <tr>
                  <th>身長</th>
                  <td>{runner.height}</td>
                </tr>
                <tr>
                  <th>クラス</th>
                  <td>
                    かけっこ
                    <span
                      style={{
                        fontWeight: 'bold',
                        marginLeft: '5px',
                        fontSize: '13pt',
                      }}
                    >
                      {convertRunnerGrade(runner)}
                    </span>
                    <span style={{ marginLeft: '5px' }}>級</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
