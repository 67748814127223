import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';

//////////////////////////////////////////////////////
//up
/////////////////////////////////////////////////////

export const re_kabeoshiBasicVideos = [
  {
    link: 'https://www.youtube.com/embed/usm_mhrZSd4',
    title: '壁押しドリル',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1～4:19級の昇級テストでのコーチからのコメントを思い出しながら、「壁押しももあげ20歩」×
          4セットおこなう！
          <br />
          動画2～4の注意ポイントを忘れてしまった子は確認してね！
        </div>
        <div className={classes.title_important}>ふくしゅうのポイント</div>
        <div className={classes.content}>
          1. よい姿勢 ☆よこからみてまっすぐかな？かかとはういてるかな？
          <br />
          2. 足の上がり ☆ふとももが90度あがっているかな？
        </div>
        <div className={classes.title_important}>
          うえ2つができた子はチャレンジ！
        </div>
        <div className={classes.content}>
          3. 「45度～60度」くらい倒してチャレンジしてみよう！
          <br />
          <br />
          ☆まずは正しい形でできる角度で！角度浅めから練習しよう！
          <br />
          ☆よろしければ「保護者様に腰をつかんで真後ろに軽く（椅子を引くくらいの力感で）引っ張ってもらいながら」壁押しをしてみてください！その際お子様はお尻が引けないように頑張って耐えてみるといい姿勢の感覚がつかみやすいですよ！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/a2XE9UnDs3g',
    title: '壁押しドリル注意点①',
    message: (
      <div>
        <div className={classes.subtitle}>動画2: 「壁押し」の注意ポイント1</div>
        <div className={classes.content}>☆あしを後ろにはね上げない！</div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/CVWlD11uW5U',
    title: '壁押しドリル注意点②',
    message: (
      <div>
        <div className={classes.subtitle}>動画3: 「壁押し」の注意ポイント2</div>
        <div className={classes.content}>☆かかとは浮かせる！</div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/M39McbpaKgs',
    title: '壁押しドリル注意点③',
    message: (
      <div>
        <div className={classes.subtitle}>動画4: 「壁押し」の注意ポイント3</div>
        <div className={classes.content}>☆足は最初と同じ位置に着地する！</div>
      </div>
    ),
  },
];

//////////////////////////////////////////////////////
//tech
/////////////////////////////////////////////////////
export const darumasanVideos = [
  {
    link: 'https://www.youtube.com/embed/Bdfi35kBYDU',
    title: 'ベースポジションウォーク',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: 「ベースポジションウォーク」をふくしゅう（目安10歩×3セット）！
        </div>
        <div className={classes.content}>
          ☆あしを真下に踏む際、腕を下に振ってリズムをとるとやりやすいかも！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！※足音がなりますので、気になる方はご注意ください！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/ICJshfVfBQA',
    title: '連続ハイニー',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2: 「連続ハイニー20歩」× 4セット（目安）行う！
        </div>
        <div className={classes.content}>
          ☆なれてきたら下にふむ意識でやってみよう！その際は腕も下にポンポンふろう！
          <br />
          ☆よろしければ保護者様が横で手を叩いてリズムをとってあげるとやりやすいかも！目安は1秒に3～4回くらいがおススメ！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！※足音がなりますので、気になる方はご注意ください！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/youybYscJHU',
    title: 'だるまさんが転んだハイニー',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3: 「だるまさんが転んだハイニー」を楽しみながら練習！
        </div>
        <div className={classes.content}>
          ☆ピタッととまれるようになったらゲームクリア！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！※足音がなりますので、気になる方はご注意ください！
        </div>
      </div>
    ),
  },
];

export const threeHighKneeVideos = [
  {
    link: 'https://www.youtube.com/embed/Yi3w66j82jo',
    title: '連続ハイニー前進',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: 「連続ハイニー前進」を大特訓（目安5～10分）！！
        </div>
        <div className={classes.content}>
          ☆1歩1歩ていねいにやってみてね！なにより大事なのは「いい姿勢」です！毎回始める前に背伸びしてお尻を高くしてみましょう！
          <br />
          ☆このメニューはとても重要なので、あせらずゆっくり特訓しましょう！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/zayX9cfq0O0',
    title: '3歩ハイニー',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「3歩ハイニー」を楽しみながら練習！
          <br />
          ⇒ピタッととまれるようになったらゲームクリア！昇級テストにチャレンジしてみてね！
          <br />
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本（ピタッととまるところが3回程度うつっている動画）を送信！
        </div>
        <div className={classes.title_important}>昇級のポイント</div>
        <div className={classes.content}>
          1. よい姿勢 ☆おしりはたか～い位置にあるかな？
          <br />
          2. ハイニーができている ☆ふとももがちゃんとあがっているかな？
          <br />
          3. ベースポジションでとまる
          ☆足の上がった姿勢でピタッと1秒以上とまろう！
          <br />
          <br />
          ☆よろしければ保護者様が「1・2・ピタッ！（2秒あけて）1・2・ピタッ！・・・」といった感じで声をかけてあげて下さい！やりやすくなるし練習が楽しくなりますよ！
        </div>
      </div>
    ),
  },
];

export const highKneeDashVideo = [
  {
    link: 'https://www.youtube.com/embed/RgfOs-J-nWk',
    title: 'Y字ポジションもも上げスプリント',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:
          「Y字ポジションもも上げスプリント10m⇒そのままうでをおろして15mダッシュ」×
          3本行う！
        </div>
        <div className={classes.content}>
          ☆「あしをからだの前で動かす」感覚のままダッシュしてみよう！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/Yi3w66j82jo',
    title: '連続ハイニー前進',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2: 「連続ハイニー前進10m⇒そのまま15mダッシュ」を3～5本おこなう！！
        </div>
        <div className={classes.content}>
          ☆連続ハイニーの時のいい姿勢や足の動かし方のままダッシュしてみよう！
        </div>
      </div>
    ),
  },
];

//////////////////////////////////////////////////////
//family
/////////////////////////////////////////////////////

export const stepQuickVideo = [
  {
    link: 'https://www.youtube.com/embed/6yputLk4kuI',
    title: '階段クイック',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: 「階段クイック10秒」で何歩できるかチャレンジしてみよう！
        </div>
        <div className={classes.content}>
          ☆よろしければご家族様と勝負形式で行ってみて下さい！
        </div>
      </div>
    ),
  },
];

/////////////////////////////////////////////////////////
//TRAC
/////////////////////////////////////////////////////////

export const supportJump = [
  {
    link: 'https://www.youtube.com/embed/g6GNzxJK0jA',
    title: 'サポートジャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>足のバネを強くしよう！①</div>
        <div className={classes.content}>
          ☆ぜひ動画のまねをしてやってみてね！
        </div>
      </div>
    ),
  },
];

export const toeJump = [
  {
    link: 'https://www.youtube.com/embed/4QCKyTlD9sI',
    title: 'つまさきジャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>足のバネを強くしよう！②</div>
        <div className={classes.content}>☆あしの前がわでジャンプしよう！</div>
      </div>
    ),
  },
];

export const senobiJump = [
  {
    link: 'https://www.youtube.com/embed/M73-We8iGdk',
    title: 'せのびジャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>足のバネを強くしよう！③</div>
        <div className={classes.content}>☆背のびをしたままジャンプしよう！</div>
      </div>
    ),
  },
];

export const jumpAndRun = [
  {
    link: 'https://www.youtube.com/embed/jtGF0dSXeKI',
    title: 'スタートの構え',
    message: (
      <div>
        <div>
          <div className={classes.subtitle}>
            足の前がわではずむ感覚をおぼえる
            <br />
            ☆昇級テストの挑戦は右下の ➕ボタン⇒昇級に挑戦」から行えます！
          </div>
          <div className={classes.title_important}>昇級のポイント</div>
          <div className={classes.content}>
            1. 良いしせいを作る！
            <br />
            2. 力を入れず、はずむようにジャンプ
            <br />
            3. そのまま走る！
          </div>
        </div>
      </div>
    ),
  },
];
