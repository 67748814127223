import React, { FC } from 'react';

import { VideoType } from 'adapters/repositories/typeDefinition';
import { TrainingMenuViewer } from './TrainingMenuAccordion';
import { AIScore, thresholdMapper } from 'components/MenuConst/thresholds';

//eslint-disable-next-line

type Props = {
  video: VideoType;
};

type infoType = {
  username: string;
  gender: string;
};

export const TrainingComponent: FC<Props> = ({ video }) => {
  const ai_eval = JSON.parse(
    (!!video?.score?.ai_eval && video.score.ai_eval) || '',
  ) as AIScore;

  const postureScore = thresholdMapper('posture', ai_eval);
  const legScore = thresholdMapper('legScore', ai_eval);
  const armScore = thresholdMapper('armSwing', ai_eval);
  const noAccountInfo =
    ((!!video?.no_account_info &&
      JSON.parse(video.no_account_info)) as infoType) ||
    ({ username: '', gender: '' } as infoType);
  console.log(noAccountInfo);

  const yobina = crtYobina(noAccountInfo);

  return (
    <div style={{ marginTop: '12px' }}>
      <TrainingMenuViewer
        posture={postureScore}
        arm={armScore}
        leg={legScore}
        yobina={yobina}
      />
    </div>
  );
};

const crtYobina = (info: infoType) => {
  if (!!info.gender && !!info.username) {
    const keisyo =
      (info.gender === '男性' && 'くん') ||
      (info.gender === '女性' && 'ちゃん') ||
      'さん';
    return String(info.username + keisyo);
  }
  return 'きみ';
};

export const crtYobinaFromVideo = (video: VideoType) => {
  const noAccountInfo =
    ((!!video?.no_account_info &&
      JSON.parse(video.no_account_info)) as infoType) ||
    ({ username: '', gender: '' } as infoType);
  console.log(noAccountInfo);

  const yobina = crtYobina(noAccountInfo);

  return yobina;
};
