import React from 'react';
import classes from '../guidanceTools.module.css';

import { Button } from '@material-ui/core';

export const LetterPopover = (
  isActive: boolean,
  onClickFunc: () => void,
  isBefore: boolean,
) => {
  if (isActive) {
    return (
      <div className={classes.popover}>
        <span style={{ fontWeight: 'bold', fontSize: '14pt' }}>
          先生からのコメントがとどいたよ！
        </span>
        <div
          style={{
            margin: '24px 0 12px 0',
            textAlign: 'center',
            width: '100%',
          }}
        >
          <Button
            onClick={onClickFunc}
            style={{
              width: '60%',
              fontWeight: 'bold',
              fontSize: '12pt',
              backgroundColor: '#0000ff',
              color: 'white',
            }}
            variant="contained"
          >
            {'みる！'}
          </Button>
        </div>
      </div>
    );
  }
  if (isBefore) {
    return (
      <div className={classes.popover}>さあ、今週もれんしゅうがんばろう！</div>
    );
  }
  return (
    <div className={classes.popover}>
      先生からのコメントは月曜日にかくにんできるよ！
      <br />
      もう少しだけまっててね！
    </div>
  );
};
