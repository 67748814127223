import React, { FC } from 'react';

import 'components/components.css';
import { Grid, GridSize, Card } from '@material-ui/core';
import { VerticalLine, SpaceLine } from './StepFunctions/Lines';
import { MessageBar } from './StepFunctions/MessageBar';
import sample_thumbnail from 'static/sample_thumbnail.png';
import coach_murai from 'static/coach-murai.png';
import classes from './videoPlayer.module.css';
import coachClasses from './coachComment.module.css';
import { requirementsToPass_10_kyu } from 'components/MenuConst/GoalsForEachGrade/CheckpointsFor10Pkg/starndardsFor10Grades';

export const FirstLoginView: FC = () => {
  const sampleResult = [
    {
      title: 'よい姿勢',
      isBorderPassed: true,
      isAllPassed: true,
    },
    {
      title: '足を体の前で回す',
      isBorderPassed: true,
      isAllPassed: true,
    },
    {
      title: '足のつき方',
      isBorderPassed: true,
      isAllPassed: false,
    },
    {
      title: 'スピードを出す',
      isBorderPassed: true,
      isAllPassed: true,
    },
  ];

  // 級の合格に関わる。メッセージバーで必要なものが格納されている。
  const necessitiesForTheGrade = requirementsToPass_10_kyu['6'];

  // result
  const upOrStay = {
    title: '合格',
    isBorderPassed: true,
    isAllPassed: true,
  };

  return (
    <div>
      <span style={{ fontSize: '12pt', fontWeight: 'bold' }}>
        添削後はこのような成績表が見られます！
      </span>
      <div style={{ fontSize: '10pt', marginTop: '3px' }}>
        <span style={{ fontSize: '11pt', color: 'red' }}>昇級</span>
        するともっといろんな項目が見られるかも？
      </div>

      <p style={{ fontSize: '10pt' }}>成績表（例）</p>
      <div className={classes.videoWrapper}>
        <video
          style={{ margin: '3px' }}
          className={classes.videoBasic}
          src={
            'https://idaten-compressed-video.s3.amazonaws.com/video/7ec97e8fd971bce2fe82d73291193cc7.mp4'
          }
          controls
          muted
          loop
          poster={sample_thumbnail}
        ></video>
      </div>

      <Card
        style={{
          padding: '6px',
          paddingBottom: '10px',
          marginTop: '10px',
        }}
      >
        {sampleResult.map((ckpt, idx: number) => (
          <>
            <Grid container key={idx}>
              <VerticalLine idx={idx} />
              <Grid item xs={(12 - idx) as GridSize}>
                <MessageBar ckpt={ckpt} necessities={necessitiesForTheGrade} />
              </Grid>
            </Grid>
            {/* 間隔分の縦線 */}
            <SpaceLine idx={idx} maxLen={sampleResult.length} />
          </>
        ))}
        <div style={{ marginTop: '16px' }}></div>
        <MessageBar ckpt={upOrStay} necessities={necessitiesForTheGrade} />
      </Card>

      <div
        style={{
          backgroundColor: '#dbebc4',
          padding: '10px',
          borderRadius: '10px',
          marginTop: '20px',
          width: '96%',
        }}
      >
        <Grid container alignItems="flex-end">
          <Grid item xs={2}>
            <img
              className={coachClasses.muraiIcon}
              alt=""
              src={coach_murai}
              width="90%"
              style={{ borderRadius: '50%' }}
            />
          </Grid>
          <Grid xs={10}>
            <div style={{ marginLeft: '5px' }}>
              <div className={coachClasses.coachComment}>
                {
                  'idatenくん、継続的に練習ができていて素晴らしいですね！今回の動画は7級の目標である「足を体の前で回す！」が大変良くできています！まずidatenくんのよかった点としては、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！姿勢もばっちりです！そんなidatenくんに上級者向けの伸びしろポイントをお伝えすると「足を体の遠くについてしまっている」ことがあります。足を腰から遠く前に着いてしまうと1歩1歩ブレーキがかかってしまいます！上がっている足を「真下にポンポンおとして走る」ことを意識してみましょう！'
                }
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
