import { ckptDetails_7_20 } from 'components/MenuConst/GoalsForEachGrade/CheckpointsFor20Pkg/13_kyu';

type options = {
  [key in ckptDetails_7_20]: string[];
};

export const optionsForGrade_7_20pkg: options = {
  構えで前脚に体重がのっている: ['◯:ちょうど良い', '×:足首が膝より前'],
  構えで上体角度が45度程度: ['◯:45度程度', '×:倒しすぎ', '×:起きすぎ'],
  構えで横から見て上体と後ろ足がまっすぐ: [
    '◯:まっすぐ',
    '×:腰が引けている',
    '×:腰が反っている',
  ],
  走りで6歩以上体を倒せている: ['◯:倒せている', '×:すぐ起きてしまっている'],
  足を前に前に持ってくる: ['◯:出来ている', '×:出来ていない'],
  うでを前に前に振る: ['◯:前に振れている', '×:振れていない'],
};
