// 20級パッケージの4級（バウンディング）
type ckptTypeForGrade_16_20kyu =
  | 'よい姿勢'
  | '空中マリオポーズ'
  | 'ポンポンはずむ'
  | '前に進む';

export const ckptForGrade_16_20kyu: ckptTypeForGrade_16_20kyu[] = [
  'よい姿勢',
  '空中マリオポーズ',
  'ポンポンはずむ',
  '前に進む',
];

export const requirement_16_20kyu: ckptTypeForGrade_16_20kyu[] = [
  'よい姿勢',
  '空中マリオポーズ',
  'ポンポンはずむ',
];

export type ckptDetails_16_20 =
  | '前後傾'
  | '腰の引け具合'
  | '足が前に戻ってきている'
  | '後ろに蹴り上げている'
  | 'リズムが早く大股走りになっている'
  | 'はずめている'
  | '膝がつぶれている'
  | '前に進んでいる'
  | '腕が使えている'



  type allCheckpoints_16_20kyu_type = {
    [key in ckptTypeForGrade_16_20kyu]: ckptDetails_16_20[];
  };

  export const allCheckPoints_16_20kyu: allCheckpoints_16_20kyu_type = {
    よい姿勢: ['腰の引け具合', '前後傾'],
    空中マリオポーズ: ['足が前に戻ってきている'],
    ポンポンはずむ:['リズムが早く大股走りになっている' ,'はずめている'],
    前に進む: ['前に進んでいる'],
  };

  export const borderLine_16_20kyu: allCheckpoints_16_20kyu_type = {
    よい姿勢: ['腰の引け具合', '前後傾'],
    空中マリオポーズ: ['足が前に戻ってきている'],
    ポンポンはずむ:['リズムが早く大股走りになっている' ,'はずめている'],
    前に進む: ['前に進んでいる'],
  };

