import React from 'react';
import Skip_thumb from 'components/MenuConst/thumbnails/skip_challenge.jpg';
import ySprint_6_thumb from 'components/MenuConst/thumbnails/y_sprint_6.png';
import cycleDash_thumb from 'components/MenuConst/thumbnails/cycle_dash.png';
import intro_6th_thumb from 'components/MenuConst/thumbnails/intro_6th.jpg';
import henkei_6th_thumb from 'components/MenuConst/thumbnails/henkeidash_6th.jpg';
import challenge6th_thumb from 'components/MenuConst/thumbnails/challenge_6th.jpg';
import {
  cycleDashVideos,
  skipBasicVideos,
  ySprint6Videos,
  henkei6thVideos,
  intro6thVideos,
} from './videos';

export const cycle = {
  title: '自転車☆1部家でもできる！#昇級テスト',
  grade: 4,
  duration: 20,
  menuVariation: ['tech'],
  image: cycleDash_thumb,
  video: cycleDashVideos,
};

export const skipBasic = {
  duration: 10,
  grade: 4,
  menuVariation: ['run'],
  title: 'スキップチャレンジ！',
  image: Skip_thumb,
  video: skipBasicVideos,
};

export const y_sprint_6 = {
  title: 'Y字もも上げスプリント',
  grade: 4,
  duration: 12,
  menuVariation: ['up'],
  image: ySprint_6_thumb,
  video: ySprint6Videos,
};

export const challenge_6th = {
  duration: 5,
  hitWordList: ['6', '昇級', 'ろく', 'ろっきゅう'],
  title: '6級の昇級用メニュー',
  grade: 4,
  image: challenge6th_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/2kWvq8aO5Co',
      title: '6級の昇級用メニュー',
      message: <span>6級の昇級テスト挑戦動画はコチラ！</span>,
    },
  ],
};

export const intro_6th = {
  duration: 2,
  hitWordList: ['6', 'ろく', '昇級', 'メッセージ', 'めっせーじ', 'ろっきゅう'],
  title: '6級の方へのメッセージ',
  grade: 4,
  image: intro_6th_thumb,
  video: intro6thVideos,
  menuVariation: ['family'],
};

export const henkei_6th = {
  title: '変形ダッシュ',
  grade: 7,
  duration: 5,
  image: henkei_6th_thumb,
  video: henkei6thVideos,
  menuVariation: ['family'],
};
