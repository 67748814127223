import axios from 'axios';
import { HOST, setTokenToHeader } from 'v1apiRequest';
import { AuthApiInfo, RunnerInfo } from '../types';

export const sendRegisterInfo = (params: AuthApiInfo) =>
  axios.post(`${HOST}/register/`, {
    email: params.email,
    password: params.password,
    re_password: params.confirmPassword,
    username: params.userName,
    gender: params.gender,
    birthday: params.birthday,
    height: params.height,
    app: params.app,
    app_version: params.app_version,
    app_domain: params.domain,
    student_group: decideStudentGroup(params.student_group),
  });

export const postDjoserRegister = (params: AuthApiInfo) =>
  axios.post(`${HOST}/auth/users/`, {
    email: params.email,
    password: params.password,
    re_password: params.confirmPassword,
    username: params.userName,
    gender: params.gender,
    height: params.height,
    app: params.app,
    app_version: params.app_version,
    app_domain: params.domain,
  });

export const sendRunnerRegister = (params: RunnerInfo) =>
  axios.post(`${HOST}/register-runner/`, params, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: setTokenToHeader(),
    },
  });

function decideStudentGroup(
  student_group: string | undefined = undefined,
): string {
  if (!!student_group) {
    return convertStudentGroup(student_group);
  }

  switch (process.env.REACT_APP_FRONT_DOMAIN) {
    case 'https://www.idaten-nas.com/':
      return 'nas';
    case 'https://www.idaten-app.com/':
      return 'newtrac';
    case 'https://www.idaten-kids.com/':
      return 'ai';
    default:
      return 'normal';
  }
}

function convertStudentGroup(groupName: string): string {
  if (groupName.indexOf('nas') !== -1) {
    return 'nas';
  }

  return 'newtrac';
}
