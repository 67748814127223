import React from 'react';
import classes from '../guidanceTools.module.css';
import { useNavigate } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { buttonStateType } from '../type';

export const SearchButtonPopover = (
  buttonState: buttonStateType,
  videoId: string | null,
) => {
  const navigate = useNavigate();

  const handleClick = () => {
    console.log(videoId);
    if (!!videoId) {
      navigate(`/logs/result/${videoId}`);
    }
  };

  if (buttonState === 'shouldTouch') {
    return (
      <div className={classes.popover}>
        <span style={{ fontWeight: 'normal' }}>
          先生からのコメントをまってる間は自分の動きを見てみよう！
        </span>
        <div
          style={{
            margin: '24px 0 12px 0',
            textAlign: 'center',
            width: '100%',
          }}
        >
          <Button
            onClick={handleClick}
            style={{
              width: '60%',
              fontWeight: 'bold',
              fontSize: '12pt',
              backgroundColor: '#0000ff',
              color: 'white',
            }}
            variant="contained"
          >
            {'みる！'}
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div className={classes.popover}>
      昇級テストに挑戦したあとは自分の動きをかくにんできるよ！
    </div>
  );
};
