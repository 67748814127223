import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from 'contexts/AccountContext';

import { Button, TextField, Grid } from '@material-ui/core';
import { SendButton } from '../components/SendButton';
import { GenderInput } from 'pages/auth/Register/components/GenderInput';
import { HeightInput } from 'pages/auth/Register/components/HeightInput';
import { HandleChangeType, RunnerInfo } from '../types';
import { sendRunnerRegister } from '../api/register';

export const AddRunnerPage = () => {
  const navigate = useNavigate();

  const { accountInfo } = React.useContext(AccountContext);
  const [runnerInfo, setRunnerInfo] = React.useState<RunnerInfo>({
    userName: '',
    gender: '',
    height: 140,
  });
  const [isUploading, setIsUploading] = React.useState(false);
  const [validationMessage, setValidationMessage] = React.useState({
    userName: '',
    gender: '',
    height: '',
  });
  const [isFormComplete, setIsFormComplete] = React.useState(false);

  React.useEffect(() => {
    const formValues = Object.values({
      ...runnerInfo,
      showPassword: 'as',
    });
    const isAllFilled = formValues.every((f) => String(f).length > 0);
    const isNoValidation = Object.values(validationMessage).every(
      (f: string) => f.length === 0,
    );

    if (isAllFilled && isNoValidation) {
      setIsFormComplete(true);
    } else setIsFormComplete(false);
  }, [runnerInfo, validationMessage]);

  const handleInfoChange = (key: keyof RunnerInfo) => (e: HandleChangeType) => {
    setRunnerInfo({ ...runnerInfo, [key]: e.target.value });
    if (key === 'userName') {
      if (e.target.value.length > 7) {
        setValidationMessage({
          ...validationMessage,
          userName: 'おなまえは７文字以内がおすすめです',
        });
        return;
      }
      setValidationMessage({
        ...validationMessage,
        userName: '',
      });
    }
  };

  const handleSendClick = () => {
    const student_group =
      (!!accountInfo?.runners && accountInfo.runners[0].student_group) ||
      'normal';
    if (!accountInfo) {
      alert('ホーム画面に一度戻ってください');
      navigate('/');
      return;
    }
    const sendInfo = {
      ...runnerInfo,
      ...{
        last_name: 'df',
        first_name: 'ads',
        owner: accountInfo.id,
        username: runnerInfo.userName,
        student_group: student_group,
      },
    };
    console.log(sendInfo);
    setIsUploading(true);
    sendRunnerRegister(sendInfo)
      .then((res) => {
        //alert('account created');
        console.log(res.data);
        setIsUploading(false);
        localStorage.setItem('currentRunner', String(res.data.id));
        navigate('/');

        // 今度はここからトークンを取りに行く処理
      })
      .catch((error) => {
        console.log(error);
        setIsUploading(false);
      });
  };

  return (
    <div style={{ textAlign: 'center' }}>
      ランナーを追加
      <div>
        <TextField
          style={{ marginTop: '20px', width: '70%' }}
          value={runnerInfo.userName}
          label="お名前（下の名前）"
          onChange={handleInfoChange('userName')}
          helperText={validationMessage.userName}
          placeholder="（例）ひかる"
        />

        <div style={{ marginTop: '20px' }}>
          <Grid container style={{ width: '70%', margin: 'auto' }}>
            <Grid item xs={6}>
              <GenderInput
                gender={runnerInfo.gender}
                handleChange={handleInfoChange('gender')}
              />
            </Grid>
            <Grid item xs={6}>
              <HeightInput
                handleHeightChange={handleInfoChange('height')}
                height={runnerInfo.height}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      {isFormComplete ? (
        <SendButton
          isUploading={isUploading}
          onClickFunc={handleSendClick}
          title={'登録'}
        />
      ) : (
        <Button variant="contained" disabled style={{ marginTop: '30px' }}>
          登録
        </Button>
      )}
    </div>
  );
};
