import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';

//////////////////////////////////////////////////////
//up
/////////////////////////////////////////////////////

export const armSwingWalkVideos = [
  {
    link: 'https://www.youtube.com/embed/e4zKa-kz_2o',
    title: 'うで振りウォーク',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画1）「うで振りウォーク15m」× 2セット（目安）行う！
        </div>
        <div className={classes.content}>
          慣れてきたら「うで振りウォークでスピードを上げる⇒そのまま15mくらいダッシュ！」×
          3セット（目安）行う！
          <br />
          ☆まだうでふり慣れないな・・・という子は（動画2,3）を見ながらうでふりの復習をしてみよう！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/xwRSfjwvLMs',
    title: 'その場うでふり',
    message: <div>（動画2）「その場腕振り」を復習してみよう！</div>,
  },
  {
    link: 'https://www.youtube.com/embed/mXkqgxMT_l0',
    title: 'リズム腕振り',
    message: (
      <div>
        （動画3）「リズム腕振り」を復習してみよう！
        <br />
        ☆保護者さまはよろしければ手をたたいてリズムをとってあげてください！はじめはゆっくり、慣れてきたら1秒に4回のリズムがおすすめです！
      </div>
    ),
  },
];

//////////////////////////////////////////////////////
//tech
/////////////////////////////////////////////////////

export const highKneeDashVideos = [
  {
    link: 'https://www.youtube.com/embed/RgfOs-J-nWk',
    title: 'Y字ポジションもも上げスプリント',
    message: (
      <span>
        （動画1）「Y字ポジションもも上げスプリント10m⇒そのままうでをおろして15mダッシュ」×
        3本行う！
        <br />
        ☆「あしをからだの前で動かす」感覚のままダッシュしてみよう！
      </span>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/Yi3w66j82jo',
    title: '連続ハイニー前進',
    message: (
      <span>
        （動画2）「連続ハイニー前進10m⇒そのまま15mダッシュ」を3～5本おこなう！！
        <br />
        ☆連続ハイニーの時のいい姿勢や足の動かし方のままダッシュしてみよう！
        <br />
        ☆慣れてきたときこそ「いい姿勢（まっすぐ＋おしり高く）」を忘れずに！腕ふり忘れてないかも確認！
      </span>
    ),
  },
];

//////////////////////////////////////////////////////
//family
/////////////////////////////////////////////////////

export const dash30m7thVideos = [
  {
    link: 'https://www.youtube.com/embed/KDRUp0TnCBI',
    title: '7級イントロ動画',
    message: (
      <div>
        （動画1）下の動画を見なおした上で、「30mダッシュ」にたくさんチャレンジ！
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本を送信！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          昇級のポイント
        </div>
        1. よい姿勢 ☆たおしすぎていないかな？あごを引いて15m先に地面を見よう！
        <br />
        2. 足を体の前で回す
        ☆ふとももがちゃんとあがっているかな？ハイニーダッシュのときのように「あしをからだの前で動かす」感覚のままダッシュしてみよう！
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ2つができた子はチャレンジ！～
        </div>
        3. 足のつき方 ☆ぼしきゅうからつけているかな？
        <br />
        4. うで振り ☆下にポンポンふろう！
        <br />
        5. スピードを出す
        ☆なれてきたらチャレンジ！思いっきり走る練習はとてもだいじ！
      </div>
    ),
  },
];

////////////////////////////////////////////////////
//20級パッケージ17級用のrun
////////////////////////////////////////////////////

//うでふりウォークは上に

export const springVideos = [
  {
    link: 'https://www.youtube.com/embed/1isIaXlEFZw',
    title: 'ベースポジションジャンプ移動',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「ベースポジションジャンプ移動」をたくさんやってみる（目安3～4分）！！
        </div>
        <div className={classes.content}>
          ☆両方の足でやってみよう！かっこいい姿勢の移動できるかな？
          <br />
          ☆よろしければ保護者の方は肩を軽く押してあげて下さい！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/QYWtDk9aCuM',
    title: '全力ケンケン前進',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「全力ケンケン」にチャレンジ！
        </div>
        <div className={classes.content}>
          ☆何歩で行けたかな？よかったら毎練習自己ベストに挑戦しよう！
          <br />
          ☆両方の足でやってみよう！負荷が大きいのでやりすぎ注意！（目安は左右1～3回ずつ）！
        </div>
      </div>
    ),
  },
];

export const highKneeDash_17th_Videos = [
  {
    link: 'https://www.youtube.com/embed/RgfOs-J-nWk',
    title: 'Y字ポジションもも上げスプリント',
    message: (
      <span>
        （動画1）「Y字ポジションもも上げスプリント10m⇒そのままうでをおろして15mダッシュ」×
        3本行う！
        <br />
        ☆「あしをからだの前で動かす」感覚のままダッシュしてみよう！
      </span>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/Yi3w66j82jo',
    title: '連続ハイニー前進',
    message: (
      <span>
        （動画2）「連続ハイニー前進10m⇒そのまま15mダッシュ」を3～5本おこなう！！
        <br />
        ☆連続ハイニーの時のいい姿勢や足の動かし方のままダッシュしてみよう！
        <br />
        ☆慣れてきたときこそ「いい姿勢（まっすぐ＋おしり高く）」を忘れずに！腕ふり忘れてないかも確認！
      </span>
    ),
  },
];

////////////////////////////////////////////////////
//20級パッケージ17級用のfamily
////////////////////////////////////////////////////

//かけっこ診断はgrade_0のvideosから

export const towelhighkneeVideos = [
  {
    link: 'https://www.youtube.com/embed/7VW4zxcKa5Q',
    title: 'タオルもも上げ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「タオルももあげ20歩」× 3本（目安）を行う！
        </div>
        <div className={classes.content}>
          ☆なれてきたら前に進んだり走ったりしてみよう！
          <br />
          ☆ハイニーについて忘れちゃった子は次の動画を見返してみよう！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/ICJshfVfBQA',
    title: '連続ハイニー',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2: 「連続ハイニー」の復習はこちら！！
        </div>
      </div>
    ),
  },
];

export const henkeiDashVideos = [
  {
    link: 'https://www.youtube.com/embed/6a4_35tpqq0',
    title: '変形ダッシュ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「変形ダッシュ15m」× 4本（目安）やってみよう！！
        </div>
      </div>
    ),
  },
];

////////////////////////////////////////////////////
// TRAC
////////////////////////////////////////////////////

export const basic_swing_1 = [
  {
    link: 'https://www.youtube.com/embed/Cn7ZktZc0GQ',
    title: '腕ふりの練習 A',
    message: (
      <div>
        <div className={classes.subtitle}>うでふりの練習をしよう A</div>
        <div className={classes.content}>
          ☆ぜひ動画のまねをしてやってみてね！
        </div>
      </div>
    ),
  },
];

export const basic_swing_2 = [
  {
    link: 'https://www.youtube.com/embed/KPpJv2U81Ds',
    title: '腕ふりの練習 B',
    message: (
      <div>
        <div className={classes.subtitle}>うでふりの練習をしよう B</div>
        <div className={classes.content}>
          ☆ぜひ動画のまねをしてやってみてね！
        </div>
      </div>
    ),
  },
];

export const basic_swing_3 = [
  {
    link: 'https://www.youtube.com/embed/02DsNQLzy58',
    title: '腕ふりの練習 C',
    message: (
      <div>
        <div className={classes.subtitle}>うでふりの練習をしよう C</div>
        <div className={classes.content}>
          ☆ぜひ動画のまねをしてやってみてね！
        </div>
      </div>
    ),
  },
];

export const swing_intro = [
  {
    link: 'https://www.youtube.com/embed/C86NSrb8iHw',
    title: '腕ふりの解説',
    message: (
      <div>
        <div className={classes.subtitle}>うでふりの勉強をしよう！</div>
        <div className={classes.content}>☆この動画は見るだけでOK</div>
      </div>
    ),
  },
];

export const armSwingTest = [
  {
    link: 'https://www.youtube.com/embed/g5q8WHPklJg',
    title: '腕ふり！',
    message: (
      <div>
        <div>
          <div className={classes.subtitle}>
            その場腕ふりをやってみる！
            <br />
            ☆昇級テストの挑戦は右下の ➕ボタン⇒昇級に挑戦」から行えます！
          </div>
          <div className={classes.title_important}>昇級のポイント</div>
          <div className={classes.content}>
            1. 良いしせいを作る！
            <br />
            2. ひじをしっかり曲げる
            <br />
            3. うでをしっかりふり下ろす！
          </div>
        </div>
      </div>
    ),
  },
];
