import React, { FC, useState } from 'react';
import {
  basicMenu,
  y_sprint,
  kabeoshiBasic,
  highKnee,
  cycle,
  threeStep,
  mark_run,
  y_highKnee,
  arm_swing_Walk,
  MenuType,
  //eslint-disable-next-line
} from 'components/MenuConst/DiagnoseMenu';
import { Card, Grid, CardActionArea } from '@material-ui/core';
import classes from './timeviewer.module.css';
import murai from 'static/coach-murai.png';

import { useMenuWatchModal } from 'hooks/useMenuWatchModal';
import { evalNumber } from 'components/MenuConst/CommentForEachPoint';

type Props = {
  posture: evalNumber;
  arm: evalNumber;
  leg: evalNumber;
  yobina: string;
};

export const TrainingMenuViewer: FC<Props> = ({
  posture,
  arm,
  leg,
  yobina,
}) => {
  const customizedMenu_1 = y_sprint;
  const customizedMenu_2 = crtcustomizedMenu(posture, arm, leg);
  const handleMenuClick = (menu: MenuType) => () => {
    console.log('click');
    forceModalOpen(true);
    setSelectedMenu(menu);
  };

  const [selectedMenu, setSelectedMenu] = useState<MenuType>(basicMenu);

  const { forceModalOpen, MenuWatchModal } = useMenuWatchModal(selectedMenu);

  return (
    <div style={{ textAlign: 'left' }}>
      {MenuWatchModal}

      <div style={{ margin: '12px 20px 12px 20px' }}>
        <Grid container alignItems="flex-end">
          <Grid item xs={1}>
            <img
              className={classes.muraiIcon}
              alt=""
              src={murai}
              width="90%"
              style={{ borderRadius: '50%' }}
            />
          </Grid>
          <Grid xs={11}>
            <div style={{ marginLeft: '5px' }}>
              <div className={classes.coachComment2}>
                {yobina}におすすめの練習は
                <br />
                <span style={{ fontWeight: 'bold', color: '#ff1493' }}>
                  「{customizedMenu_2.title}」
                </span>
                だ！
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          fontWeight: 'bold',
          marginBottom: '16px',
          textAlign: 'center',
        }}
      >
        おすすめ練習
        <br />
        <span
          style={{ fontWeight: 'normal', fontSize: '10pt', color: '#424242' }}
        >
          （サムネイル画像をタップすると動画を見られます！）
        </span>
      </div>
      <div>
        <MenuCard handleClickFunc={handleMenuClick} menuList={basicMenu} />
        <MenuCard
          handleClickFunc={handleMenuClick}
          menuList={customizedMenu_1}
        />
        <MenuCard
          handleClickFunc={handleMenuClick}
          menuList={customizedMenu_2}
        />
      </div>
    </div>
  );
};

type handleClickFuncType = (menu: MenuType) => () => void;

type MenuCardProps = {
  handleClickFunc: handleClickFuncType;
  menuList: MenuType;
};
export const MenuCard: FC<MenuCardProps> = ({ handleClickFunc, menuList }) => {
  return (
    <Card style={{ width: '94%', margin: '0 0 8px 3%' }}>
      {/* <div
        style={{ padding: '3px 0 0 0', textAlign: 'center', fontSize: '10pt' }}
      >
        ステップ１
      </div> */}
      <Grid container style={{ marginTop: '6px' }}>
        <Grid item xs={4}>
          <div style={{ padding: '3px' }}>
            <CardActionArea>
              <img
                alt=""
                width="96%"
                src={menuList.image}
                onClick={handleClickFunc(menuList)}
              ></img>
            </CardActionArea>
          </div>
        </Grid>
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={9}>
              <div style={{ fontWeight: 'bold', fontSize: '10pt' }}>
                {menuList.title}
              </div>
              <div style={{ fontSize: '10pt' }}>
                - IDATEN Kids{menuList.grade}
              </div>
              <div style={{ fontSize: '10pt' }}>
                <span>難易度: </span>
                <Stars count={menuList.difficulty} />
              </div>
            </Grid>
            <Grid item xs={3}>
              <TargetPoints targets={menuList.target} />
            </Grid>
          </Grid>

          {/* <div style={{ textAlign: 'center', margin: '0px 0 8px 0' }}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleClickFunc(menuList)}
            >
              練習を見る
            </Button>
          </div> */}
        </Grid>
      </Grid>
    </Card>
  );
};

type StarProps = {
  count: number;
};

const Stars: FC<StarProps> = ({ count }) => {
  if (count === 1) {
    return (
      <>
        <span style={{ color: '#ffa500' }}>★</span>
        <span style={{ color: '#a9a9a9' }}>☆☆☆</span>
      </>
    );
  }
  if (count === 2) {
    return (
      <>
        <span style={{ color: '#ffa500' }}>★★</span>
        <span style={{ color: '#a9a9a9' }}>☆☆</span>
      </>
    );
  }
  if (count === 3) {
    return (
      <>
        <span style={{ color: '#ffa500' }}>★★★</span>
        <span style={{ color: '#a9a9a9' }}>☆</span>
      </>
    );
  }
  return (
    <>
      <span style={{ color: '#ffa500' }}>★★★★</span>
    </>
  );
};

type TargetProps = {
  targets: string[];
};

const TargetPoints: FC<TargetProps> = ({ targets }) => {
  return (
    <>
      <div
        style={{
          fontSize: '8pt',
          color: '#424242',
          textAlign: 'center',
          width: '80%',
        }}
      >
        ねらい
      </div>
      {targets.map((targetWord) => (
        <div
          style={{
            borderRadius: '20px',
            fontSize: '8pt',
            border: '1px solid #6495ed',
            backgroundColor: '#6495ed',
            color: 'white',
            textAlign: 'center',
            width: '80%',
            marginBottom: '4px',
            fontWeight: 'bold',
          }}
        >
          {targetWord}
        </div>
      ))}
    </>
  );
};

const crtcustomizedMenu = (
  posture: evalNumber,
  arm: evalNumber,
  leg: evalNumber,
) => {
  ////////////
  ////posture前傾しすぎ（1,7）
  if (posture === '1' || posture === '7') {
    return y_highKnee;
  }

  ////////////
  ////leg全然上がってない（1,2）

  //パターン１：動きが小さい（後ろに蹴ってない）（1）
  if (leg === '1') {
    return kabeoshiBasic;
  }
  //パターン2：後ろに蹴っている（2）
  if (leg === '2') {
    return kabeoshiBasic;
  }

  ////////////
  ////posture後傾（3,4）
  if (posture === '3' || posture === '4') {
    return cycle;
  }

  ////////////
  ////postureやや前傾（2）

  if (posture === '2') {
    //足ちょっと上がってない&流れ気味
    if (leg === '3') {
      return y_highKnee;
    }
    //足ちょっと上がってない
    if (leg === '4') {
      return highKnee;
    }
    // 上がり具合はよいが流れ
    if (leg === '6') {
      return cycle;
    }
    //leg === '5'（あがりすぎ）
    if (leg === '5') {
      //腕ふりおおきい
      if (arm === '4' || arm === '5' || arm === '6') {
        return arm_swing_Walk;
      }
      return mark_run;
    }
    //leg === '7'（といいつつ流れ気味）の時
    if (arm === '1' || arm === '2' || arm === '3') {
      return arm_swing_Walk;
    }
    if (arm === '4' || arm === '5' || arm === '6') {
      return arm_swing_Walk;
    }
    return threeStep;
  }

  ////////////
  ////posture前後傾はいいが足がめちゃ流れ気味（⇒腰が引けている）（5）

  if (posture === '5') {
    return y_highKnee;
  }

  ////////////
  ////postureよい（6）
  if (posture === '6') {
    //足ちょっと上がってない&流れ気味
    if (leg === '3') {
      return cycle;
    }
    //足ちょっと上がってない
    if (leg === '4') {
      return highKnee;
    }
    // 上がり具合はよいが流れ
    if (leg === '6') {
      return cycle;
    }
    //leg === '5'（あがりすぎ）
    if (leg === '5') {
      return mark_run;
    }
    ////以下 leg === '7'
    //腕振り小さい
    if (arm === '1' || arm === '2' || arm === '3') {
      return arm_swing_Walk;
    }
    //腕振り大きい
    if (arm === '4' || arm === '5' || arm === '6') {
      return arm_swing_Walk;
    }
    //腕振り前ひじ伸びている
    if (arm === '7') {
      return arm_swing_Walk;
    }
    //腕振りひじをかかえこんでいる
    if (arm === '9') {
      return arm_swing_Walk;
    }
    //all A
    return threeStep;
  }

  return arm_swing_Walk;
};
