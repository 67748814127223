import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Fade, Backdrop } from '@material-ui/core';

const useStylesForModal = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fffafa',
    border: '3px solid #87cefa',
    borderRadius: '20px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 1, 1),
    textAlign: 'center',
  },
  paper_yellow: {
    backgroundColor: '#fffafa',
    border: '3px solid #ffd700',
    borderRadius: '20px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 1, 1),
    textAlign: 'center',
  },
}));

type ModalProps = {
  children: JSX.Element | JSX.Element[];
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ModalWrapper: FC<ModalProps> = ({
  children,
  isModalOpen,
  setIsModalOpen,
}) => {
  const classes = useStylesForModal();
  //   console.log(classes);

  const handleClose = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      onClose={handleClose}
      closeAfterTransition
      disableEscapeKeyDown
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <div className={classes.paper}>{children}</div>
      </Fade>
    </Modal>
  );
};

export const CustomizedModalWrapper: FC<ModalProps> = ({
  children,
  isModalOpen,
  setIsModalOpen,
}) => {
  const classes = useStylesForModal();

  const handleClose = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      onClose={handleClose}
      closeAfterTransition
      disableEscapeKeyDown
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <div className={classes.paper_yellow}>{children}</div>
      </Fade>
    </Modal>
  );
};
