// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'smoo... Remove this comment to see the full error message
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

export const scrollToCheckbox = () => {
    const targetElement = document.getElementById('checkboxes');

    // 画面上部から要素までの距離
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const rectTop = targetElement.getBoundingClientRect().top;
    // 現在のスクロール距離
    const offsetTop = window.pageYOffset;
    // スクロール位置に持たせるバッファ
    const buffer = 20;
    console.log(rectTop);
    const top = rectTop + offsetTop - buffer;

    window.scrollTo({
        top: top,
        behavior: 'smooth',
    });
};

export const goToTags = () => {
    const targetElement = document.getElementById('tags');
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const rectTop = targetElement.getBoundingClientRect().top;
    const offsetTop = window.pageYOffset;
    const buffer = 100;
    const top = rectTop + offsetTop - buffer;

    window.scrollTo({
        top,
        behavior: 'smooth',
    });
};

export const goToSendButton = () => {
    const targetElement = document.getElementById('sendButton');
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const rectTop = targetElement.getBoundingClientRect().top;
    const offsetTop = window.pageYOffset;
    const buffer = 100;
    const top = rectTop + offsetTop - buffer;

    window.scrollTo({
        top,
        behavior: 'smooth',
    });
};
