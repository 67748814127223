import { menuType } from 'pages/PracticePage/type';
import { dash10m2kyuVideos, re_kabeoshi3highkneeVideos } from './videos';
import kabeoshi3highknee_thumb from 'components/MenuConst/thumbnails/kabeoshi3highknee.png';
import startpose from 'components/MenuConst/thumbnails/start_posture.png';
import { howToStartPoseVideos } from '../grade_0/videos';
import dash_10m_thumb from 'components/MenuConst/thumbnails/dash_10m.jpg';

export const re_threeHighKnee: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '壁押し3歩ハイニー',
  isInside: 'able',
  target: ['しせい', '足の動き'],
  menuVariation: ['up'],
  hitWordList: ['3', 'ハイニー'],
  grade: 15,
  image: kabeoshi3highknee_thumb,
  video: re_kabeoshi3highkneeVideos,
};

export const re_howToStartPose: menuType = {
  duration: 5,
  title: 'スタートのかまえ',
  target: ['スタート'],
  isInside: 'able',
  menuVariation: ['up'],
  hitWordList: [
    'スタート',
    'すたーと',
    '構え',
    'かまえ',
    'いえ',
    '家',
    'おうち',
  ],
  grade: 7,
  image: startpose,
  video: howToStartPoseVideos,
};

export const dash10m_2kyu_20: menuType = {
  duration: 5,
  title: '10mダッシュ ☆昇級テスト',
  target: ['スタート'],
  isInside: 'unable',
  menuVariation: ['tech'],
  hitWordList: ['スタート', 'すたーと', '10', 'じゅう', 'ダッシュ', 'だっしゅ'],
  grade: 9,
  image: dash_10m_thumb,
  video: dash10m2kyuVideos,
};
