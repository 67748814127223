import React, { FC, useState } from 'react';

import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteVideoById } from 'v1apiRequest';
import { ConfirmationModal } from 'components/UtilsComponents';
import { VideoLabelForUser } from 'pages/LogPage/components/VideoLabelForUser';
import { ImgWithLoading } from 'pages/LogPage/components/ImgWithLoading';
import 'index.css';
import 'App.css';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { formatDateFromString } from 'utils/funcs/formatTime';

type handleClickType = (
  vId: string,
) => React.MouseEventHandler<HTMLImageElement>;

type Props = {
  video: VideoType;
  handleClick: handleClickType;
};

export const VideoCard: FC<Props> = (props) => {
  const { video, handleClick } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClickTrashBox = () => {
    setIsModalOpen(true);
  };
  const handleVideoDelete = (vId: string) => () => {
    deleteVideoById(vId)
      .then((res) => {
        console.log(res);
        window.location.reload(); //FIXME: ここはAPIをもう一回叩くくらいで良いかも
      })
      .catch((e) => console.log(e));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div id="videoCard">
      <ConfirmationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        execFunc={handleVideoDelete(video.id)}
        cancelFunc={handleCancel}
      />
      <Grid container>
        <Grid item xs={4}>
          <div>
            <ImgWithLoading
              src={`${video.thumbnail}`}
              width="92%"
              onClickFunc={handleClick(video.id)}
            />
          </div>
        </Grid>

        <Grid item xs={6}>
          <div style={{ textAlign: 'left' }}>
            <div style={{ fontSize: '10pt', display: 'flex' }}>
              <div onClick={handleClick(video.id)}>
                {formatDateFromString(video.uploaded_date).split('日')[0]}日
                <br />
                {formatDateFromString(video.uploaded_date).split('日')[1]}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={2}>
          <div style={{ marginLeft: '5px' }}>
            <VideoLabelForUser video={video} />
            <div style={{ textAlign: 'right', marginTop: '6px' }}>
              <DeleteIcon fontSize="small" onClick={handleClickTrashBox} />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
