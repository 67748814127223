import {
  ckptForGrade_0_20kyu,
  requirement_0_20kyu,
  allCheckPoints_0_20kyu,
  borderLine_0_20kyu,
} from './20_kyu';
import {
  allCheckPoints_1_20kyu,
  borderLine_1_20kyu,
  ckptForGrade_1_20kyu,
  requirement_1_20kyu,
} from './19_kyu';
import { ckptForGrade_10_20kyu, requirement_10_20kyu } from './grade_10';
import {
  allCheckPoints_11_20kyu,
  borderLine_11_20kyu,
  ckptForGrade_11_20kyu,
  requirement_11_20kyu,
} from './9_kyu';
import {
  allCheckPoints_12_20kyu,
  borderLine_12_20kyu,
  ckptForGrade_12_20kyu,
  requirement_12_20kyu,
} from './8_kyu';
import {
  allCheckPoints_13_20kyu,
  borderLine_13_20kyu,
  ckptForGrade_13_20kyu,
  requirement_13_20kyu,
} from './grade_13';
import { ckptForGrade_14_20kyu, requirement_14_20kyu } from './grade_14';
import {
  ckptForGrade_15_20kyu,
  requirement_15_20kyu,
  allCheckPoints_15_20kyu,
  borderLine_15_20kyu,
} from './5_kyu';
import {
  allCheckPoints_16_20kyu,
  borderLine_16_20kyu,
  ckptForGrade_16_20kyu,
  requirement_16_20kyu,
} from './4_kyu';
import { ckptForGrade_17_20kyu, requirement_17_20kyu } from './grade_17';
import { ckptForGrade_18_20kyu, requirement_18_20kyu } from './grade_18';
import { ckptForGrade_19_20kyu, requirement_19_20kyu } from './grade_19';
import {
  allCheckPoints_2_20kyu,
  borderLine_2_20kyu,
  ckptForGrade_2_20kyu,
  requirement_2_20kyu,
} from './grade_2';
import {
  allCheckPoints_3_20kyu,
  borderLine_3_20kyu,
  ckptForGrade_3_20kyu,
  requirement_3_20kyu,
} from './grade_3';
import {
  allCheckPoints_4_20kyu,
  borderLine_4_20kyu,
  ckptForGrade_4_20kyu,
  requirement_4_20kyu,
} from './grade_4';
import {
  allCheckPoints_5_20kyu,
  borderLine_5_20kyu,
  ckptForGrade_5_20kyu,
  requirement_5_20kyu,
} from './grade_5';
import {
  allCheckPoints_6_20kyu,
  borderLine_6_20kyu,
  ckptForGrade_6_20kyu,
  requirement_6_20kyu,
} from './grade_6';
import {
  allCheckPoints_7_20kyu,
  borderLine_7_20kyu,
  ckptForGrade_7_20kyu,
  requirement_7_20kyu,
} from './13_kyu';
import {
  allCheckPoints_8_20kyu,
  borderLine_8_20kyu,
  ckptForGrade_8_20kyu,
  requirement_8_20kyu,
} from './grade_8';
import {
  allCheckPoints_9_20kyu,
  borderLine_9_20kyu,
  ckptForGrade_9_20kyu,
  requirement_9_20kyu,
} from './11_kyu';
import {
  allCheckPoints_3kyu_20,
  borderLine_3kyu_20,
  ckptForGrade_3kyu_20,
  requirement_3kyu_20,
} from './3_kyu';
import {
  allCheckPoints_2kyu_20,
  borderLine_2kyu_20,
  ckptForGrade_2kyu_20,
  requirement_2kyu_20,
} from './2_kyu';

export const standardsFor20grades = {
  20: ckptForGrade_0_20kyu,
  19: ckptForGrade_1_20kyu,
  18: ckptForGrade_2_20kyu,
  17: ckptForGrade_3_20kyu,
  16: ckptForGrade_4_20kyu,
  15: ckptForGrade_5_20kyu,
  14: ckptForGrade_6_20kyu,
  13: ckptForGrade_7_20kyu,
  12: ckptForGrade_8_20kyu,
  11: ckptForGrade_9_20kyu,
  10: ckptForGrade_10_20kyu,
  9: ckptForGrade_11_20kyu,
  8: ckptForGrade_12_20kyu,
  7: ckptForGrade_13_20kyu,
  6: ckptForGrade_14_20kyu,
  5: ckptForGrade_15_20kyu,
  4: ckptForGrade_16_20kyu,
  3: ckptForGrade_3kyu_20,
  2: ckptForGrade_2kyu_20,
  1: ckptForGrade_3kyu_20,
};

export const requirementsToPass_20_kyu = {
  20: requirement_0_20kyu,
  19: requirement_1_20kyu,
  18: requirement_2_20kyu,
  17: requirement_3_20kyu,
  16: requirement_4_20kyu,
  15: requirement_5_20kyu,
  14: requirement_6_20kyu,
  13: requirement_7_20kyu,
  12: requirement_8_20kyu,
  11: requirement_9_20kyu,
  10: requirement_10_20kyu,
  9: requirement_11_20kyu,
  8: requirement_12_20kyu,
  7: requirement_13_20kyu,
  6: requirement_14_20kyu,
  5: requirement_15_20kyu,
  4: requirement_16_20kyu,
  3: requirement_3kyu_20,
  2: requirement_2kyu_20,
  1: requirement_3kyu_20,
};

export const allCheckpoints_20_kyu = {
  20: allCheckPoints_0_20kyu,
  19: allCheckPoints_1_20kyu,
  18: allCheckPoints_2_20kyu,
  17: allCheckPoints_3_20kyu,
  16: allCheckPoints_4_20kyu,
  15: allCheckPoints_5_20kyu,
  14: allCheckPoints_6_20kyu,
  13: allCheckPoints_7_20kyu,
  12: allCheckPoints_8_20kyu,
  11: allCheckPoints_9_20kyu,
  10: allCheckPoints_5_20kyu,
  9: allCheckPoints_11_20kyu,
  8: allCheckPoints_12_20kyu,
  7: allCheckPoints_13_20kyu,
  6: allCheckPoints_1_20kyu,
  5: allCheckPoints_15_20kyu,
  4: allCheckPoints_16_20kyu,
  3: allCheckPoints_3kyu_20,
  2: allCheckPoints_2kyu_20,
  1: allCheckPoints_3kyu_20,
};

export const toGetGoodRequirements_20 = {
  20: borderLine_0_20kyu,
  19: borderLine_1_20kyu,
  18: borderLine_2_20kyu,
  17: borderLine_3_20kyu,
  16: borderLine_4_20kyu,
  15: borderLine_5_20kyu,
  14: borderLine_6_20kyu,
  13: borderLine_7_20kyu,
  12: borderLine_8_20kyu,
  11: borderLine_9_20kyu,
  10: borderLine_1_20kyu,
  9: borderLine_11_20kyu,
  8: borderLine_12_20kyu,
  7: borderLine_13_20kyu,
  6: borderLine_1_20kyu,
  5: borderLine_15_20kyu,
  4: borderLine_16_20kyu,
  3: borderLine_3kyu_20,
  2: borderLine_2kyu_20,
  1: borderLine_3kyu_20,
};
