import React from 'react';
import { ExplanationPageMobile } from './ExplanationPageMobile';
import { ExplanationPagePC } from './ExplanationPagePC';
import { useWindowSize } from 'hooks/useWindowSize';

export const NASExplanationPage = () => {
  const size = useWindowSize();

  console.log(size);

  const isSmartPhoneView = size[0] < 500 || size[1] / size[0] > 1.5;

  if (isSmartPhoneView) {
    return (
      <>
        <ExplanationPageMobile />
      </>
    );
  }
  return <ExplanationPagePC />;
};

type sizeType = number[];
export const calculateVideoScreen = (size: sizeType) => {
  return {
    width: Math.trunc(size[0] * 0.9),
    height: Math.trunc(size[0] * 0.6),
  };
};
