import React, { FC, memo } from 'react';
// import { useNavigate } from 'react-router-dom';
import { usePopover } from 'hooks/usePopover';
import { SearchButtonPopover } from './popovers/SearchButtonPopover';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { onboardType } from './type';
import classes from './guidanceTools.module.css';
import SearchIcon from '@mui/icons-material/Search';

type Props = {
  onboardState: onboardType;
  video: VideoType | null;
};
export const SearchButton: FC<Props> = memo(({ onboardState, video }) => {
  const videoId = video?.id || null;
  const buttonState = decideButtonState(onboardState);
  const innerComponent = SearchButtonPopover(buttonState, videoId);

  const { handleClick, CustomPopover } = usePopover(innerComponent);
  return (
    <>
      <div className={classes.loupePosition}>
        <div onClick={handleClick}>
          {buttonState === 'shouldTouch' && (
            <div className={classes.shouldBeTouched}>
              <div className={classes.iconContent}>
                <SearchIcon
                  style={{
                    fontSize: '22vw',
                    color: '#a9a9a9',
                  }}
                />
              </div>
            </div>
          )}
          {buttonState === 'canTouch' && (
            <div className={classes.canTouch}>
              <div className={classes.iconContent}>
                <SearchIcon
                  style={{
                    fontSize: '16vw',
                    color: '#a9a9a9',
                  }}
                />
              </div>
            </div>
          )}

          {buttonState === 'disabled' && (
            <div className={classes.disabled}>
              <div className={classes.iconContent}>
                <SearchIcon
                  style={{
                    fontSize: '16vw',
                    color: '#a9a9a9',
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {CustomPopover}
      </div>
    </>
  );
});

const decideButtonState = (onboardState: onboardType) => {
  if (
    ['beforeMsg', 'beforePractice', 'beforeChallenge'].includes(onboardState)
  ) {
    return 'disabled';
  }
  if (onboardState === 'beforeLetter') {
    return 'shouldTouch';
  }
  return 'canTouch';
};
