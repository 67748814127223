import React from 'react';
import classes from './header.module.css';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LogoutIcon from '@mui/icons-material/Logout';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';

import {
  Menu,
  MenuItem,
  Fade,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

// import { AppContext } from './router/Router';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import { SwipableMenu } from '../../SwipableMenu';
import { AccountContext } from 'contexts/AccountContext';
import { RunnerSwitcher } from './RunnerSwitcher';

import './AccountSwitch.css';

export const MemberHeader = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { setIsAuthenticated } = React.useContext(AuthContext);

  const { accountInfo, currentRunner } = React.useContext(AccountContext);

  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.setItem('access', 'undefined');
    localStorage.setItem('currentRunner', '');
    setIsAuthenticated(false);
    navigate(`/login`);
  };
  const handleGoToAccount = () => {
    setAnchorEl(null);
    console.log(accountInfo);
    navigate(`/mypage`);
  };

  const handleAddRunner = () => {
    setAnchorEl(null);
    navigate(`/auth/add-runner`);
  };

  if (!currentRunner || !accountInfo) {
    return <></>;
  }

  return (
    <div>
      <div className={classes.headerBar}>
        <span className={classes.sidebar}>
          <SwipableMenu account={accountInfo} />
        </span>
        <p className={classes.appTitle}>IDATEN Kids</p>
        <p className={classes.mypage}>
          <AccountCircleIcon
            onClick={handleClick}
            aria-controls="fade-menu"
            aria-haspopup="true"
            style={{ fontSize: '28px', color: '#555555' }}
          />

          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleGoToAccount}>
              <span style={{ fontSize: '10pt' }}>
                選択中のランナー: &nbsp;{currentRunner.username}
              </span>
            </MenuItem>
            <MenuItem onClick={handleGoToAccount}>
              <ListItemIcon style={{ minWidth: '36px' }}>
                <ContactMailIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>マイページ</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              {' '}
              <ListItemIcon style={{ minWidth: '36px' }}>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>ログアウト</ListItemText>
            </MenuItem>

            <MenuItem>
              <ListItemIcon style={{ minWidth: '36px' }}>
                <SwitchAccountIcon fontSize="small" />
              </ListItemIcon>
              <RunnerSwitcher
                runnerList={accountInfo?.runners}
                goAddNewRunner={handleAddRunner}
                handleClose={handleClose}
              />
            </MenuItem>
          </Menu>
        </p>
      </div>
    </div>
  );
};
