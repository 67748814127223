import React from 'react';
import straight_leg_thumb from 'components/MenuConst/thumbnails/straight_leg.png';
import mark_run_thumb from 'components/MenuConst/thumbnails/mark_run.png';
import dash_10m_thumb from 'components/MenuConst/thumbnails/dash_10m.jpg';
import howto_50m_thumb from 'components/MenuConst/thumbnails/howto50m.jpg';
import intro_1st_thumb from 'components/MenuConst/thumbnails/intro_1st.jpg';
import challenge1st_thumb from 'components/MenuConst/thumbnails/challenge_1st.jpg';
import { menuType } from 'pages/PracticePage/type';
import {
  dash10mVideos,
  howto50mVideos,
  markRunVideos,
  straightLegVideos,
} from './videos';

export const dash_10m_1st: menuType = {
  title: '10mダッシュ上級#昇級テスト',
  grade: 9,
  duration: 10,
  image: dash_10m_thumb,
  video: dash10mVideos,
  isInside: 'unable',
  menuVariation: ['tech'],
  target: ['スタート', '全力走'],
};

export const straight_leg: menuType = {
  duration: 15,
  grade: 9,
  title: 'ストレートレッグ',
  image: straight_leg_thumb,
  video: straightLegVideos,
  target: ['足の動き', '全力走'],
  menuVariation: ['run'],
  isInside: 'unable',
};

export const mark_run: menuType = {
  duration: 15,
  grade: 9,
  title: 'マーク走',
  image: mark_run_thumb,
  video: markRunVideos,
  target: ['足の動き', '全力走'],
  menuVariation: ['run'],
  isInside: 'unable',
};

export const howto50m: menuType = {
  title: '50m走の走り方☆おまけ',
  grade: 9,
  duration: 10,
  target: ['全力走'],
  menuVariation: ['family'],
  image: howto_50m_thumb,
  video: howto50mVideos,
  isInside: 'unable',
};

export const challenge_1st = {
  duration: 5,
  hitWordList: ['1', '昇級', 'いち', 'いっきゅう'],
  title: '1級の昇級用メニュー',
  grade: 9,
  image: challenge1st_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/Vn70HQtQAmY',
      title: '1級の昇級用メニュー',
      message: <span>1級の昇級テスト挑戦動画はコチラ！</span>,
    },
  ],
};

export const intro_1st = {
  duration: 2,
  hitWordList: ['1', '昇級', 'メッセージ', 'めっせーじ', 'いっきゅう', 'いち'],
  title: '1級の方へのメッセージ',
  grade: 9,
  image: intro_1st_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/aHFYkpRmMTo',
      title: '1級イントロ動画',
      message: <span>1級の方へのメッセージはコチラ！ </span>,
    },
  ],
};
