import React from 'react';
import {
  checkPointListJA,
  ScoreEditType,
  //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

type checkListsType = {
  [key in checkpointShallow]: checkPointListJA[];
};

export const evalEachPoint = (
  checklistDict: checkListsType,
  border: checkListsType,
  judgedScore: ScoreEditType,
) =>
  Object.keys(checklistDict).map((c) => {
    const ckptShallow = c;
    console.log('ckpt=', ckptShallow);
    return {
      title: ckptShallow,
      isBorderPassed: border[ckptShallow].every(
        (ckptDeep) => !ckptDeep || judgeIsPassed(judgedScore[ckptDeep]),
      ),
      isAllPassed: checklistDict[ckptShallow].every((ckptDeep) =>
        judgeIsPassed(judgedScore[ckptDeep]),
      ),
    };
  });

const judgeIsPassed = (str: string | undefined) => {
  //console.log(str);
  if (!str) {
    // throw new Error('キーがおかしい: 多分checklistMapper内のキーがscoreにない');
    return false;
  }
  return str.indexOf('◯') !== -1;
};

type evalForEachType = {
  title: string;
  isAllPassed: boolean;
  isBorderPassed: boolean;
};

type crtFinalResultType = (
  evaluation: evalForEachType[],
  necess: string[],
) => evalForEachType[];

export const crtFinalResult: crtFinalResultType = (evaluation, necess) => {
  if (evaluation.every((evalForEach) => evalForEach.isAllPassed)) {
    return [
      ...evaluation,
      { title: 'かんぺき！', isAllPassed: true, isBorderPassed: true },
    ];
  }
  if (
    evaluation.every(
      (evalForEach) =>
        evalForEach.isBorderPassed || !necess.includes(evalForEach.title),
    )
  ) {
    return [
      ...evaluation,
      { title: '合格！', isAllPassed: false, isBorderPassed: true },
    ];
  }
  return [
    ...evaluation,
    { title: 'もう一回', isAllPassed: false, isBorderPassed: false },
  ];
};

export const crtFinalResultDict: crtFinalResultType = (evaluation, necess) => {
  if (evaluation.every((evalForEach) => evalForEach.isAllPassed)) {
    return [{ title: 'かんぺき！', isAllPassed: true, isBorderPassed: true }];
  }
  if (
    evaluation.every(
      (evalForEach) =>
        evalForEach.isBorderPassed || !necess.includes(evalForEach.title),
    )
  ) {
    return [{ title: '合格！', isAllPassed: false, isBorderPassed: true }];
  }
  return [{ title: 'もう一回', isAllPassed: false, isBorderPassed: false }];
};
