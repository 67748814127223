import React, { FC, memo } from 'react';
import { Box } from '@mui/material';
import { Button } from '@material-ui/core';
import nasTopImage from 'static/lp/nas/nasTopImage2.png';
import ux_explanation from 'static/lp/nas/ux_explanation.png';
// import classes from './naspage.module.css';
import { AppliStrength } from 'pages/PurchasePage/components/AppliStrength';
import { SalesPoints } from 'pages/PurchasePage/components/SalesPoints';
import { CustomerReview } from 'pages/PurchasePage/components/CustomerReview';
import classes from './naspage.module.css';
import { useNavigate } from 'react-router-dom';

export const ExplanationPageMobile: FC = memo(() => {
  const navigate = useNavigate();
  return (
    <div>
      <img src={nasTopImage} alt="" width="100%" />
      <div style={{ margin: '24px 8px 8px 8px' }}>
        <div
          dangerouslySetInnerHTML={{
            __html: ` <video
                  width="100%"
                  autoplay
                  muted
                  playsinline
                  loop
                  poster="https://shimotakaido.s3.amazonaws.com/lesson_videos/online_school_thumbnail.png"
                >
                <source src="https://shimotakaido.s3.amazonaws.com/lesson_videos/IDATEN_v1221.mp4" />
                </video>`,
          }}
        />
      </div>
      <div style={{ textAlign: 'center', margin: '48px 4vw 16px 4vw' }}>
        <p style={{ fontSize: '16pt', fontWeight: 'bold' }}>教室概要説明</p>
        <div
          style={{
            fontSize: '10pt',
            fontWeight: 'bold',
            textAlign: 'left',
            color: '#424242',
          }}
        >
          本教室はかけっこ20級から始まり、 毎週の
          <span style={{ color: '#ff0000' }}>昇級テスト</span>
          をこなす中で走りの技術を一つ一つ身につける 「昇級式 ✖️ マンツーマン」
          のかけっこ教室です！
        </div>
      </div>

      <div style={{ marginTop: '16px' }}>
        <img src={ux_explanation} alt="" width="100%" />
      </div>

      <div style={{ textAlign: 'center', margin: '48px 4vw 16px 4vw' }}>
        <p style={{ fontSize: '16pt', fontWeight: 'bold' }}>取り組み方</p>
        <div
          style={{
            fontSize: '10pt',
            fontWeight: 'bold',
            textAlign: 'left',
            color: '#424242',
          }}
        >
          本教室は専用アプリを用いて
          <span style={{ color: '#ff0000' }}>オンライン✖️マンツーマン</span>
          で行われます！
          <div style={{ margin: '8px 0px 8px 0px' }}>
            1. 各級で身につける走りのポイントを理解 <br />
            2. おすすめ練習に取り組む
            <br />
            3. 昇級テストに挑戦
            <br />
            4. コーチによる丁寧な添削
          </div>
          のサイクルを毎週回しながら、1級を目指して一つずつ走りの基礎を身につけていただきます！
          <br />
          <div
            style={{
              fontWeight: 'normal',
              fontSize: '8pt',
              color: '#424242',
              marginTop: '6px',
            }}
          >
            ※
            昇級テストには必ずしも毎週挑戦していただく必要はございません。各自のペースでお取り組み下さい。
            <div>
              ※
              各級40~60分程度、練習に取り組んでいただくことを想定しております。
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', margin: '48px 4vw 16px 4vw' }}>
        <p style={{ fontSize: '16pt', fontWeight: 'bold' }}>
          昇級テスト撮影サポートについて
        </p>
        <div
          style={{
            fontSize: '10pt',
            fontWeight: 'bold',
            textAlign: 'left',
            color: '#424242',
          }}
        >
          <span style={{ color: '#ff0000' }}>毎週金曜日17:30-18:30</span>
          にNAS西日暮里店7Fアリーナを開放し、スタッフが動画撮影のサポートを行います。また余った時間を使って参加者のみんなでリレーや練習も行いますので、よろしければぜひご参加ください！
        </div>
      </div>
      <Box style={{ margin: '4px 0px 0px 4px' }}>
        <AppliStrength />
        <div style={{ marginTop: '20px' }}>
          <SalesPoints />
        </div>
        <CustomerReview />
      </Box>
      <div className={classes.fixBar}>
        <div style={{ padding: '12px' }}>
          <Button
            color="primary"
            variant="contained"
            size="medium"
            onClick={() => {
              navigate('/login');
            }}
          >
            会員様ログイン
          </Button>
        </div>
      </div>
    </div>
  );
});
