import { menuType } from 'pages/PracticePage/type';
import marchWalk_thumb from 'components/MenuConst/thumbnails/marchWalk_thumb.png';
import highknee_trac_thumb from 'components/MenuConst/thumbnails/highknee_trac_thumb.png';
import { high_knee, march_walk } from './videos';

export const MarchWalk: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'その場行進',
  isInside: 'able',
  target: ['しせい', '足の動き'],
  menuVariation: ['tech'],
  hitWordList: ['その場行進', 'そのばこうしん'],
  grade: 2,
  image: marchWalk_thumb,
  video: march_walk,
};

export const HighKnee: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'その場ハイニー #昇級テスト',
  isInside: 'able',
  target: ['しせい', '足の動き'],
  menuVariation: ['tech'],
  hitWordList: ['その場ハイニー'],
  grade: 2,
  image: highknee_trac_thumb,
  video: high_knee,
};
