import React, { useState } from 'react';
import { AccountContext } from 'contexts/AccountContext';
import { getAccountInfoAdapter } from 'adapters/getAccountInfoAdapter';
import { AccountType } from 'adapters/repositories/typeDefinition';
import { shouldUpdateAccount } from '../shouldUpdateAccount';

export const useFetchAccount = () => {
  const { accountInfo, setAccountInfo, currentRunner, setCurrentRunner } =
    React.useContext(AccountContext);

  const [fetchedAccount, setFetchedAccount] = useState<AccountType | undefined>(
    accountInfo,
  );

  let runner = currentRunner;

  React.useEffect(() => {
    getAccountInfoAdapter()
      .then((accountFromApi: AccountType) => {
        if (!accountFromApi || !accountFromApi.runners) {
          return;
        }
        const shouldUpdate = shouldUpdateAccount(accountFromApi, accountInfo);
        console.log('SHOULD UPDATE=', shouldUpdate);

        // 昇級してしまっていた、または採点が完了していた時の対策
        if (shouldUpdate) {
          setFetchedAccount(accountFromApi);
          setAccountInfo(accountFromApi);
          const newestRunnerId = localStorage.getItem('currentRunner'); // FIXME:ここが空文字になる恐れがあるので注意
          runner = !!currentRunner
            ? accountFromApi.runners.find((r) => r.id === newestRunnerId)
            : accountFromApi.runners[0];
          setCurrentRunner(runner || accountFromApi.runners[0]);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return {
    fetchedAccount: fetchedAccount,
    currentRunner: runner,
  };
};
