import React from 'react';
import classes from '../purchasepage.module.css';
import add_friend from 'static/add_friend.png';

export const Inquiry = () => {
  return (
    <>
      <div style={{ width: '100%' }}>
        <div className={classes.underfukidashi}>お申し込み & お問い合わせ</div>
        <div style={{ color: '#404040', marginTop: '30px' }}>
          ↓ お申し込みやご質問は公式LINEアカウントより承ります！ ↓
        </div>
        <div style={{ marginTop: '20px', marginBottom: '60px' }}>
          <a href={'https://lin.ee/W5UAOP8'} target="_blank" rel="noreferrer">
            <img src={add_friend} alt="line" width="40%"></img>
          </a>
        </div>
      </div>
      <div style={{ width: '100%' }} id="applyForm">
        <br />
      </div>
      <div style={{ width: '100%', marginTop: '220px' }}>
        <br />
      </div>
    </>
  );
};
