// 10級パッケージの8級
type ckptTypeForGrade_2_20kyu =
  | 'よい姿勢'
  | 'ハイニーができている'
  | 'ベースポジションでとまる';

export const ckptForGrade_2_20kyu: ckptTypeForGrade_2_20kyu[] = [
  'よい姿勢',
  'ハイニーができている',
  'ベースポジションでとまる',
];

export const requirement_2_20kyu: ckptTypeForGrade_2_20kyu[] = [
  'よい姿勢',
  'ハイニーができている',
  'ベースポジションでとまる',
];

export type ckptDetails_2_20 =
  | '前後傾'
  | '膝のつぶれ'
  | 'ハイニーができている'
  | 'ベースポジションで止まる'
  // 採点用
  | '腰が落ちていない'
  | 'リズム'
  | '股関節から動かせている'
  | 'うで振り'
  | 'リーチアウト';

type allCheckpoints_2_20kyu_type = {
  [key in ckptTypeForGrade_2_20kyu]: ckptDetails_2_20[];
};

export const allCheckPoints_2_20kyu: allCheckpoints_2_20kyu_type = {
  よい姿勢: ['前後傾', '膝のつぶれ'],
  ハイニーができている: ['ハイニーができている'],
  ベースポジションでとまる: ['ベースポジションで止まる'],
};

export const borderLine_2_20kyu: allCheckpoints_2_20kyu_type = {
  よい姿勢: ['前後傾'],
  ハイニーができている: ['ハイニーができている'],
  ベースポジションでとまる: ['ベースポジションで止まる'],
};
