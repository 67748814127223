import React, { FC, memo } from 'react';

import { Grid, GridSize } from '@material-ui/core';

import { RunnerType, VideoType } from 'adapters/repositories/typeDefinition';
import { VerticalLine, SpaceLine } from './Lines';
import { MessageBar } from './MessageBar';
import { evalEachPoint, crtFinalResultDict } from './func';
import { UpOrStayBar } from './UpOrStayBar';
import { grades, ScoreEditType } from 'components/MenuConst/GradeGoalConst';
import { convertGradeByRunner } from 'components/constants';
import {
  requirementsToGetStar,
  requirementsToPass,
  starndardsToGetGood,
} from 'components/MenuConst/GoalsForEachGrade/standardsForEachGrade';
import {
  allCheckpoints_20_kyu,
  requirementsToPass_20_kyu,
  toGetGoodRequirements_20,
} from 'components/MenuConst/GoalsForEachGrade/CheckpointsFor20Pkg/standardsFor20Grades';

type Props = {
  video: VideoType;
  runner: RunnerType;
};

export const StepFunctionScoreView: FC<Props> = memo(({ video, runner }) => {
  if (!video.score || !runner) {
    return <div>スコアがありません</div>;
  }

  // ビデオに対応する級を用意
  const grade = convertGradeByRunner(runner, video.grade_before);
  console.log(grade);
  const detailCheckPoints = JSON.parse(video.score.details) as ScoreEditType;
  console.log(detailCheckPoints);
  const scoredGrade = detailCheckPoints.scoredGrade as grades;

  //MustとniceToHave合わせてこれが全部できたら完璧
  const checkPointsForTheGrade =
    (!!scoredGrade && allCheckpoints_20_kyu[scoredGrade]) ||
    requirementsToGetStar(runner, video.grade_before);
  console.log('requirements to get star=', checkPointsForTheGrade);

  //Mustのみ。各メッセージバーの合格と不合格の境目
  const border =
    (!!scoredGrade && toGetGoodRequirements_20[scoredGrade]) ||
    starndardsToGetGood(runner, video.grade_before);
  // 級の合格に関わる。メッセージバーで必要なものが格納されている。
  const necessitiesForTheGrade =
    (!!scoredGrade && requirementsToPass_20_kyu[scoredGrade]) ||
    requirementsToPass(runner, video.grade_before);

  const evaluation = evalEachPoint(
    checkPointsForTheGrade,
    border,
    detailCheckPoints,
  );
  const finalResult = crtFinalResultDict(evaluation, necessitiesForTheGrade)[0];

  console.log(evaluation);
  console.log(finalResult);

  return (
    <>
      {evaluation.map((ckpt, idx: number) => (
        <>
          <Grid container>
            <VerticalLine idx={idx} />
            <Grid item xs={(12 - idx) as GridSize}>
              <MessageBar ckpt={ckpt} necessities={necessitiesForTheGrade} />
            </Grid>
          </Grid>
          {/* 間隔分の縦線 */}
          <SpaceLine idx={idx} maxLen={evaluation.length} />
        </>
      ))}
      <div style={{ marginTop: '16px' }}>
        <UpOrStayBar result={finalResult} />
      </div>
    </>
  );
});
