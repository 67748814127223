import React, { FC, memo } from 'react';
import { TextField } from '@material-ui/core';

type inputEvent = React.ChangeEvent<HTMLInputElement>;
type Props = {
  value: string;
  validationMsg: string;
  handleChange: (e: inputEvent) => void;
  label: string;
  placeHolder?: string;
};

export const TextInputBox: FC<Props> = memo(
  ({ value, validationMsg, handleChange, label, placeHolder }) => {
    return (
      <TextField
        id="standard-basic"
        style={{
          width: '70%',
          backgroundColor: 'white',
        }}
        value={value}
        label={label}
        onChange={handleChange}
        helperText={validationMsg}
        placeholder={placeHolder}
      />
    );
  },
);
