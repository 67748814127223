type timeType = {
  time: string;
  timeDelta: string;
};
export const formatTimeFromBackend = (timeStamp: string): timeType => {
  const TokyoDate = new Date(timeStamp);

  const time = `${TokyoDate.getHours()}時${TokyoDate.getMinutes() + 1}分`;

  return { time: time, timeDelta: '' };
};

export function formatDateFromString(string: string): string {
  const TokyoDate = new Date(string);
  let nengappi =
    TokyoDate.getFullYear() +
    '年' +
    (TokyoDate.getMonth() + 1) +
    '月' +
    TokyoDate.getDate() +
    '日' +
    TokyoDate.getHours() +
    '時' +
    TokyoDate.getMinutes() +
    '分';

  return nengappi;
}
