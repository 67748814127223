import { emailValidator } from 'utils/funcs/emailValidator';
type GenderType = '男性' | '女性' | 'その他' | undefined;

type RunnerInfoType = {
  gender: GenderType;
  height: number;
  email: string;
  username: string;
};

export const formValidator = (runnerInfo: RunnerInfoType) => {
  if (!!emailValidator(runnerInfo.email) || runnerInfo.email.length < 1) {
    return false;
  }

  if (runnerInfo.username.length < 1 || !runnerInfo.gender) {
    return false;
  }
  return true;
};
