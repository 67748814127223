import React from 'react';
import diagnose_thumb from 'components/MenuConst/thumbnails/diagnose_thumb.png';
import intro_14th_thumb from 'components/MenuConst/thumbnails/intro_14th.jpg';
import Skip_thumb from 'components/MenuConst/thumbnails/skip_challenge.jpg';
import skaterjump_thumb from 'components/MenuConst/thumbnails/skaterjump.jpg';
import singlelegtraining_thumb from 'components/MenuConst/thumbnails/singleLeg_cross.jpg';
import startpose from 'components/MenuConst/thumbnails/start_posture.png';
import single_catch_thumb from 'components/MenuConst/thumbnails/single_catch.jpg';

import {
  skipBasicVideos,
  skaterJumpVideos,
  singleLegTrainingVideos,
  singleLegPlayVideos,

} from './videos';

import {
  fiftyTrialVideos,
  howToStartPoseVideos,
} from '../grade_0/videos';

import { menuType } from 'pages/PracticePage/type';
import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';


export const skipBasic_14th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'スキップ',
  isInside: 'unable',
  target: ['足の動き', 'スタート'],
  menuVariation: ['run'],
  hitWordList: [
    'すきっぷ',
    'スキップ',
  ],
  grade: 6,
  image: Skip_thumb,
  video: skipBasicVideos,
};

export const skaterJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'スケータージャンプ',
  isInside: 'half',
  target: ['スタート'],
  menuVariation: ['run'],
  hitWordList: [
    'スケータージャンプ',
    'すけーたー',
  ],
  grade: 6,
  image: skaterjump_thumb,
  video: skaterJumpVideos,
};

export const singleLegTraining: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '片足トレーニング！',
  isInside: 'able',
  target: ['しせい'],
  menuVariation: ['run'],
  hitWordList: [
    'かたあし',
    '片足',
    '回転',
    '十字',
  ],
  grade: 6,
  image: singlelegtraining_thumb,
  video: singleLegTrainingVideos,
};

export const trial_50m_14th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'かけっこ診断！☆おすすめ',
  isInside: 'unable',
  target: ['しせい', '足の動き', '腕ふり'],
  menuVariation: ['family'],
  hitWordList: [
    'かけっこ',
    '診断',
  ],
  grade: 6,
  image: diagnose_thumb,
  video: fiftyTrialVideos,
};

export const howToStartPose_14th: menuType = {
  duration: 5,
  title: 'スタートのかまえ',
  target: ['スタート'],
  isInside: 'able',
  menuVariation: ['family'],
  hitWordList: [
    'スタート',
    'すたーと',
    '構え',
    'かまえ',
    'いえ',
    '家',
    'おうち',
  ],
  grade: 6,
  image: startpose,
  video: howToStartPoseVideos,
};

export const singleLegPlay: menuType = {
  duration: 5,
  title: '片足あそび',
  target: ['しせい', 'スタート'],
  isInside: 'able',
  menuVariation: ['family'],
  hitWordList: [
    '片足',
    'かたあし',
    'キャッチ',
    'もの',
    '物',
    '家',
    'おうち',
  ],
  grade: 6,
  image: single_catch_thumb,
  video: singleLegPlayVideos,
};


export const intro_14th: menuType = {
  duration: 3,
  hitWordList: ['14', '昇級', 'メッセージ', 'めっせーじ', 'きゅう'],
  title: '14級の方へのメッセージ',
  menuVariation: ['levelUp'],
  target: ['足の動き'],
  isInside: 'able',
  grade: 6,
  image: intro_14th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['14'],
      title: 'intro14th',
      message: <span>14級の方へのメッセージはコチラ！ </span>,
    },
  ],
};
