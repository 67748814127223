import { ckptDetails_4_10 } from 'components/MenuConst/GoalsForEachGrade/CheckpointsFor10Pkg/grade_4';

type options = {
  [key in ckptDetails_4_10]: string[];
};

export const optionsForGrade_4_10pkg: options = {
  前後傾: ['◯:ちょうど良い', '×:前傾しすぎ', '×:後傾しすぎ'],
  腰が落ちていない: ['◯:腰高い', '×:落ちている（膝のつぶれ）'],
  後ろに蹴っていない: ['◯:できている', '×:蹴ってしまっている'],
  足の上がり: ['◯:足が上がっている', '×:足が上がっていない'],
  腕が地面と平行: ['◯:平行である', '×:出来ていない'],
  下に踏む: ['◯:できている', '×:体の遠くに接地'],
  接地様式: [
    '◯:ちょうど良い',
    '×:かかとからついている',
    '×:つま先だけでついている',
  ],
  スピードが出せている: ['◯:OK', '×:遅すぎる'],
};
