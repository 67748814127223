import { menuType } from 'pages/PracticePage/type';
import step_thumb from 'components/MenuConst/thumbnails/2step_thumb.png';
import diagnose from 'components/MenuConst/thumbnails/diagnose_thumb.png';
import { diagnoseForLevelUp, twoStepVideo } from './videos';

export const twoSteps: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '2ステップ',
  isInside: 'able',
  target: ['しせい', '足の動き'],
  menuVariation: ['up'],
  hitWordList: ['2ステップ', 'step'],
  grade: 17,
  image: step_thumb,
  video: twoStepVideo,
};

export const kakekkoDiagnoseTest: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'かけっこ診断 ☆昇級テスト',
  isInside: 'unable',
  target: ['しせい', '足の動き'],
  menuVariation: ['tech'],
  hitWordList: ['かけっこ診断'],
  grade: 17,
  image: diagnose,
  video: diagnoseForLevelUp,
};
