import React from 'react';

import classes from 'components/MenuConst/menutextDecoration.module.css';

/////////////////////////////////////////////////////////
//up
/////////////////////////////////////////////////////////

export const re_basePositinJumpVideos = [
  {
    link: 'https://www.youtube.com/embed/cbhe66Q-s18',
    title: 'ベースポジション',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: 「ベースポジション」を復習
        </div>
        <div className={classes.content}>
          →「ベースポジションで15秒キープ」× 左右足2回ずつチャレンジ！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/AvMfxvRFdIw',
    title: '小刻み腿上げ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2: 「こきざみもも上げ20回」× 左右足2セットずつチャレンジ！
        </div>
        <div className={classes.content}>
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/NXabBLVbK9Q',
    title: 'ベースポジションジャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3: 「ベースポジションジャンプ10回」× 左右足2セットずつ行う！
        </div>
        <div className={classes.content}>
          ☆ドリブルジャンプの時のように、地面についているあし側の肩をかるーくドリブルしてあげると弾む感覚がつかみやすいかもしれません！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！※足音がなりますので、気になる方はご注意ください！
        </div>
      </div>
    ),
  },
];

export const basePositinWalkVideos = [
  {
    link: 'https://www.youtube.com/embed/Bdfi35kBYDU',
    title: 'ベースポジションウォーク',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: 「ベースポジションウォーク20歩」× 3～5セット（目安）行う！
        </div>
        <div className={classes.content}>
          ☆まず1セット目は2秒キープでやってみて、慣れてきたら連続で行ってみよう！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！※足音がなりますので、気になる方はご注意ください！
        </div>
      </div>
    ),
  },
];

/////////////////////////////////////////////////////////
//tech
/////////////////////////////////////////////////////////

//スタートの構えはgrade_0から

export const kabeoshiBasicVideos = [
  {
    link: 'https://www.youtube.com/embed/usm_mhrZSd4',
    title: '壁押しドリル',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1～4:「壁押しももあげ」を正しい形で出来るようになるまで大特訓（目安10分）！！
          <br />
          動画2～4の注意ポイントも確認してね！
          <br />
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本（5歩程度うつっている動画）を送信！
        </div>
        <div className={classes.title_important}>昇級のポイント</div>
        <div className={classes.content}>
          1. よい姿勢 ☆よこからみてまっすぐかな？かかとはういてるかな？
          <br />
          2. 足の上がり ☆ふとももが90度あがっているかな？
        </div>
        <div className={classes.title_important}>
          うえ2つができた子はチャレンジ！
        </div>
        <div className={classes.content}>
          3. 「45度～60度」くらい倒してチャレンジしてみよう！
          <br />
          <br />
          ☆まずは正しい形でできる角度で！角度浅めから練習しよう！
          <br />
          ☆壁を強く押す意識をすると姿勢がくずれてしまう場合は、一旦意識しなくて大丈夫です！
          <br />
          ☆よろしければ「保護者様に腰をつかんで真後ろに軽く（椅子を引くくらいの力感で）引っ張ってもらいながら」壁押しをしてみてください！その際お子様はお尻が引けないように頑張って耐えてみるといい姿勢の感覚がつかみやすいですよ！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！たくさん練習してたくさん失敗するのがかけっこ1級への近道です！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/a2XE9UnDs3g',
    title: '壁押しドリル注意点①',
    message: (
      <div>
        <div className={classes.subtitle}>動画2: 「壁押し」の注意ポイント1</div>
        <div className={classes.content}>☆あしを後ろにはね上げない！</div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/CVWlD11uW5U',
    title: '壁押しドリル注意点②',
    message: (
      <div>
        <div className={classes.subtitle}>動画3: 「壁押し」の注意ポイント2</div>
        <div className={classes.content}>☆かかとは浮かせる！</div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/M39McbpaKgs',
    title: '壁押しドリル注意点③',
    message: (
      <div>
        <div className={classes.subtitle}>動画4: 「壁押し」の注意ポイント3</div>
        <div className={classes.content}>☆足は最初と同じ位置に着地する！</div>
      </div>
    ),
  },
];

/////////////////////////////////////////////////////////
//run
/////////////////////////////////////////////////////////

//うでふり・バネ人間はgrade_0から

export const yHighkneeSprintVideos = [
  {
    link: 'https://www.youtube.com/embed/69otlz4YMvw',
    title: 'Y字しせいで走る(お手本)',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:20級の昇級テストでのコーチからのコメントを思い出しながら、「Y字しせいで20m走る」
          × 4本（目安）行う！
        </div>
        <div className={classes.title_important}>ふくしゅうのポイント</div>
        <div className={classes.content}>
          1. 体がまっすぐ ☆手の位置を高くたもって体をたおしすぎないのがだいじ！
          <br />
          2. ポンポン走る ☆おしりの位置はたか～くできているかな？
        </div>
        <div className={classes.title_important}>
          うえ2つができた子はチャレンジ！
        </div>
        <div className={classes.content}>
          3. なれてきた子は「スピードを出す」にもチャレンジしてみよう！
          <br />
          ☆タオルをご用意できる方は、（動画3）を参考に、タオルを持った状態で行ってみよう！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/RgfOs-J-nWk',
    title: 'Y字ポジションもも上げスプリント',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2: 「Y字ポジションもも上げスプリント20m」× 3～4本行う！
        </div>
        <div className={classes.content}>
          ☆「あしをからだの前で動かす」感覚をおぼえよう！
        </div>
      </div>
    ),
  },
];

/////////////////////////////////////////////////////////
//family
/////////////////////////////////////////////////////////

//かけっこ診断はgrade_0から

export const singleLegRotationVideos = [
  {
    link: 'https://www.youtube.com/embed/o4uQYa_S2fk',
    title: '片足回転ジャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: 「片足回転ジャンプ」を3分くらいやってみよう！
        </div>
        <div className={classes.content}>
          ☆ぜひ「逆」までチャレンジしてみて下さい！また実はしんどいので疲れすぎないよう気を付けてください！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </div>
      </div>
    ),
  },
];

/////////////////////////////////////////////////////////
//TRAC
/////////////////////////////////////////////////////////

export const startPosture = [
  {
    link: 'https://www.youtube.com/embed/Td5g7luiz2c',
    title: 'スタートの構え',
    message: (
      <div>
        <div>
          <div className={classes.subtitle}>
            スタートのかまえをおぼえる！
            <br />
            ☆昇級テストの挑戦は右下の ➕ボタン⇒昇級に挑戦」から行えます！
          </div>
          <div className={classes.title_important}>昇級のポイント</div>
          <div className={classes.content}>
            1. 前足に体重をかける！
            <br />
            2. 体をしっかり前にたおす！
          </div>
        </div>
      </div>
    ),
  },
];
