import axios, { AxiosRequestConfig } from 'axios';
import { HOST, setTokenToHeader } from 'v1apiRequest';

export async function postVideoWithProgress(
  params: FormData,
  onProgressFunc: React.Dispatch<React.SetStateAction<number>>,
  completeFunc: () => void,
  failFunc: () => void,
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const config: AxiosRequestConfig = {
    onUploadProgress: (progressEvent) => {
      let percentCompleted = Math.round(
        // eslint-disable-next-line
        (progressEvent.loaded * 99) / progressEvent.total,
      );
      onProgressFunc(percentCompleted);
    },
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: setTokenToHeader(),
    },
  };
  console.log(params);

  axios
    .post(`${HOST}/post-videos/`, params, config)
    .then(() => {
      console.log('OK');
      completeFunc();
      setIsUploading(false);
    })
    .catch(() => {
      console.log('fail');
      failFunc();
      setIsUploading(false);
    });
}
