import {
  gradeGoalDict,
  ScoreEditType, //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { RunnerType } from 'adapters/repositories/typeDefinition';

export function crtComment2nd(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  const setence1 = crt_line1(score, yobina);
  const sentence2 = crt_line2(runnerInfo, score, yobina);
  const sentence3 = crt_line3(score);
  return (
    setence1 +
    '<<paragraph>>' +
    sentence2 +
    '<<paragraph>>' +
    sentence3 +
    '<<paragraph>>'
  );
}

function crt_line1(score: ScoreEditType, yobina: string) {
  const head1 = yobina + 'だんだんスタートにも慣れてきましたか？';
  const head2 = `今回の動画は2級の目標である「${gradeGoalDict['2']}」`;
  let evaluation =
    score.eval === '◯合格'
      ? 'が大変良くできています！1級昇級おめでとうございます！'
      : 'に少しだけ課題があります。';
  return head1 + head2 + evaluation;
}

function crt_line2(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  console.log(score);
  return commentLogic(score, yobina);
}

const commentLogic = (score: ScoreEditType, yobina: string) => {
  console.log(score);
  if (score['腰の引け具合'] !== '◯:良い') {
    if (score['倒れ込み角度'] === '×:前傾しすぎ') {
      return `${yobina}の一番の伸びしろポイントとして「壁に深く倒れ込みすぎている」ことがあります。深く倒れ込みすぎるとどうしてもいい姿勢や足をあげるのが難しくなってしまうので、まずは地面との角度60度くらいで練習してみてください！`;
    }
    if (score['腰の引け具合'] === '×:反っている') {
      return `${yobina}の一番の伸びしろポイントとして「腰が反っている」ことがあります。「おへその下にグーッと力を入れる」や「お尻の割れ目に500円玉を挟むイメージでお尻にきゅっと力を入れる」「あごを少し引いてみる」などいろいろ試してみて自分に合ったものを探してみましょう！横から見て体が1本の棒のようになればばっちりです！`;
    }

    if (score['かかとが浮いているか'] === '×:ついてしまっている') {
      return `${yobina}の一番の伸びしろポイントとして「地面についている方の足のかかとがついている」ことがあります。かかとがついてしまうと少しだけ腰が曲がってしまうので、かかとは浮かせる意識を持ちましょう！これになれるとスタートダッシュが一段速くなりますよ！`;
    }

    if (score['前に進んでいるか'] === '×:進んでいる') {
      return `${yobina}の一番の伸びしろポイントとして「地面につく足が少しずつ前に進んでいる」ことがあります。前に進んでしまうとだんだん腰が曲がってしまいいい姿勢がキープできなくなります！最初と同じ位置に足を戻すようにしましょう！地面に線を引くなど目印をつけると意識しやすいですよ！`;
    }

    return `${yobina}の一番の伸びしろポイントとして「お尻が引けてしまっている」ことがあります。そんな${yobina}におすすめの練習としては、「保護者様に腰をつかんで真後ろに軽く（椅子を引くくらいの力感で）引っ張ってもらいながら」練習してみてください！その際${yobina}はお尻が引けないように頑張って耐えてみて下さいね！いい姿勢の感覚がつかめると思います！これになれるとスタートダッシュが一段速くなりますよ！`;
  }

  if (score['足の上がり'] === '×:上がっていない') {
    if (score['倒れ込み角度'] === '×:前傾しすぎ') {
      return `${yobina}の一番の伸びしろポイントとして「壁に深く倒れ込みすぎている」ことがあります。深く倒れ込みすぎるとどうしてもいい姿勢や足をあげるのが難しくなってしまうので、まずは地面との角度60度くらいで練習してみてください！`;
    }

    if (score['後ろへの蹴り上げ'] === '×:蹴り上げている') {
      return `まず${yobina}のよかった点としては、体全体を一本の棒のようにまっすぐできていますね！姿勢は何より一番大事なポイントなので意識してくれて素晴らしいです！${yobina}の伸びしろポイントとして「足を後ろに蹴り上げてしまっている」ことがあります。もも上げというよりは「膝をあげる」ようなイメージで練習しましょう！ただいきなり連続もも上げだと少しむずかしいので、まずは①壁押しのももを上げた姿勢をとる⇒②保護者様が手をたたく⇒③手拍子に合わせて足を入れ替え、ももが上がった姿勢でとまる（このとき太ももが90度程度上がっていれば成功）、といった形で練習してみて下さい！手拍子の回数はまずは1回からはじめて、慣れてきたら回数を2回、3回と増やしていきましょう！10回連続で入れかえられたらばっちりです！`;
    }

    return `まず${yobina}のよかった点としては、体全体を一本の棒のようにまっすぐできていますね！姿勢は何より一番大事なポイントなので意識してくれて素晴らしいです！そんな${yobina}の伸びしろポイントとして「ももの上りがまだ小さい」ことがあります。目安として90度ぐらいももが上がるとよいです！ただいきなり連続もも上げだと少しむずかしいので、まずは①壁押しのももを上げた姿勢をとる⇒②保護者様が手をたたく⇒③手拍子に合わせて足を入れ替え、ももが上がった姿勢でとまる（このとき太ももが90度程度上がっていれば成功）、といった形で練習してみて下さい！手拍子の回数はまずは1回からはじめて、慣れてきたら回数を2回、3回と増やしていきましょう！10回連続で入れかえられたらばっちりです！`;
  }

  if (score['足を空中で入れかえる'] === '×:出来ていない') {
    return `${yobina}の今回の動画ですが、しっかり足をあげることもできていますし、体全体が一本の棒のようにまっすぐになっていて素晴らしいです！動画をしっかり見て練習してくれたのが伝わります！そんな${yobina}くんはあとは先生のお手本のように「足を空中で入れかえる」ことができればばっちしです！ただいきなり連続もも上げだと少しむずかしいので、まずは①壁押しのももを上げた姿勢をとる⇒②保護者様が手をたたく⇒③手拍子に合わせて足をスパンと入れかえ、ももが上がった姿勢でとまる（このとき太ももが90度程度上がっていれば成功）、といった形で練習してみて下さい！手拍子の回数はまずは1回からはじめて、慣れてきたら回数を2回、3回と増やしていきましょう！10回連続で入れかえられたらばっちりです！これができるとスタートがめちゃくちゃ速くなりますよ！`;
  }

  if (score['リーチアウト'] === '×:足が開いている') {
    return `${yobina}の今回の動画ですが、しっかり足をあげることもできていますし、体全体が一本の棒のようにまっすぐになっていて素晴らしいです！動画をしっかり見て練習してくれたのが伝わります！そんな${yobina}くんに上級者向けの伸びしろポイントをお伝えすると「足が上がっているときに膝がひらかない」ように出来るとさらによいです！目安として一番足が上がったときの膝の角度が90度くらいになるとよいです！先生のお手本を見て真似してみてください！これが出来ればスタートダッシュでお友達に差をつけられますよ！`;
  }

  if (score['倒れ込み角度'] === '×:起きすぎ') {
    return `まず${yobina}のよかった点としては、しっかり足を空中で入れかえることもできていますし、体全体が一本の棒のようにまっすぐになっていて素晴らしいです！ここまでできる${yobina}なら上級者向けの課題として、より深い前傾角度でもうまく壁押しできるかチャレンジしてみてもよいかもしれません！！まずは地面との角度60度くらいで練習してみて、慣れてきたら45度くらいまで持っていけると完璧です！！これが出来るとスタートダッシュで体をしっかり倒しても足が前に前にくるようになってめちゃくちゃ速く走れますよ！`;
  }

  if (score['腕が地面と平行'] === '×:平行でない') {
    return `${yobina}の今回の動画ですが、しっかり足を空中で入れかえることもできていますし、体全体が一本の棒のようにまっすぐになっていて素晴らしいです！そんな${yobina}に唯一いえることとしては「腕が地面と平行になっていない」ことがあります。これが出来ると壁を前に押すイメージがしやすくなり、よりいい姿勢や足があげやすくなるのでぜひ意識してみて下さい！`;
  }

  return 'しっかり足をあげることもできていますし、体全体が一本の棒のようにまっすぐになっていて素晴らしいです！文句なしの満点合格です！今回やった練習はスタートをマスターする上でとても大事になりますので、是非今のばっちりなフォームを忘れないよう復習頑張ってみて下さい！';
};

function crt_line3(score: ScoreEditType) {
  let evaluation =
    score.eval === '◯合格'
      ? 'それではいよいよ1級にチャレンジです！がんばってみてください！'
      : '####いい具合になぐさめる####';
  return evaluation;
}
