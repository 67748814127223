import {
  gradeGoalDict,
  ScoreEditType, //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { RunnerType } from 'adapters/repositories/typeDefinition';

export function crtComment4th(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  const setence1 = crt_line1(score, yobina);
  const sentence2 = crt_line2(runnerInfo, score, yobina);
  const sentence3 = crt_line3(score, yobina);
  return (
    setence1 +
    '<<paragraph>>' +
    sentence2 +
    '<<paragraph>>' +
    sentence3 +
    '<<paragraph>>'
  );
}

function crt_line1(score: ScoreEditType, yobina: string) {
  const head1 =
    yobina +
    '、最初と比べると少しずつフォームが変わってきたのではないでしょうか？';
  const head2 = `今回の動画は4級の目標である「${gradeGoalDict['4']}」`;
  let evaluation =
    score.eval === '◯合格'
      ? 'が大変良くできています！3級昇級おめでとうございます！ここまでよくがんばりましたね！'
      : 'に少しだけ課題があります。';
  return head1 + head2 + evaluation;
}

function crt_line2(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  console.log(score);
  return commentLogic(score, yobina);
}

const commentLogic = (score: ScoreEditType, yobina: string) => {
  if (score['前後傾'] === '×:前傾しすぎ') {
    return `${yobina}の一番の伸びしろポイントとして「体を少し前に倒しすぎている」ことがあります。体を前に倒すことは大事ですが、「こぶし半個分」というのを意識して、もう少しだけ体を起こすと、より走りやすくなると思います！目安として15m先の地面を見ながら走ってみましょう！これが出来ると足も前に返ってきやすくなりますよ！`;
  }

  if (score['腰が落ちていない'] === '×:落ちている（膝のつぶれ）') {
    return `まず${yobina}のよかった点としては、「腰を折らずに」走ることが出来ていますね！そんな${yobina}の伸びしろポイントとして「少し腰の位置が下がってしまっている」ことがあります。走り出す前の足踏みの時から走っている間まで、「キーホルダーになった気分で頭のてっぺんを上から紐でつられている」ようなイメージを持ってみて下さい！いい姿勢から背伸びした腰やお尻が高ーい位置のままポンポン走れるようになりますよ！`;
  }

  if (score['足の上がり'] === '×:足が上がっていない') {
    if (score['後ろに蹴っていない'] === '×:蹴ってしまっている') {
      return `まず${yobina}のよかった点としては、「腰を折らずに背の高いまま」走ることが出来ていますね！${yobina}の伸びしろポイントとして「後ろに地面を蹴って走っている」ことがあります。速く走りたいと思うとつい地面を後ろに蹴ってしまいがちですが、それをしてしまうと足が前で回らなくなってしまいます！自転車を立ちこぎするとき、ペダルを「下に踏んで」進みますよね？走るときも同じです！まずは6級でやった自転車を復習して、慣れてきたら「足があがった姿勢（ベースポジションのような姿勢）を空中で一瞬維持する（足が上がっていないとペダルを踏めませんよね？）」を意識して30mダッシュをしてみましょう！一気に楽に体が前に進むようになりますよ！`;
    }

    if (score['前後傾'] === '×:後傾しすぎ') {
      return `まず${yobina}のよかった点としては、「腰を折らずに背の高いまま」走ることが出来ていますね！${yobina}の伸びしろポイントとして「より足が前でおおきく動く」とよいです！そのために、「地面を下に踏む」感覚を覚えましょう！まずは「足があがった姿勢（ベースポジションのような姿勢）を空中で一瞬維持する」を意識して、慣れてきたら「上から空き缶を潰すように地面を踏む」ことを意識しましょう！一気に楽に体が前に進むようになりますよ！練習としてはベースポジションウォークや123ハイニーを「下に踏む意識で」やってみましょう！いい足音（高くて短い音）がなるか、もチェックポイントです！また足を頑張ってあげようと思うと、どうしても上半身が後ろにいってしまいますので、目安としてあごが上がらないように少し引くくらいの感じで15m先の地面を見ながら走ってみましょう！`;
    }

    return `まず${yobina}のよかった点としては、「腰を折らずに背の高いまま」走ることが出来ていますね！${yobina}の伸びしろポイントとして「より足が前でおおきく動く」とよいです！そのために、「地面を下に踏む」感覚を覚えましょう！まずは「足があがった姿勢（ベースポジションのような姿勢）を空中で一瞬維持する」を意識して、慣れてきたら「上から空き缶を潰すように地面を踏む」ことを意識しましょう！一気に楽に体が前に進むようになりますよ！練習としてはベースポジションウォークや123ハイニーを「下に踏む意識で」やってみましょう！いい足音（高くて短い音）がなるか、もチェックポイントです！`;
  }

  if (score['前後傾'] === '×:後傾しすぎ') {
    return `まず${yobina}のよかった点としては、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！${yobina}の伸びしろポイントとして「上半身が少し後ろに傾いている」ことがあります。足を頑張ってあげようと思うと、どうしても上半身が後ろにいってしまいますが、10級で習った「こぶし半個分だけ前に倒す」を意識しましょう！目安として10m先の地面を見ながら走ってみましょう！またあごが上がらないように少し引くくらいの感じで走ってみてもよいですね！体を少し前に倒せるようになると、まさに自転車の立ち漕ぎみたいに一気に前にスイスイ進むようになりますよ！`;
  }

  if (score['下に踏む'] === '×:体の遠くに接地') {
    return `まず${yobina}のよかった点としては、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！姿勢もばっちりです！そんな${yobina}に上級者向けの伸びしろポイントをお伝えすると「足を体の遠くについてしまっている」ことがあります。足を腰から遠く前に着いてしまうと1歩1歩ブレーキがかかってしまいます！自転車を立ちこぎするとき、ペダルは前でなく「下に」踏んで進みますよね？走るときも同じです！「上から空き缶を潰すように地面を踏む」ことを意識しましょう！足が体の近くにつけるようになり、楽に体が前に進むようになりますよ！練習としてはベースポジションウォークや123ハイニーを「下に踏む意識で」やっていましょう！いい足音（高くて短い音）がなるか、もチェックポイントです！これが出来ると「母趾球（ぼしきゅう）」あたりから地面につくのもやりやすくなりますよ！`;
  }

  if (score['接地様式'] === '×:かかとからついている') {
    return `まず${yobina}のよかった点としては、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！そんな${yobina}の一番の伸びしろポイントをお伝えすると「足をかかとからついてしまっている」ことがあります。トランポリンジャンプやその場足踏みでもかかとからつくとポンポンしづらいですよね？目安として「母趾球（ぼしきゅう）」あたりから地面につけるとよいです！また足を腰から遠く前に着いてしまうとかかとから着きやすくなってしまっています。体の近くにつくために「足を真下にポンポンおとして走る」ことを意識してみましょう！イメージは「上から空き缶を潰すように地面を踏む」ような感じです（腰は高く保ったままですよ）！ベースポジションウォークや123ハイニー、3ステップを「上から空き缶を潰すようなイメージで」やっていましょう（つま先も忘れずに）！いい足音（高くて短い音）がなるか、もチェックポイントです！`;
  }

  if (score['接地様式'] === '×:つま先だけでついている') {
    return `まず${yobina}のよかった点としては、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！そんな${yobina}の一番の伸びしろポイントをお伝えすると「足をつま先からつきすぎている」ことがあります。トランポリンジャンプやその場足踏みでもつま先からつきすぎるとポンポンしづらいですよね？目安として「母趾球（ぼしきゅう）」あたりから地面につけるとよいです！またこれに関してはベースポジションウォークの時のように「つま先をあげる」「足を真下にポンポンおとして走る」というのをできるようになると一気にうまくなる子が多いです！イメージは上から空き缶を潰すように地面を踏むような感じです！ベースポジションウォークや123ハイニー、3ステップを「上から空き缶を潰すようなイメージで」やっていましょう（つま先も忘れずに）！いい足音（高くて短い音）がなるか、もチェックポイントです！`;
  }

  if (score['前さばき'] === '×:出来ていない') {
    return `まず${yobina}のよかった点としては、だいぶ「あしがあがっている」姿勢がとれるようになってきましたね！正直${yobina}の走りは小学生のレベルを超えつつあります！なので中学生にいうようなレベルのアドバイスをするのですが「より足が前でおおきく動く」とよいです！そのために、「地面を下に踏む」「あしを空中で入れかえる」感覚を覚えましょう！「地面を下に踏む」に関しては、まずは「足があがった姿勢（ベースポジションのような姿勢）を空中で一瞬維持する」⇒慣れてきたら「上から空き缶を潰すように地面を踏む」ことを意識しましょう！練習としてはベースポジションウォークや123ハイニーを「下に踏む意識で」やってみましょう！いい足音（高くて短い音）がなるか、もチェックポイントです！「あしを空中で入れかえる」に関しては連続ハイニーや3ステップをたくさん復習してみて下さい！`;
  }

  if (score['うで振り'] === '×:悪い') {
    return `まず${yobina}のよかった点としては、「いい姿勢であしを前で回して」走ることが出来ていますね！よく練習を頑張ってくれたのが伝わります！そんな${yobina}の伸びしろポイントとしてずばり「腕振り」です！肩の力を抜いて下にポンポン振るようにして走ってみましょう！腕を正しく動かせると足もより楽に前で回せるようになりますし、体もポンポン楽に進むようになりますよ！腕振りはおうちでも取り組みやすい練習ですので、ぜひ習慣にしてみて下さい！`;
  }

  if (score['スピードが出せている'] === '×:遅すぎる') {
    return `4級まで頑張ってくれた${yobina}、もう走りのフォームはばっちりです！ここからの${yobina}に必要なのは、「全力を出す練習をたくさんする」ということです。ここからは今まで練習してきたフォームを染み込ませて、意識しなくても今のフォームで走れるようになるのが重要です。①保護者様が横で手拍子をしてリズムをとる（初めは1秒に4歩弱くらいのリズムで、なれてきたら1秒に4.5歩くらいまで行けるとすごいです！）②〇〇は手拍子に接地を合わせてポンポン走るというのにトライしてみて下さい！ポイントは「上がっている足を上からポンポン足を落とす」ことです！「上から空き缶を潰すように地面を踏む」ようなイメージで走ってみましょう！`;
  }

  return 'これはもう文句なしですね、すばらしいです！';
};

function crt_line3(score: ScoreEditType, yobina: string) {
  let evaluation =
    score.eval === '◯合格'
      ? 'それでは3級からはスタートの練習になります！スタートが上手になると50m走やスピード感が一気に変わってくるので、ぜひ練習メニューを楽しみにしていてください！'
      : '####いい具合になぐさめる####';
  return evaluation;
}
