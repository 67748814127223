import { RunnerType } from 'adapters/repositories/typeDefinition';
import { convertGradeByRunner, is10GradesPackage } from 'components/constants';
import { nonFukushuGrades } from '../GoalsForEachGrade/targetsForEachGrade';
import { grades, grades10 } from '../GradeGoalConst';
import { optionsFor10Grades } from './ten_grades_package/optionsFor10Grades';
import { optionsFor20Grades } from './twenty_grades_package/optionsFor20Grades';
import { optionDict_10kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/10kyu';
import { optionDict_9kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/9kyu';
import { optionDict_8kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/8kyu';
import { optionDict_7kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/7kyu';
import { optionDict_6kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/6kyu';
import { optionDict_5kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/5kyu';
import { optionDict_4kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/4kyu';
import { optionDict_3kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/3kyu';
import { optionDict_2kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/2kyu';
import { optionDict_1kyu_trac } from '../GoalsForEachGrade/CheckpointsForTrac10kyu/1kyu';

export const optionsForEachCkpt = (runner: RunnerType, grade: number) => {
  if (runner.student_group === 'newtrac') {
    const g = convertGradeByRunner(runner, grade) as grades10;
    return optionsForNewTrac[g];
  }
  if (is10GradesPackage(runner)) {
    const g = convertGradeByRunner(runner, grade) as grades10;
    return optionsFor10Grades[g];
  }

  // 以下、20級パッケージとして処理
  const g = convertGradeByRunner(runner, grade) as grades;
  //復習の級だった場合optionをweak_pointの級にする
  if (!nonFukushuGrades.includes(g)) {
    return optionsFor20Grades[runner.weak_point ?? '20'];
  }
  return optionsFor20Grades[g];
};

const optionsForNewTrac = {
  10: optionDict_10kyu_trac,
  9: optionDict_9kyu_trac,
  8: optionDict_8kyu_trac,
  7: optionDict_7kyu_trac,
  6: optionDict_6kyu_trac,
  5: optionDict_5kyu_trac,
  4: optionDict_4kyu_trac,
  3: optionDict_3kyu_trac,
  2: optionDict_2kyu_trac,
  1: optionDict_1kyu_trac,
};
