import { VideoType } from 'adapters/repositories/typeDefinition';

export function formatUploadDate(vObj: VideoType) {
  const date = new Date(vObj.uploaded_date);
  const convertedDate = new Date(date.setHours(date.getHours() + 0));
  const dateString = stringifyDate(
    convertedDate.getFullYear(),
    convertedDate.getMonth() + 1,
    convertedDate.getDate(),
  );
  return dateString;
}

export function extractUploadDate(objList: VideoType[]) {
  const dateList = objList.map((vObj) => {
    const date = new Date(vObj.uploaded_date);
    const convertedDate = new Date(date.setHours(date.getHours() + 0));
    const dateString = stringifyDate(
      convertedDate.getFullYear(),
      convertedDate.getMonth() + 1,
      convertedDate.getDate(),
    );

    return {
      day: dateString,
      grade: calcGrade(
        vObj.grade_before,
        vObj.stay_times,
        vObj.analysis_status === 'done',
      ),
    };
  });
  return dateList;
}

export function calcGrade(grade: number, st: number, isScored: boolean) {
  if (st === 0 && grade > 1 && isScored) {
    return grade - 1;
  }
  return grade;
}

export function sortVideoByDate(videoList: VideoType[]) {
  return videoList.sort((a, b) => {
    if (a.uploaded_date < b.uploaded_date) return 1;
    if (a.uploaded_date > b.uploaded_date) return -1;
    return 0;
  });
}

export const createCalendar = (year: number, month: number) => {
  const first = new Date(year, month - 1, 1).getDay();

  const last = new Date(year, month, 0).getDate();

  return [0, 1, 2, 3, 4, 5].map((weekIndex) => {
    return [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
      const day = dayIndex + 1 + weekIndex * 7;
      return day - 1 < first || last < day - first ? null : day - first;
    });
  });
};

export function stringifyDate(year: number, month: number, day: number) {
  return `${year}-${addZero(month)}-${addZero(day)}`;
}

export function addZero(num: number) {
  return num / 10 >= 1 ? String(num) : `0${num}`;
}

export function getVideoMonthly(
  videoList: VideoType[],
  year: number,
  month: number,
) {
  let retList = [];
  const yearMonth = `${year}-${addZero(month)}`;
  for (const video of videoList) {
    if (video.uploaded_date.indexOf(yearMonth) !== -1) {
      retList.push(video);
    }
    continue;
  }
  return retList;
}
