import React from 'react';
import challenge5th_thumb from 'components/MenuConst/thumbnails/challenge_5th.png';//未変更
import intro_5th_thumb from 'components/MenuConst/thumbnails/intro_5th.jpg';

import kabeoshimarch_thumb from 'components/MenuConst/thumbnails/kabeoshimarch.png';
import threeHighKnee_thumb from 'components/MenuConst/thumbnails/threeHighKnee_thumb.jpg';
import kabeoshi_Ade_thumb from 'components/MenuConst/thumbnails/kabeoshi_seq.png';
import kabeoshi3highknee_thumb from 'components/MenuConst/thumbnails/kabeoshi3highknee.png';
import lungecross_thumb from 'components/MenuConst/thumbnails/lungecross.jpg'
import skaterjump_thumb from 'components/MenuConst/thumbnails/skaterjump.jpg';
import dash_10m_thumb from 'components/MenuConst/thumbnails/dash_10m.jpg';

import { menuType } from 'pages/PracticePage/type';

import {
  kabeoshimarchVideos,
  re_threehighknee_5thVideos,
  re_kabeoshiAde5thVideos,
  kabeoshi3highkneeVideos,
  lungecrossVideos,
  skaterJump5thVideos,
  dash10m5thVideos,

} from './videos';

import { introVideoDictFor20Grades } from 'components/MenuConst/VideoLinks/introVideoLinks';

// up: 準備, tech: ぎじゅつ, run: 体力, family: 家族

// プレイリスト

////
//up
////
export const kabeoshimarch: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'かべおしマーチ',
  isInside: 'able',
  target: ['しせい', 'スタート'],
  menuVariation: ['up'],
  hitWordList: [
    'かべおし',
    '壁',
  ],
  grade: 15,
  image: kabeoshimarch_thumb,
  video: kabeoshimarchVideos,
};

export const re_threehighknee_5th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '3歩ハイニー',
  isInside: 'able',
  target: ['しせい', '足の動き'],
  menuVariation: ['up'],
  hitWordList: [
    '3',
    'ハイニー',
  ],
  grade: 15,
  image: threeHighKnee_thumb,
  video: re_threehighknee_5thVideos,
};

////
//tech
////

export const re_kabeoshiAde5th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'かべおし上級',
  isInside: 'able',
  target: ['しせい', 'スタート'],
  menuVariation: ['tech'],
  hitWordList: [
    'かべおし',
    '壁',
  ],
  grade: 15,
  image: kabeoshi_Ade_thumb,
  video: re_kabeoshiAde5thVideos,
};

export const kabeoshi3highknee: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'かべおし3歩ハイニー ☆昇級テスト',
  isInside: 'able',
  target: ['しせい', 'スタート'],
  menuVariation: ['tech'],
  hitWordList: [
    'かべおし',
    '壁',
  ],
  grade: 8,
  image: kabeoshi3highknee_thumb,
  video: kabeoshi3highkneeVideos,
};

////
//run
////

export const lungecross: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '入れかえジャンプ',
  isInside: 'able',
  target: ['スタート'],
  menuVariation: ['run'],
  hitWordList: [
    'いれかえ',
    '入れかえ',
    '入れ替え',
    'じゃんぷ',
    'ジャンプ',
  ],
  grade: 15,
  image: lungecross_thumb,
  video: lungecrossVideos,
};


export const skaterJump5th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'スケータージャンプ',
  isInside: 'unable',
  target: ['スタート'],
  menuVariation: ['run'],
  hitWordList: [
    'スケーター',
    'すけーたー',
  ],
  grade: 15,
  image: skaterjump_thumb,
  video: skaterJump5thVideos,
};


export const dash10m_5th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '10mダッシュ',
  isInside: 'unable',
  target: ['スタート'],
  menuVariation: ['run'],
  hitWordList: [
    'スタート',
    'すたーと',
    '10',
    'じゅう',
    'ダッシュ',
    'だっしゅ',
  ],
  grade: 15,
  image: dash_10m_thumb,
  video: dash10m5thVideos,
};


////
//family
////

//かけっこ診断、変形ダッシュは他の級より

////
//levelup
////

//12級の連続壁押しのままになっている、動画でき次第差し替え
export const challenge_5th: menuType = {
  duration: 5,
  hitWordList: ['5', '昇級', 'Y字', 'ワイ', 'Y', 'スプリント', 'タオル'],
  title: '5級の昇級用メニュー',
  grade: 15,
  menuVariation: ['levelUp'],
  image: challenge5th_thumb,
  isInside: 'unable',
  target: ['しせい'],
  video: [
    {
      link: 'https://www.youtube.com/embed/1jV1BqnPCyk',
      title: '5級の昇級用メニュー',
      message: (
        <span>
          5級の昇級テスト挑戦動画はコチラ！
          <br />
          ☆動画の指示に従って撮影し、画面右下の「+ボタン」から「昇級に挑戦」をタップして、動画を送信しよう！
          <br />
          ☆詳しい練習方法やポイントは「おすすめ」から確認！
        </span>
      ),
    },
  ],
};

export const intro_5th: menuType = {
  duration: 2,
  isInside: 'able',
  target: ['しせい'],
  hitWordList: ['5', '昇級', 'メッセージ', 'めっせーじ', 'じゅう'],
  title: '5級の方へのメッセージ',
  menuVariation: ['levelUp'],
  grade: 15,
  image: intro_5th_thumb,
  video: [
    {
      link: introVideoDictFor20Grades['5'],
      title: 'intro5th',
      message: <span>5級の方へのメッセージはコチラ！ </span>,
    },
  ],
};
