// 20級パッケージの5級（壁押し3歩）
type ckptTypeForGrade_15_20kyu =
  | 'よい姿勢'
  | '空中であしを入れかえる'
  | 'ピタッととまる'
  | '体をたおせているか';

export const ckptForGrade_15_20kyu: ckptTypeForGrade_15_20kyu[] = [
  'よい姿勢',
  '空中であしを入れかえる',
  'ピタッととまる',
  '体をたおせているか',
];

export const requirement_15_20kyu: ckptTypeForGrade_15_20kyu[] = [
  'よい姿勢',
  '空中であしを入れかえる',
  'ピタッととまる',
];

export type ckptDetails_15_20 =
  | '腕が地面と平行'
  | '腰の引け具合'
  | 'リーチアウト'
  | '足の上がり'
  | '足を空中で入れかえる'
  | 'ベースポジションで止まる'
  | '倒れ込み角度'
  // 採点用
  | 'かかとが浮いているか'
  | '前に進んでいるか'
  | '後ろへの蹴り上げ';


  type allCheckpoints_15_20kyu_type = {
    [key in ckptTypeForGrade_15_20kyu]: ckptDetails_15_20[];
  };

  export const allCheckPoints_15_20kyu: allCheckpoints_15_20kyu_type = {
    よい姿勢: ['腰の引け具合', '腕が地面と平行'],
    空中であしを入れかえる: ['足の上がり', '足を空中で入れかえる', 'リーチアウト'],
    ピタッととまる:['ベースポジションで止まる'],
    体をたおせているか: ['腰の引け具合', '倒れ込み角度'],
  };

  export const borderLine_15_20kyu: allCheckpoints_15_20kyu_type = {
    よい姿勢: ['腰の引け具合'],
    空中であしを入れかえる: ['足の上がり', '足を空中で入れかえる'],
    ピタッととまる:['ベースポジションで止まる'],
    体をたおせているか: ['腰の引け具合', '倒れ込み角度'],
  };

