import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, CircularProgress } from '@material-ui/core';

import { postResetPasswordInitiate } from '../../v1apiRequest';

export const ResetPasswordFirstPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [isUploading, setIsUploading] = React.useState(false);
  const [validationMessage, setValidationMessage] = React.useState('');
  const [isFormComplete, setIsFormComplete] = React.useState(false);

  React.useEffect(() => {
    const isAllFilled = String(email).length > 0;
    const isNoValidation = validationMessage.length === 0;

    if (isAllFilled && isNoValidation) {
      setIsFormComplete(true);
    } else setIsFormComplete(false);
  }, [email, validationMessage]);

  const handleMailChange = (e: any) => {
    setEmail(e.target.value);
    const reg =
      /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
    if (reg.test(e.target.value)) {
      setValidationMessage('');
    } else {
      setValidationMessage('emailアドレスが不適切です');
    }
  };

  const handleSendClick = () => {
    setIsUploading(true);
    postResetPasswordInitiate(email)
      .then((res) => {
        //alert('account created');
        setIsUploading(false);
        console.log(res.data);
        alert('認証メールを送信しました');
        //navigate('/relogin');
      })
      .catch((error) => {
        console.log(error);
        setIsUploading(false);
      });
  };

  const gotoLogin = () => {
    navigate('/login');
  };

  return (
    <div style={{ textAlign: 'center' }}>
      パスワード再設定
      <div style={{ padding: '20px' }}>
        ↓に入力されたメールアドレスに再設定用のリンクをお送りします。
        <div>
          <TextField
            style={{ marginTop: '20px', width: '70%' }}
            value={email}
            label="email"
            onChange={handleMailChange}
            helperText={validationMessage}
          />
        </div>
      </div>
      <div>
        {isFormComplete ? (
          isUploading ? (
            <Button variant="contained" disabled style={{ marginTop: '30px' }}>
              送信 <CircularProgress size={20} />
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '30px' }}
              onClick={handleSendClick}
            >
              送信
            </Button>
          )
        ) : (
          <Button variant="contained" disabled style={{ marginTop: '30px' }}>
            送信
          </Button>
        )}
      </div>
      <div style={{ marginTop: '30px' }}>
        <span className="textlink" onClick={gotoLogin}>
          ログインページへ戻る
        </span>
      </div>
    </div>
  );
};
