import { menuType } from 'pages/PracticePage/type';
import {
  swing_intro,
  basic_swing_3,
  basic_swing_1,
  basic_swing_2,
  armSwingTest,
} from './videos';

import arm_swing_intro from 'components/MenuConst/thumbnails/arm_swing_intro.png';
import arm_swing_a from 'components/MenuConst/thumbnails/arm_swing_a.png';
import arm_swing_b from 'components/MenuConst/thumbnails/arm_swing_b.png';
import arm_swing_c from 'components/MenuConst/thumbnails/arm_swing_c.png';
import arm_swing_sample from 'components/MenuConst/thumbnails/armswing_sample.png';

export const SwingIntro: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'うでふり かいせつ',
  isInside: 'able',
  target: ['腕ふり'],
  menuVariation: ['tech'],
  hitWordList: ['サポート', 'ジャンプ'],
  grade: 2,
  image: arm_swing_intro,
  video: swing_intro,
};

export const Swing_A: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'うでふりの練習 A',
  isInside: 'able',
  target: ['腕ふり'],
  menuVariation: ['tech'],
  hitWordList: ['うでふりの練習', 'A'],
  grade: 2,
  image: arm_swing_a,
  video: basic_swing_1,
};

export const Swing_B: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'うでふりの練習 B',
  isInside: 'able',
  target: ['腕ふり'],
  menuVariation: ['tech'],
  hitWordList: ['うでふりの練習', 'B'],
  grade: 2,
  image: arm_swing_b,
  video: basic_swing_2,
};

export const Swing_C: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'うでふりの練習 C',
  isInside: 'able',
  target: ['腕ふり'],
  menuVariation: ['tech'],
  hitWordList: ['うでふりの練習', 'C'],
  grade: 2,
  image: arm_swing_c,
  video: basic_swing_3,
};

export const ArmSwingTest: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'その場うでふり #昇級テスト',
  isInside: 'unable',
  target: ['腕ふり'],
  menuVariation: ['tech'],
  hitWordList: ['その場腕', 'そのばうでふり'],
  grade: 2,
  image: arm_swing_sample,
  video: armSwingTest,
};
