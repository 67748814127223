// TRAC10級パッケージの1級

export const ckptFor1kyu_trac10 = [
  'しせい',
  '足のつき方',
  '足をつく場所',
  'つま先の方向',
] as const;

export type ckpt_1kyu_type = typeof ckptFor1kyu_trac10[number];

export const requirement_1kyu_trac: ckpt_1kyu_type[] = [
  'しせい',
  '足のつき方',
  '足をつく場所',
  'つま先の方向',
];

type options = {
  [key in ckpt_1kyu_type]: string[];
};

export const optionDict_1kyu_trac: options = {
  しせい: ['◯良い', '×腰が落ちている', '×前屈している'],
  足のつき方: ['◯ちょうど良い', '×つま先接地すぎる', '×かかと接地'],
  足をつく場所: ['◯体の真下についている', '×体の前に接地'],
  つま先の方向: ['◯まっすぐ向いている', '×上がりすぎている', '×下がっている'],
};

type complimentType = {
  [key in ckpt_1kyu_type]: string;
};

// まずxxくんの良い点として、に続く文章を書く
export const complimentDict_1: complimentType = {
  しせい:
    '「良いしせいのまま走れている」点があります！何度も言っている通り、しせいは走りの中で一番大事なので、しっかりマスターできているのが素晴らしいです！',
  足のつき方:
    '上手に足をつけている点があります！しっかり「ぼしきゅう」のあたりで地面をとらえられていてとっても良いですね！',
  足をつく場所:
    '「しっかり体の真下に足をつけている」という点があります。ブレーキにならず、とても良い位置に足をつけていますね！',
  つま先の方向:
    '走るときにずっとつま先が前を向いている点があります！足をつく瞬間につま先が下に向いてしまう人が多いですが、そうなることがなくしっかり前に向けられているのが大変良いですね！',
};

export const nobishiroOptions_1_trac = Object.values(optionDict_1kyu_trac)
  .flat()
  .filter((value) => !value.includes('◯'));

type nobishiroDict = {
  [key in typeof nobishiroOptions_1_trac[number]]?: string;
};

// そんな〇〇くんの伸びしろポイントはズバリ、に続く文章を書く
export const nobishiroDict_1: nobishiroDict = {
  '×腰が落ちている':
    '「少し腰が落ちてしまっていること」です！スピードを出したいときこそ、まずはしせいを第一に意識しましょう！',
  '×前屈している':
    '「体を前にかたむけすぎている」ことです。スピードを出したいときこそ、まずはしせいを第一に意識しましょう！',
  '×つま先接地すぎる':
    '「足をつく場所がつま先がわに近すぎる」ことです！つま先で接地するという意識はとても良いですが、もう少しだけ土踏まずに近い部分で足をついてみましょう！',
  '×かかと接地':
    'かかとから足をついてしまっていることです。走る前にその場つま先ジャンプをして接地する位置（足をどこからつくか）を確認してみましょう！',
  '×下がっている':
    '「あしを上げたときに少しつま先が下がってしまっている」ことです！つま先が下がると強い力を加えられずブレーキになってしまうので、つま先をまっすぐに向けられるように練習しましょう！',
  '×上がりすぎている':
    '「つま先を上げすぎてしまっている」ことです。つま先を上げる意識づけはとても良いですが少し頑張りすぎなようです。つま先をあげすぎるとかかと接地になりやすいので、あくまでつま先は「まっすぐ」を心がけましょう！',
};
