import React, { FC } from 'react';
import { RunnerType, VideoType } from 'adapters/repositories/typeDefinition';
import { returnStandardsForTheRunner } from 'components/MenuConst/GoalsForEachGrade/standardsForEachGrade';
import { Grid } from '@material-ui/core';
import classes from '../coachComment.module.css';

type Props = {
  video: VideoType;
  runner: RunnerType | undefined;
};

export const SelfCheckTable: FC<Props> = ({ video, runner }) => {
  if (!runner) {
    return <></>;
  }
  const standards = returnStandardsForTheRunner(runner, video.grade_before);
  console.log(standards);

  return (
    <>
      <div
        style={{
          backgroundColor: '#dbebc4',
          padding: '10px',
          borderRadius: '10px',
          marginTop: '20px',
          width: '96%',
        }}
      >
        <span style={{ fontSize: '10pt', fontWeight: 'bold' }}>練習の感想</span>
        <Grid container alignItems="flex-end">
          <Grid xs={1}></Grid>
          <Grid item xs={11}>
            <div className={classes.kansou}>
              {!!video.video_memo && video.video_memo !== ''
                ? video.video_memo
                : '特になし'}
            </div>
          </Grid>
        </Grid>
      </div>
      {/* {standards.map((standard) => {
        return <div>{standard}</div>;
      })} */}
    </>
  );
};
