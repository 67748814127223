import React, { FC } from 'react';

import { Grid, GridSize } from '@material-ui/core';
import classes from './stepFunctions.module.css';

type VerticalLineProps = {
  idx: number;
};

export const VerticalLine: FC<VerticalLineProps> = ({ idx }) => {
  return (
    <>
      {idx === 0 && <></>}
      {idx === 1 && (
        <Grid item xs={1}>
          {idx > 0 ? <div className={classes.cascadingLine}>&nbsp;</div> : null}
        </Grid>
      )}
      {idx > 1 && (
        <>
          <Grid item xs={(idx - 1) as GridSize}></Grid>
          <Grid item xs={1}>
            <div className={classes.cascadingLine}>&nbsp;</div>
          </Grid>
        </>
      )}
    </>
  );
};

type SpacelLineProps = {
  idx: number;
  maxLen: number;
};

export const SpaceLine: FC<SpacelLineProps> = ({ idx, maxLen }) => {
  return (
    <Grid container>
      {idx === 0 && <div className={classes.cascadingSpace}></div>}
      {idx === 1 && (
        <>
          <Grid xs={1}></Grid>
          <Grid xs={1}>
            <div className={classes.cascadingSpace}></div>
          </Grid>
        </>
      )}
      {idx > 1 && idx < maxLen - 1 && (
        <>
          <Grid xs={idx as GridSize}></Grid>
          <Grid xs={1}>
            <div className={classes.cascadingSpace}></div>
          </Grid>
        </>
      )}

      <Grid xs={(10 - idx) as GridSize}></Grid>
    </Grid>
  );
};
