import React, { FC, useEffect } from 'react';
import { AccountContext } from 'contexts/AccountContext';
import { PageTitle } from 'components/common/PageTitle';
import { scrollToCheckbox, goToTags } from '../UploadPage/func';
import { ManualViewer } from '../UploadPage/components/ManualVideo';
import { UploadNotification } from 'components/Notifications';
import { TextInput } from '../UploadPage/components/TextInput';
import { ReplaceVideoView } from 'pages/LevelUpPage/components/ReplaceVideoPage';
import { useGetRunners } from './hooks/useGetRunners';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useVideoUploadComponent } from 'pages/UploadPage/hooks/useVideoUpload';
import { useCheckboxComponent } from 'pages/LevelUpPage/hooks/useCheckbox';
import { useActiveStep } from 'pages/LevelUpPage/hooks/useActiveStepViewer';

type alertStatusType = {
  open: boolean;
  status: 'OK' | 'ERROR' | 'default';
};

type inputEvent = React.ChangeEvent<HTMLInputElement>;
type handleInputChange = (e: inputEvent) => void;

export const CoachVideoSendPage: FC = () => {
  const { accountInfo } = React.useContext(AccountContext);
  const [isUploading, setIsUploading] = React.useState(false);
  const [formContent, setFormContent] = React.useState('');
  const [student, setStudent] = React.useState<RunnerType | undefined>(
    undefined,
  );
  const [alertStatus, setAlertStatus] = React.useState<alertStatusType>({
    open: false,
    status: 'default',
  });
  const { runners } = useGetRunners();

  const { activeStep, setActiveStep } = useActiveStep();

  const onChooseVideo = React.useCallback(() => {
    setActiveStep(1);
    scrollToCheckbox();
  }, []);

  const { file, uploadButton } = useVideoUploadComponent({
    onChooseVideo: onChooseVideo,
  });

  const handleFormChange: handleInputChange = (e) => {
    setFormContent(e.target.value);
    if (e.target.value.length < 1) {
      setActiveStep(1);
      return;
    }
    if (isChecked && e.target.value.length > 0) {
      setActiveStep(2);
      return;
    }
  };

  console.log(accountInfo);

  if (!accountInfo) {
    <>a</>;
  }

  const { isChecked, checkBox } = useCheckboxComponent();

  useEffect(() => {
    if (!file) {
      setActiveStep(0);
      return;
    }
    if (isChecked && formContent.length < 1) {
      setActiveStep(1);
      goToTags();
      return;
    }

    if (!isChecked && formContent.length > 0) {
      setActiveStep(1);
      return;
    }

    if (isChecked && formContent.length > 0) {
      setActiveStep(2);
      return;
    }
  }, [formContent, isChecked]);

  const params = new FormData();
  if (!!file && !!student && !!student.owner && activeStep >= 2) {
    const is_exam = true;
    params.append('content', file);
    params.append('fileName', file.name);
    params.append('uploaded_by', student.owner.id);
    params.append('upload_runner', student.id);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
    params.append('is_exam', is_exam);
    params.append('text', formContent);
    params.append('menuText', 'デフォルト');
  }

  const sendCompleteFuncs = () => {
    setActiveStep(0);
    alert('送信完了！');
  };

  const sendFailFuncs = () => {
    console.log('upload失敗');
    setIsUploading(false);
    setAlertStatus({ open: true, status: 'ERROR' });
  };

  const reibun = '（例）気持ちよく走れたが、足を上げられているか自信がない。';

  const handleChangeRunner = (event) => {
    const rId = event.target.value;
    const selectedStudent = runners.filter((r) => r.id === rId);
    setStudent(selectedStudent[0]);
  };
  console.log('selectedStudent=', student);

  return (
    <div>
      <PageTitle title={'生徒用動画送信画面'} />
      <UploadNotification
        alertStatus={alertStatus}
        setAlertStatus={setAlertStatus}
      />

      <div
        style={{
          textAlign: 'center',
          padding: '5px',
        }}
      >
        {!!runners && (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={(!!student && student.id) || undefined}
            label="生徒"
            onChange={handleChangeRunner}
          >
            {runners.map((r) => (
              <MenuItem value={r.id} key={r.id}>
                {r.last_name}
                {r.username}
              </MenuItem>
            ))}
          </Select>
        )}
      </div>
      <div style={{ textAlign: 'center' }}>
        <div>
          {uploadButton}
          <div
            style={{
              margin: '10px',
              marginTop: '50px',
              fontSize: '12pt',
              fontWeight: 'bold',
            }}
            id="checkboxes"
          >
            {(!!student && <ManualViewer runner={student} />) || <></>}
            {!!file && <>{checkBox}</>}
          </div>

          <div
            style={{
              textAlign: 'center',
              padding: '5px',
            }}
            id="tags"
          >
            {activeStep >= 1 ? (
              <>
                <TextInput
                  title="✏️先生への質問や練習の感想を書こう！"
                  reibun={reibun}
                  value={formContent}
                  onChange={handleFormChange}
                />
              </>
            ) : null}
          </div>

          <>
            <ReplaceVideoView
              isUploading={isUploading}
              formContent={formContent}
              activeStep={activeStep}
              params={params}
              completeFunc={sendCompleteFuncs}
              failFunc={sendFailFuncs}
            />
          </>

          <div style={{ marginTop: '200px' }}></div>
        </div>
      </div>
    </div>
  );
};
