import React from 'react';
import darumasan_thumb from 'components/MenuConst/thumbnails/darumasan_thumb.jpg';
import threeHighKnee_thumb from 'components/MenuConst/thumbnails/threeHighKnee_thumb.jpg';
import highknee_dash_thumb from 'components/MenuConst/thumbnails/highknee_dash.jpg';
import re_kabeoshi_thumb from 'components/MenuConst/thumbnails/kabeoshi_re.jpg';
import stepQuick_thumb from 'components/MenuConst/thumbnails/stepQuick.jpg';
import intro_8th_thumb from 'components/MenuConst/thumbnails/intro_8th.jpg';
import challenge8th_thumb from 'components/MenuConst/thumbnails/challenge_8th.jpg';
import {
  re_kabeoshiBasicVideos,
  darumasanVideos,
  threeHighKneeVideos,
  highKneeDashVideo,
  stepQuickVideo,
} from './videos';

export const re_kabeoshi = {
  grade: 2,
  duration: 10,
  title: 'かべおし復習',
  isInside: 'able',
  menuVariation: ['up'],
  image: re_kabeoshi_thumb,
  video: re_kabeoshiBasicVideos,
};

export const darumasan = {
  duration: 10,
  title: 'だるまさんがころんだハイニー',
  menuVariation: ['tech'],
  isInside: 'able',
  grade: 2,
  hitWordList: [
    'はいにー',
    'ハイニー',
    'だるまさん',
    'ダルマ',
    'いえ',
    '家',
    'おうち',
  ],
  image: darumasan_thumb,
  video: darumasanVideos,
};
export const threeHighKnee = {
  grade: 2,
  duration: 15,
  title: '3歩ハイニー☆昇級テスト',
  menuVariation: ['tech'],
  hitWordList: ['はいにー', 'ハイニー', '3', 'さんほ', 'いえ', '家', 'おうち'],
  image: threeHighKnee_thumb,
  video: threeHighKneeVideos,
};

export const highKneeDash = {
  grade: 2,
  duration: 10,
  title: 'たくさん走ろう！',
  hitWordList: ['ももあげ', 'だっしゅ', 'ダッシュ', 'ハイニー'],
  menuVariation: ['run'],
  image: highknee_dash_thumb,
  video: highKneeDashVideo,
};

export const stepQuick = {
  grade: 2,
  duration: 10,
  title: '階段クイック',
  isInside: 'able',
  hitWordList: [' かいだん', '階段'],
  menuVariation: ['family'],
  image: stepQuick_thumb,
  video: stepQuickVideo,
};

export const intro_8th = {
  duration: 2,
  hitWordList: ['8', '昇級', 'メッセージ', 'めっせーじ', 'はち', 'はっきゅう'],
  title: '8級の方へのメッセージ',
  grade: 2,
  image: intro_8th_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/2rYT4Tt3Gvk',
      title: '8級イントロ動画',
      message: <span>8級の方へのメッセージはコチラ！ </span>,
    },
  ],
  menu: (
    <div>
      <span style={{ textAlign: 'center' }}>練習C</span>

      <ul style={{ fontSize: '10pt', textAlign: 'left' }}>
        <li>動画1/5を見て、Y字姿勢の作り方を確認</li>
        <li>
          動画2/5～4/5を見て、「Y字しせいで20m走る」を、特に注意ポイント①を気をつけながら、(5本目安)行う！(タオルをご用意できる方は動画5/5を参考に、タオルを持った状態で行う！)
        </li>
      </ul>
    </div>
  ),
};

export const challenge_8th = {
  grade: 1,
  duration: 5,
  title: '8級の昇級用メニュー',
  hitWordList: [
    '8',
    '昇級',
    'かべ',
    '壁',
    'おし',
    '押し',
    '家',
    'はち',
    'はっきゅう',
  ],
  image: challenge8th_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/ctJ6sFe9rjg',
      title: 'お手本動画3歩ハイニー',
      message: <span>8級の昇級テスト挑戦動画はコチラ！</span>,
    },
  ],
  menu: (
    <div>
      <span style={{ textAlign: 'center' }}>練習A</span>
      <ul style={{ fontSize: '10pt', textAlign: 'left' }}>
        <li>
          動画1/6を見て、「壁を押しやすい、いい姿勢」を見つける！（角度は60度だと難しかったら75度くらいでもOK！！ご両親などにうまくできているかチェックしてもらおう！）
        </li>
        <li>
          動画2/6～6/6を見て、「壁押しももあげ」を正しい形で出来るようになるまで特訓（目安5分）！
          （付き添いの方がいましたら、手のひらを「太ももが90度上がったらちょうどあたる位置」においてあげてください！お子様は、その手のひらに太ももを当てるように練習するとやりやすいです！）
          <br />
          ※壁を強く押す意識をすると姿勢がくずれてしまう場合は、一旦意識しなくて大丈夫です！
          <br />
          ⇒慣れたら、「壁押しももあげ」を10歩×3セット行う！
        </li>
      </ul>
    </div>
  ),
};
