import React, { FC, useState } from 'react';
import Popover from '@mui/material/Popover';

export const usePopover = (Children: JSX.Element) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    handleClick: handleClick,
    CustomPopover: (
      <CustomPopover
        anchorEl={anchorEl}
        handleClose={handleClose}
        Children={Children}
      />
    ),
  };
};

type Props = {
  anchorEl: HTMLDivElement | null;
  handleClose: () => void;
  Children: JSX.Element;
};

const CustomPopover: FC<Props> = ({ Children, anchorEl, handleClose }) => {
  const id = Boolean(anchorEl) ? 'simple-popover' : undefined;
  return (
    <Popover
      id={id}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {Children}
    </Popover>
  );
};
