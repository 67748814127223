import React from 'react';
import { Button } from '@material-ui/core';
import classes from '../purchasepage.module.css';

export const FixBarButton = () => {
  return (
    <div className={classes.fixBar}>
      <div style={{ padding: '12px' }}>
        <Button
          color="primary"
          variant="contained"
          size="medium"
          onClick={scrollToApplyForm}
        >
          お申し込みはこちら！
        </Button>
      </div>
    </div>
  );
};

const scrollToApplyForm = () => {
  const targetElement = document.getElementById('applyForm');

  // 画面上部から要素までの距離
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const rectTop = targetElement.getBoundingClientRect().top;
  // 現在のスクロール距離
  const offsetTop = window.pageYOffset;
  // スクロール位置に持たせるバッファ
  const buffer = -100;

  const top = rectTop + offsetTop - buffer;

  window.scrollTo({
    top: top,
    behavior: 'smooth',
  });
};
