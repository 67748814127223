import React, { FC, memo } from 'react';
import classes from './onboardingCarousel.module.css';
import joy_girl from 'static/materials/joy_girl.png';
import practice from 'static/materials/practice.png';

export const SixthView: FC = memo(() => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '20%' }}>
          <img src={joy_girl} alt="" width="60%"></img>
        </div>
        <div style={{ width: '80%' }}>
          <div className={classes.yellowBorder}>
            やることは かんたん！
            <br />
            たったの2ステップ！
          </div>
        </div>
      </div>
      <div style={{ marginTop: '16px' }}>
        <img src={practice} alt="" width="85%"></img>
      </div>
    </>
  );
});
