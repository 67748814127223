import React from 'react';
import axios from 'axios';
import { Button, TextField, CircularProgress } from '@material-ui/core';
import { AccountContext } from 'contexts/AccountContext';
import { PageTitle } from '../components/common/PageTitle';

import '../index.css';
import '../App.css';

export const InquiryPage = () => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'accountInfo' does not exist on type 'Str... Remove this comment to see the full error message
  const { accountInfo } = React.useContext(AccountContext);

  React.useEffect(() => {
    console.log('アカウントページ');
  }, []);

  return (
    <div
      style={{
        textAlign: 'center',
        width: '99%',
        overflowY: 'scroll',
        height: `${window.parent.screen.height * 0.85}px`,
      }}
    >
      <PageTitle title={'お問い合わせ'} />

      <div>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; styele: { marginTop: st... Remove this comment to see the full error message */}
        <div styele={{ marginTop: '20px' }}>
          <InquiryForm email={accountInfo.email} user={accountInfo.username} />
        </div>
      </div>
      {/* <Button onClick={handleTest}>追加</Button> */}
    </div>
  );
};

export const InquiryForm = (props: any) => {
  const [formContent, setFormContent] = React.useState('');
  const [isUploading, setIsUploading] = React.useState(false);

  const handleChange = (e: any) => {
    setFormContent(e.target.value);
  };

  const handleSendClick = () => {
    console.log(formContent);
    setIsUploading(true);
    axios
      .post('https://hptpuuxeme.execute-api.us-east-1.amazonaws.com/prod', {
        message: formContent,
        email: props.email,
        userName: props.user,
      })
      .then((res) => {
        console.log(res);
        setIsUploading(false);
        alert('送信完了！');
        setFormContent('');
      })
      .catch((res) => {
        console.log(res);
        setIsUploading(false);
      });
  };

  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          padding: '5px',
        }}
      >
        <div style={{ padding: '6px' }}>お問い合わせフォーム</div>
        <span style={{ fontSize: '10pt' }}>
          （お使いになった感想も大歓迎です）
        </span>
        <TextField
          variant="outlined"
          label="お問い合わせ内容"
          style={{ width: '100%', marginTop: '26px' }}
          multiline
          placeholder="（例）動画がうまく見られない、操作が難しい、など"
          value={formContent}
          onChange={handleChange}
        />
      </div>
      <div>
        {isUploading ? (
          <Button variant="contained" disabled style={{ marginTop: '20px' }}>
            送信 <CircularProgress size={20} />
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '30px' }}
            onClick={handleSendClick}
          >
            送信
          </Button>
        )}
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
