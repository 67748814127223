import React, { FC, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';

type Props = {
  src: string;
  width: string;
  onClickFunc: any;
};

export const ImgWithLoading: FC<Props> = ({ src, width, onClickFunc }) => {
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const scWidth = window.parent.screen.width * 0.22;
  return (
    <>
      {isImgLoaded ? (
        <img src={src} width={width} alt="" onClick={onClickFunc}></img>
      ) : (
        <div style={{ width: width }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={scWidth}
            height={scWidth * 0.55}
          />
          <img
            src={src}
            width="0px"
            onLoad={() => setIsImgLoaded(true)}
            alt=""
          ></img>
        </div>
      )}
    </>
  );
};
