import { menuType } from 'pages/PracticePage/type';
import mountain_walker_thumb from 'components/MenuConst/thumbnails/mountain_walker_thumb.png';
import mountain_climber_thumb from 'components/MenuConst/thumbnails/mountain_climber_thumb.png';
import kabe_walk_thumb from 'components/MenuConst/thumbnails/kabe_walk_thumb.png';
import kabe_sample_thumb from 'components/MenuConst/thumbnails/kabe_sample_thumb.png';
import {
  kabe_walk,
  mountain_climber,
  mountain_walker,
  sequential_kabe,
} from './videos';

export const MountainWalker: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'マウンテンウォーカー',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['tech'],
  hitWordList: ['マウンテンウォーカー', 'まうんてんうぉ'],
  grade: 2,
  image: mountain_walker_thumb,
  video: mountain_walker,
};

export const MountainClimber: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'マウンテンクライマー',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['tech'],
  hitWordList: ['マウンテンクライマー', 'まうんてんくらいま'],
  grade: 2,
  image: mountain_climber_thumb,
  video: mountain_climber,
};

export const KabeWalk: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '壁おしウォーク',
  isInside: 'able',
  target: ['足の動き', 'スタート'],
  menuVariation: ['tech'],
  hitWordList: ['かべおしウォーク', '壁押し'],
  grade: 2,
  image: kabe_walk_thumb,
  video: kabe_walk,
};

export const KabeTest: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '連続かべおし #昇級テスト',
  isInside: 'able',
  target: ['足の動き', 'スタート'],
  menuVariation: ['tech'],
  hitWordList: ['連続かべおし', 'れんぞく'],
  grade: 2,
  image: kabe_sample_thumb,
  video: sequential_kabe,
};
