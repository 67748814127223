import { ckptDetails_16_20 } from 'components/MenuConst/GoalsForEachGrade/CheckpointsFor20Pkg/4_kyu';

type options = {
  [key in ckptDetails_16_20]: string[];
};

export const optionsForGrade_16_20pkg: options = {
  前後傾: ['◯:ちょうど良い', '◯:のけぞっている', '×:前傾しすぎ'],
  腰の引け具合: ['◯:良い', '×:ひけている', '×:反っている'],
  足が前に戻ってきている: ['◯:かんぺき', '◯:70点', '×:全然戻っていない'],
  後ろに蹴り上げている: ['◯:けっていない', '×:蹴り上げている'],
  リズムが早く大股走りになっている: [
    '◯:バウンディングになっている',
    '×:大股走り',
  ],
  はずめている: ['◯:ポンポンしている', '◯:70点', '×:つぶれぎみ'],
  膝がつぶれている: ['◯:固く使えている', '×:つぶれている'],
  前に進んでいる: ['◯:前に進んでいる', '×:進んでいない'],
  腕が使えている: ['◯:使えている', '×:使えていない'],
};
