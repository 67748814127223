import React, { FC } from 'react';
import { TabInfo } from 'components/TabInfo';
import { TimeViewer } from './TimeViewer';
import { VideoPlayer } from 'pages/ResultPage/components/VideoPlayerWithoutFrame';
import { Card } from '@material-ui/core';
import { ResultComponent } from 'pages/FiftyResultPage/components/ResultComponent';
import { SuggestMenu } from './SuggestMenu';

import { RunnerType, VideoType } from 'adapters/repositories/typeDefinition';
// eslint-disable-next-line
import { Excuse } from '../BasicInfo';

type Props = {
  runner: RunnerType | undefined;
  video: VideoType;
};

export const DiagnoseViewer: FC<Props> = ({ video, runner }) => {
  if (!video.score) {
    return (
      <>
        <Card>
          {' '}
          <VideoPlayer isLoading={false} videoInfo={video} />
        </Card>
        <TimeViewer video={video} />
      </>
    );
  }
  return (
    <TabInfo labels={['フォーム分析', '予想タイム', 'おすすめ練習']}>
      <>
        <div style={{ textAlign: 'center' }}>
          <ResultComponent video={video} />
        </div>
      </>

      <>
        <Card>
          {' '}
          <VideoPlayer isLoading={false} videoInfo={video} />
        </Card>
        <TimeViewer video={video} />
        <Excuse />
      </>
      <>
        <div style={{ textAlign: 'center' }}>
          <SuggestMenu video={video} runner={runner} />
        </div>
      </>
    </TabInfo>
  );
};
