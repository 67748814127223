import React, { FC } from 'react';
import {
  InputAdornment,
  IconButton,
  Input,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { AccountInfoType } from 'features/auth/types';

type inputEvent = React.ChangeEvent<HTMLInputElement>;
type handleInputChange = (e: inputEvent) => void;

type Props = {
  accountInfo: AccountInfoType;
  title: string;
  validationMsg: string;
  value: string;
  handleClickShowPassword: any;
  handleInfoChange: handleInputChange;
};

export const PasswordInput: FC<Props> = ({
  accountInfo,
  title,
  value,
  validationMsg,
  handleClickShowPassword,
  handleInfoChange,
}) => {
  return (
    <FormControl style={{ width: '70%', backgroundColor: 'white' }}>
      <InputLabel htmlFor="standard-adornment-password">{title}</InputLabel>
      <Input
        id="standard-adornment-password"
        value={value}
        type={accountInfo.showPassword ? 'text' : 'password'}
        onChange={handleInfoChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {accountInfo.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText id="standard-adornment-password" style={{ color: 'red' }}>
        {validationMsg}
      </FormHelperText>
    </FormControl>
  );
};
