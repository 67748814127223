import React from 'react';
import challenge4th_thumb from 'components/MenuConst/thumbnails/challenge_4th.jpg';
import dash_30m_4th_thumb from 'components/MenuConst/thumbnails/dash_30m.jpg';
import cycleDash_thumb from 'components/MenuConst/thumbnails/cycle_dash.png';
import intro_4th_thumb from 'components/MenuConst/thumbnails/intro_4th.jpg';
import { cycleDashVideos, dash30m4thVideos } from './videos';

export const dash_30m_4th = {
  duration: 15,
  grade: 6,
  title: '30mダッシュ#昇級テスト',
  image: dash_30m_4th_thumb,
  video: dash30m4thVideos,
  menuVariation: ['tech'],
};

export const cycle_dash = {
  title: '自転車',
  grade: 6,
  duration: 8,
  image: cycleDash_thumb,
  video: cycleDashVideos,
  menuVariation: ['run'],
};

export const challenge_4th = {
  duration: 5,
  hitWordList: ['4', '昇級', 'よん'],
  title: '4級の昇級用メニュー',
  grade: 6,
  image: challenge4th_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/Cta-XP0f0xE',
      title: '4級の昇級用メニュー',
      message: <span>4級の昇級テスト挑戦動画はコチラ！</span>,
    },
  ],
};

export const intro_4th = {
  duration: 2,
  hitWordList: ['4', '昇級', 'メッセージ', 'めっせーじ', 'よんきゅう'],
  title: '4級の方へのメッセージ',
  grade: 6,
  image: intro_4th_thumb,
  video: [
    {
      link: 'https://www.youtube.com/embed/nZppBE08D0o',
      title: '4級イントロ動画',
      message: <span>4級の方へのメッセージはコチラ！ </span>,
    },
  ],
};
