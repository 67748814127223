import {
  ScoreEditType, //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { RunnerType } from 'adapters/repositories/typeDefinition';
import { gradeTargetDict_20 } from 'components/MenuConst/GoalsForEachGrade/targetsForEachGrade';

export function crtComment4th_20(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  const setence1 = crt_line1(score, yobina);
  const sentence2 = crt_line2(runnerInfo, score, yobina);
  const sentence3 = crt_line3(score);
  return (
    setence1 +
    '<<paragraph>>' +
    sentence2 +
    '<<paragraph>>' +
    sentence3 +
    '<<paragraph>>'
  );
}

function crt_line1(score: ScoreEditType, yobina: string) {
  const head1 = yobina + '、バウンディングやってみていかがでしたか？';
  const head2 = `今回の動画は4級の目標である「${gradeTargetDict_20['4']}」`;
  let evaluation =
    score.eval === '◯合格'
      ? 'が大変良くできています！3級昇級おめでとうございます！'
      : 'に少しだけ課題があります。';
  return head1 + head2 + evaluation;
}

function crt_line2(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  console.log(score);
  return commentLogic_4th(score, yobina);
}

export const commentLogic_4th = (score: ScoreEditType, yobina: string) => {
  if (score['腰の引け具合'] !== '◯:良い') {
    if (score['前後傾'] === '×:前傾しすぎ') {
      return `体倒しすぎ`;
    }
    if (score['腰の引け具合'] === '×:反っている') {
      return `腰がそっている`;
    }

    return `腰が引けている`;
  }

  if (score['足が前に戻ってきている'] === '×:全然戻っていない') {
    if (score['後ろに蹴り上げている'] === '×:蹴り上げている') {
      return `・ほめ: 姿勢はある程度できている
      ・伸びしろ: 後ろにけっている
      ・改善策: 後ろに砂が跳ばないイメージで`;
    }

    if (score['リズムが早く大股走りになっている'] === '×:大股走り') {
      return `・ほめ: 姿勢はある程度できている
      ・伸びしろ: バウンディングになっていない（ただの大股走り）
      ・改善策: まずは前でなく上にポンポンはずむイメージで。動画の先生のお手本のリズムを見て真似しよう（隣で手拍子してもらったり自分でポンポンリズムを口ずさむをやりやすい！）`;
    }

    return `ほめ: 姿勢はある程度できている
    ・伸びしろ: 足が前に戻ってきていない
    ・改善策: いい具合にアドバイス`;
  }

  if (score['はずめている'] === '×:つぶれぎみ') {
    if (score['リズムが早く大股走りになっている'] === '×:大股走り') {
      return `・ほめ: 足はある程度もどってきている
      ・伸びしろ: バウンディングになっていない（ただの大股走り）
      ・改善策: まずは前でなく上にポンポンはずむイメージで。動画の先生のお手本のリズムを見て真似しよう（隣で手拍子してもらったり自分でポンポンリズムを口ずさむをやりやすい！）`;
    }

    if (score['膝がつぶれている'] === '×:つぶれている') {
      return `・ほめ: 足はある程度もどってきている
      ・伸びしろ: 膝がつぶれている
      ・改善策: 膝を固く使う、その場マリオジャンプやばね人間チャレンジをたくさんおこなう`;
    }

    return `ほめ: 足はある程度もどってきている
    ・伸びしろ: つぶれている
    ・改善策: いい具合にアドバイス`;
  }

  if (score['前に進んでいる'] === '×:進んでいない') {
    if (score['前後傾'] === '◯:のけぞっている') {
      return `・ほめ: ある程度はずめている
      ・伸びしろ: のけぞっていて前に進んでいない
      ・改善策: こぶし半個分たおす、10m先の地面を見る、かるーいのぼりでやってみる`;
    }

    if (score['腕が使えている'] === '×:使えていない') {
      return `・ほめ: ある程度はずめている
      ・伸びしろ: 腕が使えていないので前に進んでいない
      ・改善策: 前に前にふろう
      `;
    }

    return `ほめ: ある程度はずめている
    ・伸びしろ: 前に進んでいない
    ・改善策: いい具合にアドバイス（かるーいのぼりでやってみるとか20m何歩で行けるかチャレンジをたくさんするとか）`;
  }

  if (score['足が前に戻ってきている'] === '◯:70点') {
    return `・ほめ: ある程度はずめている
    ・伸びしろ: もっと足が戻ってくるとgood
    ・改善策: いい具合にアドバイス
    `;
  }

  if (score['はずめている'] === '◯:70点') {
    return `・ほめ: あしはばっちりもどってきている
    ・伸びしろ: もっとはずめるとパーフェクト
    ・改善策: いい具合にアドバイス`;
  }

  return 'いい具合にアドバイス';
};

function crt_line3(score: ScoreEditType) {
  let evaluation =
    score.eval === '◯合格'
      ? `3級では、今まで習ったことを実際の走りの中でできるかにチャレンジしてもらいます！がんばってみてください！`
      : '####いい具合になぐさめる####';
  return evaluation;
}
