import { grades, ScoreEditType } from 'components/MenuConst/GradeGoalConst';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { crtComment10th } from './crtComment10kyu';
import { crtComment9th } from './crtComment9kyu';
import { crtComment8th } from './crtComment8kyu';
import { crtComment7th } from './crtComment7kyu';
import { crtComment6th } from './crtComment6kyu';
import { crtComment5th } from './crtComment5kyu';
import { crtComment4th } from './crtComment4kyu';
import { crtComment3rd } from './crtComment3kyu';
import { crtComment2nd } from './crtComment2kyu';
import { crtComment1st } from './crtComment1kyu';

export const autoCommentForTrac10Pkg = (
  chosenVideo: VideoType,
  score: ScoreEditType,
  kyuOfVideo: grades,
) => {
  console.log(kyuOfVideo);
  const runnerInfo = chosenVideo.upload_runner;
  const runnerName = runnerInfo.username;
  const keisyo = runnerInfo.gender === '男性' ? 'くん' : 'ちゃん';
  const yobina = runnerName + keisyo;
  let comment = '';

  if (kyuOfVideo === '10') {
    comment = crtComment10th(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '9') {
    comment = crtComment9th(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '8') {
    comment = crtComment8th(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '7') {
    comment = crtComment7th(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '6') {
    comment = crtComment6th(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '5') {
    comment = crtComment5th(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '4') {
    comment = crtComment4th(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '3') {
    comment = crtComment3rd(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '2') {
    comment = crtComment2nd(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '1') {
    comment = crtComment1st(runnerInfo, score, yobina);
  }
  if (kyuOfVideo === '0') {
    comment = crtComment1st(runnerInfo, score, yobina);
  }

  return comment + crt_line4(chosenVideo.video_memo);
};

function crt_line4(video_memo: string | undefined) {
  if (!video_memo) {
    return '来週からはよかったら質問してね！！';
  }

  return `質問してくれた「${video_memo}」という点ですが、`;
}
