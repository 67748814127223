import React from 'react';

import { VideoSendButtonWithProgress } from './UploadButton';

export const ReplaceVideoView = ({
  videos = [],
  params,
  activeStep,
  completeFunc,
  formContent,
  failFunc,
}: any) => {
  const examVideo = detectReplaceOrNot(videos);

  if (!examVideo) {
    return (
      <VideoSendButtonWithProgress
        title="この動画で挑戦"
        activeStep={activeStep}
        color="secondary"
        completeFunc={completeFunc}
        failFunc={failFunc}
        params={params}
      />
    );
  }
  if (!!examVideo.thumbnail)
    return (
      <div style={{ paddingTop: '20px' }}>
        <img width="200px" alt="" src={`${examVideo.thumbnail}`} />
        <div
          style={{
            padding: '12px',
            textAlign: 'left',
          }}
        >
          下のボタンを押すと昇級にチャレンジする動画が
          <span
            style={{
              fontSize: '12pt',
              color: 'red',
              fontWeight: 'bold',
            }}
          >
            ↑
          </span>
          の動画から今回選択したものに変更されますがよろしいでしょうか？
        </div>

        <VideoSendButtonWithProgress
          title="動画を変更"
          activeStep={activeStep}
          color="secondary"
          completeFunc={completeFunc}
          failFunc={failFunc}
          params={params}
          formContent={formContent}
        />
      </div>
    );
  return <></>;
};

// もし挑戦中の動画があればその動画を返す
function detectReplaceOrNot(videos: any[]) {
  const examVideos = videos.filter(
    (video: any) => video.is_exam && video.analysis_status !== 'done',
  );
  if (examVideos.length > 0) return examVideos[0];
  return false;
}
