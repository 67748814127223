// 10級パッケージの6級
type ckptTypeForGrade_4_10kyu =
  | 'よい姿勢'
  | '足を体の前で回す'
  | '足のつき方'
  | 'スピードを出す';

export const ckptForGrade_4_10kyu: ckptTypeForGrade_4_10kyu[] = [
  'よい姿勢',
  '足を体の前で回す',
  '足のつき方',
  'スピードを出す',
];

export const requirement_4_10kyu: ckptTypeForGrade_4_10kyu[] = [
  'よい姿勢',
  '足を体の前で回す',
];

export type ckptDetails_4_10 =
  | '前後傾'
  | '後ろに蹴っていない'
  | '下に踏む'
  | '足の上がり'
  | '接地様式'
  | 'スピードが出せている'
  // 採点用
  | '腰が落ちていない'
  | '腕が地面と平行';

type allCheckpoints_4_10kyu_type = {
  [key in ckptTypeForGrade_4_10kyu]: ckptDetails_4_10[];
};

export const allCheckPoints_4_10kyu: allCheckpoints_4_10kyu_type = {
  よい姿勢: ['前後傾'],
  足を体の前で回す: ['後ろに蹴っていない', '足の上がり', '下に踏む'],
  足のつき方: ['接地様式'],
  スピードを出す: ['スピードが出せている'],
};

export const borderLine_4_10kyu: allCheckpoints_4_10kyu_type = {
  よい姿勢: ['前後傾'],
  足を体の前で回す: ['後ろに蹴っていない', '足の上がり'],
  足のつき方: ['接地様式'],
  スピードを出す: ['スピードが出せている'],
};
