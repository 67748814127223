import React, { FC } from 'react';
import CampaignIcon from '@mui/icons-material/Campaign';
import classes from './stepFunctions.module.css';

type checkPointType = {
  title: string;
  isBorderPassed: boolean;
  isAllPassed: boolean;
};

type Props = {
  ckpt: checkPointType;
  necessities: string[];
};

export const MessageBar: FC<Props> = ({ ckpt, necessities }) => {
  if (ckpt.isAllPassed) {
    return (
      <>
        <div className={classes.blockCorrect}>
          <div className={classes.iconExcellent}>★</div>
          <div className={classes.message}>{ckpt.title}</div>
        </div>
      </>
    );
  }

  if (ckpt.isBorderPassed) {
    return (
      <>
        <div className={classes.blockCorrect}>
          <div className={classes.iconGood}>👍</div>
          <div className={classes.message}>{ckpt.title}</div>
        </div>
      </>
    );
  }

  if (necessities.includes(ckpt.title)) {
    return (
      <>
        <div className={classes.blockError}>
          <div className={classes.icon}>
            <CampaignIcon style={{ color: 'white' }} />
          </div>
          <div className={classes.message}>{ckpt.title}</div>
        </div>
        <div className={classes.bottomArrow}></div>
      </>
    );
  }
  return (
    <>
      <div className={classes.blockAlert}>
        <div className={classes.icon}>
          <CampaignIcon style={{ color: 'white' }} />
        </div>
        <div className={classes.message}>{ckpt.title}</div>
      </div>
      <div className={classes.bottomArrow}></div>
    </>
  );
};
