import axios from 'axios';
import { HOST } from 'v1apiRequest';
import { LoginInfoType } from '../types';

export const sendLoginInfo = (params: LoginInfoType) =>
  axios.post(`${HOST}/auth/jwt/create/`, {
    email: params.email,
    password: params.password,
  });

export type LoginResponse = {
  access: string;
};
