import React, { FC, memo } from 'react';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { Grid } from '@material-ui/core';
import takei from 'static/coach-murai.png';
import iiduka from 'static/iiduka.png';
import classes from './coachComment.module.css';
import { CommentBody } from './CommentBody';

type Props = {
  video: VideoType;
};

export const CoachCommentViewer: FC<Props> = memo(({ video }) => {
  if (!video) {
    return <></>;
  }
  const coachComment =
    (!!video.score &&
      !!video.score.coach_comment &&
      video.score.coach_comment) ||
    '';
  console.log(!!video.score && video.score.coach_comment);
  const paragraphs = coachComment.split('<<paragraph>>');

  console.log(coachComment.split('<<paragraph>>'));
  const coachFig =
    (video.upload_runner.student_group === 'newtrac' && iiduka) || takei;
  return (
    <div
      style={{
        backgroundColor: '#dbebc4',
        padding: '10px',
        borderRadius: '10px',
        marginTop: '20px',
        width: '96%',
      }}
    >
      <div>
        <span style={{ fontSize: '10pt', fontWeight: 'bold' }}>練習の感想</span>
        <Grid container alignItems="flex-end">
          <Grid xs={1}></Grid>
          <Grid item xs={11}>
            <div className={classes.kansou}>
              {!!video.video_memo && video.video_memo !== ''
                ? video.video_memo
                : '特になし'}
            </div>
          </Grid>
        </Grid>
      </div>
      <div>
        {!!video.score && video.score.coach_comment ? (
          <div style={{ marginTop: '10px' }}>
            <Grid container alignItems="flex-end">
              <Grid item xs={1}>
                <img
                  className={classes.muraiIcon}
                  alt=""
                  src={coachFig}
                  width="90%"
                  style={{ borderRadius: '50%' }}
                />
              </Grid>
              <Grid xs={11}>
                <div style={{ marginLeft: '5px' }}>
                  <div className={classes.coachComment}>
                    <CommentBody comment={paragraphs[0]} />
                  </div>
                </div>
              </Grid>
            </Grid>

            {paragraphs.length > 1 &&
              paragraphs.map((p, i) => {
                if (i > 0) {
                  return (
                    <Grid container style={{ marginTop: '10px' }}>
                      <Grid xs={1}></Grid>
                      <Grid xs={11}>
                        <div style={{ marginLeft: '5px' }}>
                          <div className={classes.coachComment}>
                            <CommentBody comment={p} />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  );
                }
                return <></>;
              })}
          </div>
        ) : null}
      </div>
    </div>
  );
});
