import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';

export const BoundingVideos = [
  {
    link: 'https://www.youtube.com/embed/bP_AidOuQks',
    title: 'バウンディング',
    message: (
      <div>
        <div className={classes.title_important}>ふくしゅうのポイント</div>
        <div className={classes.content}>
          1. よい姿勢は作れているかな？
          <br />
          2. しっかりジャンプできているかな？ ☆ 一歩一歩で大きくはずもう！
          <br />
          3. 空中マリオポーズ ☆空中でリラックスしてマリオポーズを作ってみよう！
          <br />
          <br />
          ☆難しかったら最初は遠くに進もうとせずゆっくりやりましょう！まずはリズムをつかむのが大事です！リズムがわかってきたら、少しずつ遠くに跳ぶようにしてみましょう！
        </div>
      </div>
    ),
  },
];
