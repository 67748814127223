import React, { FC } from 'react';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import { ValidationMsgType } from './ErrorCheckLogic';
import { RegisterStepper } from './Stepper';
import { PasswordInput } from './components/PasswordInputForm';
import classes from './registerpage.module.css';
import { AccountInfoType, infoKeys } from 'features/auth/types';
import { HeightInput } from './components/HeightInput';
import { GenderInput } from './components/GenderInput';
import { BirthdayInput } from './components/BirthdayInput';
import { CustomizedTextInput } from './components/CustomizedTextInput';
import joy_family from 'static/materials/joy_family.png';

type inputEvent = React.ChangeEvent<HTMLInputElement>;
type handleInputChange = (keyName: infoKeys) => (e: inputEvent) => void;
type heightChangeFunc = (
  event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
) => void;

type Props = {
  accountInfo: AccountInfoType;
  handleInfoChange: handleInputChange;
  handleHeightChange: heightChangeFunc;
  validationMessage: ValidationMsgType;
  handleClickShowPassword: () => void;
  isFormComplete: boolean;
  isUploading: boolean;
  goToLogin: () => void;
  goToPrefilledLogin: () => void;
  handleSendClick: () => void;
};

export const SmartPhoneRegisterView: FC<Props> = ({
  accountInfo,
  handleInfoChange,
  handleHeightChange,
  validationMessage,
  handleClickShowPassword,
  isFormComplete,
  isUploading,
  goToLogin,
  goToPrefilledLogin,
  handleSendClick,
}) => {
  return (
    <div className={classes.registerWrapper}>
      {/* <div className={classes.registerBack}>
        <img src={register_background} alt="check" width="99%"></img>
      </div> */}
      <div className={classes.registerPage}>
        <RegisterStepper activeStep={0} />
        <div>
          <img src={joy_family} alt="" width="80%"></img>
          <br />
          <span
            style={{ fontWeight: 'bold', fontSize: '12pt', color: '#424242' }}
          >
            かけっこを楽しく学ぼう！
          </span>
        </div>
        <div style={{ textAlign: 'left', marginLeft: '10vw' }}>
          <CustomizedTextInput
            value={accountInfo.userName}
            label="&nbsp;おなまえ（下の名前）"
            handleChange={handleInfoChange('userName')}
            helperText={validationMessage.userName}
            placeHolder="（例）ひかる"
          />
          <CustomizedTextInput
            value={accountInfo.email}
            label="&nbsp;email"
            handleChange={handleInfoChange('email')}
            helperText={validationMessage.email}
            placeHolder=""
          />

          <div style={{ marginTop: '20px', maxWidth: '50vw' }}>
            <div style={{ marginBottom: '20px' }}>
              <GenderInput
                gender={accountInfo.gender}
                handleChange={handleInfoChange('gender')}
              />
            </div>

            <div style={{ marginBottom: '20px' }}>
              <HeightInput
                handleHeightChange={handleHeightChange}
                height={accountInfo.height}
              />
            </div>
          </div>
          <BirthdayInput
            accountInfo={accountInfo}
            handleInfoChange={handleInfoChange}
          />
          <div style={{ marginTop: '24px' }}>
            <CustomizedTextInput
              value={accountInfo?.student_group || ''}
              label="&nbsp;コースID"
              handleChange={handleInfoChange('student_group')}
              helperText={
                (!accountInfo.student_group &&
                  '特に指定がない場合は「特になし」と入力') ||
                ''
              }
              placeHolder="特になし"
            />
          </div>

          <div style={{ marginTop: '24px' }}>
            <PasswordInput
              title="&nbsp;パスワード"
              accountInfo={accountInfo}
              value={accountInfo.password}
              handleClickShowPassword={handleClickShowPassword}
              handleInfoChange={handleInfoChange('password')}
              validationMsg={validationMessage.password}
            />
          </div>
        </div>
        <div style={{ textAlign: 'left', marginLeft: '10vw' }}>
          {isFormComplete && isUploading && (
            <Button variant="contained" disabled style={{ marginTop: '30px' }}>
              登録 <CircularProgress size={20} />
            </Button>
          )}
          {isFormComplete && !isUploading && (
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '30px' }}
              onClick={handleSendClick}
            >
              登録
            </Button>
          )}
          {!isFormComplete && (
            <Button variant="contained" disabled style={{ marginTop: '30px' }}>
              登録
            </Button>
          )}
        </div>

        <div style={{ margin: '24px 0 12px 0' }}>
          <Typography style={{ fontSize: '9pt' }}>
            アカウントをお持ちの方は
            <span className="textlink" onClick={goToLogin}>
              ログイン
            </span>
            画面へ
          </Typography>
        </div>
        <div style={{ margin: '12px 0 60px 0' }}>
          <Typography style={{ fontSize: '9pt' }}>
            おためしアカウントでログインしたい方は
            <span className="textlink" onClick={goToPrefilledLogin}>
              コチラ
            </span>
            から
          </Typography>
        </div>
      </div>
    </div>
  );
};
