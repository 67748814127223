import React, { FC } from 'react';

import { Grid, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import '../calendar.css';

export type yearMonthType = {
  month: number;
  year: number;
};

type CalendarHeaderProps = {
  yearMonth: yearMonthType;
  setYearMonth: (yearMonth: yearMonthType) => void;
  monthlyTime: number;
};

export const CalendarHeader: FC<CalendarHeaderProps> = ({
  yearMonth,
  setYearMonth,
  monthlyTime,
}) => {
  const handleClick = (n: number) => () => {
    const nextMonth = yearMonth.month + n;
    if (nextMonth > 12) {
      setYearMonth({
        year: yearMonth.year + 1,
        month: 1,
      });
    } else if (nextMonth < 1) {
      setYearMonth({
        year: yearMonth.year - 1,
        month: 12,
      });
    } else {
      setYearMonth({
        year: yearMonth.year,
        month: nextMonth,
      });
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <IconButton onClick={handleClick(-1)} color="primary">
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8}>
          <div>
            <span style={{ fontSize: '14pt' }}>{yearMonth.year}</span>/ &nbsp;
            <span style={{ fontSize: '20pt' }}>{yearMonth.month}</span>月
          </div>
        </Grid>

        <Grid item xs={2}>
          <IconButton onClick={handleClick(1)} color="primary">
            <ArrowForwardIcon />
          </IconButton>
        </Grid>
      </Grid>
      <div
        style={{
          borderRadius: '5px',
          padding: '2px',
          backgroundColor: '#66ccff',
          color: 'white',
          display: 'flex',
          fontWeight: 'bolder',
          justifyContent: 'space-evenly',
        }}
      >
        {' '}
        <span>
          {yearMonth.month}月の練習回数: {monthlyTime}回
        </span>
      </div>
    </div>
  );
};
